import {
  Button, Grid
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoData from "../../../components/NoData";
import SnackBar from "../../../components/SnackBar";
import LineIcon from "../../../components/SVG/Line";
import CirclePluse from "../../../components/SVG/PluseIcon";
import MyContext from "../../../Provider/MyContext";
import {
  deleteCategories,
  fetchAddonCategories,
  fetchAllAddons, fetchAllCategories, fetchOrderTypes, getPizzaConfig,
  fetchDietaryAttributes
} from "../../../redux/actions";
import styles from "../../../styles/index";
import {
  AvenirBlackH3, AvenirBlackH4, H2, H4
} from "../../../utils/text";
import AlertDialog from "./../../../components/Dialog";
import CreateCategory from "./createCategory";
import SetPostionItem from "./setPostionItem";
const Categories = () => {
  const categories = useSelector((state) => state.menu.categories);
  const style = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const cats = _.sortBy(categories, [
    function (o) {
      return o.order;
    },
  ]);
  const catNames = categories.map((cat) => cat.name.toLowerCase());
  const [snackbar, setSnackbar] = useState(null);
  const [openSetPostion, togglePostionDrawer] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState("");
  const Context = useContext(MyContext);
  const [heading, setHeading] = useState(Context.langData.delete_item_cat);
  const [bodyMessage, setBodyMessage] = useState(
    Context.langData.delete_item_body
  );
  useEffect(() => {
    dispatch(fetchOrderTypes());
    dispatch(fetchAllCategories());
    dispatch(fetchAllAddons());
    dispatch(fetchAddonCategories());
    dispatch(getPizzaConfig());
    dispatch(fetchDietaryAttributes())
  }, []);
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      toggleCreateDrawer(false);
      toggleEditDrawer(false);
      togglePostionDrawer(false);
      setSnackbar({
        message: Context.langData.cat_create_succ,
        severity: "success",
      });
      dispatch(fetchAllCategories());
    } else {
      setSnackbar({
        message: Context.langData.category_create_failed,
        severity: "error",
      });
    }
  };
  const postionCallback = () => {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    togglePostionDrawer(false);
    dispatch(fetchAllCategories());
  };

  const deleteCallback = () => {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    setSnackbar({ message: Context.langData.delete_succ, severity: "success" });
    dispatch(fetchAllCategories());
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      toggleCreateDrawer(false);
      toggleEditDrawer(false);
      setSnackbar({
        message: Context.langData.cat_edit_succ,
        severity: "success",
      });
      dispatch(fetchAllCategories());
    } else {
      setSnackbar({
        message: Context.langData.category_edit_failed,
        severity: "error",
      });
    }
  };
  const edit = (cat) => {
    setSelectedItem(cat);
    toggleCreateDrawer(false);
    toggleEditDrawer(true);
    console.log("edit", cat);
  };
  const deleteCat = (cat) => {
    setId(cat.cat_id);
    setShowDialog(true);
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteCategories([id], deleteCallback));
    }
    setShowDialog(false);
  };
  const getDateFormat = (timestamp) => {
    var val = moment.unix(timestamp).format("MMMM DD, YYYY");
    return val;
  };
  return (
    <Grid
      data-qaid="categories_main_grid"
      container
      justify="flex-start"
      spacing={3}
      style={{ backgroundColor: "#FFFFFF", borderTop: "1px solid #EEEEEE" }}
    >
      <>
        {cats.length > 0 ? (
          <>
            <Grid
              data-qaid="categories_grid"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginRight: 20,
                marginBottom: 10,
                marginTop: 32,
                marginLeft: 32,
              }}
            >
              <Grid
                data-qaid="categories_grid"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <H2 label={Context.langData.categories} />
              </Grid>
              <Grid
                data-qaid="categories_grid"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Button
                  className={style.buttonClass}
                  data-qaid="categories_creategrid"
                  style={{
                    backgroundColor: "#00B6C9",
                    color: "white",
                    marginRight: 10,
                    height: 40,
                  }}
                  variant="contained"
                  id="cancel"
                  onClick={() => navigate("/home/menu/positions")}
                >
                  <AvenirBlackH4
                    data-qaid="categories_createlabel"
                    label={Context.langData.manage}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid="categories_creategrid"
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                    marginRight: 10,
                    height: 40,
                    width: 157,
                  }}
                  variant="contained"
                  id="cancel"
                  onClick={() => toggleCreateDrawer(true)}
                >
                  <CirclePluse data-qaid="categories_createpluse" />
                  <AvenirBlackH4
                    data-qaid="categories_createlabel"
                    label={Context.langData.create_category}
                    style={{ paddingLeft: 5 }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid
              data-qaid="categories_body_grid"
              item
              xs={12}
              style={{ marginLeft: 32, marginRight: 32, marginBottom: 30 }}
            >
              <Grid
                data-qaid="categories_child_grid"
                container
                justify="flex-start"
                spacing={3}
              >
                {cats.map((cat) => {
                  return (
                    <>
                      <Grid
                        data-qaid="categories_content_grid"
                        key={cat.cat_id}
                        style={{
                          border: `1px solid #C3C3C3`,
                          marginTop: 10,
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 5,
                          flexDirection: "row",
                          width: "100%",
                          height: 78,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/home/menu/itemsList", { state: cat })
                        }
                      >
                        <Grid
                          data-qaid="categories_child_grid"
                          item
                          xs={12}
                          lg={8}
                          sm={12}
                          md={8}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <AvenirBlackH3
                            data-qaid="categories_cat_name_label"
                            label={cat.name}
                            style={{ marginLeft: 32, marginRight: 16 }}
                          />
                          <LineIcon
                            data-qaid="categories_lineiocn"
                            style={{ marginRight: 16 }}
                          />
                          <H4
                            data-qaid="categories_lostmodified"
                            label={`${Context.langData.lastModified
                              } ${getDateFormat(cat.last_updated_on)}`}
                            style={{ color: "#54575A" }}
                          />
                        </Grid>
                        <Grid
                          data-qaid="categories_right_grid"
                          item
                          xs={12}
                          lg={4}
                          sm={12}
                          md={4}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: 32,
                          }}
                        >
                          {/* <RightVector data-qaid="categories_rightvector" /> */}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : (
          <NoData page="category" callback={() => toggleCreateDrawer(true)} />
        )}
      </>
      {snackbar && (
        <SnackBar
          data-qaid="categories_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}

      {openCreate && (
        <CreateCategory
          data-qaid="categories_createcategory"
          open={openCreate}
          catNames={catNames}
          callback={createCallback}
          onClose={() => toggleCreateDrawer(false)}
        />
      )}
      {openSetPostion && (
        <SetPostionItem
          data-qaid="categories_setpostion"
          open={openSetPostion}
          itemsList={cats}
          callback={postionCallback}
          onClose={() => togglePostionDrawer(false)}
        />
      )}
      <AlertDialog
        data-qaid="categories_alertDialog"
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </Grid>
  );
};

export default Categories;
