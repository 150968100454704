import * as React from "react"

const Menu = React.memo(({ width = 24, height = 24, color = "#fff", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 1.375a.5.5 0 00-.5.5v1.75h.5a.75.75 0 010 1.5h-.5v2.25h.5a.75.75 0 010 1.5h-.5v2.25h.5a.75.75 0 010 1.5h-.5v2.25h.5a.75.75 0 010 1.5h-.5v1.75a.5.5 0 00.5.5H17.5a.5.5 0 00.5-.5V1.875a.5.5 0 00-.5-.5H3.75zm-2 15v1.75a2 2 0 002 2H17.5a2 2 0 002-2V1.875a2 2 0 00-2-2H3.75a2 2 0 00-2 2v1.75h-.5a.75.75 0 000 1.5h.5v2.25h-.5a.75.75 0 000 1.5h.5v2.25h-.5a.75.75 0 000 1.5h.5v2.25h-.5a.75.75 0 000 1.5h.5zm7-10.625c-.517 0-1.125.53-1.125 1.437s.608 1.438 1.125 1.438 1.125-.531 1.125-1.438c0-.906-.608-1.437-1.125-1.437zM6.125 7.187c0-1.51 1.07-2.937 2.625-2.937 1.554 0 2.625 1.428 2.625 2.937 0 1.253-.737 2.449-1.875 2.82v4.368a.75.75 0 11-1.5 0v-4.368c-1.138-.371-1.875-1.567-1.875-2.82zM12.5 4.25a.75.75 0 01.75.75v3.125a.5.5 0 001 0V5a.75.75 0 011.5 0v3.125a2 2 0 01-1.25 1.854v4.396a.75.75 0 11-1.5 0V9.979a2 2 0 01-1.25-1.854V5a.75.75 0 01.75-.75z"
        fill={color}
      />
    </svg>
  )
})

export default Menu
