import * as React from "react"

const FBIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    style={{
      enableBackground: "new 0 0 20 20",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M19.9 10.1c0-5.5-4.4-9.9-9.9-9.9S.1 4.6.1 10.1c0 4.9 3.6 9 8.3 9.8V13H6v-2.9h2.5V7.9C8.5 5.4 9.9 4 12.2 4c1.1 0 2.2.2 2.2.2v2.4h-1.2c-1.2 0-1.6.8-1.6 1.5V10h2.7l-.4 2.9h-2.3v6.9c4.7-.7 8.3-4.8 8.3-9.7z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)

export default FBIcon