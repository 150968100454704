import React, { useState, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core'
import Header from './header';
import StepBar from '../StepBar';
import Input from '../Input';
import { AvenirBlackH2, H4 } from '../../../utils/text';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveAccountInfo } from '../../../redux/actions'
const AccountSettings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [storeId, setStoreId] = useState('')
  const [apiToken, setApiToken] = useState('');
  const accountSettings = useSelector(state => state.builder.accountSettings)
  const [checkoutId, setCheckoutId] = useState('')
  const [errors, setErrors] = useState({})
  useEffect(() => {
    setStoreId(accountSettings.store_id);
    setApiToken(accountSettings.api_token);
    setCheckoutId(accountSettings.checkout_id)
  }, [accountSettings])
  const onSave = useCallback((next = false) => {
    const schema = Yup.object().shape({
      storeId: Yup.string().required('Please input a valid Store ID.'),
      apiToken: Yup.string().required("Please input a valid API Token."),
      checkoutId: Yup.string().required("Please input a valid Checkout ID.")
    })
    const obj = { storeId, apiToken, checkoutId }
    schema.validate(obj, { abortEarly: false }).then((v) => {
      setErrors({});
      console.log(v)
      let d = {
        store_id: storeId,
        api_token: apiToken,
        checkout_id: checkoutId
      }
      dispatch(saveAccountInfo(d, (status) => {
        if (status) {

          if (next) {
            console.log("move to next", next)
            navigate('/builder/3')
          }
        }
      }))

    }).catch(err => {
      let errs = {};
      err.inner.map(inr => {
        errs[inr.path] = inr.errors[0]
      })
      setErrors(errs);
      console.log(err)
    })
  })
  const onNext = useCallback(() => {
    onSave(true)
  })
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <div style={{ flex: 3, width: '100%' }}>
        <Header
          step={1}
          onSave={onSave}
          onNext={onNext}
        />
      </div>
      <div style={{ flex: 17, width: '100%' }}>
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid item xs={12} lg={8}>
            <Grid item xs={12}>
              <StepBar currentStep={1} />
            </Grid>
            <Grid xs={12}>
              <Grid container style={{ backgroundColor: 'white', borderRadius: 10, padding: 48 }}>
                <Grid item xs={12} style={{ paddingBottom: 20, marginBottom: 30, borderBottom: '1px solid #EEEEEE' }}>
                  <AvenirBlackH2 label='Payment information' />
                </Grid>
                <Grid item xs={12}>
                  <H4 label='With Moneris Checkout, you can easily and securely process customer transactions on your website. If you have the reservation module, filling out the Store ID and API token fields below will enable the ability to charge customers for reservation deposits.' />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 20, marginTop: 20, marginBottom: 30, borderBottom: '1px solid #EEEEEE' }}>
                  <AvenirBlackH2 label='Your Moneris account' />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <span style={{ color: "#051d33", fontSize: 14, fontFamily: 'AvenirLT' }}>
                      Your store ID is provided in your welcome email from Moneris. <a href="https://www3.moneris.com/mpg/" target="_blank" style={{ color: "#306e7e", textDecoration: 'underline' }}>Click here</a> to go to the Merchant Resource Centre to obtain your API token.
                    </span>

                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Input
                      value={storeId}
                      onChange={(e) => setStoreId(e)}
                      id="storeid"
                      maxLength={256}
                      // hasRegex={true}
                      error={errors.storeId}
                      // info={<Info title="Store ID" info={" : received when you sign up for an account with Moneris"} />}
                      placeholder="Store ID*"
                      paddingLeft={0}
                      paddingRight={0}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Input
                      value={apiToken}
                      // hasRegex={true}
                      maxLength={256}
                      onChange={(e) => setApiToken(e)}
                      id="api"
                      // info={<Info title="API token" info={" : received when you sign up for an account with Moneris"} />}
                      error={errors.apiToken}
                      // hasRegex={true}
                      placeholder="API token*"
                      paddingLeft={0}
                      paddingRight={0}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Input
                      value={checkoutId}
                      maxLength={256}
                      onChange={(e) => setCheckoutId(e)}
                      id="checkout"
                      // hasRegex={true}
                      // info={<Info title="Checkout ID" info={" : received when you sign up for an account with Moneris"} />}
                      error={errors.checkoutId}
                      placeholder="Checkout ID*"
                      paddingLeft={0}
                      paddingRight={0}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AccountSettings
