import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import clsx from 'clsx';
import { fetchTracker } from '../../../redux/actions'
import { useSelector, useDispatch } from 'react-redux';
import RefreshButton from '../../../components/RefreshButton';
import EditIcon from '../../../components/SVG/Edit';
import { H3, TBold, H4, AvenirBlackH4 } from '../../../utils/text';
import IconButton from '@material-ui/core/IconButton';
import MyContext from '../../../Provider/MyContext';
import styles from '../../../styles/index';
import { ConvertPrice } from '../../../utils/util';
const Tracker = ({ classes, dates, trackerSetting }) => {
  const style = styles();
  const dispatch = useDispatch();
  const tracker = useSelector(state => state.dashboard.tracker);
  const [topSalesCategory, setTopSalesCategory] = useState(0);
  const [data, setData] = useState([])
  const [value, setValue] = useState(0);
  const Context = useContext(MyContext);
  const [buttonArray, setButtonArray] = useState([Context.langData.items, Context.langData.categories]);

  useEffect(() => {
    if (dates)
      dispatch(fetchTracker(dates))
  }, [dates])
  useEffect(() => {
    const data = tracker?.data || {};
    let items = [];
    if (topSalesCategory == 0) {
      items = data?.item_info || []
    }
    else {
      const dataList = data?.category_info
      let trackerId = dataList.filter((e) => tracker.cat_id_list.includes(e.cat_id))
      items = trackerId
    }
    setData(items);
  }, [tracker, topSalesCategory])
  const refresh = useCallback(() => {
    dispatch(fetchTracker(dates))
  }, [dates])
  const handleChange = (item) => {
    setValue(item);
    setTopSalesCategory(item)
  }
  return (
    <Paper data-qaid="tracker_paper" className={clsx(classes.paper, classes.noSidePadding, classes.relative, classes.chart)}>
      <Grid data-qaid="tracker_header_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Grid data-qaid="tracker_title_grid" item sm={12} lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 20 }}>
          <H3 data-qaid="tracker_heder_text" label={Context.langData.tracker}></H3>
        </Grid>
        <Grid data-qaid="tracker_header_icon_grid" item sm={12} lg={5} md={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <IconButton data-qaid="tracker_edit_icon_button" aria-label="tracker_settings" onClick={() => trackerSetting(true, value)}>
            <EditIcon data-qaid="tracker_edit_icon" color='#004C60' width='20' height='20'  ></EditIcon>
          </IconButton>
          <RefreshButton data-qaid="tracker_refresh_icon" refresh={refresh} />
        </Grid>
      </Grid>
      <Grid data-qaid="tracker_tab_main_grid" container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 29, paddingRight: 20 }}>
        <Grid data-qaid="tracker_tab_item_grid" className="trackerList" item sm={12} lg={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 20 }}>
          {
            buttonArray.map((item, i) => {
              return (
                <Button className={style.buttonClass} data-qaid="settings_button" style={{ backgroundColor: value == i ? '#051D33' : '#EEEEEE', marginRight: 1, borderRadius: 0, borderTopLeftRadius: i == 0 ? 5 : 0, borderBottomLeftRadius: i == 0 ? 5 : 0, borderTopRightRadius: buttonArray.length - 1 == i ? 5 : 0, borderBottomRightRadius: buttonArray.length - 1 == i ? 5 : 0 }} onClick={() => handleChange(i)}>
                  <AvenirBlackH4 data-qaid="settings_button_label" label={item} style={{ color: value == i ? '#FFFFFF' : '#051D33', paddingLeft: 8, paddingRight: 8, paddingTop: 10, paddingBottom: 10, lineHeight: '24px' }} />
                </Button>
              )
            }
            )
          }
        </Grid>
      </Grid>
      <Grid data-qaid="tracker_content_grid" container justify="flex-start" className={classes.paddingHorizontal}>
        <Grid data-qaid="tracker_content_grid1" item xs={5} className={clsx(classes.pad, classes.left)}>
        </Grid>
        <Grid data-qaid="tracker_content_grid2" item xs={2} className={clsx(classes.pad, classes.right)}>
          <H4 data-qaid="tracker_content_quantity" label={Context.langData.quantity} style={{ color: '#707272' }}>
          </H4>
        </Grid>
        <Grid data-qaid="tracker_content_grid3" item xs={5} className={clsx(classes.pad, classes.right)}>
          <H4 data-qaid="tracker_content_total" label={Context.langData.total} style={{ color: '#707272', paddingRight: 5 }}>
          </H4>
        </Grid>
      </Grid>
      <div style={{ height: 160, overflow: 'auto' }}>
        {
          data.map((sale, index) => {
            return (
              <Grid data-qaid={`tracker_data_grid"${index}`} container justify="flex-start" className={classes.paddingHorizontal} >
                <Grid data-qaid="tracker_data_map_name" item xs={5} className={clsx(classes.pad, classes.left)}>
                  <H4 data-qaid={`tracker_data_name ${index}`} label={sale.name} style={{ color: '#000000',wordBreak:'break-all' }} />
                </Grid>
                <Grid data-qaid="tracker_data_quantity" item xs={2} className={clsx(classes.pad, classes.right)}>
                  <H4 data-qaid={`tracker_data_quantity ${index}`} label={sale.quantity} style={{ color: '#000000' }} />
                </Grid>
                <Grid data-qaid="tracker_data_price" item xs={5} className={clsx(classes.pad, classes.right)} >
                  <H4 data-qaid={`tracker_data_finalPrice ${index}`} label={`$${ConvertPrice(sale.final_price)}`} style={{ color: '#000000', paddingRight: 5 }} />
                </Grid>
              </Grid>
            )
          })
        }
      </div>
    </Paper >
  )
}

export default React.memo(Tracker);
