import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Button, Grid, Card, CardContent, CardActions, IconButton, Typography, InputLabel, Select, MenuItem, Checkbox, Box
} from '@material-ui/core';
import DataTable from '../../../components/DataTable';
import styles from '../../../styles/index';
import RightDrawer from '../../../components/RightDrawer'
import InputText from '../../../components/InputText';
import {
    deletePayment,
    fetchPayments,
    createPaymets,
    EditPayments,
    fetchConfigPayments
} from '../../../redux/actions';
import VerticalTabs from '../../../components/verticalTabs';
import AlertDialog from './../../../components/Dialog';
import { truncate } from 'lodash';
import MaskedInput from 'react-text-mask';
import SimpleTable from '../../../components/SimpleTable';
import CirclePluse from '../../../components/SVG/PluseIcon';
import DeleteIcon from '../../../components/SVG/Delete';
import MyContext from '../../../Provider/MyContext';
import { H4, H2, H3, AvenirBlackH4, H8 } from '../../../utils/text';
import CenterModal from '../../../components/CenterModal';
import SnackBar from '../../../components/SnackBar';
import SettingIcon from '../../../components/SVG/Settings';
import CircleClose from '../../../components/SVG/CloseCircle';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    mainDiv: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '2%'

    }
}));

const PaymentList = () => {
    const style = styles();
    const classes = useStyles();
    const Context = useContext(MyContext);
    const columns = [
        { field: 'name', headerName: Context.langData.p_method, size: '100%' },
    ];
    const offlinecolumn = [
        { field: 'name', headerName: Context.langData.p_method, size: '40%' },
        { field: 'payment_name', headerName: Context.langData.per_charge, size: '30%' },
        { field: 'payment_name', headerName: Context.langData.service_tax, size: '30%' },
    ];
    const Payments = useSelector(state => state.settingsReducer.payments);
    const ConfigPayments = useSelector(state => state.settingsReducer.configPayments);
    const [selectedId, setSelectedId] = React.useState([]);
    const [btnDisable, setBtnDisable] = React.useState(true);
    const [openCreate, toggleCreateDrawer] = useState(false);
    const [openEdit, toggleEditDrawer] = useState(false);
    const [row, setRow] = useState({});
    const [selectedTab, setSelectedTab] = useState(true);
    const [selectedTitle, setSelectedTitle] = useState(Context.langData.custom);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [heading, setHeading] = useState(Context.langData.delete_payment);
    const [bodyMessage, setBodyMessage] = useState(Context.langData.delete_payment_body);
    const [snackbar, setSnackbar] = useState(null)

    const Tabs = [
        {
            name: Context.langData.custom,
            id: 0
        }, {
            name: Context.langData.online_gateway,
            id: 1
        }, {
            name: Context.langData.offline_gateways,
            id: 2
        }
    ]
    useEffect(() => {
        if (selectedTab) {
            indexCallback(0);
        } else {
            indexCallback(1);
        }
    }, [Payments])
    function onCheckedIds(ids) {
        setSelectedId(ids);
        if (ids.length > 0) {
            setBtnDisable(false);
        } else {
            setBtnDisable(true);
        }
    }
    function onClickAction(rowValue) {
        setRow(rowValue)
        toggleEditDrawer(true);
    }
    const btnProps = {};
    if (btnDisable) {
        btnProps["disabled"] = true
    }
    function commanCallback() {
        toggleCreateDrawer(false);
        toggleEditDrawer(false);
        dispatch(fetchPayments());
    }
    function createCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.payment_method_create_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.payment_method_create_failed, severity: 'error' });
        }
        commanCallback();

    } function editCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.payment_method_edit_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.payment_method_edit_failed, severity: 'error' });
        }
        commanCallback();
    }
    function deleteCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.payment_method_delete_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.payment_method_delete_failed, severity: 'error' });
        }
        commanCallback();
    }
    const handleClose = (val) => {
        if (val) {
            dispatch(deletePayment(JSON.stringify(selectedId), deleteCallback));
        }
        setShowDialog(false)
    }
    function deletePrinter(value) {
        setShowDialog(value)
    }
    function indexCallback(value) {
        const Index = Tabs.findIndex(element => element.id == value);
        setSelectedTitle(Tabs[Index].name);
        if (value == 0) {
            setSelectedTab(true);
        } else {
            setSelectedTab(false);
            dispatch(fetchConfigPayments());
        }
    }
    const onPageinationChange = (value) => {
        console.log("on page change")
    }
    return (
        <div data-qaid="paymentList_div"  >
            <Grid data-qaid="paymentList_grid" container direction="row" style={{ padding: 20 }}>
                <Grid data-qaid="paymentList_tabs" item xs={12} lg={2} style={{ backgroundColor: '#FAFAFA' }}>
                    <VerticalTabs data-qaid="paymentList_vtabs" tabData={Tabs} indexCallback={indexCallback} style={{ marginTop: 24 }}></VerticalTabs>
                </Grid>

                {selectedTab ?
                    < Grid data-qaid="paymentList_addbutton" item xs={12} lg={10} style={{ marginTop: 24 }} >

                        <Grid data-qaid="paymentList_create" item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginRight: 20, marginBottom: 10 }} >
                            <Button className={style.buttonClass} data-qaid="paymentList_createbutton" style={{ backgroundColor: "#FFB600", color: "white", marginRight: 10 }} variant="contained" id="cancel" onClick={() => toggleCreateDrawer(true)}  >
                                <CirclePluse data-qaid="paymentList_create_icon" />
                                <AvenirBlackH4 data-qaid="paymentList_createlabel" label={Context.langData.create_payment} style={{ paddingLeft: 5 }} />
                            </Button>
                            <Button className={style.buttonClass} data-qaid="paymentList_delete" {...btnProps} style={{ backgroundColor: btnDisable == true ? '#EEEEEE' : '#FFB600', color: "white" }} variant="contained" id="cancel" onClick={() => deletePrinter(true)} >
                                <DeleteIcon data-qaid="paymentList_deleteicon" width={16} height={16} style={{ marginRight: 5 }} />
                                <AvenirBlackH4 data-qaid="paymentList_deletelabel" label={Context.langData.delete} />
                            </Button>

                        </Grid>
                        <DataTable data-qaid="paymentList_datatable" style={{ marginRight: 10 }} columns={columns} rows={Payments} onCheckedIds={onCheckedIds} onClickAction={onClickAction} onPageChange={onPageinationChange} />

                    </Grid>
                    : < Grid data-qaid="paymentList_onliegrid" item xs={12} lg={10}>

                        <SimpleTable data-qaid="paymentList_simpletable" columns={offlinecolumn} rows={ConfigPayments}></SimpleTable>
                    </Grid>
                }
            </Grid>
            {
                openCreate &&
                <CreatePayment
                    data-qaid="paymentList_createpayment"
                    open={openCreate}
                    onClose={() => toggleCreateDrawer(false)}
                    payments={Payments}
                    callback={createCallback}
                />
            }
            {
                openEdit &&
                <EditPaymentDrawer
                    data-qaid="paymentList_editpayment"
                    open={openEdit}
                    row={row}
                    payments={Payments}
                    onClose={() => toggleEditDrawer(false)}
                    callback={editCallback}
                />
            }
            <AlertDialog
                data-qaid="paymentList_alertDialog"
                showDialog={showDialog}
                body={bodyMessage}
                heading={heading}
                IsError={false}
                handleClose={handleClose} />
        </div >
    );
};
const CreatePayment = ({ open, callback, onClose, payments }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [enable, setEnable] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const save = () => {
        if (payments.indexOf(paymentMethod.toLowerCase().trim()) == -1) {
            dispatch(createPaymets({ payment_name: paymentMethod.trim(), is_pending: enable ? 1 : 0 }, callback))
            setError("");
        }
        else {
            setError(paymentMethod + " already exist");
        }
    }

    const onNameChange = (val) => {
        setPaymentMethod(val)
        if (payments.indexOf(val.toLowerCase()) == -1) {
            setError("")
        }
        else {
            setError(val + " already exist");
        }
    }
    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.create_payment_method} />
                        <Grid container direction="column" style={{ marginLeft: 56, marginRight: 56 }}>
                            <Grid data-qaid="payment_namegrid" item xs={10} lg={10} sm={10} md={10} style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, marginRight: 32 }}>
                                <AvenirBlackH4 data-qaid="payment_namelabel" label={Context.langData.p_method} style={{ marginBottom: 12 }} />
                                <InputText
                                    value={paymentMethod}
                                    error={error}
                                    onChange={(val) => onNameChange(val.target.value)}
                                    id="payment_method" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="createoffer_action_grid" container style={{ backgroundColor: '#FAFAFA', border: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="createoffer_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} onClick={onClose} variant="contained" style={{ backgroundColor: '#00B6C9', marginRight: 16 }} >
                                    <AvenirBlackH4 data-qaid="createoffer_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="createoffer_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="createoffer_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )
}

const EditPaymentDrawer = ({ open, row, callback, onClose, payments }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [enable, setEnable] = useState(false);
    const [error, setError] = useState(null);
    const [id, setId] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        if (row) {
            setId(row.payment_id);
            if (row.is_pending == 1) {
                setEnable(true);
            } else {
                setEnable(false);
            }
            setPaymentMethod(row.payment_name);

        }
    }, [])
    const save = () => {
        if (payments.indexOf(paymentMethod.toLowerCase().trim()) == -1) {
            dispatch(EditPayments({ payment_name: paymentMethod.trim(), is_pending: enable ? 1 : 0, payment_id: id }, callback))
            setError("");
        }
        else {
            setError(paymentMethod + " already exist");
        }
    }

    const onNameChange = (val) => {
        setPaymentMethod(val)
        if (payments.indexOf(val.toLowerCase()) == -1) {
            setError("")
        }
        else {
            setError(val + " already exist");
        }
    }
    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.edit_payment_method} />

                        <Grid container direction="column" style={{ marginLeft: 56, marginRight: 56 }}>
                            <Grid data-qaid="editpayment_namegrid" item xs={10} lg={10} sm={10} md={10} style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, marginRight: 32 }}>
                                <AvenirBlackH4 data-qaid="editpayment_namelabel" label={Context.langData.p_method} />
                                <InputText
                                    data-qaid="editpayment_nameip"
                                    value={paymentMethod}
                                    error={error}
                                    onChange={(val) => onNameChange(val.target.value)}
                                    id="payment_method" />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="editpayment_action_grid" container style={{ backgroundColor: '#FAFAFA', border: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="editpayment_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="editpayment_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#00B6C9', marginRight: 16 }} >
                                    <AvenirBlackH4 data-qaid="editpayment_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="editpayment_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="editpayment_save_text" label={Context.langData.edit} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )
}


export default PaymentList;