import React from 'react';
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux';
import InstagramIcon from '../../../components/SVG/InstagramIcon';
import TwitterIcon from '../../../components/SVG/TwitterIcon';
import FacebookIcon from '../../../components/SVG/FBIcon';

const SocialIcons = () => {
  const { theme, info } = useSelector(state => state.builder);
  const openNewTab = (url) => {
    console.log(url)
    if (url) {
      if (url.indexOf("http") == -1) {
        url = "https://" + url
      }
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }
  const color = theme.color_pallete?.link_color || '#004C60';
  return (
    <div style={{ width: 36, height: 124, backgroundColor: 'transparent' }}>
      <Grid container >
        <Grid item xs={12} style={{ height: 36, backgroundColor: color }}>
          <Grid container justify="center" alignItems="center" style={{ height: 36, cursor: 'pointer' }} onClick={() => openNewTab(info?.social_media?.instagram_url)}>
            <InstagramIcon />
          </Grid>

        </Grid>
        <Grid item xs={12} style={{ height: 36, marginTop: 8, marginBottom: 8, backgroundColor: color }}>
          <Grid container justify="center" alignItems="center" style={{ height: 36, cursor: 'pointer' }} onClick={() => openNewTab(info?.social_media?.twitter_url)}>
            <TwitterIcon />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ height: 36, backgroundColor: color }}>
          <Grid container justify="center" alignItems="center" style={{ height: 36, cursor: 'pointer' }} onClick={() => openNewTab(info?.social_media?.facebook_url)}>
            <FacebookIcon />
          </Grid>

        </Grid>
      </Grid>


    </div>
  )
}

export default SocialIcons