import React, { useState, useCallback, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid,
} from '@material-ui/core';
import VerticalTabs from '../../../components/verticalTabs';
import moment from 'moment';
import Consolidated from './consolidated';
import Kitchens from './kitchens';
import Vendors from './Vendors';
import Purchases from './purchases';
import Consumptions from './consumptions';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAllVendors,
    fetchDepartments
} from '../../../redux/actions';
import MyContext from '../../../Provider/MyContext';
const InventoryReport = () => {
    const useStyles = makeStyles({
        btnApply: {
            backgroundColor: '#f0585f',
            color: 'white',
            marginLeft: 10
        },
        btnClear: {
            backgroundColor: '#dcdcdc',
            color: 'black'
        },

    });
    const classes = useStyles();
    const Context = useContext(MyContext);
    const dispatch = useDispatch();
    const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
    const callback = (value) => {
        setSelectIndexTabChange(value);
    }
    useEffect(() => {
        dispatch(fetchAllVendors());
        dispatch(fetchDepartments());
    }, []);
    const Tabs = [
        { name: Context.langData.consolidated, id: 0 },
        { name: Context.langData.consumption, id: 1 },
        { name: Context.langData.kitchens, id: 2 },
        { name: Context.langData.vendors, id: 3 },
        { name: Context.langData.purchases, id: 4 },
    ]
    console.log(Tabs)
    return (
        <Grid container data-qaid="inventoryreport_index_cardcontent">
            <Grid data-qaid="inventoryreport_index_grid" container>
            </Grid>

            <Grid data-qaid="inventoryreport_index_bidy_grid" container style={{ padding: 20 }}>
                <Grid data-qaid="inventoryreport_index_tab_grid" item xs={12} lg={2} style={{ backgroundColor: '#FAFAFA' }}>
                    <VerticalTabs data-qaid="inventoryreport_index_tabs" tabData={Tabs} indexCallback={callback} />
                </Grid>
                <Grid data-qaid="inventoryreport_index_content_grid" item xs={12} lg={10} style={{ paddingLeft: '10px' }}>
                    {
                        selectIndexTabChange == 0 &&
                        <Consolidated data-qaid="inventoryreport_index_consolodateted" />
                    }
                    {
                        selectIndexTabChange == 1 &&
                        <Consumptions data-qaid="inventoryreport_index_consumptions" />
                    }
                    {
                        selectIndexTabChange == 2 &&
                        <Kitchens data-qaid="inventoryreport_index_kitchens" />
                    }
                    {
                        selectIndexTabChange == 3 &&
                        <Vendors data-qaid="inventoryreport_index_vendors" />
                    }
                    {
                        selectIndexTabChange == 4 &&
                        <Purchases data-qaid="inventoryreport_index_purchases" />
                    }
                </Grid>

            </Grid>
        </Grid>
    )
}

export default InventoryReport;