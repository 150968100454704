import * as React from "react";

function MinusIcon(props) {
  return (
    <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.895752C10 1.06834 9.86009 1.20825 9.6875 1.20825H0.3125C0.139911 1.20825 0 1.06834 0 0.895752C0 0.723163 0.139911 0.583252 0.3125 0.583252H9.6875C9.86009 0.583252 10 0.723163 10 0.895752Z" fill="#CCCCCC"/>
    </svg>


  );
}

export default MinusIcon;
