import {
	IconButton,
	Typography,
	createStyles,
	withStyles
} from "@material-ui/core";
import { combine } from "../utils";



const styles = (theme) =>
	createStyles({
		leftBorderRadius: {
			borderRadius: "50% 0 0 50%"
		},
		rightBorderRadius: {
			borderRadius: "0 50% 50% 0"
		},
		buttonContainer: {
			display: "flex"
		},
		button: {
			height: 36,
			width: 36,
			padding: 0
		},
		buttonText: {
			lineHeight: 1.6,
			color: "#051D33",
			fontSize: 12,
			fontFamily: 'AvenirLT'
		},
		outlined: {
			// border: `1px solid ${theme.palette.primary.dark}`
		},
		filled: {
			"&:hover": {
				backgroundColor: "#92D2E7"
			},
			backgroundColor: "#92D2E7"
		},
		highlighted: {
			backgroundColor: "#EEEEEE"
		},
		contrast: {
			color: theme.palette.primary.contrastText
		},
		buttonDisabledText: {
			color: "#c3c3c3"
		}
	});

const Day = props => {
	const { classes,width } = props;
	const w = width || 36;
	return (
		<div
			style={{
				width:w,height:w
			}}
			className={combine(
				classes.buttonContainer,
				props.startOfRange && classes.leftBorderRadius,
				props.endOfRange && classes.rightBorderRadius,
				!props.disabled && props.highlighted && classes.highlighted
			)}>
			<IconButton
			style={{
				width:w,height:w
			}}
				className={combine(
					classes.button,
					!props.disabled && props.outlined && classes.outlined,
					!props.disabled && props.filled && classes.filled
				)}
				disabled={props.disabled}
				onClick={props.onClick}
				onMouseOver={props.onHover}>
				<Typography
					// color={!props.disabled ? "initial" : "textSecondary"}
					className={combine(
						classes.buttonText,
						props.disabled ? classes.buttonDisabledText : ""
					)}
					variant="body2">
					{props.value}
				</Typography>
			</IconButton>
		</div>
	);
};

export default withStyles(styles)(Day);
