import * as React from "react"

const Settings = React.memo(({ width = 24, height = 24, color = "#fff", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.597 1.33a1.007 1.007 0 00-1.133.256l-.002.002-.786.862-.001.002a2.256 2.256 0 01-3.35-.001l-.001-.001-.781-.863a1.01 1.01 0 00-1.596.138c-.111.175-.166.38-.156.587l.056 1.166A2.257 2.257 0 013.475 5.85l-1.164-.059a1.01 1.01 0 00-1.03 1.226c.045.202.152.386.306.525l.866.787a2.262 2.262 0 01.55 2.592 2.258 2.258 0 01-.55.76l-.867.782a1.007 1.007 0 00.728 1.752h.001l1.166-.06a2.255 2.255 0 012.37 2.37l-.06 1.164v.001a1.008 1.008 0 001.751.723l.787-.866a2.26 2.26 0 013.35 0l.782.864.001.002a1.007 1.007 0 001.749-.725v-.002l-.06-1.162a2.258 2.258 0 012.37-2.37l1.166.06a1.007 1.007 0 00.728-1.754l-.869-.783a2.26 2.26 0 010-3.352l.868-.784a1.008 1.008 0 00-.725-1.753l-1.167.059a2.257 2.257 0 01-2.364-2.368l.06-1.166a1.008 1.008 0 00-.621-.983zM12.715.06a2.257 2.257 0 012.75 2.317l-.058 1.166a1.006 1.006 0 001.054 1.056l1.167-.059a2.258 2.258 0 011.625 3.93l-.869.783a1.008 1.008 0 000 1.497l.868.783a2.258 2.258 0 01-1.628 3.93l-1.166-.06A1.009 1.009 0 0015.4 16.46l.06 1.17a2.257 2.257 0 01-3.92 1.625l-.004-.002-.784-.868a1.01 1.01 0 00-1.496 0l-.788.868a2.26 2.26 0 01-3.575-.307 2.257 2.257 0 01-.35-1.315v-.003l.06-1.167a1.007 1.007 0 00-1.057-1.057l-1.167.06a2.258 2.258 0 01-2.32-2.748c.103-.455.343-.867.688-1.18l.868-.783a1.009 1.009 0 000-1.497l-.868-.788A2.257 2.257 0 012.37 4.542h.002l1.167.06a1.006 1.006 0 001.058-1.06V3.54l-.056-1.167A2.26 2.26 0 017.29.062c.454.102.865.34 1.177.684l.001.001.785.866a1.006 1.006 0 001.494 0l.003-.002.786-.863.001-.001a2.257 2.257 0 011.178-.688z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.876a3.125 3.125 0 100 6.25 3.125 3.125 0 000-6.25zM5.625 10a4.375 4.375 0 118.75 0 4.375 4.375 0 01-8.75 0z"
        fill={color}
      />
    </svg>
  )
})

export default Settings
