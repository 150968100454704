import React, { useContext } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import RightDrawer from '../../../components/RightDrawer';
import InputText from '../../../components/InputText';
import { useDispatch } from 'react-redux';
import MyContext from '../../../Provider/MyContext';
import { saveVendor } from '../../../redux/actions';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AvenirBlackH4, H4, H8, AvenirBlackH3, H5 } from '../../../utils/text';
import InventoryIcon from '../../../components/SVG/InventoryIcon';
import CircleClose from '../../../components/SVG/CloseCircle';
import styles from '../../../styles/index';
import InputMaskComponent from '../../../components/TextInputMask';
import ProvinceData from '../../profile/customers/province';
import SingleSelect from '../../../components/SingleSelect';
import ExclamationIcon from '../../../components/SVG/ExclamationIcon';
const useStyles = makeStyles((theme) => ({
  formStyle: {
    display: 'flex',
    height: '100% !important',
  },
}));
const CreateVendor = ({ open, onClose, callback }) => {
  const classes = useStyles();
  const style = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Context = useContext(MyContext);

  const validateSchema = () => {
    const validate = Yup.object().shape({
      name: Yup.string()
        .max(255)
        .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Please enter valid text")
        .required(Context.langData.vendeor_req),
      email: Yup.string()
        .email(Context.langData.email_valid_error)
        .max(255)
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z\u00C0-\u00FF]{2,}$/, Context.langData.email_valid_error)
        .required(Context.langData.email_required_error),
      mobile: Yup.string()
        .min(14, Context.langData.phone_valid_error)
        .required(Context.langData.phone_req_error),
      province: Yup.string()
        .max(255)
        .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Please enter valid text")
        .required(Context.langData.province_required_error),
      postal_code: Yup.string()
        .min(7, Context.langData.invalid_postal_code)
        .required(Context.langData.postalcode_required_error),
      street_number: Yup.string()
        .max(255)
        .matches(/^[0-9]+$/, "Please enter valid number")
        .required(Context.langData.street_number_req),
      street_name: Yup.string()
        .max(255)
        .matches(/^[a-zA-Z0-9\s\u00C0-\u00FF]+$/, "Please enter valid text")
        .required(Context.langData.street_name_req),
      unitNumber: Yup.string()
        .max(255)
        .matches(/^[0-9]*$/, "Please enter valid number"),
      city: Yup.string()
        .max(255)
        .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Please enter valid text")
        .required(Context.langData.city_req),
    });
    return validate;
  };  

  return (
    <RightDrawer data-qaid="createtaxe_centermodal" open={open} padding={1}>
      <Formik
        initialValues={{
          name: '',
          email: '',
          mobile: '',
          address: '',
          street_number: '',
          street_name: '',
          unitNumber: '',
          province: '',
          city: '',
          postal_code: '',
        }}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          if (values.mobile.length >= 14)
            dispatch(
              saveVendor(
                {
                  profile: {
                    vendorName: values.name,
                    email: values.email,
                    phoneNumber:
                      values.mobile == null
                        ? null
                        : values.mobile.replace(/-/g, ''),
                    address: {
                      streetNumber: values.street_number,
                      streetName: values.street_name,
                      unitNumber: values.unitNumber,
                      city: values.city,
                      province: values.province,
                      postalCode:
                        values.postal_code == null
                          ? null
                          : values.postal_code.replace(/ /g, ''),
                    },
                  },
                },
                (status) => {
                  callback(status);
                }
              )
            );
        }}>
        {({
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          values,
          errors,
          touched,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit} className={classes.formStyle}>
            <Grid
              style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                width: 500,
              }}>
              <Grid
                style={{
                  display: 'flex',
                  height: '90%',
                  flexDirection: 'column',
                  overflowX: 'auto',
                }}>
                <Grid
                  data-qaid="createcategory_close_grid"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                  }}>
                  <CircleClose
                    data-qaid="createcategory_closeicon"
                    size={20}
                    onClick={onClose}
                    style={{ marginRight: 36, marginTop: 20 }}
                  />
                </Grid>
                <Grid
                  data-qaid="createcategory_settings_grid"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginLeft: 36,
                    marginBottom: 10,
                  }}>
                  <InventoryIcon
                    data-qaid="createcategory_setting_icon"
                    style={{ marginRight: 10 }}
                    width={22.5}
                    height={24}
                    color="#707272"
                  />
                  <H4
                    data-qaid="createcategory_settings"
                    label={Context.langData.inventory}
                    style={{ color: '#707272' }}
                  />
                </Grid>
                <H8
                  data-qaid="createcategory_title"
                  style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }}
                  label={Context.langData.vendor_text}
                />
                <Grid
                  data-qaid="createVendor_mainContainer"
                  style={{
                    flexDirection: 'column',
                    marginLeft: 36,
                    marginRight: 36,
                  }}>
                  <AvenirBlackH3
                    data-qaid="createVendor_vendorInfoLabel"
                    label={Context.langData.vendor_info}
                    style={{
                      borderBottom: '1px solid #EEEEEE',
                      paddingBottom: 10,
                    }}
                  />
                  <Grid
                    data-qaid="createVendor_vendorNameGrid"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      data-qaid="createVendor_vendorNameLabel"
                      label={Context.langData.vendor_name}
                    />
                    <InputText
                      data-qaid="createtaxe_catinput"
                      value={values.name}
                      maxLength={64}
                      onChange={handleChange}
                      helperText={touched.name && errors.name}
                      error={touched.name && errors.name}
                      name="name"
                      id="name"
                    />
                  </Grid>
                  <Grid
                    data-qaid="createVendor_emailLabelView"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      data-qaid="createVendor_emailLabel"
                      label={Context.langData.email}
                    />
                    <InputText
                      data-qaid="createtaxe_catinput"
                      value={values.email}
                      maxLength={64}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={touched.email && errors.email}
                      name="email"
                      id="email"
                    />
                  </Grid>
                  <Grid
                    data-qaid="createVendor_phoneLabelView"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      data-qaid="createVendor_phoneNumberLabel"
                      label={Context.langData.mobile}
                      style={{ marginBottom: 5 }}
                    />
                    <InputMaskComponent
                      id="mobile"
                      value={values.mobile}
                      regex="phone"
                      name="mobile"
                      helperText={touched.mobile && errors.mobile}
                      error={touched.mobile && errors.mobile}
                      onBlur={(error) => {
                        setFieldTouched('mobile', true, true);
                        setFieldError('mobile', error);
                      }}
                      isFormik={true}
                      handleChange={(val) => {
                        setFieldValue('mobile', val);
                      }}
                      isVendor={true}
                    />
                  </Grid>
                  <AvenirBlackH3
                    data-qaid="createVendor_vendorLabel"
                    label={Context.langData.vendor_add}
                    style={{
                      borderBottom: '1px solid #EEEEEE',
                      marginTop: 20,
                      paddingBottom: 10,
                    }}
                  />
                  <Grid
                    data-qaid="createVendor_countryLabelView"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      data-qaid="createVendor_unitNumberLabel"
                      label={Context.langData.unit_number}
                    />
                    <InputText
                      data-qaid="unitNumber"
                      value={values.unitNumber}
                      maxLength={32}
                      helperText={touched.unitNumber && errors.unitNumber}
                      error={touched.unitNumber && errors.unitNumber}
                      onChange={handleChange}
                      name="unitNumber"
                      id="unitNumber"
                    />
                  </Grid>
                  <Grid
                    data-qaid="createVendor_streetNumberLabelView"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      data-qaid="createVendor_streetNumberLabel"
                      label={Context.langData.street_number}
                    />
                    <InputText
                      data-qaid="createtaxe_catinput"
                      value={values.street_number}
                      maxLength={64}
                      helperText={touched.street_number && errors.street_number}
                      error={touched.street_number && errors.street_number}
                      onChange={handleChange}
                      name="street_number"
                      id="street_number"
                    />
                  </Grid>
                  <Grid
                    data-qaid="createVendor_streetNameLabelView"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      data-qaid="createVendor_streetNameLabel"
                      label={Context.langData.street_name}
                    />
                    <InputText
                      data-qaid="createtaxe_catinput"
                      value={values.street_name}
                      maxLength={64}
                      helperText={touched.street_name && errors.street_name}
                      error={touched.street_name && errors.street_name}
                      onChange={handleChange}
                      name="street_name"
                      id="street_name"
                    />
                  </Grid>
                  <Grid
                    data-qaid="createVendor_cityLabelView"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      data-qaid="createVendor_city"
                      label={Context.langData.city}
                    />
                    <InputText
                      data-qaid="createtaxe_catinput"
                      value={values.city}
                      maxLength={32}
                      helperText={touched.city && errors.city}
                      error={touched.city && errors.city}
                      onChange={handleChange}
                      name="city"
                      id="city"
                    />
                  </Grid>
                  <Grid
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <AvenirBlackH4
                      label={Context.langData.province_state}
                      style={{ marginBottom: 5 }}
                    />
                    <SingleSelect
                      minWidth={'100%'}
                      data-qaid="alltrancation_ordertype_singleselect"
                      list={ProvinceData}
                      value={values.province}
                      valueKey="value"
                      displayKey="name"
                      placeholder="Select"
                      border={false}
                      height={56}
                      handleChange={(province) => {
                        setFieldValue('province', province.target.value);
                      }}
                    />
                    {Boolean(touched.province && errors.province) ? (
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-end',
                          marginLeft: 0,
                        }}>
                        <ExclamationIcon
                          color="#AB1212"
                          style={{ marginRight: 10 }}
                        />
                        <H5
                          label={errors.province}
                          style={{
                            color: '#AB1212',
                            display: 'flex',
                            margin: '3px 0px 0px 0px',
                          }}>
                          {' '}
                        </H5>
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid
                    data-qaid="createVendor_postalcodeLabelView"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingBottom: 20,
                    }}>
                    <AvenirBlackH4
                      label={Context.langData.postal_code}
                      style={{ marginBottom: 5 }}
                    />
                    <InputMaskComponent
                      helperText={touched.postal_code && errors.postal_code}
                      error={touched.postal_code && errors.postal_code}
                      style={{ marginTop: 20 }}
                      fullWidth
                      value={values.postal_code}
                      regex="postal"
                      maxLength={7}
                      onBlur={(error) => {
                        setFieldTouched('postal_code', true, true);
                        setFieldError('postal_code', error);
                      }}
                      handleChange={(val) => {
                        setFieldValue('postal_code', val);
                      }}
                      id="postal_code"
                      placeholder="X1X 1X1"
                      isVendor={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'sticky',
                }}>
                <Grid
                  data-qaid="createmore_action_grid"
                  container
                  style={{ backgroundColor: '#FAFAFA' }}>
                  <Grid
                    data-qaid="createmore_cancel_btn"
                    item
                    xs={12}
                    lg={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 32,
                      marginBottom: 32,
                    }}>
                    <Button
                      className={style.buttonClass}
                      data-qaid="createVendor_cancelButton"
                      style={{
                        backgroundColor: '#FFF',
                        marginRight: 10,
                        width: 103,
                        height: 40,
                        border: '2px solid #051D33',
                      }}
                      variant="contained"
                      id="cancel"
                      onClick={onClose}>
                      <AvenirBlackH4
                        data-qaid="createVendor_cancelButton"
                        label={Context.langData.cancel}
                      />
                    </Button>
                    <Button
                      className={style.buttonClass}
                      data-qaid="createVendor_saveButton"
                      style={{
                        backgroundColor: '#FFB600',
                        marginRight: 10,
                        width: 90,
                        height: 40,
                      }}
                      variant="contained"
                      id="cancel"
                      type="submit"
                      onClick={handleSubmit}>
                      <AvenirBlackH4
                        data-qaid="createVendor_saveLabel"
                        label={Context.langData.save}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </RightDrawer>
  );
};

export default CreateVendor;
