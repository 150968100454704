import Lottie from "react-lottie";
import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useSelector } from 'react-redux';
import AnimationData from '../utils/BlueDotsLoading.json';
import {
    Grid
} from '@material-ui/core';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
};
const Loader = () => {
    let loading = useSelector(state => state.loader.loading)
    return (
        <Modal
            data-qaid="centermodal_modal"
            open={loading}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <Lottie width={300} height={300} options={defaultOptions} />
            </Grid>
        </Modal>

    )
};

export default Loader;