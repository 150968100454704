import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { generateRecipeWorkBook, uploadRecipe } from './recipe';
import moment from 'moment';
import { setHeaderStyle, setNormalRowStyle } from './workbook';
import {
  downloadSalesReport,
  downloadItemSales,
  downloadDailySales,
  downloadAlcoholSales,
  downloadAddonSales,
  downloadEmpSales,
  downloadGroupsData,
  downloadPettyCashReport,
  downloadContactTracing,
  downloadModifierSales,
} from './salesReport';
import { downloadTransactions, downloadPurchases } from './transactions';
import {
  downloadConsolidateReport,
  downloadConsumptionReport,
  downloadKitchenReport,
  downloadVendorsReport,
  downloadPurchasesReport,
} from './inventoryReports';
import {
  downloadCustomers,
  downloadEmployees,
  uploadCustomers,
} from './profile';
import { downloadEodReport } from './eodReports';
const downloadAs = async (workbook, fileName = 'sample.xlsx') => {
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, fileName);
};
const getWookBook = () => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'MGA Merchant portal';
  return workbook;
};
const writeToExcel = async () => {
  // const state = {
  //   data: [
  //     [1, 2, 3, 4, 5, 6, 7],
  //     [2, 3, 4, 5, 6, 7, 8]
  //   ],
  //   cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */
  // }
  // const ws = XLSX.utils.aoa_to_sheet(state.data);
  // const wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  /* generate XLSX file and send to client */
  // XLSX.writeFile(wb, "sheetjs.xlsx");
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'MGA Merchant portal';
  const worksheet = workbook.addWorksheet('My Sheet');

  worksheet.columns = [
    { header: 'Id', key: 'id', width: 10 },
    { header: 'Name', key: 'name', width: 100 },
    { header: 'D.O.B.', key: 'dob', width: 15 },
  ];

  worksheet.addRow({ id: 1, name: 'John Doe', dob: new Date(1970, 1, 1) });
  worksheet.addRow({ id: 2, name: 'Jane Doe', dob: new Date(1965, 1, 7) });
  console.log('EXCEL', workbook.xlsx);
  // XLSX.writeFile(workbook, "sheetjs.xlsx");
  // save under export.xlsx
  await downloadAs(workbook);
  // await workbook.xlsx.writeFile('export.xlsx').then(() => {
  //   console.log("saved");
  // })
  //   .catch((err) => {
  //     console.log("err", err);
  //   });

  //load a copy of export.xlsx
  // const newWorkbook = new ExcelJS.Workbook();
  // await newWorkbook.xlsx.readFile('export.xlsx');

  // const newworksheet = newWorkbook.getWorksheet('My Sheet');
  // newworksheet.columns = [
  //   { header: 'Id', key: 'id', width: 10 },
  //   { header: 'Name', key: 'name', width: 32 },
  //   { header: 'D.O.B.', key: 'dob', width: 15, }
  // ];
  // await newworksheet.addRow({ id: 3, name: 'New Guy', dob: new Date(2000, 1, 1) });

  // await newWorkbook.xlsx.writeFile('export2.xlsx');
};

const DownloadItemRecipe = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet('Item_recipe');
  var array = [];
  console.log('data....data', data);
  data.map((obj, index) => {
    if (obj.items != null) {
      var items = obj['items'];
      if (items.length > 0) {
        items.map((val, i) => {
          if (i == 0) {
            var firstObj = {};
            firstObj['item_name'] = obj.name;
            firstObj['name'] = val.name;
            firstObj['quantity'] = val.quantity;
            firstObj['unitTypeCode'] = val.unitTypeCode;
            array.push(firstObj);
          } else {
            var nextObj = {
              name: val.name,
              quantity: val.quantity,
              unitTypeCode: val.unitTypeCode,
            };
            array.push(nextObj);
          }
        });
      }
    }
  });
  ws.addRow(['Item name', 'Stock name', 'Quantity', 'unitTypeCode']);
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  setHeaderStyle(ws, 1);

  array.map((d, i) => {
    ws.addRow([d.item_name, d.name, d.quantity, d.unitTypeCode]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(workbook, `item_recipe_${moment().format('yyyyMMDDHHmmss')}.xlsx`);
};
const downloadAddonRecipe = async (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet(
    `addon_recipe_${moment().format('yyyyMMDDHHmmss')}.xlsx`
  );
  var array = [];
  data?.map(function (item, index) {
    var recipies = [];
    if (item?.items?.length > 0) {
      recipies = item.items;
    }
    var firstObj = { addon_name: item.name };
    if (recipies.length != 0) {
      recipies.map((stock, i) => {
        if (i == 0) {
          firstObj['name'] = stock.name;
          firstObj['quantity'] = stock.quantity;
          firstObj['unitTypeCode'] = stock.unitTypeCode;
          array.push(firstObj);
        } else {
          var nextObj = {
            name: stock.name,
            quantity: stock.quantity,
            unitTypeCode: stock.unitTypeCode,
          };
          array.push(nextObj);
        }
      });
    } else {
      array.push(firstObj);
    }
  });
  ws.addRow(['Addon name', 'Stock name', 'Quantity', 'unitTypeCode']);
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  setHeaderStyle(ws, 1);
  array.map((d, i) => {
    ws.addRow([d.addon_name, d.name, d.quantity, d.unitTypeCode]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `addon_recipe_${moment().format('yyyyMMDDHHmmss')}.xlsx`
  );
};

export {
  writeToExcel,
  DownloadItemRecipe,
  uploadRecipe,
  downloadSalesReport,
  getWookBook,
  downloadTransactions,
  downloadPurchases,
  downloadItemSales,
  downloadDailySales,
  downloadAlcoholSales,
  downloadAddonSales,
  downloadEmpSales,
  downloadGroupsData,
  downloadPettyCashReport,
  downloadConsolidateReport,
  downloadConsumptionReport,
  downloadKitchenReport,
  downloadVendorsReport,
  downloadPurchasesReport,
  downloadCustomers,
  downloadEmployees,
  downloadAddonRecipe,
  uploadCustomers,
  downloadContactTracing,
  downloadModifierSales,
  downloadEodReport,
};
