import * as React from "react"

const User = React.memo(({ width = 24, height = 24, color = "#fff", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.375a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm-5 3.75a5 5 0 1110 0 5 5 0 01-10 0zM6.64 15.046a8.746 8.746 0 019.564 1.91.625.625 0 01-.886.881 7.497 7.497 0 00-10.636 0 .625.625 0 11-.886-.88 8.747 8.747 0 012.844-1.911z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.25a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z"
        fill={color}
      />
    </svg>
  )
})

export default User
