import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Box, Button } from '@material-ui/core'
import { H4, AvenirBlackH4, H6, AvenirBlackH3 } from '../../../../utils/text';
import { useSelector } from 'react-redux';
import CustomCheckbox from '../../../../components/CustomCheckbox'
import CircleClose from '../../../../components/SVG/CircleClose'
import RightDrawer from '../../../../components/RightDrawer';
import BackIcon from '../../../../components/SVG/Back'
import AutoComplete from '../../../../components/AutoCompliteModifiers';
import Counter from '../../../../components/Counter'
import AddonItem from './AddonItem';
import SnackBar from '../../../../components/SnackBar'
import Counter_multiQty from '../../../../components/Counter_multiQty';

const Modifier = ({ onClose, show, onSave, isMandatory = true, item, addedModifiers, isEdit = false }) => {
  const allAddons = useSelector(state => state.menu.addons)
  const [isAllowMultiSelect, toggleAllowMultiSelect] = useState(false)
  const [isAllowMultiQuantity, toggleAllowMultiQuantity] = useState(false)
  const [selectedAddon, setSelectedAddon] = useState(null)
  const [snackbar, setSnackbar] = useState(null)
  const [addonItems, setAddonItems] = useState([])
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(1);
  const [minQty, setMinQty] = useState(1);
  const [maxQty, setMaxQty] = useState(1);

  const addons = useMemo(() => {
    let addons = [];
    allAddons.map((addon) => {
      let a = addedModifiers.findIndex(e => e.addon_cat_id == addon.addon_cat_id);
      if (a == -1) {
        addons.push(addon)
      }
    })
    return addons;
  }, [allAddons, addedModifiers])

  const onSaveModifier = () => {
    if(isAllowMultiSelect && (maxQty > max)){
      let quantityErr = `Multi-quantity max value can't be greater than multi-select max value`
      setSnackbar({ message: quantityErr, severity: 'error' });
    }else{
      onSave({ ...selectedAddon, addons: addonItems, min, max, isAllowMultiSelect, isAllowMultiQuantity, minQty, maxQty })
    }
  }

  useEffect(() => {
    if (item) {
      if (item.max > 1) {
        toggleAllowMultiSelect(true)
      }
      if (item.maxQty > 1) {
        toggleAllowMultiQuantity(true)
      }
      let updateMaxQty = item.maxQty == null ? 2 : item.maxQty
      let updateMinQty = item.minQty == null ? 1 : item.minQty
      setTimeout(() => {
        setMin(item.min)
        setMax(item.max)
        setMinQty(updateMinQty)
        setMaxQty(updateMaxQty)
      }, 300)
      setAddonItems(item.addons)
      setSelectedAddon(item)
    }
  }, [item])
  const onAddonChange = (e) => {
    setSelectedAddon(e)
    if (e) {
      setAddonItems(e.addons)
    } else {
      setAddonItems([])
    }
  }
  useEffect(() => {
    let mi = 1;
    let ma = max == 1 ? 2 : max;
    let miQ = 1;
    let maQ = maxQty == 1 ? 2 : maxQty;

    if (isMandatory) {
      if (!isAllowMultiSelect) {
        ma = 1;
      }
      if (!isAllowMultiQuantity) {
        maQ = 1;
      }
    } else {
      mi = 0;
      miQ = 0;

      if (!isAllowMultiSelect) {
        ma = 1;
      }
      if (!isAllowMultiQuantity) {
        maQ = 1;
      }
    }
    setMax(ma);
    setMin(mi);
    setMaxQty(maQ);
    setMinQty(miQ);
  }, [isAllowMultiSelect, isAllowMultiQuantity])
  const onAddonItemChange = (item, index) => {
    const temp = [...addonItems]
    temp[index] = item;
    setAddonItems(temp)

  }
  const onAddonItemDelete = (index) => {
    let minCount = isAllowMultiSelect ? 2 : 1;
    if (1 < addonItems.length) {
      if (min == max) {
        setMin(min - 1)
      }
      if (max == addonItems.length) {
        setMax(max - 1)
      }
      const temp = [...addonItems.slice(0, index), ...addonItems.slice(index + 1)];
      setAddonItems(temp)
    }


  }
  const handleQtyError = (error) => {
    console.log(error)
    setSnackbar({ message: error, severity: 'error' });
  }
  const onCountError = (error) => {
    console.log(error)
    setSnackbar({ message: error, severity: 'error' });
  }
  const str = isMandatory ? "mandatory" : 'optional'
  return (
    <RightDrawer open={show} padding={1} >
      <>
        {
          snackbar &&
          <SnackBar
            data-qaid="categories_snackbar"
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        }
        <Grid style={{ position: 'relative', display: 'flex', height: '85%', width: 600, padding: '10px 24px', flexDirection: 'column', height: '100%' }}>
          <Grid container style={{ overflow: 'auto', height: window.innerHeight - 70, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <Grid style={{ width: '100%', paddingBottom: 100 }}>
              <Grid
                container
                data-qaid="assignkitchen_close_grid"
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CircleClose
                  data-qaid="assignkitchen_closeicon"
                  size={20}
                  onClick={onClose}
                  style={{ marginTop: 20 }} />
              </Grid>
              <Grid
                container
                data-qaid="assignkitchen_settings_grid"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',

                  marginBottom: 10
                }}>
                <BackIcon
                  data-qaid="categories_editBack_icon"
                  onClick={onClose}
                  style={{ cursor: 'pointer' }} />
              </Grid>

              <Grid container>
                <H6 label={`Modifiers (${str})`} />
              </Grid>


              {
                !item &&
                <>
                  <Grid container style={{ marginTop: 50, borderBottom: '1px solid #e6e6e6', paddingBottom: 10 }}>
                    <AvenirBlackH3 label="Add modifier group" />
                  </Grid>
                  <Grid container direction="column">
                    <AvenirBlackH3 label="modifier group" style={{ marginTop: 20 }} />
                    <AutoComplete
                      options={addons}
                      style={{ width: '100%' }}
                      labelKey="addon_cat_name"
                      onChange={e => { onAddonChange(e) }}
                    />
                  </Grid>
                </>
              }

              {
                selectedAddon &&

                <Box display={selectedAddon ? 'contents' : 'none'} >
                  <Grid container >
                    <Grid container style={{ marginTop: 60, borderBottom: '1px solid #e6e6e6', paddingBottom: 10 }}>
                      <AvenirBlackH3 label={`Configure "${selectedAddon.addon_cat_name}"`} />
                    </Grid>
                    <Grid container >
                      <Grid container direction="row" alignItems="flex-start" style={{ marginTop: 20 }}>
                        <Grid item xs={6} style={{ marginTop: 10 }}>
                        <Grid style={{width: '204px'}}>
                            <Grid container direction="row" alignItems="center" >
                              <AvenirBlackH4 label="Allow multi-select" style={{ marginRight: 20 }} />
                              <CustomCheckbox
                                checked={isAllowMultiSelect}
                                onChange={e => toggleAllowMultiSelect(e.target.checked)} />
                            </Grid>
                            <Grid>
                              <H4 label='Allow more than 1 choice' />
                            </Grid>
                        </Grid>
                          {
                          isAllowMultiSelect &&
                          <Grid style={{ marginTop: 44, }}>
                            <Grid container alignItems="center" style={{display:'flex', flexDirection:'row'}}>
                              <Grid >
                                <H4 label="Min selections:" />
                              </Grid>
                              <Grid style={{width: '40%', marginLeft: 20}}>
                                <Counter
                                  count={min}
                                  onChange={setMin}
                                  label="min"
                                  onError={onCountError}
                                  isMandatory={isMandatory}
                                  otherCount={max}
                                  min={isMandatory ? 1 : 0}
                                  max={isMandatory ? max : 0} />
                              </Grid>

                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 20 }}>
                              <Grid >
                                <H4 label="Max selections:" />
                              </Grid>
                              <Grid style={{width: '40%', marginLeft: 20}}>
                                <Counter
                                  count={max}
                                  onChange={setMax}
                                  label="max"
                                  isMandatory={isMandatory}
                                  onError={onCountError}
                                  otherCount={min}
                                  min={isMandatory ? min > 2 ? min : 2 : min > 2 ? min : 2}
                                  max={addonItems.length}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        </Grid>
                        { isAllowMultiSelect &&
                        <Grid item xs={6} style={{ marginTop: 10 }}>
                          <Grid style={{width: '230px', marginBottom : '24px'}}>
                          <Grid container direction="row" alignItems="center" >
                            <AvenirBlackH4 label="Allow multi-quantity:" style={{ marginRight: 20 }} />
                            <CustomCheckbox
                              checked={isAllowMultiQuantity}
                              onChange={e => toggleAllowMultiQuantity(e.target.checked)} />
                          </Grid>
                          <Grid>
                            <H4 label='Allow item to be chosen more than once' />
                          </Grid>
                          </Grid>
                          {
                          isAllowMultiQuantity &&
                          <Grid style={{ marginTop: 10, }}>
                            <Grid container alignItems="center" style={{display:'flex', flexDirection:'row'}}>
                              <Grid >
                                <H4 label="Min selections:" />
                              </Grid>
                              <Grid style={{width: '40%', marginLeft: 20}}>
                                <Counter_multiQty
                                  count={minQty}
                                  onChange={setMinQty}
                                  label="min"
                                  onError={handleQtyError}
                                  isMandatory={isMandatory}
                                  otherCount={maxQty}
                                  min={isMandatory ? 1 : 0}
                                  max={isMandatory ? maxQty : 0} 
                                  multiSelectMax={max}
                                />
                              </Grid>

                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 20 }}>
                              <Grid >
                                <H4 label="Max selections:" />
                              </Grid>
                              <Grid style={{width: '40%', marginLeft: 20}}>
                                <Counter_multiQty
                                  count={maxQty}
                                  onChange={setMaxQty}
                                  label="max"
                                  isMandatory={isMandatory}
                                  onError={handleQtyError}
                                  otherCount={minQty}
                                  min={isMandatory ? minQty > 2 ? minQty : 2 : minQty > 2 ? minQty : 2}
                                  max={isAllowMultiSelect ? max : 10}
                                  multiSelectMax={max}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        </Grid>
                        }
                          
                      </Grid>
                      <Grid container style={{ marginTop: 20 }}>
                        {

                          addonItems.map((addon, index) => {

                            return (
                              <Grid container key={index}>

                                <AddonItem
                                  item={addon}
                                  onChange={(item) => onAddonItemChange(item, index)}
                                  onDelete={() => onAddonItemDelete(index)}
                                />
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              }
            </Grid>
          </Grid>
          <Grid container style={{ position: 'absolute', backgroundColor: 'white', height: 60, borderTop: '1px solid #EEE', justifyContent: 'flex-end', bottom: 0, left: 0, alignItems: 'center' }}>
            <Grid item xs={12} md={6} style={{ marginRight: 20 }}>
              <Grid container justify="flex-end">
                <Button style={{ border: '2px solid #707272', marginRight: 10 }} onClick={onClose}>
                  <AvenirBlackH4 label="Cancel" />
                </Button>
                <Button
                  disabled={selectedAddon == null}
                  variant="contained"
                  style={{ backgroundColor: '#FFB600' }} onClick={onSaveModifier}>
                  <AvenirBlackH4 label="Save" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  )
}

export default Modifier
