import * as React from "react"

const RotateRight = ({ width = 24, height = 24, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#prefix__clip0)"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16.5 11.265l3 3 3-3M11.25 20.984a8.974 8.974 0 01-3-.253M16.285 18.91a8.971 8.971 0 01-1.906 1.228M4.688 18.887a8.982 8.982 0 01-1.942-2.3" />
        <path d="M1.531 12.765a9 9 0 1117.969-.75v2.25" />
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="translate(24 0.0151367) rotate(90)" />
        </clipPath>
      </g>
    </svg>
  )
}

export default RotateRight
