import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
    Grid,
    Button,
    makeStyles,
    Typography
} from '@material-ui/core';
import VerticalTabs from '../../../components/verticalTabs';
import Vendors from './vendors';
import Kitchens from './kitchens';
import { useSelector, useDispatch } from 'react-redux';
import MyContext from '../../../Provider/MyContext';

const useStyles = makeStyles({
    btnApply: {
        backgroundColor: '#f0585f',
        color: 'white',
        marginLeft: '10px'
    },
    btnClear: {
        backgroundColor: '#dcdcdc',
        color: 'black'
    },
})

const InventoryCostings = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
    const Context = useContext(MyContext);

    const Tabs = [
        { name: Context.langData.vendors, id: 0 },
        { name: Context.langData.kitchens, id: 1 },
    ]

    const callback = (value) => {
        if (value == 1) {
            setSelectIndexTabChange(1)
        }
        else {
            setSelectIndexTabChange(0)
        }
    }

    return (
        <Grid data-qaid="costing_index_main_grid" container style={{ backgroundColor: 'white', width: '100%', height: '100%', padding:20 }}>
            <Grid data-qaid="costing_index_content_grid" container>
                <Grid lg={2} xs={12} data-qaid="costing_index_vertical_grid" item style={{ backgroundColor: '#FAFAFA', height: '100%' }}>
                    <VerticalTabs data-qaid="costing_index_verticalTab" tabData={Tabs} indexCallback={callback} />
                </Grid>
                <Grid lg={10} xs={12} data-qaid="costing_index_content_grid" item style={{ paddingLeft: '10px', height: '100%' }}>
                    {
                        selectIndexTabChange == 0 &&
                        <Vendors data-qaid="costing_index_vendors" />
                    }

                    {
                        selectIndexTabChange == 1 &&
                        <Kitchens data-qaid="costing_index_kitchens" />
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InventoryCostings;