import * as React from "react"

function CircleCheck({ width = 49, height = 48, color = '#1C7D26', ...props }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5 45c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21zm24-21c0 13.255-10.745 24-24 24S.5 37.255.5 24s10.745-24 24-24 24 10.745 24 24z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.131 13.518a1.5 1.5 0 01.184 2.113L21.382 33.41a1.5 1.5 0 01-2.264.039l-6.4-7.111a1.5 1.5 0 012.23-2.007l5.246 5.829 13.824-16.457a1.5 1.5 0 012.113-.184z"
                fill={color}
            />
        </svg>
    )
}

export default CircleCheck
