import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SingleSelect from '../../../../components/SingleSelect';
import {
    fetchAllGroupsData,
    fetchAllVendors,
    saveRaisedIndent,
    fetchAllStocks
} from '../../../../redux/actions';
import AutoCompliteDropDown from '../../../../components/AutoComplete';
import Delete from '../../../../components/SVG/Delete';
import moment from 'moment';
import RightDrawer from '../../../../components/RightDrawer';
import MyContext from '../../../../Provider/MyContext';
import InventoryIcon from '../../../../components/SVG/InventoryIcon';
import CircleClose from '../../../../components/SVG/CircleClose';
import { AvenirBlackH4, H4, H6, DotText } from '../../../../utils/text';
import SingleDate from '../../../../components/SingleDate';
import InputText from '../../../../components/InputText';
import styles from '../../../../styles/index';
import {
  getToday,
  getTodayMMDDYYYY,
  ConvertDateToUTCFormat,
} from '../../../../utils/util';
const RaiseIndent = ({ open, onClose, row, callback }) => {
  const style = styles();
  const dispatch = useDispatch();
  const groupsData = useSelector((state) => state.inventory.groupsData);
  const getStocks = useSelector((state) => state.inventory.stocks);
  const [allGroups, setAllGroups] = useState([]);
  const vendorData = useSelector((state) => state.inventory.vendors);
  const [vendorName, setVendorName] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [selectedCat, setSelectedCat] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const Context = useContext(MyContext);
  const [error, setError] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  console.log('DATE VALUE', dateValue, moment(dateValue).format('YYYY-MM-DD'));
  useEffect(() => {
    setCurrentDate(getToday());
    setDateValue(getTodayMMDDYYYY());
    dispatch(fetchAllGroupsData());
    dispatch(fetchAllStocks());
    dispatch(fetchAllVendors());
  }, []);

    const handleSave = () => {
        setIsTouched(true);
        var vendorId = vendorName;
        console.log("vendorId", vendorName)
        var VName = '';
        var items = []
        if (vendorData && vendorData.length > 0) {
            var vendorItem = vendorData.filter((e) => e.vendor_id == vendorName)
            if (vendorItem.length > 0) {
                VName = vendorItem[0].name;
            }
        }
        if (itemsList && itemsList.length > 0) {
            itemsList.map((Sitem) => {
                var item = {
                    id: Sitem.id,
                    requestedQuantity: Number(Sitem.requestedQuantity)
                }
                if (Sitem.requestedQuantity > 0) {
                    items.push(item)
                }
            })
        }
        if (items.length > 0) {
            var reqBody = {
                vendorId: vendorId,
                raisedDate: ConvertDateToUTCFormat(dateValue),
                items: items,
            }
            dispatch(saveRaisedIndent(reqBody, callback));
        } else {
            setError(Context.langData.vendor)
        }
    }
    useEffect(() => {
        if (vendorData && vendorData.length) {
            setVendorName(vendorData[0].vendor_id)
        }
    }, [vendorData])
    const selectedStock = (value) => {
        var itemListArray = [...itemsList];
        var item = itemListArray.filter((e) => e.name == value);
        if (item.length > 0) {
        } else {
            if (getStocks && getStocks.length > 0) {
                var stock = getStocks.filter((e) => e.name == value)
                if (stock.length > 0) {
                    var obj = { name: stock[0].name, requestedQuantity: '', unitTypeCode: stock[0].unitTypeCode, id: stock[0].id }
                    itemListArray.push(obj);
                }
            }
        }
        setItemsList(itemListArray);
    }
    const updateQtyValue = (index, value) => {
        var item = [...itemsList];
        item[index] = { ...item[index], requestedQuantity: parseFloat(value) ? parseFloat(value) : '' }
        setItemsList(item);
    }
    const removeStock = (index) => {
        var item = [...itemsList];
        item.splice(index, 1)
        setItemsList(item)
    }
    const selectCatDropDown = (value) => {
        var itemArray = []
        if (groupsData && groupsData.length > 0) {
            var catItem = groupsData.filter((e) => e.id == value)
            if (catItem.length > 0) {
                if (catItem[0].items && catItem[0].items.length > 0) {
                    catItem[0].items.map((item) => {
                        var StockItem = getStocks.filter((stocke) => stocke.id == item.id)
                        if (StockItem.length > 0) {
                            var obj = { name: StockItem[0].name, requestedQuantity: '', unitTypeCode: StockItem[0].unitTypeCode, id: StockItem[0].id }
                            itemArray.push(obj)
                        }
                    })
                }
            }
        }
        setAllGroups(value);
        setItemsList(itemArray);
    }
    const dateCallback = (value) => {
        setDateValue(value)
    }
    const handleSelect = (value) => {
        selectedStock(value)
    }
    return (
        <RightDrawer open={open} padding={1}>
            <>
                <Grid data-qaid="raiseIndent_body_grid" style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid data-qaid="raiseIndent_child1_grid" style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto', paddingBottom: 30 }}>
                        <Grid data-qaid="raiseIndent_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="raiseIndent_closeicon" size={20} onClick={onClose} style={{ marginRight: 36, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="raiseIndent_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 36, marginBottom: 10 }}>
                            <InventoryIcon data-qaid="raiseIndent_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="raiseIndent_settings" label={Context.langData.inventory} style={{ color: '#707272' }} />
                        </Grid>
                        <H6 data-qaid="raiseIndent_title" style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }} label={Context.langData.raise_purchase_order} />
                        <Grid data-qaid="raiseIndent_main_grid" direction="column" style={{ marginLeft: 36, marginBottom: 20, marginRight: 36, width: 500, display: 'flex', flexDirection: 'column' }} >
                            <Grid data-qaid="raiseIndent_subContainer2" container style={{ marginTop: 10, display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Grid data-qaid="raiseIndent_vendorContainer" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <Grid data-qaid="raiseIndent_vendorGrid" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '50%' }}>
                                        <AvenirBlackH4 data-qaid="raiseIndent_vendorLabel" label={Context.langData.vendor} style={{ marginBottom: 10 }} />
                                        <SingleSelect
                                            minWidth={'100%'}
                                            list={vendorData}
                                            valueKey="vendor_id"
                                            displayKey="name"
                                            placeholder="Select"
                                            value={vendorName}
                                            handleChange={(e) => setVendorName(e.target.value)}
                                            border={false}
                                            height={56}
                                        />
                                    </Grid>
                                    <Grid data-qaid="raiseIndent_dateGrid" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '50%', marginLeft: 20 }}>
                                        <AvenirBlackH4 data-qaid="raiseIndent_vendorLabel" label={Context.langData.raise_date} style={{ marginBottom: 10 }} />
                                        {/* <DatePicker data-qaid="raiseindent_date_picker" callback={dateCallback} value={dateValue} width={250} height={56} /> */}
                                        <SingleDate
                                            value={dateValue}
                                            height={56}
                                            border={false}
                                            callback={dateCallback}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid data-qaid="raiseIndent_vendorGrid" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', marginTop: 20 }}>
                                    <AvenirBlackH4 data-qaid="raiseIndent_vendorLabel" label={Context.langData.select_group} style={{ marginBottom: 10 }} />
                                    <SingleSelect
                                        minWidth={'100%'}
                                        list={groupsData}
                                        valueKey="id"
                                        displayKey="name"
                                        placeholder={Context.langData.all_group}
                                        value={allGroups}
                                        handleChange={(e) => selectCatDropDown(e.target.value)}
                                        border={false}
                                        height={56}
                                    />
                                </Grid>
                                <Grid data-qaid="raiseIndent_stockItemsGrid" item style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                    <AutoCompliteDropDown
                                        options={getStocks}
                                        onChange={handleSelect}
                                        labelKey={'name'}
                                        key={itemsList}
                                        placeholder={Context.langData.serch_stock_items}
                                    />
                                </Grid>
                            </Grid>
                            <Grid data-qaid="raiseIndent_table_body" item style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Grid data-qaid="raiseIndent_subContainer3" item style={{ height: '40px', width: '100%', backgroundColor: '#F3FAFD', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Grid data-qaid="raiseIndent_dateGrid" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '50%' }}>
                                        <AvenirBlackH4 data-qaid="raiseIndents_itemHeading" label={Context.langData.items} style={{ marginLeft: 20 }} />
                                    </Grid>
                                    <Grid data-qaid="raiseIndent_dateGrid" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '50%' }}>
                                        <AvenirBlackH4 data-qaid="raiseIndents_request_qtyHeading" label={Context.langData.request_qty} />
                                    </Grid>
                                </Grid>
                                {itemsList ?
                                    itemsList.map((itemName, index) => {
                                        return (
                                            <Grid key={itemName.stock_name} data-qaid="raiseIndent_stockNameContainer" item style={{ marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: "#FAFAFA", minHeight: 86 }}>
                                                <Grid data-qaid="raiseIndent_dateGrid" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '50%' }}>
                                                    <DotText data-qaid="raiseIndent_stockNameLabel" label={itemName.name} style={{ marginLeft: 20 }} />
                                                </Grid>
                                                <Grid data-qaid="raiseIndent_dateGrid" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '50%', flexDirection: 'row', maxHeight: 50 }}>
                                                    <Grid style={{ display: 'flex', maxWidth: '70%' }}>
                                                        <InputText
                                                            style={{ display: 'flex' }}
                                                            height={38}
                                                            name="requestedQuantity"
                                                            maxLength={8}
                                                            error={(!itemName.requestedQuantity && isTouched) ? Context.langData.raise_quantity_error : ''}
                                                            id={itemName.name}
                                                            value={itemName.requestedQuantity}
                                                            inputPlaceholder={0}
                                                            onChange={(e) => { updateQtyValue(index, e.target.value) }}
                                                        />
                                                        <H4 data-qaid="raiseIndent_unitsLabel" label={itemName.unitTypeCode} style={{ marginLeft: 10, marginTop: 10, marginRight: 10 }} />
                                                    </Grid>
                                                    <Grid style={{ display: 'flex', maxWidth: '30%', alignItems: 'center' }}>
                                                        <Delete data-qaid="raiseIndent_deleteIcon" width={24} height={24} onClick={() => { removeStock(index) }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10, marginRight: 10, marginLeft: 50 }}></Delete>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }) : null
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid data-qaid="createGroup_cancel_main_Grid" style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end', border: '1px solid #C3C3C3', backgroundColor: '#FAFAFA' }}>
                        <Grid data-qaid="createGroup_cancelGrid" item style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingTop: '25px', alignItems: 'center', paddingBottom: '25px', paddingRight: 20 }}>
                            <Button className={style.buttonClass} data-qaid="createGroup_cancelButton" style={{ backgroundColor: "#FFF", marginRight: 10, width: 103, border: '2px solid #051D33', height: 40 }} variant="outlined" id="cancel" onClick={() => onClose()}  >
                                <AvenirBlackH4 data-qaid="createGroup_cancelLabel" label={Context.langData.cancel} style={{ color: '#004C60' }} />
                            </Button>
                            <Button className={style.buttonClass} data-qaid="createGroup_saveButton" style={{ backgroundColor: "#FFB600", marginRight: 10, width: 90, height: 40 }} variant="contained" id="cancel" type='submit' onClick={() => handleSave()}>
                                <AvenirBlackH4 data-qaid="createGroup_saveLabel" label={Context.langData.confirm} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer >
    )
}

export default RaiseIndent;
