import instance from './axiosInstance';
import urls from './urls';

const fetchTransactions = (params) => {
  return (dispatch) => {
    instance.get(urls.transactions.transactions, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_TRANSACTIONS', payload: response.data })
    })
  }
}
const fetchTransactionDetails = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.transactions.transactionDetails, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        callback(response.data)
    })
  }
}
const fetchOnlineTransactions = (params) => {
  return (dispatch) => {
    instance.get(urls.transactions.online, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: 'SET_ONLINE_ORDERS', payload: response.data })
    })
  }
}
const transactionrefund = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.transactions.refund, params).then((response) => {
      callback(true, response.data);
    }).catch(err => {
      callback(false)
    })
  }
}
const transactionrefundDetails = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.transactions.refun_details, params).then((response) => {
      callback(true, response.data);
    }).catch(err => {
      callback(false)
    })
  }
}
export {
  fetchTransactions,
  fetchOnlineTransactions,
  fetchTransactionDetails,
  transactionrefund,
  transactionrefundDetails
}