import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, } from '@material-ui/core'
import _ from 'lodash';
import {
    AvenirBlackH4, AvenirBlackH3, AvenirBlackLightH3,
    H6, H4
} from '../../../utils/text';
import RightDrawer from '../../../components/RightDrawer'
import CircleClose from '../../../components/SVG/CloseCircle';
import { ConvertPrice } from '../../../utils/util';
import styles from '../../../styles/index';
import BackIcon from '../../../components/SVG/Back';
import CustomCheckbox from '../../../components/CustomCheckbox';
import MyContext from '../../../Provider/MyContext';

const GiftCardRefundModal = ({ open, callback, onClose }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const dispatch = useDispatch();
    const [isCheckedTrue, setIsCheckedTrue] = useState(true);
    const refundObj = useSelector(state => state.transactions.refundObj);
    const [giftCardNumber, setGiftCardNumber] = useState('0000');
    const handleContinue = () => {
        var obj = { ...refundObj, "is_gift_card": isCheckedTrue };
        dispatch({ type: "SET_TRANSACTION_REFUND", payload: obj })
        callback(isCheckedTrue);
    }
    useEffect(() => {
        if (refundObj.order_payment_id && refundObj.payments) {
            var userPayments = refundObj.payments.filter(e => e.order_payment_id == refundObj.order_payment_id);
            if (userPayments.length > 0) {
                userPayments.map(i => {
                    if (i.ptype.toLowerCase() == 'gift') {
                        setGiftCardNumber(i.masked_number);
                    }
                })

            }

        }
    }, [refundObj])
    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto' }}>
                        <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, marginTop: 20 }}>
                            <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={() => onClose(true)} style={{ marginRight: 40, cursor: 'pointer' }} />
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, marginLeft: 49 }}>
                            <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-start', width: '40%', alignItems: 'center' }}>
                                <BackIcon data-qaid="createcategory_closeicon" size={20} onClick={() => onClose(false)} style={{ marginRight: 40, cursor: 'pointer' }} />
                            </Grid>
                        </Grid>
                        <Grid data-qaid="transactions_refund_main_grid" container direction="column" style={{ marginLeft: 49, marginBottom: 20, marginRight: 49, width: 500 }} >
                            <H6 label={Context.langData.refund_order} style={{ marginBottom: 32 }} />
                            <AvenirBlackLightH3 label={Context.langData.gift_card_body_1} style={{ marginBottom: 10 }} />
                            <AvenirBlackLightH3 label={Context.langData.gift_card_body_2} style={{ marginBottom: 10 }} />
                            <AvenirBlackLightH3 label={giftCardNumber} style={{ marginBottom: 10 }} />
                            <AvenirBlackLightH3 label={Context.langData.gift_card_body_3} style={{ marginBottom: 10 }} />
                            <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CustomCheckbox
                                    data-qaid="refund_checkBox_item"
                                    checked={isCheckedTrue}
                                    onChange={() => setIsCheckedTrue(true)}
                                    unCheckColor={isCheckedTrue ? "#C3C3C3" : "#004C60"}
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    marginLeft={0}
                                />
                                <H4 label={Context.langData.yes} />
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CustomCheckbox
                                    data-qaid="refund_checkBox_item"
                                    checked={!isCheckedTrue}
                                    onChange={() => setIsCheckedTrue(false)}
                                    unCheckColor={!isCheckedTrue ? "#C3C3C3" : "#004C60"}
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    marginLeft={0}
                                />
                                <H4 label={Context.langData.no} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="transactions_refund_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                            <Grid data-qaid="transactions_refund_cancel_btn" item xs={6} lg={6} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 32, marginBottom: 32, paddingLeft: 49, alignItems: 'center' }}>
                                <AvenirBlackH3 label={`${Context.langData.refund_total_amount}${ConvertPrice(refundObj.refund_amount)}`} style={{ color: '#000000' }} />
                            </Grid>
                            <Grid data-qaid="transactions_refund_cancel_btn" item xs={6} lg={6} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="transactions_refund_cancel_button" onClick={() => { onClose(true) }} variant="outlined" style={{ backgroundColor: '#FFFFFF', marginRight: 16, border: '2px solid #707272', width: 103 }} >
                                    <AvenirBlackH4 data-qaid="transactions_refund_cancel_text" label={Context.langData.cancel} style={{ color: '#004C60' }} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="transactions_refund_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 49 }}
                                    onClick={() => { handleContinue() }}
                                >
                                    <AvenirBlackH4 data-qaid="transactions_refund_save_text" label={Context.langData.continue} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )
}

export default GiftCardRefundModal