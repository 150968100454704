import * as React from "react"

function PettyCashIcon({ width = 24, height = 24, color = "#fff", ...props }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.645 6H9a.5.5 0 010 1H7.645a.395.395 0 00-.147.76l1.376.551A1.395 1.395 0 018.354 11H7a.5.5 0 010-1h1.354a.395.395 0 00.147-.76l-1.376-.551A1.395 1.395 0 017.645 6z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 5a.5.5 0 01.5.5v1a.5.5 0 01-1 0v-1A.5.5 0 018 5zM8 10a.5.5 0 01.5.5v1a.5.5 0 01-1 0v-1A.5.5 0 018 10z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.553.276A.5.5 0 016 0h4a.5.5 0 01.447.276l1.5 3A.5.5 0 0112 3.5v10a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-10a.5.5 0 01.053-.224l1.5-3zM6.309 1L5 3.618V13h6V3.618L9.691 1H6.309z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5.5A.5.5 0 0110 0h3.5a.5.5 0 01.416.223l2 3A.5.5 0 0115.5 4h-4a.5.5 0 010-1h3.066l-1.334-2H10a.5.5 0 01-.5-.5zM2.084.223A.5.5 0 012.5 0H6a.5.5 0 010 1H2.768L1.434 3H4.5a.5.5 0 010 1h-4a.5.5 0 01-.416-.777l2-3zM0 6a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4A.5.5 0 010 6zM11 6a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4A.5.5 0 0111 6zM0 8.5A.5.5 0 01.5 8h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM11 8.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM0 11a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4A.5.5 0 010 11zM11 11a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.5 3a.5.5 0 01.5.5V13h3.5a.5.5 0 010 1h-4a.5.5 0 01-.5-.5v-10A.5.5 0 01.5 3zM15.5 3a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-4a.5.5 0 010-1H15V3.5a.5.5 0 01.5-.5zM4 3.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5z"
                fill={color}
            />
        </svg>
    )
}

export default PettyCashIcon
