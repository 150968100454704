import jquery from 'jquery';
import moment from 'moment';
import { ConvertPrice } from '../../utils/util';
const initialState = {
    consolidated: [],
    consumptions: [],
    kitchens: [],
    vendors: [],
    purchases: [],
    contactTracing: {}
}

const parseConsumptions = (response) => {
    response.map((res) => {
        res["variation"] =  res.variation * 100 + "%"
    })
    return response
}


const inventoryReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CONSOLIDATED_SUCCESS':
            return {
                ...state,
                consolidated: action.payload
            }
        case "SET_CONSUMPTIONS":
            return {
                ...state,
                consumptions: parseConsumptions(action.payload)
            }
        case 'GET_KITCHEN_DATA_SUCCESS':
            return {
                ...state,
                kitchens: action.payload
            }
        case 'GET_VENDORS_DATA_SUCCESS':
            return {
                ...state,
                vendors: action.payload
            }
        case 'GET_PURCHASES_DATA_SUCCESS':
            return {
                ...state,
                purchases: action.payload
            }
        case 'GET_CONTACTTRACING_DATA_SUCCESS':
            return {
                ...state,
                contactTracing: action.payload
            }
        case 'CLEAR':
            return {
                ...initialState
            }
        default:
            return state;
    }
}

export default inventoryReportReducer;
