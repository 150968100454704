
import * as React from "react"

const Add = ({ width = 24, height = 24, color = "#fff", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.167 16a.5.5 0 01-.5-.5V.5a.5.5 0 111 0v15a.5.5 0 01-.5.5z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.167a.5.5 0 01.5-.5h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5z"
        fill={color}
      />
    </svg>
  )
}

export default Add
