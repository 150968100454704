import {
	Paper,
	Grid,
	createStyles,
	withStyles
} from "@material-ui/core";
import {
	getDate, isSameMonth,
	isToday, format,
	isWithinInterval,addMonths,addYears,isSameDay,
	isAfter,
	isBefore
 } from "date-fns";
import {
	chunks,
	getDaysInMonth,
	isStartOfRange,
	isEndOfRange,
	inDateRange,
	isRangeSameDay
} from "../utils";
import Header from "./Header";
import Day from "./Day";
import { H5 } from '../../utils/text'
import { NavigationAction } from "../types";

const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const styles = (theme) =>
	createStyles({
		root: {
			width: 290
		},
		weekDaysContainer: {
			marginTop: 10,
			paddingLeft: 30,
			paddingRight: 30
		},
		daysContainer: {
			paddingLeft: 15,
			paddingRight: 15,
			marginTop: 15,
			marginBottom: 20
		}
	});



const Month = props => {
	const {
		classes,
		value: date,
		marker,
		setValue: setDate,
		minDate,
		selected,
		maxDate,
		handlers,
		width
	} = props;

	const [back, forward] = props.navState;
	return (
		<Paper square elevation={0} style={{width:width}}>
			<Grid container>
				<Header
					date={date}
					setDate={setDate}
					nextDisabled={!forward}
					prevDisabled={!back}
					onYearChange={(year)=>handlers.onYearChange(year)}
					onClickPrevious={() =>{
					handlers.onPreMonth()
				}
					}
onClickNext={()=>{
	handlers.onNextMonth()
}}
				/>

				<Grid
					item
					container
					direction="row"
					justify="space-between"
					className={classes.weekDaysContainer}>
					{WEEK_DAYS.map(day => (
						<H5 label={day} key={day} style={{ color: "#707272" }} />
					))}
				</Grid>

				<Grid
					item
					container
					direction="column"
					justify="space-between"
					className={classes.daysContainer}>
					{chunks(getDaysInMonth(date), 7).map((week, idx) => (
						<Grid key={idx} container direction="row" justify="center">
							{week.map(day => {
								const isStart = false;
								const isEnd = false;
								// const isRangeOneDay = isRangeSameDay(dateRange);
								const highlighted =false

								return (
									<Day
										key={format(day, "mm-dd-yyyy")}
										filled={isSameDay(day,selected)}
										outlined={isToday(day)}
										width={(36*width)/290}
										disabled={
											!isSameMonth(day,date) ||
											isAfter(day,maxDate) ||
											isBefore(day,minDate)

										}
                    //
                    //
                    //
										onClick={() => handlers.onDateClick(day)}
										// onHover={() => handlers.onDayHover(day)}
										value={getDate(day)}
									/>
								);
							})}
						</Grid>
					))}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles)(Month);
