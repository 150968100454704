const iconsData = [
  {
    name: "Tables",
    list: [
      {
        id: "1",
        content: 'rect'
      },
      {
        id: "2",
        content: 'circle'
      }
    ]
  },
  {
    name: "Decors",
    list: [{
      id: "3",
      content: 'handwash'
    },
    {
      id: "4",
      content: 'flowervash'
    },
    {
      id: "5",
      content: 'door'
    },
    {
      id: "6",
      content: 'kitchen'
    }
    ]
  }
]
const ITEMS = [

  {
    id: "1",
    content: 'table',
    category: 'Table',
    width: 20,
    height: 20
  },
  {
    id: "2",
    content: 'bar',
    category: 'Bar',
    width: 8,
    height: 24
  },
  {
    id: "3",
    content: 'kitchen',
    category: 'Kitchen',
    width: 20,
    height: 20
  },
  {
    id: "4",
    content: 'wall',
    category: 'Wall',
    width: 20,
    height: 20
  },
  {
    id: "7",
    content: 'rect',
    category: 'Desk',
    width: 20,
    height: 15
  },
  {
    id: "5",
    content: 'door',
    category: 'Door',
    width: 20,
    height: 20
  },
  {
    id: "6",
    content: 'flowervash',
    category: 'Plant',
    width: 20,
    height: 20
  }, {
    id: "8",
    content: "stair",
    category: 'Stairs',
    width: 30,
    height: 30
  }
];
const deviceDimensions = [
  {
    device: 'iPad pro 12.9-in',
    inch: "12.9",
    id: 'ipad_pro_129',
    sizes: [1366, 1024]
  },
  // {
  //   device: 'iPad Pro 11-in',
  //   inch: "11.0",
  //   id: 'ipad_pro_110',
  //   sizes: [1194, 834]
  //   // sizes: [500, 800]
  // },
  // {
  //   device: 'iPad Air 10.9-in',
  //   inch: "10.9",
  //   id: 'ipad_air_109',
  //   sizes: [1112, 834]
  // },
  {
    device: 'iPad 10.2-in',
    inch: "10.2",
    id: 'ipad_102',
    sizes: [1080, 810]
  },
  // {
  //   device: 'iPad Pro 9.7-in',
  //   inch: "9.7",
  //   id: 'ipad_pro_97',
  //   sizes: [1024, 768]
  // },
  {
    device: 'iPad mini 7.9-in',
    inch: "7.9",
    id: 'ipad_79',
    sizes: [1024, 768]
  },
  {
    device:'Samsung galaxy A8',
    inch:'8.0',
    id:"galaxy_tab_a",
    sizes:[961,601]
  },
  {
    device:'Samsung galaxy S7',
    inch:'11.0',
    id:"galaxy_s7",
    sizes:[1204,752]
  },
  {
    device:'Samsung galaxy A7',
    inch:'10.4',
    id:"galaxy_a7",
    sizes:[1333,800]
  },
  {
    device:'Huawei mediapad M5 lite',
    inch:'11.0',
    id:"huawei_m5",
    sizes:[1129,706]
  },
  {
    device:'Lenovo M10 plus',
    inch:'11.0',
    id:"lenovo_m10",
    sizes:[1280,800]
  },
  {
    device:'Lenovo M8',
    inch:'11.0',
    id:"lenovo_m8",
    sizes:[960,600]
  }
]
export {
  ITEMS,
  iconsData,
  deviceDimensions
}
