import React from 'react';

function PencileIcon({ width = 20, height = 20, color = "#004C60", ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5931 0.0558867C20.1729 0.0519614 20.7476 0.162947 21.2842 0.382432C21.8207 0.601917 22.3085 0.92555 22.7193 1.33463L22.19 1.86606L22.7214 1.33681C23.1305 1.74758 23.4542 2.23534 23.6736 2.77191C23.8931 3.30848 24.0041 3.88322 24.0002 4.46293C23.9963 5.04264 23.8775 5.61583 23.6508 6.14937C23.4246 6.68151 23.0956 7.1637 22.6825 7.56824L8.18535 22.0654C8.09032 22.1604 7.97152 22.2282 7.84135 22.2615L0.936346 24.0325C0.679581 24.0984 0.407123 24.0238 0.219686 23.8364C0.0322492 23.6489 -0.0423246 23.3765 0.0235308 23.1197L1.79453 16.2147C1.82792 16.0846 1.89566 15.9657 1.99069 15.8707L16.4878 1.37365C16.8923 0.960543 17.3745 0.63145 17.9067 0.405302C18.4402 0.178571 19.0134 0.059812 19.5931 0.0558867ZM21.6598 2.39645C21.3896 2.12769 21.069 1.91505 20.7163 1.77077C20.3631 1.62632 19.9848 1.55327 19.6033 1.55585C19.2218 1.55844 18.8445 1.6366 18.4933 1.78582C18.1422 1.93505 17.8241 2.15238 17.5574 2.42526L17.5514 2.43142L3.1965 16.7862L1.79138 22.2647L7.26984 20.8596L21.6308 6.49863C21.9037 6.23195 22.121 5.91388 22.2702 5.56272C22.4195 5.21156 22.4976 4.83431 22.5002 4.45277C22.5028 4.07123 22.4298 3.69296 22.2853 3.33981C22.141 2.98717 21.9285 2.66656 21.6598 2.39645Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0757 1.78572C16.3686 1.49283 16.8435 1.49283 17.1363 1.78572L22.2703 6.91972C22.5632 7.21262 22.5632 7.68749 22.2703 7.98038C21.9775 8.27328 21.5026 8.27328 21.2097 7.98038L16.0757 2.84638C15.7828 2.55349 15.7828 2.07862 16.0757 1.78572Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9817 3.87972C14.2746 3.58683 14.7495 3.58683 15.0423 3.87972L20.1763 9.01372C20.4692 9.30662 20.4692 9.78149 20.1763 10.0744C19.8835 10.3673 19.4086 10.3673 19.1157 10.0744L13.9817 4.94038C13.6888 4.64749 13.6888 4.17262 13.9817 3.87972Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99069 15.8707C2.28329 15.5775 2.75765 15.5776 3.05083 15.8702L8.18983 20.9992C8.48301 21.2918 8.48347 21.7667 8.19086 22.0599C7.89825 22.353 7.42338 22.3535 7.1302 22.0609L1.9912 16.9319C1.69802 16.6393 1.69808 16.1639 1.99069 15.8707Z" fill={color} />
        </svg>

    )
}

export default PencileIcon;