import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    makeStyles,
    Grid,
    Button
} from '@material-ui/core';
import TopSearchBar from '../../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import AlertDialog from './../../../components/Dialog';
import { useDispatch } from 'react-redux';
import { deleteRoles, fetchRoles } from '../../../redux/actions';
import CirclePluse from '../../../components/SVG/PluseIcon';
import CustomerDataTable from '../../../components/CustomerDataTable';
import { AvenirBlackH7, AvenirBlackH4, H6, H5 } from '../../../utils/text';
import MyContext from '../../../Provider/MyContext';
import DownloadIcon from '../../../components/SVG/DownlodeIcon';
import DeleteIcon from '../../../components/SVG/Delete';
import SingleSelect from '../../../components/SingleSelect';
import MuiAlert from '@material-ui/lab/Alert';
import styles from '../../../styles/index';
import SnackBar from '../../../components/SnackBar'
import ContextMenu from '../../../components/ContextMenu';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
}));
const Roles = () => {
    const style = styles();
    const Roles = useSelector(state => state.profileReducers.roles);
    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [isError, setIsError] = useState(false);
    const [errorObj, setErrorObj] = useState([]);
    const [heading, setHeading] = useState('');
    const [bodyMessage, setBodyMessage] = useState('');
    const [ids, setIds] = useState([]);
    const limit = useSelector(state => state.profileReducers.limit);
    const [pagenumber, setPagenumber] = useState(0);
    const [serchText, setSerchText] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const Context = useContext(MyContext);
    const [snackbar, setSnackbar] = useState(null);
    const [showExtra, setShowExtra] = useState(false);

    const navigate = useNavigate();
    const [columns, setColumns] = useState(
        [{ id: 'name', field: 'full_name', headerName: 'Name', sort: true, size: 4, desc: true },
        { id: 'application', field: 'application', headerName: 'Permissions', sort: true, size: 4, desc: true },
        { id: 'role_count', field: 'role_count', headerName: 'No. of employees', sort: true, size: 4, desc: true },
        ]);

    const handleDeleteRole = () => {
        onDeleteRoles(ids)
    }

    const ContextMenuData = [
        {
          id: 1,
          icon: DeleteIcon,
          name: Context.langData.delete,
        },
    
      ];

      const callBack = (id) => {
        if (id == 1) {
          onDeleteRoles(ids);
        }
      }
    
    const handleSort = (name, desc, index) => {
        var data = [...columns];
        data[index] = { ...data[index], desc: desc }
        setColumns(data);
        setOrder(desc ? 'asc' : 'desc');
        if (name == 'full_name') {
            name = 'name'
        }
        setOrderBy(name);
    }
    useEffect(() => {
        var offsetValue = 0;
        if (pagenumber > 0) {
            offsetValue = pagenumber - 1;
        }
        var queryString = { limit: limit, offset: offsetValue };
        if (serchText) {
            queryString["search_text"] = serchText
        }
        if (orderBy) {
            queryString["sortBy"] = orderBy
            queryString["orderBy"] = order;
        }
        dispatch(fetchRoles(queryString));
    }, [pagenumber, serchText, orderBy, order])
    // useEffect(() => {
    //     var offsetValue = 0;
    //     if (pagenumber > 0) {
    //         offsetValue = pagenumber - 1;
    //     }
    //     var queryString = { limit: limit, offset: offsetValue };
    //     if (serchText) {
    //         queryString["search_text"] = serchText
    //     }
    //     if (orderBy) {
    //         queryString["sortBy"] = orderBy
    //         queryString["orderBy"] = order;
    //     }
    //     dispatch(fetchRoles(queryString));
    // }, [])

    const classes = useStyles();
    function onDeleteRoles(ids) {
        var newArray = [];
        if (ids.length) {
            for (let x = 0; x < ids.length; x++) {
                var obj = Roles.roles.filter((e) => e.role_id === ids[x]);
                if (obj.length > 0) {
                    newArray.push(obj[0]);
                }
            }
        }
        var errorDeleteObj = [];
        var errorFlag = false;
        newArray.map((rowId) => {
            var rowObj = Roles.roles.filter((element) => element.role_id === rowId.role_id);
            if (rowObj[0].is_editable == 0 || rowObj[0].role_count > 0) {
                var obj = rowObj[0];
                if (rowObj[0].is_editable == 0) {
                    obj["super_admin"] = true;
                } else {
                    obj["super_admin"] = false;
                }
                errorDeleteObj.push(obj);
            } else {

            }
        });
        if (errorDeleteObj.length > 0) {
            errorFlag = true;
        } else {
        }
        if (newArray.length > 0) {
            setIds(ids);
            console.log(" error object values", JSON.stringify(errorDeleteObj), "errorFlag", errorFlag);
            setRows(newArray);
            setIsError(errorDeleteObj);
            setIsError(errorFlag);
            setShowDialog(true);
            if (errorFlag) {
                var string = '';
                errorDeleteObj.map((item) => {
                    if (item.super_admin) {
                        string = `` + string + ` The Super Admin role cannot be deleted.\n`
                            setHeading("Error")
                            setShowExtra(false)
                    } else {
                        string = 'This role has 1 or more employee profiles assigned to it and cannot be deleted. Reassign employee(s) to other roles before deleting.'
                        setHeading("Error")
                        setShowExtra(false)
                        // if (item.role_count > 1) {
                        //     string = `` + string
                        //         + item.name + ` role has ` + item.role_count + ` employees.\n`
                        //         setHeading("Error")
                        // } else {
                        //     string = `` + string
                        //         + item.name + ` role has ` + item.role_count + ` employee.\n`
                        //         setHeading("Error")
                        // }
                    }
                });
                setBodyMessage(string);

            } else {
                if (newArray.length > 1) {
                    setBodyMessage("Are you sure you want to delete " + newArray.length + " roles?");
                    setHeading("Delete roles?")
                    setShowExtra(true)
                } else {
                    setBodyMessage("Are you sure you want to delete this role?");
                    setHeading("Delete role?")
                    setShowExtra(true)
                }
            }
        } else {

        }
    }
    function onColumnClick(col) {
        navigate("/home/profile/employees/editRole", { state: { id: col.role_id, tab: 2, from: 'role' } });
    }
    const handleClose = (val) => {
        if (val) {
            dispatch(deleteRoles({ delete_role_id: ids }, callback));
        }
        setShowDialog(false)
    }
    function callback(isSuccess) {
        dispatch(fetchRoles())
        if (isSuccess) {
            setSnackbar({ message: Context.langData.role_deleted_successfully, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.role_delete_failed, severity: 'error' });
        }
    }

    const onCheckedIds = (checkedIds) => {
        setIds(checkedIds)
    }
    const totalNumber = (total) => {
        var totalPages = Math.floor(total / 25) + (total % 25 !== 0 ? 1 : 0)
        return totalPages;
    }
    const onChangeText = (value) => {
        setSerchText(value)
    }
    const onPageinationChange = (value) => {
        setPagenumber(value);
    }
    return (
        <Grid container data-qaid="rolesMainGrid">
            {Roles.roles && Roles.roles.length > 0 || serchText
                ?
                <>
                    <Grid data-qaid="rolesSubGrid1" container style={{ margin: 20, alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                        <Grid data-qaid="roles_TopSearchBar" item lg={9} xs={12} >
                            <TopSearchBar
                                value={serchText}
                                onChange={(val) => onChangeText(val.target.value)}
                                name="serchText"
                                id="serchText"
                                placeholder={Context.langData.search_role}
                                style={{ height: 56, width: '100%' }}
                            />
                        </Grid>
                        <Grid data-qaid="roles_buttonContainer" lg={3} xs={12} style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button data-qaid="roles_delete_button" style={{ marginRight: 10, width: 100 }} variant="text" id="cancel" onClick={() => handleDeleteRole()}>
                                <DeleteIcon data-qaid="roles_deleteicon" width={20} height={20} style={{paddingRight: 5}}/>
                                <AvenirBlackH4 data-qaid="roles_delete_label" label={Context.langData.delete} />
                            </Button>
                            <Button className={style.buttonClass} data-qaid="roles_creategrid" style={{ backgroundColor: "#FFB600", color: "white", height: 40, width: 156 }} variant="contained" id="cancel" onClick={() => navigate('/home/profile/employees/createRoleFirstStep')}  >
                                <CirclePluse data-qaid="roles_createpluse" />
                                <AvenirBlackH4 data-qaid="roles_createlabel" label={Context.langData.create_role} style={{ marginLeft: 5 }} />
                            </Button>
                            {/* <ContextMenu menuItems={ContextMenuData} callBack={callBack} style={{paddingLeft:20}}/> */}
                        </Grid>
                    </Grid>
                    <Grid item data-qaid="rolesSubGrid2" lg={12} style={{ marginLeft: 20 }} >
                        {Roles && Roles.roles && Roles.roles.length > 0 ?

                            <CustomerDataTable
                                data-qaid="roles_datatable"
                                columns={columns}
                                rows={Roles.roles}
                                onClickAction={onColumnClick}
                                onPageChange={onPageinationChange}
                                style={{ width: '100%' }}
                                handleSort={handleSort}
                                onCheckedIds={onCheckedIds}
                                total={totalNumber(Roles.total)}
                            />
                            :
                            <Grid data-qaid="roles_labelView" item lg={12} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '10%', flexDirection: 'column' }}>
                                <H6 data-qaid="roles_noRecords_label" label={Context.langData.no_records_roles} style={{ textAlign: 'center', width: '55%', fontWeight: 'bold' }} />
                            </Grid>
                        }
                    </Grid>
                </>
                :
                <Grid data-qaid="roles_noRecordesMainView" item lg={12} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '10%', flexDirection: 'column' }}>
                    <H6 data-qaid="roles_noRecordes_label" label={Context.langData.no_roles_records} style={{ textAlign: 'center', width: '70%', fontWeight: 'bold' }} />
                    <Grid item data-qaid="roles_createButtonView" style={{ marginTop: 30 }}>
                        <Button className={style.buttonClass} data-qaid="roles_buttonView" style={{ backgroundColor: "#FFB600", color: "white", height: 40 }} variant="contained" id="cancel" onClick={() => navigate('/home/profile/employees/createRoleFirstStep')}  >
                            <CirclePluse data-qaid="customers_createpluse" style={{ marginRight: 5 }} />
                            <AvenirBlackH4 data-qaid="customers_createlabel" label={Context.langData.create_role} />
                        </Button>
                    </Grid>
                </Grid>
            }
            {
                snackbar &&
                <SnackBar
                    data-qaid="roles_snackbar"
                    open={true}
                    setOpen={() => setSnackbar(null)}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            }

            <AlertDialog
                data-qaid="roles_alertDailoge"
                showDialog={showDialog}
                body={bodyMessage}
                heading={heading}
                IsError={isError}
                handleClose={handleClose}
                showExtra={showExtra}
            />
        </Grid>
    )
}

export default Roles