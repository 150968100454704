import React, { useState, useContext, useMemo } from 'react';
import { Grid, Button, Slide, FormHelperText, DialogContent, Dialog, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core'
import InputText from '../../../components/InputText'
import SingleSelect from '../../../components/SingleSelect'
import { useSelector } from 'react-redux';
import ExclamationIcon from '../../../components/SVG/ExclamationIcon';
import { H4, AvenirBlackH4, H3, H2 } from '../../../utils/text';
import MyContext from '../../../Provider/MyContext';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CreateSection = ({ onSave, onClose }) => {
  const ordertypes = useSelector(state => state.metaReducer.ordertypes)
  const [name, setName] = useState('');
  const [otype, setOtype] = useState('');
  const [nameError, setNameerror] = useState('');
  const Context = useContext(MyContext);
  const [ordertypeError, setOrdertypeError] = useState('');

  var filterList = useMemo(() => {
    return ordertypes.filter(e => e.delivery_mode_id == 1 && e.order_channel_id == 1);
  }, [ordertypes])
  const save = () => {
    if (name && otype) {
      onSave({ name, otype })
    } else {
      if (!otype) {
        setOrdertypeError(Context.langData.ordertype_name_req_error)
      }
      if (!name) {
        setNameerror(Context.langData.section_name_req_error);
      }
    }
  }
  const setNameChange = (nameVal) => {
    setName(nameVal);
    setNameerror('');

  }
  const ordertypeChange = (orderTypeVal) => {
    setOtype(orderTypeVal);
    setOrdertypeError('');

  }
  return (
    <>
      {
        filterList?.length > 0 ?
          <Grid container style={{ padding: 30, borderRadius: 10, maxWidth: 400 }}>
            <Grid item xs={12}>
              <H3 label={Context.langData.create_section} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <InputText
                error={nameError}
                maxLength={64}
                inputProps={{
                  maxlength: 64,
                  disableUnderline: true,
                }}
                variant="outlined"
                placeholder="Section name"
                value={name}
                onChange={(e) => setNameChange(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <SingleSelect
                style={{ borderColor: ordertypeError ? '#AB1212' : '#707272' }}
                list={filterList}
                handleChange={(e) => ordertypeChange(e.target.value)}
                displayKey="otype_name"
                value={otype}
                valueKey="otype_id"
                placeholder="Select Order type"
                border={false}
                minWidth={'100%'}
                height={56}
              />
              {
                ordertypeError &&
                <FormHelperText data-qaid="inputtext_formhelpertext" style={{ color: "#AB1212", display: 'flex', margin: '8px 0px 0px 0px' }}>
                  <ExclamationIcon color="#AB1212" style={{ marginRight: 10 }} />
                  {ordertypeError}
                </FormHelperText>
              }
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ paddingLeft: 50, marginTop: 20 }} justify="flex-end">
                <Grid item xs={6} style={{ padding: 10 }}>
                  <Button onClick={onClose} variant="contained" size="large" style={{ backgroundColor: '#00B6C9', width: '100%' }}>
                    <AvenirBlackH4 label={'Cancel'} />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Grid container style={{ padding: 10 }}>
                    <Button onClick={save} variant="contained" size="large" style={{ backgroundColor: '#FFB600', width: '100%' }}>
                      <AvenirBlackH4 label={'Save'} />
                    </Button>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          :
          <div data-qaid="dialog_div">
            <Dialog
              data-qaid="dialog_dialog"
              open={true}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => console.log()}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle data-qaid="dialog_dialogtitle" id="alert-dialog-slide-title" style={{ paddingTop: '5%' }}>
                <H2 data-qaid="dialog_heading_label" label={Context.langData.create_section} />
              </DialogTitle>
              <DialogContent data-qaid="dialog_dialogcontent" >
                <DialogContentText data-qaid="dialog_dialogcontenttext" id="alert-dialog-slide-description">
                  <H4 data-qaid="dialog_body_label" label={Context.langData.please_choose_order} />
                </DialogContentText>
              </DialogContent>
              <DialogActions data-qaid="dialog_dialogactions">
                <Button data-qaid="dialog_ok_button" variant="contained" onClick={onClose} style={{ backgroundColor: '#FFB600', width: '96%', height: 44 }}>
                  <AvenirBlackH4 data-qaid="dialog_ok_label" label={Context.langData.ok} />
                </Button>
              </DialogActions>
            </Dialog>
          </div>
      }
    </>
  )
}

export default CreateSection