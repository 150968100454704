import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#FAFAFA',
      default: colors.common.white,
      paper: colors.common.white,
      topbar: '#FAFAFA',
      sidebar: '#373737',
      hover: '#33d150'
    },
    primary: {
      main: "#373737",
      fontFamily: 'AvenirLT'
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      price: '#f0585f'
    },
    button: {
      dark: '#373737',
      primary: '#373737'
    }
  },
  MuiInput: {
    input: {
      "&::placeholder": {
        color: "red"
      },
    }
  },
  shadows,
  typography
});

export default theme;
