import * as React from "react"

const SvgComponent = (props) => {
  return (
    <svg
      width={24}
      height={19}
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9 5.375h18.2a2.234 2.234 0 012.084 2.796l-2.134 8.985a2.192 2.192 0 01-2.1 1.719l-.014-.75v.75H5.05a2.192 2.192 0 01-2.1-1.719L.815 8.171A2.234 2.234 0 012.9 5.375zm16.028 12a.692.692 0 00.658-.545l.003-.014 2.138-9 .005-.02a.735.735 0 00-.673-.921H2.94a.734.734 0 00-.673.922l.005.019 2.138 9 .003.014a.692.692 0 00.658.545h13.856z"
        fill="#004C60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.488.306a.75.75 0 01.081 1.057l-4.5 5.25a.75.75 0 01-1.139-.976l4.5-5.25A.75.75 0 019.488.306zM14.512.306a.75.75 0 011.057.08l4.5 5.25a.75.75 0 11-1.139.977l-4.5-5.25a.75.75 0 01.082-1.057z"
        fill="#004C60"
      />
    </svg>
  )
}

const CartIcon = React.memo(SvgComponent)
export default CartIcon
