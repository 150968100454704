import * as React from "react"

const LockoutIcon = (props) => (
    <svg
        width={80}
        height={80}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M35 45H5a3.333 3.333 0 0 1-3.333-3.333V18.333A3.333 3.333 0 0 1 5 15h70a3.333 3.333 0 0 1 3.333 3.333V35.07M15 23.333v13.334M20.577 26.343l-11.15 7.313M20.547 33.701l-11.093-7.4M35 23.333v13.334M40.577 26.343l-11.15 7.313M40.547 33.701l-11.093-7.4M71.667 51.667H48.333A3.333 3.333 0 0 0 45 55v19.997a3.333 3.333 0 0 0 3.333 3.333h23.334A3.333 3.333 0 0 0 75 74.997V55a3.333 3.333 0 0 0-3.333-3.333Z"
            stroke="#000"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M60 69.376a4.167 4.167 0 1 0 0-8.334 4.167 4.167 0 0 0 0 8.334ZM68.333 46.666a8.333 8.333 0 1 0-16.666 0v5h16.666v-5Z"
            stroke="#000"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default LockoutIcon
