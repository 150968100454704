import _ from "lodash";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import CenterModal from "../../../components/CenterModal";
import Postion from "../../../components/Position";
import MyContext from "../../../Provider/MyContext";
import {
    createCatePostion
} from "../../../redux/actions";
import styles from "../../../styles/index";
const SetPostionItem = ({ open, callback, onClose, itemsList }) => {
    const style = styles();
    const dispatch = useDispatch();
    const Context = useContext(MyContext);
    const CloseCallback = (value, data) => {
        console.log("value", value, "data", data);
        if (value) {
            if (data.length > 0) {
                var array = [];
                data.map((item, index) => {
                    var obj = [];
                    obj.push(item.cat_id);
                    obj.push(index);
                    array.push(obj);
                });
                dispatch(createCatePostion(array, callback));
            }
        } else {
            onClose();
        }
    };
    return (
        <CenterModal data-qaid="setpostion_centarmodal" open={open}>
            <Postion
                data-qaid="setpostion_postion"
                data={itemsList}
                callBack={CloseCallback}
            ></Postion>
        </CenterModal>
    );
};

export default SetPostionItem