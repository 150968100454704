import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import metaReducer from './metaReducer';
import loginReducer from './loginReducer';
import profileReducers from './profileReducer';
import storage from 'redux-persist/lib/storage';
import dashboard from './dashboard';
import inventory from './inventory';
import menu from './menu';
import settingsReducer from './settingsReducer';
import transactions from './transactions';
import floorplan from './floorplan';
import salesReportReducer from './salesReportReducer';
import inventoryReportReducer from './inventoryReportReducer';
import builder from './builder';
import pettyCashReducer from './pettyCashReducer';
import eodReportsReducer from './eodReportsReducer';
import taxConfigurationReducer from './taxConfigurationReducer';

const intialState = { appPath: null, loading: false };
function loader(state = intialState, action) {
  switch (action.type) {
    case 'SET_APP_PATH':
      return { ...state, appPath: action.payload };
    case 'LOADING':
      return { ...state, loading: true };
    case 'LOADED':
      return { ...state, loading: false };
    case 'CLEAR':
      return { ...intialState };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  metaReducer,
  loginReducer,
  loader,
  profileReducers,
  dashboard,
  menu,
  inventory,
  transactions,
  settingsReducer,
  floorplan,
  salesReportReducer,
  inventoryReportReducer,
  builder,
  pettyCashReducer,
  eodReportsReducer,
  taxConfigurationReducer,
});
export default rootReducer;
