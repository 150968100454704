import React, { useState, useContext, useEffect } from 'react';
import {
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import TextInputComponent from '../components/TextInputComponent';
import LeftVector from '../components/SVG/LeftVector';
import SingleLeftVector from '../components/SVG/SingleLeftVector';
import SingleRightVector from '../components/SVG/SingleRightVector';
import RightVector from '../components/SVG/RightVector'
import MyContext from '../Provider/MyContext';
import IconButton from '@material-ui/core/IconButton';
const CustomPagination = (props) => {
    const [currentPage, setCurrentPage] = useState(props?.offeset || 1);
    const Context = useContext(MyContext);
    const totalCount = props.total;
    useEffect(()=>{
        if(currentPage !== '' && props.onChange)
        props.onChange(currentPage-1)
    },[currentPage,props])
    const onIncreasePage = () => {
        if (props.total <= currentPage) {
        } else {
            if (currentPage == '') {
                setCurrentPage(1)
                // props.onChange(1)
            } else {
                setCurrentPage(currentPage + 1)
                // props.onChange(currentPage)
            }
        }
    }
    const onDecreasePage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
            // props.onChange(currentPage )
        } else {
            setCurrentPage(1)
            // props.onChange(1)
        }
    }

    const onLastPage = () => {
        setCurrentPage(props.total)
        // props.onChange(props.total - 1)
    }
    const onFirstPage = () => {
        setCurrentPage(1)
        // props.onChange(1)
    }
    const ongotoPage = (value) => {
        if (value <= props.total || value == '') {
            if (value >= 1 && value <= props.total) {
                // props.onChange(value)
                setCurrentPage(parseInt(value))
            } else if (value == '') {
                setCurrentPage('')
            } else {

            }
        }
    }
    const btnProps = {};
    if (currentPage == 1) {
        btnProps["disabled"] = true
    }

    return (
        <Grid data-qaid="custompagination_main_grid" container>
            <Grid data-qaid="custompagination_content_grid" item style={{ display: 'flex', alignItems: 'center', width: '100%' }}>

                <IconButton {...btnProps} data-qaid="custompagination_leftvecto_iconbutton" onClick={onFirstPage} >
                    < LeftVector data-qaid="custompagination_leftvector_icon" color={currentPage == 1 ? '#C3C3C3' : '#004C60'} style={{ cursor: 'pointer' }} ></LeftVector>
                </IconButton>
                <IconButton {...btnProps} data-qaid="custompagination_single_left_iconbutton" onClick={onDecreasePage}>
                    <SingleLeftVector color={currentPage == 1 ? '#C3C3C3' : '#004C60'} data-qaid="custompagination_single_left_icon" style={{ cursor: 'pointer', marginRight: 5 }} />
                </IconButton>
                <div data-qaid="custompagination_number_div" style={{ width: 64, backgroundColor: '#FAFAFA', height: 56, borderRadius: 4, border: '2px solid #C3C3C3', outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <TextField
                        data-qaid="custompagination_textinputcomponet"
                        value={currentPage}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        style={{ textAlign: 'center', paddingLeft: 25 }}
                        onChange={(e) => { ongotoPage(e.target.value) }}
                        variant="standard"
                    />
                </div>
                <span data-qaid="custompagination_of span" style={{ paddingLeft: '15px', paddingRight: '5px' }}><Typography variant="h5" >{Context.langData.of}</Typography></span>
                <span data-qaid="custompagination_total_span" style={{ paddingRight: '5px' }}><Typography variant="h5" >{props.total == 0 ? 1 : props.total }</Typography></span>
                <IconButton data-qaid="custompagination_single_right_iconbutton" onClick={onIncreasePage}>
                    <SingleRightVector color={currentPage == totalCount ? '#C3C3C3' : '#004C60'} data-qaid="custompagination_single_right_icon" style={{ cursor: 'pointer' }} />
                </IconButton>
                <IconButton data-qaid="custompagination_rightVector_iconbutton" onClick={onLastPage} >
                    <RightVector color={currentPage == totalCount ? '#C3C3C3' : '#004C60'} data-qaid="custompagination_rightVector_icon" style={{ cursor: 'pointer' }} ></RightVector>
                </IconButton>
            </Grid>
        </Grid >
    )
}

export default CustomPagination;