import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Title from "./Title";
import { DotText, H4 } from "../../../../utils/text";
import { ConvertPrice } from "../../../../utils/util";
import VariablePricing from "../pizzas/VariablePricing";
import DietaryAttribute from "../pizzas/Dietary";
import TaxMode from "../create/taxMode";
const Advanced = ({ advanced, setAdvanced, ordertypes }) => {
  const [editingPricing, toggleEditingPricing] = useState(false);
  const [isEditingDietary, toggleDietary] = useState(false);
  let { variablePrice, attributes } = advanced;

  const onEditPricing = () => {
    console.log("onPress");
    toggleEditingPricing(true);
  };
  const onSavePrices = (prices) => {
    setAdvanced({ ...advanced, variablePrice: prices });
    toggleEditingPricing(false);
  };
  const onSaveDietary = (values) => {
    setAdvanced({ ...advanced, attributes: values });
    toggleDietary(false);
  };

  return (
    <Grid container style={{ padding: "0px 24px", paddingBottom: 50 }}>
      <Grid
        container
        direction="row"
        style={{
          marginTop: 15,
          // borderBottom: "1px solid #EEE",
          paddingBottom: 20,
        }}>
        <Title
          title={"Variable pricing (order type)"}
          edit={true}
          onPress={onEditPricing}
          marginLeft={0}
        />
        <Grid container style={{ marginLeft: 4 }}>
          <Grid container style={{ marginTop: 16 }}>
            <Grid item xs={6} md={8}>
              <H4 label="Name" style={{ color: "#707272" }} />
            </Grid>
            <Grid item xs={6} md={4}>
              <H4 label="Price" style={{ color: "#707272" }} />
            </Grid>
          </Grid>
          {editingPricing ? (
            <VariablePricing
              open={editingPricing}
              prices={variablePrice}
              onSave={onSavePrices}
              onClose={() => toggleEditingPricing(false)}
            />
          ) : (
            <Grid container>
              {variablePrice.map((vp, index) => {
                return (
                  <Grid container key={index} style={{ marginTop: 16 }}>
                    <Grid item xs={6} md={8}>
                      <DotText label={vp.otype_name} />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <H4 label={`+$${ConvertPrice(vp.price)}`} />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{
          marginTop: 15,
          // borderBottom: "1px solid #EEE",
          paddingBottom: 20,
        }}>
        <Title
          title={"Dietary attributes"}
          edit={true}
          onPress={() => toggleDietary(true)}
          marginLeft={0}
        />
        <Grid container style={{ marginLeft: 4 }}>
          {advanced.attributes.map((attr, index) => {
            return (
              <Grid container key={index} style={{ marginTop: 10 }}>
                <Grid item xs={12}>
                  <H4 label={attr} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {isEditingDietary && (
        <DietaryAttribute
          dietary={attributes}
          open={isEditingDietary}
          onSaveDietary={onSaveDietary}
          onClose={() => toggleDietary(false)}
        />
      )}
    </Grid>
  );
};

export default Advanced;
