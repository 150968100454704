import React from 'react';
import Modal from '@material-ui/core/Modal';
import $ from 'jquery'
const CenterModal = ({ open, onClose, children, borderRadius = 0 }) => {
    const handleClose = (event) => {
        const s = $(event.target).closest("#innerView").length;
        const value = event.target?.value || 'true';
        console.log("sssssssssss", s, "eventeventevent12", value)
        if (s == 0 && onClose && value == 'true') {
            onClose()
        }
    }
    return (
        <Modal
            data-qaid="centermodal_modal"
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div
                data-qaid="centermodal_child_div"
                onClick={handleClose}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                <div id="innerView" data-qaid="centermodal_sub_div" style={{ borderRadius: borderRadius, backgroundColor: "#FFFFFF", alignSelf: 'center' }}>
                    {children}
                </div>
            </div>
        </Modal>
    )
}

export default CenterModal;