import * as React from "react"

function DownlodeIcon({ width = 20, height = 20, color = "#051D33", ...props }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.458.389a.458.458 0 10-.917 0v11.077a.458.458 0 00.917 0V.389z"
                fill={color}
                stroke={color}
                strokeWidth={0.25}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.691 7.846a.458.458 0 10-.716.573l2.667 3.333a.458.458 0 00.716 0L9.025 8.42a.458.458 0 00-.716-.573L6 10.732 3.691 7.846z"
                fill={color}
                stroke={color}
                strokeWidth={0.25}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.333 4.597A1.125 1.125 0 00.208 5.722v9.334a1.125 1.125 0 001.125 1.124h9.334a1.125 1.125 0 001.125-1.124V5.722a1.125 1.125 0 00-1.125-1.125h-3a.458.458 0 100 .917h3a.208.208 0 01.208.208v9.334a.208.208 0 01-.208.208H1.333a.208.208 0 01-.208-.208V5.722a.208.208 0 01.208-.208h3a.458.458 0 100-.917h-3z"
                fill={color}
                stroke={color}
                strokeWidth={0.25}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default DownlodeIcon
