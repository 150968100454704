import _ from 'lodash';

const handleSorting = (value, table, sortValue) => {
  let sortEodReports;
  if (value === 'executedAt') {
    sortEodReports = _.orderBy(
      table,
      [(item) => new Date(item[value])],
      [sortValue ? 'desc' : 'asc']
    );
  } else {
    sortEodReports = _.orderBy(table, [value], [sortValue ? 'desc' : 'asc']);
  }
  return sortEodReports;
};

export { handleSorting };
