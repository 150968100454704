import React from 'react';

function SearchIcon(props) {
    return (
        <svg
            {...props}
            width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.92557 3.89434C4.05454 5.53949 2.25011 10.0112 3.89526 13.8823C5.54041 17.7533 10.0122 19.5577 13.8832 17.9126C17.7542 16.2674 19.5587 11.7957 17.9135 7.92466C16.2684 4.05363 11.7966 2.24919 7.92557 3.89434ZM2.51476 14.469C0.545581 9.83551 2.70541 4.48301 7.33887 2.51384C11.9723 0.544666 17.3248 2.7045 19.294 7.33796C21.2632 11.9714 19.1033 17.3239 14.4699 19.2931C9.83642 21.2623 4.48393 19.1024 2.51476 14.469Z" fill="#004C60" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.289 16.2881C16.582 15.9952 17.0568 15.9953 17.3497 16.2882L23.838 22.7774C24.1309 23.0703 24.1308 23.5452 23.8379 23.8381C23.545 24.131 23.0701 24.1309 22.7773 23.838L16.289 17.3488C15.9961 17.0559 15.9961 16.581 16.289 16.2881Z" fill="#004C60" />
        </svg>
    )
}

export default SearchIcon;