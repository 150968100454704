import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, } from '@material-ui/core'
import _ from 'lodash';
import {
    AvenirBlackH4, AvenirBlackH3, H6
} from '../../../utils/text';
import RightDrawer from '../../../components/RightDrawer'
import CircleClose from '../../../components/SVG/CloseCircle';
import { ConvertPrice } from '../../../utils/util';
import styles from '../../../styles/index';
import BackIcon from '../../../components/SVG/Back';
import InputText from '../../../components/InputText';
import MyContext from '../../../Provider/MyContext';

const ReasonForRefundModal = ({ open, callback, onClose }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const dispatch = useDispatch();
    const [reason, setReason] = useState('');
    const refundObj = useSelector(state => state.transactions.refundObj);
    console.log("refundObj....", refundObj)
    const handleSaveReason = () => {
        callback(true);
    }
    const handleChangeReason = (value) => {
        var obj = { ...refundObj, "refund_comment": value };
        dispatch({ type: "SET_TRANSACTION_REFUND", payload: obj })
        setReason(value)
    }
    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto' }}>
                        <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, marginTop: 20 }}>
                            <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={() => onClose(true)} style={{ marginRight: 40, cursor: 'pointer' }} />
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, marginLeft: 49 }}>
                            <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-start', width: '40%', alignItems: 'center' }}>
                                <BackIcon data-qaid="createcategory_closeicon" size={20} onClick={() => onClose(false)} style={{ marginRight: 40, cursor: 'pointer' }} />
                            </Grid>
                        </Grid>
                        <Grid data-qaid="transactions_refund_main_grid" container direction="column" style={{ marginLeft: 49, marginBottom: 20, marginRight: 49, width: 500 }} >
                            <H6 label={Context.langData.reason_for_refund} style={{ marginBottom: 32 }} />
                            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                <InputText
                                    value={reason}
                                    height={140}
                                    inputPlaceholder={Context.langData.reason_placeholder}
                                    onChange={(val) => handleChangeReason(val.target.value)}
                                    id="name"
                                    multiline
                                    inputProps={{ step: 0.01, min: 0.00 }}
                                    rows={6}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="transactions_refund_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                            <Grid data-qaid="transactions_refund_cancel_btn" item xs={6} lg={6} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 32, marginBottom: 32, paddingLeft: 49, alignItems: 'center' }}>
                                <AvenirBlackH3 label={`${Context.langData.refund_total_amount}${ConvertPrice(refundObj.refund_amount)}`} style={{ color: '#000000' }} />
                            </Grid>
                            <Grid data-qaid="transactions_refund_cancel_btn" item xs={6} lg={6} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="transactions_refund_cancel_button" onClick={() => { callback(true) }} variant="outlined" style={{ backgroundColor: '#FFFFFF', marginRight: 16, border: '2px solid #707272', width: 103 }} >
                                    <AvenirBlackH4 data-qaid="transactions_refund_cancel_text" label={Context.langData.cancel} style={{ color: '#004C60' }} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="transactions_refund_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 49 }}
                                    onClick={() => { handleSaveReason() }}
                                >
                                    <AvenirBlackH4 data-qaid="transactions_refund_save_text" label={Context.langData.continue} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        </RightDrawer>
    )
}

export default ReasonForRefundModal