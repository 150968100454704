import React from 'react';
import RefreshIcon from './SVG/RefreshIcon';
import IconButton from '@material-ui/core/IconButton';

const RefreshButton = ({ refresh, ...props }) => {
  return (
    <IconButton data-qaid="refreshbutton_iconbutton" aria-label="delete" onClick={refresh} {...props}>
      <RefreshIcon data-qaid="refreshbutton_refresh_icon" />
    </IconButton>
  )
}

export default React.memo(RefreshButton)