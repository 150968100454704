import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, Menu, MenuItem } from '@material-ui/core';
import {
  fetchSalesSummary,
  fetchpaymentMethods,
  fetchorderType,
  fetchshiftReport,
  fetchtaxType,
} from '../../../redux/actions';
import _ from 'lodash';
import Daterange from '../../../components/DateRange';
import {
  AvenirBlackH4,
  AvenirBlackH2,
  AvenirBlackH7,
  TBold,
} from '../../../utils/text';
import MultiSelect from '../../../components/MultiSelect';
import MyContext from '../../../Provider/MyContext';
import CircleClose from '../../../components/SVG/CircleClose';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import SalesTable from '../../../components/SalesTable';
import { downloadSalesReport } from '../../../utils/excel';
import { convertNormalCase, ConvertPrice } from '../../../utils/util';
import { downloadSalesSummaryPdfDocument } from '../../../components/PDFTableGenerator';
const SalesSummaryTabs = () => {
  const Context = useContext(MyContext);
  const [table1, setTable1] = useState([]);
  const [table2, setTable2] = useState([]);
  const [table3, setTable3] = useState([]);
  const [table4, setTable4] = useState([]);
  const [table5, setTable5] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paymentColumns, setPaymentColumns] = useState([
    {
      field: 'type',
      display: Context.langData.payment_methods,
      size: 2,
    },
    {
      field: 'subtotal',
      display: Context.langData.subtotal,
      size: 2,
      isNumber: true,
    },
    {
      field: 'discount',
      display: Context.langData.discount_promotions,
      size: 2,
      isNumber: true,
    },
    {
      field: 'taxes',
      display: Context.langData.tax,
      size: 2,
      isNumber: true,
    },
    {
      field: 'transactions',
      display: Context.langData.transactions,
      size: 2,
      isNumber: false,
    },
    {
      field: 'sales',
      display: Context.langData.total,
      size: 2,
      isNumber: true,
    },
    {
      field: 'rounding',
      display: Context.langData.rounding,
      size: 2,
      isNumber: true,
    },
  ]);

  const [orderTypeColumns, setOrderTypeColumns] = useState([
    { field: 'type', display: Context.langData.otype, size: 3 },
    {
      field: 'subtotal',
      display: Context.langData.subtotal,
      size: 2,
      isNumber: true,
    },
    {
      field: 'discount',
      display: Context.langData.discount_promotions,
      size: 3,
      isNumber: true,
    },
    { field: 'taxes', display: Context.langData.tax, size: 2, isNumber: true },
    {
      field: 'transactions',
      display: Context.langData.transactions,
      size: 2,
      isNumber: false,
    },
    {
      field: 'sales',
      display: Context.langData.total,
      size: 2,
      isNumber: true,
    },
  ]);

  const [shiftsColumns, setShiftsColumns] = useState([
    { field: 'type', display: Context.langData.shifts, size: 3 },
    {
      field: 'subtotal',
      display: Context.langData.subtotal,
      size: 2,
      isNumber: true,
    },
    {
      field: 'discount',
      display: Context.langData.discount_promotions,
      size: 3,
      isNumber: true,
    },
    { field: 'taxes', display: Context.langData.tax, size: 2, isNumber: true },
    {
      field: 'transactions',
      display: Context.langData.transactions,
      size: 2,
      isNumber: false,
    },
    {
      field: 'sales',
      display: Context.langData.total,
      size: 2,
      isNumber: true,
    },
  ]);

  const [taxColumns, setTaxColumns] = useState([
    { field: 'type', display: Context.langData.tax_type, size: 4 },
    {
      field: 'taxable_amt',
      display: Context.langData.total_collected,
      size: 4,
      isNumber: true,
    },
    {
      field: 'sales',
      display: Context.langData.taxable_amount,
      size: 4,
      isNumber: true,
    },
  ]);
  const dispatch = useDispatch();
  const salesSummary = useSelector(
    (state) => state.salesReportReducer.salesSummary
  );
  const paymentMethod = useSelector(
    (state) => state.salesReportReducer.paymentMethods
  );
  const orderType = useSelector((state) => state.salesReportReducer.orderTypes);
  const shiftsData = useSelector(
    (state) => state.salesReportReducer.shiftReports
  );
  const taxType = useSelector((state) => state.salesReportReducer.taxTypes);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const [date, setDate] = useState({startDate: moment().startOf('day').format("YYYY-MM-DD"), endDate:moment().endOf('day').format("YYYY-MM-DD")});
  const [ordertype, setOrderType] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const onDateChange = (d) => {
    console.log({date, d}); 
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };
  const [resetDate, toggleResetDate] = useState(false);
  useEffect(() => {
    handleApplyDropdown();
  }, [date, ordertype]);
  useEffect(() => {
    if (salesSummary?.data?.length > 0) {
      setGrandTotal(salesSummary.Total);
      setTable1(salesSummary.data);
    } else {
      setTable1();
    }

    if (paymentMethod?.data?.length > 0) {
      setTable2(paymentMethod.data);
    } else {
      setTable2([]);
    }

    if (orderType?.data?.length > 0) {
      setTable3(orderType.data);
    } else {
      setTable3([]);
    }

    if (shiftsData?.data?.length > 0) {
      setTable4(shiftsData.data);
    } else {
      setTable4([]);
    }

    if (taxType?.data?.length > 0) {
      setTable5(taxType.data);
    } else {
      setTable5([]);
    }
  }, [salesSummary, paymentMethod, orderType, shiftsData, taxType]);

  const handleApplyDropdown = () => {
    let obj = {
      from_date: date.startDate,
      to_date: date.endDate
    };
    if (ordertype.length > 0) {
      obj = {
        ...obj,
        order_types: ordertype.join(','),
      };
    }
    dispatch(fetchorderType(obj));
    dispatch(fetchSalesSummary(obj));
    dispatch(fetchpaymentMethods(obj));
    dispatch(fetchshiftReport(obj));
    dispatch(fetchtaxType(obj));
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const downloadExcel = () => {
    downloadSalesReport(
      salesSummary,
      paymentMethod,
      orderType,
      shiftsData,
      taxType
    );
    handleCloseMenu();
  };

  const downloadPDF = () => {
    const dataToPDF = [
      { ...paymentMethod, headers: paymentColumns },
      { ...orderType, headers: orderTypeColumns },
      { ...shiftsData, headers: shiftsColumns },
      { ...taxType, headers: taxColumns },
    ];
    downloadSalesSummaryPdfDocument(
      dataToPDF,
      Context.langData.sales_summary,
      date,
      'SalesSummary',
      salesSummary
    );
    handleCloseMenu();
  };

  const paymentHandleSort = (name, desc, index) => {
    var columnData = [...paymentColumns];
    columnData[index] = { ...columnData[index], desc };
    setPaymentColumns(columnData);
    var sortPaymentwiseSales = _.orderBy(
      table2,
      [name],
      [desc ? 'desc' : 'asc']
    );
    setTable2(sortPaymentwiseSales);
  };

  const orderTypeHandleSort = (name, desc, index) => {
    var columnData = [...orderTypeColumns];
    columnData[index] = { ...columnData[index], desc };
    setOrderTypeColumns(columnData);
    var sortOrderTypewiseSales = _.orderBy(
      table3,
      [name],
      [desc ? 'desc' : 'asc']
    );
    setTable3(sortOrderTypewiseSales);
  };

  const shiftsHandleSort = (name, desc, index) => {
    var columnData = [...shiftsColumns];
    columnData[index] = { ...columnData[index], desc };
    setShiftsColumns(columnData);
    var sortshiftwiseSales = _.orderBy(table4, [name], [desc ? 'desc' : 'asc']);
    setTable4(sortshiftwiseSales);
  };

  const taxHandleSort = (name, desc, index) => {
    var columnData = [...taxColumns];
    columnData[index] = { ...columnData[index], desc };
    setTaxColumns(columnData);
    var sortTaxwiseSales = _.orderBy(table5, [name], [desc ? 'desc' : 'asc']);
    setTable5(sortTaxwiseSales);
  };

  const onClear = () => {
    setOrderType([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };

  return (
    <Grid
      data-qaid="salesSummary_mainContainer"
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid data-qaid="salesSummary_subContainer1" container>
        <Grid
          data-qaid="salesSummary_dateContainer"
          item
          lg={8}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Grid data-qaid="salesSummary_dateGrid" item lg={5} style={{}}>
            <AvenirBlackH4
              data-qaid="salesSummary_dateLabel"
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid="salesSummary_dateRange"
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
          <Grid
            data-qaid="salesSummary_orderTypeGrid"
            item
            style={{ paddingLeft: 10 }}>
            <AvenirBlackH4
              data-qaid="salesSummary_orderTypeLabel"
              label={Context.langData.order_types}
            />
            <MultiSelect
              list={ordertypes}
              value="otype_id"
              name="otype_name"
              placeholder="All order types"
              selectedValue={ordertype}
              handleChange={(e) => setOrderType(e)}
              border={false}
              height={56}
              width={298}
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid="salesSummary_clearContainer"
          item
          lg={4}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}>
          <Grid
            data-qaid="salesSummary_clearGrid"
            onClick={onClear}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 20,
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <CircleClose data-qaid="salesSummary_circleCloseIcon"></CircleClose>
            <AvenirBlackH4
              data-qaid="salesSummary_clearLabel"
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid="salesSummary_downlodeButtonGrid"
            style={{
              backgroundColor: '#FFB600',
              height: 40,
              marginRight: 0,
              width: '149px',
            }}
            variant="contained"
            id="cancel">
            <DownlodeIcon
              data-qaid="salesSummary_downlodeIcon"
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid="salesSummary_downlodelabel"
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id="simple-menu-download"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {table3.length > 0 ? (
        <>
          <Grid
            data-qaid="salesSummary_subContainer2"
            container
            style={{ marginTop: 40 }}>
            <Grid
              data-qaid="salesSummary_subTotalContainer"
              container
              lg={8}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}>
              {table1?.length > 0 ? (
                table1.map((item, index) => {
                  if (item.type != 'Packaging Charges') {
                    return (
                      <>
                        <Grid
                          key={index}
                          data-qaid={`salesSummary_${item.type}Grid`}
                          style={{
                            flexDirection: 'row',
                            dispaly: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <AvenirBlackH2
                            data-qaid={`salesSummary_${item.type}sales`}
                            label={`$${ConvertPrice(item.sales)}`}
                            style={{ fontWeight: 'bold' }}
                          />
                          <AvenirBlackH4
                            data-qaid={`salesSummary_${item.type}salestype`}
                            label={convertNormalCase(item.type)}
                            style={{ color: '#707272' }}
                          />
                        </Grid>
                      </>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </Grid>
            <Grid
              data-qaid="salesSummary_grandTotalGrid"
              container
              lg={4}
              style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid
                data-qaid="salesSummary_grandTotalView"
                style={{
                  flexDirection: 'row',
                  dispaly: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <AvenirBlackH7
                  data-qaid="salesSummary_grandTotalLabe1"
                  label={`$${ConvertPrice(grandTotal)}`}
                  style={{ fontWeight: 'bold' }}
                />
                <AvenirBlackH4
                  data-qaid="salesSummary_grandTotalLabe2"
                  label={Context.langData.grand_total}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid="salesSummary_subContainer3"
            container
            style={{ marginTop: 0 }}>
            <Grid
              data-qaid="salesSummary_paymentColumnsGrid"
              item
              lg={12}
              style={{ paddingTop: 40 }}>
              <SalesTable
                data-qaid="salesSummary_paymentColumns"
                handleSort={(name, desc, colIndex) =>
                  paymentHandleSort(name, desc, colIndex)
                }
                columns={paymentColumns}
                rows={table2}
              />
              {table2.length <= 0 && (
                <Grid
                  item
                  lg={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: 8,
                  }}>
                  <AvenirBlackH4
                    data-qaid="salesSummary_grandTotalLabe2"
                    label={'No results available'}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              data-qaid="salesSummary_orderTypeColumnsGrid"
              item
              lg={12}
              style={{ paddingTop: 40 }}>
              <SalesTable
                data-qaid="salesSummary_orderTypeColumns"
                handleSort={(name, desc, colIndex) =>
                  orderTypeHandleSort(name, desc, colIndex)
                }
                columns={orderTypeColumns}
                rows={table3}
              />
              {table3.length <= 0 && (
                <Grid
                  item
                  lg={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: 8,
                  }}>
                  <AvenirBlackH4
                    data-qaid="salesSummary_grandTotalLabe2"
                    label={'No results available'}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              data-qaid="salesSummary_shiftsColumnsGrid"
              item
              lg={12}
              style={{ paddingTop: 40 }}>
              <SalesTable
                data-qaid="salesSummary_shiftsColumns"
                handleSort={(name, desc, colIndex) =>
                  shiftsHandleSort(name, desc, colIndex)
                }
                columns={shiftsColumns}
                rows={table4}
              />
              {table4.length <= 0 && (
                <Grid
                  item
                  lg={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: 8,
                  }}>
                  <AvenirBlackH4
                    data-qaid="salesSummary_grandTotalLabe2"
                    label={'No results available'}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              data-qaid="salesSummary_taxColumnsGrid"
              item
              lg={12}
              style={{ paddingTop: 40 }}>
              <SalesTable
                data-qaid="salesSummary_taxColumns"
                handleSort={(name, desc, colIndex) =>
                  taxHandleSort(name, desc, colIndex)
                }
                columns={taxColumns}
                rows={table5}
              />
              {table5.length <= 0 && (
                <Grid
                  item
                  lg={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: 8,
                  }}>
                  <AvenirBlackH4
                    data-qaid="salesSummary_grandTotalLabe2"
                    label={'No results available'}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          item
          lg={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: 20,
          }}>
          <TBold
            label={Context.langData.no_results_available}
            style={{ fontSize: 32, color: '#051D33', marginTop: 20 }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SalesSummaryTabs;
