import * as React from "react"

function LeftVector({ color = '#004C60', ...props }) {
    return (
        <svg
            width={15}
            height={13}
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.5 12.006l1.41-1.41-4.58-4.59 4.58-4.59L13.5.006l-6 6 6 6z"
                fill={color}
            />
            <path
                d="M6.5 12.006l1.41-1.41-4.58-4.59 4.58-4.59L6.5.006l-6 6 6 6z"
                fill={color}
            />
        </svg>
    )
}

export default LeftVector
