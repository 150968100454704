import React, { useEffect, useContext, useMemo } from 'react';
import useStyles from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Button, Grid
} from '@material-ui/core';
import { H1, H2, H3, H4, H6, AvenirBlackH4 } from '../../utils/text'
import { useState } from 'react';
import Customers from './customers';
import Employees from './employees';
import MyContext from '../../Provider/MyContext';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchRolePermissions } from '../../redux/actions'
import NoPermissionPage from '../../components/NoPermission';
const Profile = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userPermissions = useSelector(state => state.loginReducer.userPermissions);
  const Context = useContext(MyContext);
  const [buttonArray, setButtonArray] = useState([]);
  const profilelist = [
    // { key: "customers", title: Context.langData.customer_s, id: 0 },
    { key: "employees", title: Context.langData.employees, id: 0 }
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isEmp, setIsEmp] = useState(true);
  const [title, setTitle] = useState(Context.langData.customer_s);
  const isItemHasPermission = useMemo(() => {
    var buttonName = [];
    var buttonCount = 0;
    profilelist.map((childItem) => {
      var obj = userPermissions.filter((e) => e.key == childItem.key);
      if (obj.length > 0) {
        if (obj[0].status) {
          buttonCount = buttonCount + 1;
          childObj = { ...childItem, isPermission: true }
        } else {
          childObj = { ...childItem, isPermission: false }
        }
        buttonName.push(childObj)
      } else {
        var childObj = { ...childItem, isPermission: false }
        buttonName.push(childObj)
      }
    })
    // if (buttonCount == 0) {
    //   var isRoleObj = userPermissions.filter((e) => e.key == 'employee_roles');
    //   if (isRoleObj.length > 0) {
    //     buttonCount = buttonCount + 1;
    //     buttonName[1] = { ...buttonName[1], isPermission: true }
    //   } else {
    //     buttonName[1] = { ...buttonName[1], isPermission: false }
    //   }
    // }
    if (buttonCount == 0) {
      setButtonArray([])
    } else {
      setButtonArray(buttonName)
    }
    var result = true;
    if (buttonName.length > 0) {
      result = buttonName[selectedIndex].isPermission;
    } else {
      result = false;
    }
    return result;
  }, [userPermissions])
  useEffect(() => {
    dispatch(fetchRolePermissions())
    if (state && state.tab) {
      setIsEmp(state.emp);
      setSelectedIndex(state.tab)
    }
  }, [state])

  const handleSelectItem = (item) => {
    setSelectedIndex(item)
    setTitle(Context.langData.employees)
    // if (item == 0) {
    //   setTitle(Context.langData.customer_s)
    // } else {
    //   setTitle(Context.langData.employees)
    // }
  }
  const renderSelectedView = () => {
    // hide customers tab
    // if (selectedIndex == 0) {
    //   if (isItemHasPermission) {
    //     return (
    //       <Customers data-qaid="profile_customers"></Customers>
    //     )
    //   } else {
    //     return (
    //       <NoPermissionPage data-qaid="profile_customers_nopermissions" />
    //     )
    //   }
    // } else if (selectedIndex == 1) {
    //   if (isItemHasPermission) {
    //     return (
    //       <Employees data-qaid="profile_employees" tabIndex={isEmp}></Employees>
    //     )
    //   } else {
    //     return (
    //       <NoPermissionPage data-qaid="profile_employees_nopermissions" />
    //     )
    //   }
    // }
    if (selectedIndex == 0) {
      if (isItemHasPermission) {
        return (
          <Employees data-qaid="profile_employees" tabIndex={isEmp}></Employees>
        )
      } else {
        return (
          <NoPermissionPage data-qaid="profile_employees_nopermissions" />
        )
      }
    }
  }
  return (
    <Grid data-qaid="profile_index_grid" style={{ backgroundColor: '#FFFFFF', minHeight: '100%', width: '100%', overflow: 'hidden' }}>
      <Grid data-qaid="profile_index_contentgrid" style={{ height: '20%' }}>
        <Grid data-qaid="profile_index_titlegrid" item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: 36 }}>
          <H6 data-qaid="profile_index_title_label" label={Context.langData.profiles} style={{ color: "#051D33", paddingTop: 36, fontWeigth: '800' }}></H6>
        </Grid>
        <Grid data-qaid="profile_index_bodygrid" item xs={12} sm={12} md={12} lg={12} style={{ margin: 36, flexDirextion: 'row' }}>
          {
            buttonArray.map((item, i) => {
              return (
                <Button data-qaid="profile_button" style={{ backgroundColor: selectedIndex == item.id ? '#051D33' : '#EEEEEE', marginRight: 1, borderRadius: 0, borderTopLeftRadius: i == 0 ? 5 : 0, borderBottomLeftRadius: i == 0 ? 5 : 0, borderTopRightRadius: buttonArray.length - 1 == i ? 5 : 0, borderBottomRightRadius: buttonArray.length - 1 == i ? 5 : 0, height: 52, width: 101 }} onClick={() => handleSelectItem(item.id)}>
                  <AvenirBlackH4 data-qaid="profile_button_label" label={item.title} style={{ color: selectedIndex == item.id ? '#FFFFFF' : '#051D33' }} />
                </Button>
              )
            }
            )
          }
        </Grid>
        <Grid data-qaid="profile_border" style={{ borderTop: "1px solid #EEEEEE" }}>
        </Grid>
      </Grid>
      <Grid data-qaid="profile_body_view" style={{ height: '80%' }}>
        {renderSelectedView()}
      </Grid>
    </Grid >
  );
};

export default Profile;