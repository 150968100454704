import React from "react";
import { Grid } from "@material-ui/core";
import { H4, T, TBold } from "../../utils/text";
import { useLocation, useNavigate } from "react-router-dom";
import packageInfo from "../../../package.json";
import TopBar from "../../layouts/DashboardLayout/TopBar";

const ErrorView = (props) => {
  const { status, message } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const customMessage = location.state?.message;

  const goHome = () => {
    // const unique_name = window.sessionStorage.getItem("unique_name")
    navigate(`/${packageInfo.routepath}`);
  };
  const hasContainHome = location.pathname.indexOf("home") !== -1;
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh", backgroundColor: "#F3FAFD" }}
    >
      {!hasContainHome && <TopBar showUser={false} />}

      <Grid container style={{ width: 557 }}>
        <Grid container justify="center" alignItems="center">
          <TBold
            label="Something went wrong"
            style={{ fontSize: 32, fontWeight: 800 }}
          />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: 40 }}
        >
          <TBold label={status} style={{ fontSize: 32, fontWeight: 800 }} />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: 32 }}
        >
          <T
            style={{ textAlign: "center", fontSize: 18, fontWeight: 500 }}
            label={customMessage ?? message}
          />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: 32 }}
        >
          <Grid
            container
            style={{
              backgroundColor: "#FFB600",
              width: 142,
              height: 40,
              borderRadius: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={goHome}
          >
            <H4 label="Return home" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorView;
