import * as React from "react"
const InfoFilled = React.memo(({ width = 24, height = 24, color = "#000", ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.625 0 12 0zm0 19.294a.75.75 0 01-.749-.748V9.818a.75.75 0 011.498 0v8.728a.75.75 0 01-.749.748zm1.039-12.941a1.255 1.255 0 01-1.284.532 1.246 1.246 0 01-.982-.982 1.253 1.253 0 01.532-1.284 1.25 1.25 0 011.734 1.734z"
        fill={color}
      />
    </svg>
  )
})

export default InfoFilled