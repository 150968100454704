import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core'
import CartIcon from '../../../components/SVG/CartIcon'
import { useSelector } from 'react-redux'
import RestLogoModal from './RestLogoModal';
import RightDrawer from '../../../components/RightDrawer'
import PencileIcon from '../../../components/SVG/PencileIcon';
import { AvenirBlackH4 } from '../../../utils/text'
import EditButton from './editButton'
const OrderNow = ({ isPreview = false }) => {
  const { siteSettings, info, logo } = useSelector(state => state.builder)
  const [showLogoModal, setShowLogoModal] = useState(false)
  console.log(showLogoModal)
  return (
    <>
      <Grid container style={{ marginTop: 10, paddingBottom: 10, height: 53 }} container alignItems="center">
        <Grid item xs={12} lg={8}>

          <Grid container justify="flex-start" alignItems="center" style={{ marginLeft: 120 }}>

            {
              !isPreview &&
              <Grid >
                {/* <Button
                  startIcon={<PencileIcon color="#FFF" height={16} width={16} />}
                  style={{ backgroundColor: '#004C60' }}
                  onClick={() => setShowLogoModal(true)}
                  variant="contained">
                  <span style={{ color: 'white' }}>Edit</span>
                </Button> */}
                <EditButton onClick={() => setShowLogoModal(true)} />
              </Grid>
            }


            <Grid style={{ paddingLeft: !isPreview ? 30 : 155 }}>
              {
                logo ?
                  <img src={logo} style={{ maxHeight: 50 }} />
                  :
                  <span style={{ color: '#004C60', fontSize: 26 }}>{info.website?.site_title}</span>
              }


            </Grid>

            <Grid >
              <Grid container style={{ paddingLeft: 50 }}>
                {
                  siteSettings?.about &&
                  <Grid item >
                    <Button style={{ color: '#004C60' }}>
                      About Us
                    </Button>
                  </Grid>
                }
                {
                  siteSettings?.contact &&
                  <Grid item style={{ paddingLeft: 20 }}>
                    <Button style={{ color: '#004C60' }}>Contact</Button>
                  </Grid>
                }

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container justify="center" alignItems="center">
            <Button style={{ backgroundColor: '#FFB600',width:129,height:40 }}
              variant="contained">
              <AvenirBlackH4 label="Order Now" /></Button>
            <span style={{ marginLeft: 15, marginRight: 20 }}>$0.00</span>
            <CartIcon />
          </Grid>
        </Grid>

      </Grid>
      {
        showLogoModal &&
        <RightDrawer
          open={showLogoModal}
          onCloseOutside={() => setShowLogoModal(false)}>
          <RestLogoModal
            banner={logo}
            onClose={() => setShowLogoModal(false)}
          />
        </RightDrawer>
      }
    </>
  )
}

export default OrderNow
