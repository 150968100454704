import instance from './axiosInstance';
import urls from './urls';
import axios from 'axios';
const fetchPettyCashSession = () => {
    return (dispatch) => {
        instance.get(urls.pettyCash.pettyCash_sessions).then((response) => {

            dispatch({ type: 'SET_PETTYCASH_SESSION', payload: response.data })
        })
    }
}

const createPettyCashSession = (params, callback) => {
    return (dispatch) => {
        instance.post(urls.pettyCash.pettyCash_sessions, params).then((response) => {
            console.log("responseresponse", response)
            if (response.status === 200 || response.status === 304) {
                callback(true)
            }
        }).catch(err => {
            callback(false)
        })
    }
}
const updatePettyCashSession = (params, callback) => {
    return (dispatch) => {
        instance.put(urls.pettyCash.pettyCash_sessions, params).then((response) => {
            if (response.status === 200 || response.status === 304) {
                callback(true)
            }
        }).catch(err => {
            console.log(err)
            callback(false)
        });
    }
}
const fetchPettyCashCategories = () => {
    return (dispatch) => {
        instance.get(urls.pettyCash.pettyCash_categories).then((response) => {
            if (response.status === 200 || response.status === 304) {
                dispatch({ type: 'SET_PETTYCASH_CATEGORIES', payload: response.data })
            }
        })
    }
}
const createPettyCashCategories = (params, callback) => {
    return (dispatch) => {
        instance.put(urls.pettyCash.pettyCash_categories, params).then((response) => {
            if (response.status === 200 || response.status === 304) {
                callback(true)
            }
        }).catch(err => {
            console.log(err)
            callback(false)
        });
    }
}
const editPettyCashCategories = (params, callback) => {
    return (dispatch) => {
        instance.post(urls.pettyCash.pettyCash_categories, params).then((response) => {
            if (response.status === 200 || response.status === 304) {
                callback(true)
            }
        }).catch(err => {
            console.log(err)
            callback(false)
        });
    }
}
const deletePettyCashCategories = (cred, callback) => {
    return (dispatch) => {
        instance.delete(urls.pettyCash.pettyCash_categories, { data: cred }).then((response) => {
            console.log(response)
            if (response.status === 200) {
                callback(true);
            }
        }).catch(err => {
            console.log(err)
            callback(false)
        });
    };
}
const fetchPettyCashExpenses = (params) => {
    return (dispatch) => {
        instance.get(urls.pettyCash.pettyCash_expenses, { params }).then((response) => {
            console.log(response)

            dispatch({ type: 'SET_PETTYCASH_EXPENSES', payload: response.data })

        }).catch(err => {
            console.log("fetchPettyCashExpenses error", err)
        });
    };
}
const createPettyCashExpenses = (params, callback) => {
    return (dispatch) => {
        instance.post(urls.pettyCash.pettyCash_expenses, params).then((response) => {
            callback(params.txns.length, true)
        }).catch(err => {
            console.log(err)
            callback(false)
        });
    }
}
const deletePettyCashExpenses = (cred, callback) => {
    return (dispatch) => {
        instance.delete(urls.pettyCash.pettyCash_expenses, { data: cred }).then((response) => {
            console.log(response)
            if (response.status === 200 || response.status === 304) {
                callback(true);
            }
        }).catch(err => {
            console.log(err)
            callback(false)
        });
    };
}
export {
    fetchPettyCashSession,
    fetchPettyCashCategories,
    fetchPettyCashExpenses,
    createPettyCashCategories,
    editPettyCashCategories,
    deletePettyCashCategories,
    createPettyCashExpenses,
    deletePettyCashExpenses,
    createPettyCashSession,
    updatePettyCashSession
}