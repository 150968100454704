import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import DataTable from "../../../../components/DataTable";
import styles from "../../../../styles/index";
import { deleteTax, fetchTaxes } from "../../../../redux/actions";
import AlertDialog from "../../../../components/Dialog";
import { AvenirBlackH4, TBold, T, DotText } from "../../../../utils/text";
import CirclePluse from "../../../../components/SVG/PluseIcon";
import DeleteIcon from "../../../../components/SVG/Delete";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";
import MyContext from "../../../../Provider/MyContext";
import SnackBar from "../../../../components/SnackBar";
import _ from "lodash";
import EditTaxes from "./editTaxes";
import CreateTaxes from "./createTaxes";

const TaxesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const TaxesData = useSelector((state) => state.taxConfigurationReducer.taxes);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.delete_tax);
  const [Taxes, setTaxes] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const bodyMessage =
    selectedId.length > 1
      ? "Are you sure you want to delete " + selectedId.length + " taxes?"
      : Context.langData.delete_tax_body;

  useEffect(() => {
    const filterData = _.reverse(TaxesData);
    setTaxes(filterData);
  }, [TaxesData]);

  const [columns, setColumns] = useState([
    {
      field: "code",
      headerName: Context.langData.tax,
      size: "30%",
      sort: false,
    },
    {
      field: "desc",
      headerName: Context.langData.comment,
      size: "30%",
      sort: false,
    },
    {
      field: "value",
      headerName: Context.langData.value,
      size: "40%",
      sort: false,
    },
    
  ]);
  function onCheckedIds(ids) {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }
  function onClickAction(rowValue, event) {
    setRow(rowValue);
    toggleEditDrawer(true);
  }
  const btnProps = {};
  if (btnDisable) {
    btnProps["disabled"] = true;
  }
  function commanCallback() {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchTaxes(restId.rest_id));
  }
  function createCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  function editCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  function deleteCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  }

  const handleClose = (val) => {
    if (val) {
      for (const i of selectedId) {
        dispatch(deleteTax(restId.rest_id, JSON.stringify(i), deleteCallback));
      }
    }
    setShowDialog(false);
  };
  function deleteTaxes(value) {
    setShowDialog(value);
  }
  const onPageinationChange = (value) => {
    console.log("on page change", value);
  };

  const deleteOrderType = () => {
    if (selectedId.length > 0) {
      deleteTaxes(true);
    }
  };

  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    var sortComments = _.orderBy(
      Taxes,
      [
        (d) => {
          return d[value].toLowerCase();
        },
      ],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(sortComments);
  };
  return (
    <div data-qaid="taxeslist_div">
      <>
        <>
          <Grid
            data-qaid="taxesList-blurb"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 15,
              backgroundColor: "#F3FAFD",
              width: "95%",
              height: "auto",
              borderRadius: 5,
              marginBottom: 26,
              marginLeft: 20,
            }}>
            <Grid data-qaid="taxesList-blurb-InfoIcon">
              <InfoOutlined width={24} height={24} color={"#004C60"} />
            </Grid>
            <Grid style={{ paddingLeft: 10, width: "100%" }}>
              {showMore ? (
                <Grid
                  data-qaid="taxesList-blurb-message"
                  style={{
                    color: "#051D33",
                    fontSize: 14,
                    fontFamily: "AvenirLT",
                    lineHeight: 1.5,
                    paddingTop: 4,
                  }}>
                  {Context.langData.tax_important_reminder}
                  <span
                    onClick={() => setShowMore(!showMore)}
                    style={{ cursor: "pointer", color: "#004C60" }}>
                    {showMore ? " Show Less" : ""}
                  </span>
                </Grid>
              ) : (
                <Grid
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    paddingTop: 4,
                  }}>
                  <Grid
                    data-qaid="taxesList-blurb-message"
                    style={{ width: "83%" }}>
                    <DotText label={Context.langData.tax_important_reminder} />
                  </Grid>
                  <span
                    onClick={() => setShowMore(!showMore)}
                    style={{
                      cursor: "pointer",
                      color: "#004C60",
                      marginLeft: 3,
                    }}>
                    {showMore ? "" : "Read More"}
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            data-qaid="taxeslist_grid"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 20,
              marginBottom: 10,
              marginTop: 32,
            }}>
            {Taxes.length > 0 && (
              <Button
                className={style.buttonClass}
                data-qaid="otype_delete_button"
                style={{ color: "white", marginRight: 10 }}
                variant="text"
                id="cancel"
                onClick={deleteOrderType}>
                <DeleteIcon
                  data-qaid="otype_deleteicon"
                  width={16}
                  height={16}
                  style={{ marginRight: 5 }}
                />
                <AvenirBlackH4
                  data-qaid="otype_delete_label"
                  label={Context.langData.delete}
                />
              </Button>
            )}

            <Button
              className={style.buttonClass}
              data-qaid="taxeslist_creategrid"
              style={{
                backgroundColor: "#FFB600",
                color: "white",
                marginRight: 10,
              }}
              variant="contained"
              id="cancel"
              onClick={() => toggleCreateDrawer(true)}>
              <CirclePluse data-qaid="taxeslist_createpluse" />
              <AvenirBlackH4
                data-qaid="taxeslist_createlabel"
                label={Context.langData.create_tax}
                style={{ paddingLeft: 5 }}
              />
            </Button>
            {/* <ContextMenu style={{ marginLeft: 10 }} menuItems={ContextMenuData} callBack={callBack} /> */}
          </Grid>
          {Taxes.length > 0 ? (
            <DataTable
              data-qaid="taxeslist_datatable"
              refresh={openCreate}
              columns={columns}
              rows={Taxes}
              onCheckedIds={onCheckedIds}
              onClickAction={onClickAction}
              onPageChange={onPageinationChange}
              sortingOnClick={sortingOnClick}
            />
          ) : (
            <Grid
              data-qaid="customers_labelView"
              item
              lg={12}
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                marginTop: "10%",
                flexDirection: "column",
              }}>
              <Grid
                item
                style={{
                  maxWidth: 410,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}>
                <TBold
                  label={Context.langData.no_profile_created_yet}
                  style={{ textAlign: "center", fontSize: 32 }}
                />
                <T
                  label={Context.langData.please_create_tax_profile}
                  style={{ marginTop: 20, fontSize: 18, color: "#051D33" }}
                />
              </Grid>
            </Grid>
          )}
        </>
      </>
      {openCreate && (
        <CreateTaxes
          data-qaid="taxeslist_createtaxes"
          open={openCreate}
          taxes={Taxes}
          onClose={() => toggleCreateDrawer(false)}
          callback={createCallback}
        />
      )}
      {openEdit && (
        <EditTaxes
          data-qaid="taxeslist_edittaxes"
          open={openEdit}
          row={row}
          taxes={Taxes}
          onClose={() => toggleEditDrawer(false)}
          callback={editCallback}
        />
      )}

      {snackbar && (
        <SnackBar
          data-qaid="taxeslist_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        data-qaid="taxeslist_alertdialog"
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TaxesList;
