import * as React from "react"

function UploadIcon({ width = 20, height = 20, color = "#051D33", ...props }) {
  return (
    <svg
      width={width}
      height={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M10.002 6.25c.345 0 .625.28.625.626v12.5a.625.625 0 1 1-1.25 0v-12.5c0-.345.28-.625.625-.625Z"
          fill={color}
        />
        <path
          d="M9.56 6.434a.625.625 0 0 1 .884 0l3.75 3.75a.625.625 0 0 1-.884.884L10.002 7.76l-3.308 3.308a.625.625 0 1 1-.884-.884l3.75-3.75Z"
          fill={color}
        />
        <path
          d="M5 1.25a1.875 1.875 0 0 0-1.875 1.876V13.75A1.875 1.875 0 0 0 5 15.625h1.877a.625.625 0 1 1 0 1.25H5a3.125 3.125 0 0 1-3.125-3.125V3.126A3.125 3.125 0 0 1 5 0h10a3.125 3.125 0 0 1 3.125 3.125V13.75A3.125 3.125 0 0 1 15 16.875h-1.875a.625.625 0 1 1 0-1.25H15a1.875 1.875 0 0 0 1.875-1.875V3.126A1.875 1.875 0 0 0 15 1.25H5Z"
          fill={color}
        />
      </g>

    </svg>
  )
}

export default UploadIcon
