import setState from './setState'
const initialState = {
  items: [],
  categories: [],
  addons: [],
  category_items: [],
  comments: [],
  addonCategories: [],
  pizzaConfig: [],
  itemTypes: [],
  dietary_attributes: []
};
function parseComments(response) {
  var obj = response;
  obj.map(d => {
    d["id"] = d.comment_id;
  })
  return obj;
}
function parseAddonCategories(response) {
  var obj = response;
  obj.map(d => {
    d["id"] = d.addon_cat_id;
    d["name"] = d.addon_cat_name;
  })
  return obj;
}
function menu(state = initialState, action) {
  switch (action.type) {
    case "SET_ITEMS": return setState(state, action.payload, 'items')
    case "SET_CATEGORIES": return setState(state, action.payload, 'categories')
    case "SET_ADDONS": return setState(state, action.payload, 'addons')
    case "SET_CAT_ITEMS": return setState(state, action.payload, 'category_items')
    case "PIZZA_CONFIG": return setState(state, action.payload, 'pizzaConfig')
    case "SET_COMMENTS": return setState(state, parseComments(action.payload), 'comments')
    case "SET_ADDONS_CATEGORIES": return setState(state, parseAddonCategories(action.payload), 'addonCategories')
    case "SET_ITEM_TYPES": return setState(state, action.payload, 'itemTypes')
    case "GET_DIETARY_ATTRIBUTES": return setState(state, action.payload, 'dietary_attributes')
    case "CLEAR": return {...initialState}
    default: return state
  }
}

export default menu;