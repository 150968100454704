import { update } from "lodash";

const urls = {
  login: 'login',
  logout: 'logout',
  refresh: 'refresh',
  expireToken: 'token_timeout',
  resetemail: 'resetemail',
  branchSales: 'branch/reports/total_sales',
  user: 'restaurant/user',
  reset: 'reset',
  help_support: 'restaurant/help/support',
  login_mfa_verfiation: 'login/mfa/verification',
  login_mfa_resend: 'login/mfa/resend',
  dashboard: {
    sales: 'restaurant/reports/sales',
    live: 'restaurant/reports/get_live_sales?',
    top_items: 'restaurant/reports/top_sales_items',
    top_categories: 'restaurant/reports/top_sales_categories',
    order_type: 'restaurant/reports/order_type',
    shifts: 'restaurant/reports/shifts',
    payment_type: 'restaurant/reports/payment_type',
    tracker: 'restaurant/reports/custom',
  },
  meta: {
    ordertypes: 'restaurant/settings/ordertype',
    payment_type: 'restaurant/settings/payment',
    kitchen_name: 'restaurant/kitchen',
    orderType_ps: 'restaurant/reports/pt_per_ot',
    oType_payment: 'restaurant/menu/ordertype/payments',
    order_postion: 'restaurant/menu/items/positions',
    cat_postion: 'restaurant/menu/categories/positions',
    assginKitchen: 'restaurant/kitchen/assign_kitchen',
    advanceAssginKitchen: 'restaurant/kitchen/cat_otype_assoc',
  },
  roles: {
    all: 'restaurant/employee/roles/all',
    permissions: 'restaurant/permissions',
    roles: 'restaurant/employee/roles',
    emailCheck: 'restaurant/employee/role/check',
  },
  employees: {
    all: 'restaurant/employee/all',
    allEmployees: 'restaurant/employee/export',
    employee: 'restaurant/employee',
    resetemail: 'resetemail',
    emailCheck: 'restaurant/employee/check',
  },
  transactions: {
    transactions: 'restaurant/transaction/all',
    transactionDetails: '/restaurant/transaction',
    online: 'restaurant/transactions/online/orders',
    purchases: 'restaurant/transaction/purchases',
    refund: 'restaurant/transaction/refund',
    refun_details: 'restaurant/transaction/refund/details',
  },
  customers: {
    all: 'restaurant/customer/all',
    customer: 'restaurant/customer',
    Allcustomer: 'restaurant/customer/export',
    emailCheck: 'restaurant/customer/check',
    importCustomer: 'restaurant/customer/import',
  },
  settings: {
    kitchens: 'restaurant/kitchen',
    printers: 'restaurant/settings/printer',
    meta: 'restaurant/settings/meta',
    shifts: 'restaurant/settings/shifts',
    assign: 'restaurant/settings/printer/assign',
    taxes: '{id}/taxes',
    taxProfiles: '{id}/tax-profiles',
    taxProfilesAssociation: 'restaurant/settings/tax-profiles',
    taxesUrl: '{id}/taxes',
    taxUrl: '{id}/taxes/{id2}',
    payment: 'restaurant/settings/payment',
    config_payments: 'restaurant/settings/config_payments',
    offers: 'restaurant/promotion',
    reservations: 'restaurant/settings/reservations',
    assignTaxUrl: 'restaurant/settings/taxes/assign',
    order_delivery_types: 'order_delivery_types',
    deliveryOptions: 'restaurant/delivery/option',
    remember_me: 'restaurant/settings/rememberme',
    terminal: 'restaurant/settings/terminal',
    disconnect_terminal: 'restaurant/settings/terminal/disconnections',
    eod_settings: 'restaurant/settings/endofday',
    eod_reports: 'restaurant/settings/reports?report_type_code=END_OF_DAY',
    eod_batch_update_reports: 'restaurant/settings/reports/batch-update',
  },
  menu: {
    items: 'restaurant/menu/items/get_all',
    categories: 'restaurant/menu/categories',
    addons: 'restaurant/menu/category/addons',
    menu_addons: 'restaurant/menu/addon',
    addonItem: 'restaurant/menu/addon/item',
    itemsByCatId: 'restaurant/menu/items',
    getItemById: 'restaurant/menu/items/get_info',
    itemQuickAdd: 'restaurant/menu/items/quickadd',
    comments: 'restaurant/menu/comments',
    recipeAddons: 'restaurant/menu/addon/recipe',
    assgin: 'restaurant/menu/items/addon/assign',
    presigned_url: 'restaurant/checkout/presigned_url',
    image_upload: 'restaurant/menu/items/upload_image',
    copy: 'restaurant/menu/items/copy_item',
    pizzaConfig: 'restaurant/menu/items/build_your_item',
    itemtypes: '/restaurant/items/type',
    dietary_attribute: 'restaurant/dietary/attribute',
  },
  inventory: {
    vendors: 'vendors?embed=data.items', //inventory micro-service
    departments: 'departments', //inventory micro-service, get departments
    batchProductItemGroup: 'product-item-groups/batch-update', //inventory micro-service
    stocks: 'inventory-items',
    stockItem: 'inventory-items/{id}',
    addVendor: 'vendors',
    vendor: 'vendors/{id}',
    vendorProfile: 'vendors/{id}/profile',
    getRecipeUrl: 'product-item-groups', //inventory micro-service
    kitchenStock: 'departments/{id}/inventory-items',
    kitchenStockAdjustments: '/departments/{id}/adjustments',
    kitchenValue: 'departments/{id}/inventory-value',
    groupsData: 'item-tags',
    groupData: 'item-tags/{id}',
    batchDeleteStock: 'inventory-items/batch-delete',
    issues: 'issues',
    indent: 'purchase-orders',
    specificIndent: 'purchase-orders/{id}',
    specificFulfillment: 'purchase-orders/{id}/fulfillments/{id2}',
    updateFulfillmentPaidAmount:
      'purchase-orders/{id}/fulfillments/{id2}/amount-paid',
    issueView: 'issues/{id}',
    indentStatus: 'purchase-orders/{id}/status',
    fulfillment: 'purchase-orders/{id}/fulfillments',
    indentView: 'purchase-orders', // inventory micro-service
    indentBatchDelete: 'purchase-orders/batch-delete', // inventory micro-service
    assign_stocks_to_vendor: 'vendors/{id}/items',
    recipeItems: 'restaurant/inventory/recipe/get_all',
    recipeUrl: 'restaurant/inventory/recipe',

    costingVendors: 'restaurant/inventory/stocks_cost',
    costingKitchens: 'restaurant/inventory/kitchens_stock_cost',
    liveConsumption: 'restaurant/kitchen/live_consumption',
    upload_stock: 'restaurant/inventory/stock/upload',
    uploadItemsRecipesURL: 'restaurant/inventory/recipe/items/upload',
    uploadAddonsRecipesURL: 'restaurant/inventory/recipe/addons/upload',
    issueUpload: 'restaurant/inventory/issue/upload',
    indentUpload: 'restaurant/inventory/indents/upload',
  },
  salesReports: {
    salesSummary: 'restaurant/reports/sales',
    paymentMethods: 'restaurant/reports/payment_type',
    orderType: 'restaurant/reports/order_type',
    shiftReport: 'restaurant/reports/shifts',
    taxType: 'restaurant/reports/tax',
    daywiseSales: 'restaurant/reports/days',
    itemSales: 'restaurant/reports/items',
    addonDetails: 'restaurant/report/addondetails',
    waiters: 'restaurant/reports/get_waiter_perf',
    cashier: 'restaurant/reports/cashier',
    groups: 'restaurant/reports/custom',
    updateGroup: 'restaurant/menu/categories',
    pettyCashReport: 'restaurant/pettycash/report',
    employeeReports: 'restaurant/reports/employee',
    alcoholReports: 'restaurant/reports/item/alcohol',
    onlineSalesSummary: 'restaurant/online/transaction',
    contactTracing: 'restaurant/reports/visitor_log',
  },
  inventoryReports: {
    consolidated: 'inventory/consolidated-report',
    kitchenReports: 'inventory/kitchens-report',
    vendorReports: 'inventory/vendors-report',
    purchaseReports: 'inventory/purchases-report',
    consumption: 'inventory/consumption-report',
  },
  eodReports: {
    listEodReports: 'reports/endofday?from_date={id}&to_date={id2}',
    listEodShiftReports: 'reports/endofshift?from_date={id}&to_date={id2}',
    listEodShiftReports_employeeID:
      'reports/endofshift?from_date={id}&to_date={id2}&employee_ids={id3}',
    specificEodReport: 'reports/endofday/{id}',
    specificEodShiftReport: 'reports/endofshift/{id}',
    emailReports: 'reports/{id}/email',
  },
  floorPlan: {
    floorPlanUrl: 'restaurant/floorplan',
    copy: '/restaurant/floorplan/copy',
    builder: 'restaurant/floorplan/builder',
  },
  website: {
    info: 'restaurant/website/information',
    account: 'restaurant/website/account',
    theme: 'restaurant/website/theme',
    site: 'restaurant/website/site',
    pages: 'restaurant/website/site/pages',
    webpages: 'restaurant/website/webpage',
    image: 'restaurant/website/image',
    publish: 'restaurant/website/publish',
    logo: 'restaurant/website/logo',
  },
  pettyCash: {
    pettyCash_sessions: 'restaurant/pettycash/session',
    pettyCash_categories: 'restaurant/pettycash/categories',
    pettyCash_expenses: 'restaurant/pettycash/expenses',
  },
  activation: {
    verify: 'restaurant/verify',
    active: 'restaurant/activate',
    checkEmail: 'restaurant/check_email',
    mfa_activation: 'restaurant/activate/mfa/verification',
    mfa_resend: 'restaurant/activate/mfa/resend',
  },
  sso: {
    login: 'sso/login',
    callback: 'sso/callback',
  },
  taxConfiguration: {
    taxes: '{id}/taxes',
    taxUrl: '{id}/taxes/{id2}',
    taxProfiles: '{id}/tax-profiles',
    taxProfileUrl: '{id}/tax-profiles/{id2}',

  },

  ticket_customization: {
    kitchen_ticket: '{id}/settings/kitchen-chit',
    customer_receipt: '{id}/settings/customer-receipt',
  },
};

export function insertId(url, id, id2 = '', id3 = '') {
  let ret = url.replace('{id}', String(id));
  if (id2 !== '') {
    ret = ret.replace('{id2}', String(id2));
  }
  if (id3 !== '') {
    ret = ret.replace('{id3}', String(id3));
  }
  return ret;
}

export default urls;
