import * as React from "react"

const SvgComponent = ({width=20,height=18,color="#004C60",...props}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.625 4.625C.625 4.28.905 4 1.25 4H10a.625.625 0 110 1.25H1.25a.625.625 0 01-.625-.625z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25.25c.345 0 .625.28.625.625v3.75a.625.625 0 11-1.25 0V.875c0-.345.28-.625.625-.625z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.174.8C1.526.447 2.003.25 2.5.25H10c.345 0 .625.28.625.625V9h8.125c.345 0 .625.28.625.625v6.25A1.875 1.875 0 0117.5 17.75h-15a1.875 1.875 0 01-1.875-1.875V2.125c0-.497.198-.974.55-1.326zm1.326.7a.625.625 0 00-.625.625v13.75a.625.625 0 00.625.625h15a.624.624 0 00.625-.625V10.25H10a.625.625 0 01-.625-.625V1.5H2.5z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 3.375c0-.345.28-.625.625-.625h8.75a.625.625 0 110 1.25H10a.625.625 0 01-.625-.625zM9.375 6.5c0-.345.28-.625.625-.625h8.75a.625.625 0 110 1.25H10a.625.625 0 01-.625-.625z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.125.25c.345 0 .625.28.625.625v8.75a.625.625 0 11-1.25 0V.875c0-.345.28-.625.625-.625zM16.875.25c.345 0 .625.28.625.625v8.75a.625.625 0 11-1.25 0V.875c0-.345.28-.625.625-.625z"
        fill={color}
      />
    </svg>
  )
}

const BuilderIcon = React.memo(SvgComponent)
export default BuilderIcon
