import React, { useState } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { FourSquares, DownVector, UpVector, CircleIcon } from './SVG';
import { H4 } from '../utils/text';

const CategoryDropDownList = ({ categoryName, length = null, itemList }) => {
  const [flag, setFlag] = useState(false);
  const midIndex = Math.ceil(itemList.length / 2);
  const firstColumn = itemList.slice(0, midIndex);
  const secondColumn = itemList.slice(midIndex);

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'space-between',
          marginTop: '2%',
          marginBottom: '2%',
        }}>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}>
            <FourSquares style={{ marginRight: 10 }} />
            <H4
              label={`${categoryName} (${length})`}
              style={{ fontWeight: 'normal' }}
            />
          </Grid>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'flex-end',
            }}
            onClick={() => {
              setFlag((prev) => !prev);
            }}>
            {flag ? (
              <UpVector color='#007191' />
            ) : (
              <DownVector color='#007191' />
            )}
          </Grid>
        </Grid>
      </Grid>
      {flag && (
        <Grid
          container
          style={{
            paddingLeft: 25,
            paddingRight: 25,
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingRight: 5,
            }}>
            {firstColumn.map((item, index) => (
              <Grid
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  padding: 5,
                }}>
                <CircleIcon
                  color='#007191'
                  width={8}
                  height={8}
                  style={{ marginRight: 5 }}
                />
                <H4 label={item.itemName} style={{ fontWeight: 'normal' }} />
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 5,
            }}>
            {secondColumn.map((item, index) => (
              <Grid
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  padding: 5,
                }}>
                <CircleIcon
                  color='#007191'
                  width={8}
                  height={8}
                  style={{ marginRight: 5 }}
                />
                <H4 label={item.itemName} style={{ fontWeight: 'normal' }} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      <Divider />
    </>
  );
};

export default CategoryDropDownList;
