import React, { useContext, useEffect, useState } from "react";
import OnOffSwitch from "../../../components/OnOffSwitch";
import MyContext from "../../../Provider/MyContext";
import { AvenirBlackH3 } from "../../../utils/text";
import {
  fetchRemeberMe,
  postRememberMe,
} from "../../../redux/actions/settings";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ControlCameraOutlined } from "@material-ui/icons";

function RememberMe() {
  const Context = useContext(MyContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const remeberMe = useSelector((state) => state.settingsReducer.rememberMe);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  console.log("remeberMe:", remeberMe, "rest_id:", restId);

  useEffect(() => {
    dispatch(fetchRemeberMe());
  }, []);
  console.log("RemeberMe_login", remeberMe.remember_login);

  const handleShow = (e) => {
    console.log("RemeberMe_setShow", e);
    dispatch(
      postRememberMe({
        rest_id: restId.rest_id,
        remember_login: !remeberMe,
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: 20,
        marginTop: 20,
      }}
    >
      <AvenirBlackH3
        label={Context.langData.remeber_me}
        style={{ width: 107, height: 24 }}
      />
      <OnOffSwitch onChange={(e) => handleShow(e)} show={remeberMe} />
    </div>
  );
}

export default RememberMe;
