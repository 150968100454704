import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Divider, Typography } from '@material-ui/core'
import Header from './header';
import StepBar from '../StepBar';
import Input from '../Input';
import OnOffSwitch from '../../../components/OnOffSwitch'
import CustomSwitch from '../../../components/CustomSwitch'
import CustomCheckbox from '../../../components/CustomCheckbox'
import TextInputMask from '../../../components/TextInputMask'
import Timer from '../Timer'
import { ProvinceData } from '../../../utils/util';
import * as Yup from 'yup';
import SingleSelect from '../../../components/SingleSelect'
import { useDispatch, useSelector } from 'react-redux';
import { saveWebInfo } from '../../../redux/actions'
import { useNavigate } from 'react-router-dom';
import { T, AvenirBlackH2, AvenirBlackH4, H4, AvenirBlackH3 } from '../../../utils/text'
const hours = [
  { indx: 0, id: 'mon', day: 'Monday', isOpen: true, openTime: '09:00', closeTime: '21:00' },
  { indx: 1, id: 'tue', day: 'Tuesday', isOpen: true, openTime: '09:00', closeTime: '21:00' },
  { indx: 2, id: 'wed', day: 'Wednesday', isOpen: true, openTime: '09:00', closeTime: '21:00' },
  { indx: 3, id: 'thu', day: 'Thursday', isOpen: true, openTime: '09:00', closeTime: '21:00' },
  { indx: 4, id: 'fri', day: 'Friday', isOpen: true, openTime: '09:00', closeTime: '21:00' },
  { indx: 5, id: 'sat', day: 'Saturday', isOpen: true, openTime: '09:00', closeTime: '21:00' },
  { indx: 6, id: 'sun', day: 'Sunday', isOpen: true, openTime: '09:00', closeTime: '21:00' },
]
const BasicInfo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { ordertypes } = useSelector(state => state.metaReducer)
  const info = useSelector(state => state.builder.info)
  const [errors, setErrors] = useState({})
  const [basicInfo, setBasicInfo] = useState({
    site_title: '', site_url: '', phone: '', email: '', address: '', city: '',
    province: '', postal_code: '', facebook_url: '', twitter_url: '', instagram_url: '',
    openingHours: hours, isCustomDelivery: false, customDeliveryHours: hours,
    isCustomPickup: false, customPickupHours: hours,
    delivery: '', pickup: ''
  })
  useEffect(() => {
    console.log(info)
    let {
      is_ordering_hours,
      is_pickup_hours,
      ordering_hours,
      operating_hours,
      pickup_hours,
      ...others
    } = info;
    console.log(others, "ot 1")
    let obj = {}
    obj["isCustomDelivery"] = info.is_ordering_hours;
    obj['isCustomPickup'] = info.is_pickup_hours
    obj["customPickupHours"] = hours;
    obj["customDeliveryHours"] = hours;
    obj["openingHours"] = hours;
    let contact = {}, social_media = {};

    if (others.contact) {
      contact = others.contact
    }
    console.log("Contact", contact, others)
    if (others.social_media) {
      social_media = others.social_media
    }

    let obj2 = { ...contact, ...social_media, ...others.website, ...obj }
    console.log(obj2, "ot 2")
    setBasicInfo(obj2)
  }, [info])

  const onSave = useCallback((next = false) => {
    const schema = Yup.object().shape({
      // site_title: Yup.string().max(255).required('Title is required'),
      email: Yup.string().email('Please enter a valid email address'),
      address: Yup.string().required("Please input a valid address."),
      city: Yup.string().required("Please input a valid city."),
      phone: Yup.string().required('Please enter a valid phone number').min(14, "Please enter a valid phone number").max(14, "Please enter a valid phone number"),
      postal_code: Yup.string().min(7, "Please enter a valid postal code").max(7, "Please enter a valid postal code")
    })
    let obj = { ...basicInfo }

    schema.validate(obj, { abortEarly: false }).then((v) => {

      console.log(v)
      let infoData = {
        contact: {
          phone: basicInfo.phone,
          email: basicInfo.email,
          address: basicInfo.address,
          city: basicInfo.city,
          province: basicInfo.province,
          postal_code: basicInfo.postal_code
        },
        social_media: {
          facebook_url: basicInfo.facebook_url,
          twitter_url: basicInfo.twitter_url,
          instagram_url: basicInfo.instagram_url
        },
        website: {
          site_title: '',
          site_url: ''
          // site_title: basicInfo.site_title,
          // site_url: basicInfo.site_url || ""
        },
        is_ordering_hours: basicInfo.isCustomDelivery || false,
        is_pickup_hours: basicInfo.isCustomPickup || false,
        operating_hours: basicInfo.openingHours,
        ordering_hours: basicInfo.customDeliveryHours,
        pickup_hours: basicInfo.customPickupHours
      }
      dispatch(saveWebInfo(infoData, (status) => {
        if (status)
          //  dispatch({ type: 'SET_INFO', payload: infoData })
          if (next) {
            console.log("move to next", next)
            navigate('/builder/2')
          }
      }))
      //
      setErrors({});

    }).catch(err => {
      console.log(err.inner)
      let errs = {};
      err.inner.map(inr => {
        errs[inr.path] = inr.errors[0]
      })
      setErrors(errs);
      console.log(errs)
    })

  })
  const onNext = useCallback(() => {
    onSave(true)
  })
  const onTimeChanged = (indx, val) => {
    console.log(indx, val)
    let st = { ...basicInfo }
    let hrs = [...st.openingHours];
    hrs[indx] = val;
    st["openingHours"] = hrs;
    setBasicInfo(st)
    // setOpeningHours(hrs)
  }
  const onDeliveryTimeChanged = (indx, val) => {
    let st = { ...basicInfo }
    let hrs = [...st.customDeliveryHours];
    hrs[indx] = val;
    st["customDeliveryHours"] = hrs;
    setBasicInfo(st)
    // setCustomDeliveryHours(hrs)
  }
  const onPickupTimeChanged = (indx, val) => {
    let st = { ...basicInfo }
    let hrs = [...st.customPickupHours];
    hrs[indx] = val;
    st["customPickupHours"] = hrs;
    setBasicInfo(st)
    // setCustomPickupHours(hrs)
  }
  const changeDeliveryOrderType = (e) => {
    setBasicInfo({ ...basicInfo, delivery: e })
    console.log(e)
  }
  const changePickOrderType = (e) => {
    setBasicInfo({ ...basicInfo, pickup: e })
    // console.log(e)
  }

  const blurErrr = 'kdfhkjdfgh'

  const blurTest = 'qqqq'
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <div style={{ flex: 3, width: '100%' }}>
        <Header
          step={0}
          onSave={onSave}
          onNext={onNext}
        />
      </div>
      <div style={{ flex: 17, width: '100%' }}>
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid item xs={12} lg={8}>
            <Grid item xs={12}>
              <StepBar currentStep={0} />
            </Grid>
            <Grid xs={12}>
              <Grid container style={{ backgroundColor: 'white', borderRadius: 10, padding: 40 }}>
                {/*<Grid item xs={12} style={{ paddingBottom: 20, borderBottom: '1px solid #EEEEEE' }}>
                  Website information
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Input
                    value={basicInfo.site_title}
                    onChange={(e) => setBasicInfo({ ...basicInfo, site_title: e })}
                    id="title"
                    error={errors.site_title}
                    placeholder="Site title*"
                  />
                </Grid>*/}
                {/*
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Input
                    value={basicInfo.site_url}
                    onChange={(e) => setBasicInfo({ ...basicInfo, site_url: e })}
                    id="url"
                    error={errors.site_url}
                    placeholder="Site url*"
                  />
                </Grid>
                */}

                <Grid item xs={12} style={{ marginTop: 0, paddingBottom: 20, borderBottom: '1px solid #EEEEEE' }}>
                  <AvenirBlackH2 label='Contact information' />
                </Grid>
                <Grid item xs={12}>
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid item xs={12} lg={6} >
                      <AvenirBlackH4 style={{ marginTop: 10, paddingBottom: 5 }} label='Phone #*' />
                      <TextInputMask
                        value={basicInfo.phone}
                        handleChange={(e) => setBasicInfo({ ...basicInfo, phone: e })}
                        onBlur={(value) => {
                          if (value) {
                            setErrors({ ...errors, phone: value })
                          } else {
                            let err = { ...errors };
                            delete err.phone;
                            setErrors(err)
                          }
                        }}
                        maxLength={14}
                        id="phone"
                        regex="phone"
                        error={errors.phone}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Input
                        value={basicInfo.email}
                        error={errors.email}
                        maxLength={64}
                        onChange={(e) => setBasicInfo({ ...basicInfo, email: e })}
                        id="email"
                        placeholder="Email"
                        paddingRight={0}
                      // readOnly={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={12} style={{ marginTop: 10 }}>
                  <Input
                    value={basicInfo.address}
                    error={errors.address}
                    onChange={(e) => setBasicInfo({ ...basicInfo, address: e })}
                    id="address"
                    placeholder="Address*"
                    maxLength={64}
                    paddingLeft={0}
                    paddingRight={0}
                    editable={false}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <Input
                        value={basicInfo.city}
                        error={errors.city}
                        maxLength={32}
                        onChange={(e) => setBasicInfo({ ...basicInfo, city: e })}
                        id="city"
                        placeholder="City*"
                        paddingLeft={0}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {/* <Input
                        value={basicInfo.province}
                        error={errors.province}
                        onChange={(e) => setBasicInfo({ ...basicInfo, province: e })}
                        id="province"
                        placeholder="Province*"
                      /> */}
                      <AvenirBlackH4 style={{ marginTop: 10, paddingBottom: 5 }} label='Province*' />
                      <SingleSelect
                        list={ProvinceData}
                        valueKey="value"
                        displayKey="name"
                        placeholder="Select"
                        value={basicInfo.province}
                        handleChange={(province) => setBasicInfo({ ...basicInfo, province: province.target.value })}
                        border={false}
                        height={56}
                        FormWidth={'95%'}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <AvenirBlackH4 style={{ marginTop: 10, paddingBottom: 5 }} label='Postal Code*' />
                      <TextInputMask
                        value={basicInfo.postal_code}
                        error={errors.postal_code}
                        handleChange={(e) => setBasicInfo({ ...basicInfo, postal_code: e })}
                        onBlur={(value) => {
                          if (value) {
                            setErrors({ ...errors, postal_code: value })
                          } else {
                            let err = { ...errors };
                            delete err.postal_code;
                            setErrors(err)
                          }
                        }}
                        maxLength={8}
                        regex={"postal"}
                        id="postalcode"

                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 50, paddingBottom: 20, borderBottom: '1px solid #EEEEEE' }}>
                  <AvenirBlackH2 label='Social media' />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Input
                    value={basicInfo.facebook_url}
                    onChange={(e) => setBasicInfo({ ...basicInfo, facebook_url: e })}
                    id="facebook"
                    placeholder="Facebook"
                    paddingLeft={0}
                    maxLength={256}
                    paddingRight={0}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Input
                    value={basicInfo.twitter_url}
                    onChange={(e) => setBasicInfo({ ...basicInfo, twitter_url: e })}
                    id="twitter"
                    maxLength={256}
                    placeholder="Twitter"
                    paddingLeft={0}
                    paddingRight={0}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Input
                    value={basicInfo.instagram_url}
                    onChange={(e) => setBasicInfo({ ...basicInfo, instagram_url: e })}
                    id="instagram"
                    maxLength={256}
                    placeholder="Instagram"
                    paddingLeft={0}
                    paddingRight={0}
                  />
                </Grid>
                {/*
                <Grid item xs={12} style={{ marginTop: 50, paddingBottom: 20, borderBottom: '1px solid #EEEEEE' }}>
                  Order types
                </Grid>
                <Grid item xs={12}>
                  <span>
                    Select order types for delivery and pickup.
                  </span>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 30 }}>
                  <Grid container>
                    <Grid item xs={6}>
                      Delivery
                    </Grid>
                    <Grid item xs={6}>
                      <SingleSelect
                        list={ordertypes}
                        handleChange={(e) => changeDeliveryOrderType(e.target.value)}
                        value={basicInfo.delivery || ""}
                        valueKey="otype_id"
                        displayKey="name" placeholder="Select delivery"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 30 }}>
                  <Grid container>
                    <Grid item xs={6}>
                      Pickup
                    </Grid>
                    <Grid item xs={6}>
                      <SingleSelect
                        list={ordertypes}
                        handleChange={(e) => changePickOrderType(e.target.value)}
                        value={basicInfo.pickup || ""}
                        valueKey="otype_id"
                        displayKey="otype_name" placeholder="Select pickup"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                */}
                <Grid item xs={12} style={{ marginTop: 50, paddingBottom: 20, marginBottom: 10, borderBottom: '1px solid #EEEEEE' }}>
                  <AvenirBlackH2 label='Opening hours' />
                </Grid>
                <Timings openingHours={basicInfo.openingHours} onTimeChanged={onTimeChanged} />

                <Grid item xs={12} style={{ marginTop: 50, paddingBottom: 20, marginBottom: 10, borderBottom: '1px solid #EEEEEE' }}>
                  <AvenirBlackH2 label='Ordering hours' />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <H4 label='If the times your restraunt offers delivery or pick up services are different from your opening hours, please input them below.' />
                </Grid>
                <Grid item xs={12}>
                  <CustomHours
                    label={"Custom delivery hours"}
                    openingHours={basicInfo.customDeliveryHours}
                    isShow={basicInfo.isCustomDelivery}
                    changeShow={() => setBasicInfo({ ...basicInfo, isCustomDelivery: !basicInfo.isCustomDelivery })}
                    onTimeChanged={onDeliveryTimeChanged} />
                </Grid>

                <Grid item xs={12}>
                  <CustomHours
                    label={"Custom pick up hours"}
                    openingHours={basicInfo.customPickupHours}
                    isShow={basicInfo.isCustomPickup}
                    changeShow={() => setBasicInfo({ ...basicInfo, isCustomPickup: !basicInfo.isCustomPickup })}
                    onTimeChanged={onPickupTimeChanged} />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

      </div>

    </div>
  )
}
const CustomHours = React.memo(({ label, openingHours, onTimeChanged, isShow, changeShow }) => {

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid item xs={8}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid xs={6}>
            <H4 label={label} />
          </Grid>
          <Grid item xs={6}>
            <OnOffSwitch
              onChange={(e) => changeShow()}
              show={isShow}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        isShow &&
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <Timings openingHours={openingHours} onTimeChanged={onTimeChanged} />
        </Grid>

      }
    </Grid>
  )
})
const Timings = React.memo(({ openingHours, onTimeChanged }) => {

  return (
    <Grid container>
      {
        openingHours.map((day, index) => {
          return (
            <OpeningHours key={index} options={day} onTimeChanged={onTimeChanged} />
          )
        })
      }
    </Grid>
  )
})
const OpeningHours = ({ options, onTimeChanged }) => {
  const { indx, id, day, isOpen, openTime, closeTime } = options;

  const onChangeVal = (key, val) => {
    let option = { ...options }
    option[key] = val;
    onTimeChanged(indx, option)
  }

  return (
    <Grid container style={{ display: 'flex', alignItems: 'center', paddingTop: 8, paddingBottom: 8 }}>
      <Grid xs={6} lg={3}>
        <AvenirBlackH3 label={day} />
      </Grid>
      <Grid xs={6} lg={3}>
        {/*<CustomSwitch
          onChange={(e) => onChangeVal('isOpen', e)}
          show={isOpen}
        />*/}
        <CustomCheckbox checked={isOpen} onChange={(e) => onChangeVal('isOpen', e.target.checked)} label="Open" />

      </Grid>
      <Grid xs={6} lg={3}>
        <Timer disabled={!isOpen} time={openTime} onChange={(e) => onChangeVal('openTime', e)} />
      </Grid>
      <Grid xs={6} lg={3}>
        <Timer disabled={!isOpen} time={closeTime} onChange={(e) => onChangeVal('closeTime', e)} />
      </Grid>

    </Grid>
  )
}
export default BasicInfo
