import * as React from 'react';

const FourSquares = (props) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M2.25 2.5C2.25 2.36193 2.36193 2.25 2.5 2.25L6.5 2.25C6.63807 2.25 6.75 2.36193 6.75 2.5L6.75 6.5C6.75 6.63807 6.63807 6.75 6.5 6.75L2.5 6.75C2.36193 6.75 2.25 6.63807 2.25 6.5L2.25 2.5ZM2.5 0.75C1.5335 0.75 0.75 1.5335 0.75 2.5L0.75 6.5C0.75 7.4665 1.5335 8.25 2.5 8.25H6.5C7.4665 8.25 8.25 7.4665 8.25 6.5V2.5C8.25 1.5335 7.4665 0.75 6.5 0.75L2.5 0.75ZM2.25 11.5C2.25 11.3619 2.36193 11.25 2.5 11.25H6.5C6.63807 11.25 6.75 11.3619 6.75 11.5L6.75 15.5C6.75 15.6381 6.63807 15.75 6.5 15.75H2.5C2.36193 15.75 2.25 15.6381 2.25 15.5L2.25 11.5ZM2.5 9.74999C1.5335 9.74999 0.75 10.5335 0.75 11.5L0.75 15.5C0.75 16.4665 1.5335 17.25 2.5 17.25H6.5C7.4665 17.25 8.25 16.4665 8.25 15.5L8.25 11.5C8.25 10.5335 7.4665 9.74999 6.5 9.74999H2.5ZM11.5 2.25C11.3619 2.25 11.25 2.36193 11.25 2.5V6.5C11.25 6.63807 11.3619 6.75 11.5 6.75L15.5 6.75C15.638 6.75 15.75 6.63807 15.75 6.5V2.5C15.75 2.36193 15.638 2.25 15.5 2.25L11.5 2.25ZM9.74996 2.5C9.74996 1.5335 10.5335 0.75 11.5 0.75L15.5 0.75C16.4665 0.75 17.25 1.5335 17.25 2.5V6.5C17.25 7.4665 16.4665 8.25 15.5 8.25L11.5 8.25C10.5335 8.25 9.74996 7.4665 9.74996 6.5V2.5ZM11.25 11.5C11.25 11.3619 11.3619 11.25 11.5 11.25H15.5C15.638 11.25 15.75 11.3619 15.75 11.5V15.5C15.75 15.6381 15.638 15.75 15.5 15.75H11.5C11.3619 15.75 11.25 15.6381 11.25 15.5V11.5ZM11.5 9.74999C10.5335 9.74999 9.74996 10.5335 9.74996 11.5V15.5C9.74996 16.4665 10.5335 17.25 11.5 17.25L15.5 17.25C16.4665 17.25 17.25 16.4665 17.25 15.5L17.25 11.5C17.25 10.5335 16.4665 9.74999 15.5 9.74999H11.5Z'
      fill='#007191'
    />
  </svg>
);

export default FourSquares;
