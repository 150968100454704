import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import OnOffSwitch from '../../../components/OnOffSwitch';
import { AvenirBlackH3 } from '../../../utils/text';
import MyContext from '../../../Provider/MyContext';
const Hawkeye = ({ codes, onChange, showHawkeye, filterByCode, filterCode, width }) => {
  const [_data, set_Data] = useState([0,0,0])
  const Context = useContext(MyContext);
  useEffect(() => {
    if(codes != undefined){
      set_Data(Object.values(codes))
    } else {
      set_Data([0,0,0])
    }
  }, [codes])
  
  return (
    <Grid data-qaid="hawkeye_main_grid" container alignItems="center" spacing={1} style={{ display: 'flex', flexDirection: 'row', width: width }}>
      <Grid data-qaid="hawkeye_body_grid" item xs={6} style={{ display: 'flex', flexDirection: 'row' }}>
        <AvenirBlackH3 data-qaid="hawkeye_label" label={Context.langData.hawkeye} style={{ marginTop: 10 }} />
        <OnOffSwitch data-qaid="hawkeye_onoffswith" onChange={onChange} show={showHawkeye} />
      </Grid>
      <Grid data-qaid="hawkeye_content_body" style={{ marginLeft: 10 }} xs={12}>
        {
          showHawkeye &&
          <Grid data-qaid="hawkeye_content_grid" container spacing={1} >
            {
              _data?.map((code, i) => {
                let classname = "circle color_" + i;
                classname = i == filterCode ? classname + " active" : classname
                return (
                  <Grid data-qaid="hawkeye_body_grid" item key={i}>
                    <div data-qaid="hawkeye_div" className={classname} onClick={() => filterByCode(i)}>{code}</div>
                  </Grid>
                )
              })
            }
          </Grid>
        }

      </Grid>
    </Grid>
  )
}

export default Hawkeye;