import * as React from "react";

const WarningIcon = ({ width = 24, height = 24, color = "#051D33", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13500_102867)">
        <path
          d="M8 9.5C8.27333 9.5 8.5 9.27333 8.5 9V4C8.5 3.72667 8.27333 3.5 8 3.5C7.72667 3.5 7.5 3.72 7.5 4V9C7.5 9.27333 7.72667 9.5 8 9.5Z"
          fill={color}
        />
        <path
          d="M15.5 6.79331L9.20667 0.499976C9.04667 0.339976 8.86 0.21331 8.65333 0.126643C8.24 -0.0466903 7.76667 -0.0466903 7.34667 0.126643C7.14 0.21331 6.95333 0.339976 6.79333 0.499976L0.5 6.79331C0.18 7.11331 0 7.54664 0 7.99998C0 8.44664 0.18 8.88664 0.5 9.20664L6.79333 15.5C7.11333 15.82 7.54667 16 8 16C8.45333 16 8.88667 15.82 9.20667 15.5L15.5 9.20664C15.82 8.88664 16 8.44664 16 7.99998C16 7.54664 15.82 7.11331 15.5 6.79331ZM14.7933 8.49998L8.5 14.7933C8.23333 15.06 7.76667 15.06 7.5 14.7933L1.20667 8.49998C1.07333 8.36664 1 8.18664 1 7.99998C1 7.81331 1.07333 7.63331 1.20667 7.49998L7.5 1.20664C7.56667 1.13998 7.64 1.09331 7.72667 1.05331C7.9 0.979977 8.1 0.979977 8.26667 1.05331C8.35333 1.08664 8.43333 1.13998 8.5 1.20664L14.7933 7.49998C14.9267 7.63331 15 7.81331 15 7.99998C15 8.18664 14.9267 8.36664 14.7933 8.49998Z"
          fill={color}
        />
        <path
          d="M8 11.98C8.40501 11.98 8.73333 11.6516 8.73333 11.2466C8.73333 10.8416 8.40501 10.5133 8 10.5133C7.59499 10.5133 7.26666 10.8416 7.26666 11.2466C7.26666 11.6516 7.59499 11.98 8 11.98Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13500_102867">
          <rect width={width} height="16" fill={height} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
