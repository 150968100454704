

const initialState = {
  loginDetails: { restaurants: [] },
  selectedRestaurant: null,
  branchSales: [],
  userPermissions: [],
  backendSessionTimeout: false,
  sessionTimeOut: false,
  mfa_required:false,
};

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loginDetails: action.payload,
        selectedRestaurant: setSelectedRestaurant(action.payload),
      }
    case "LOGOUT":
      return {
        ...initialState
      }
    case "CLEAR":
      return {
        ...initialState
      }
      case "MFA_REQUIRED":
      return {
        ...state,
        mfa_required:action.payload
      }
    case "SET_BRANCH_SALES":
      return {
        ...state,
        branchSales: action.payload
      }
    case 'SET_BRANCH':
      return {
        ...state,
        selectedRestaurant: action.payload,
      }
    case 'SET_USER_PERMISSIONS':
      return {
        ...state,
        userPermissions: action.payload,

      }
    case "SET_OAUTH_TOKEN":
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          oauth_token: action.payload.oauth_token,
          refresh_token: action.payload.refresh_token,
          mfa_session_token: true
        },
      }
    case 'SET_BACKED_SESSION_TIMEOUT':
      return {
        ...state,
        backendSessionTimeout: action.payload,

      }
    case 'SET_SESSION_TIMEOUT':
      return {
        ...state,
        sessionTimeOut: action.payload,

      }
    default:
      return state
  }
}

const setSelectedRestaurant = (data) => {
  return data.restaurants[0]
}



export default loginReducer;
