import * as React from "react"

const TabletFrame = ({ width = 834, height = 625, ...props }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <rect x={2} y={2} width={width} height={height} rx={40} fill="#000" />
      <rect
        x={2}
        y={2}
        width={width}
        height={height}
        rx={40}
        fill="url(#b)"
        fillOpacity={0.4}
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={399.805}
        y1={312.5}
        x2={711.21}
        y2={517.427}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0} />
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <filter
        id="a"
        x={0}
        y={0}
        width={width}
        height={height}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6901_350055"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_6901_350055"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default TabletFrame
