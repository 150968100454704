import React, { useEffect } from 'react';
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import styles from '../styles';
import { AvenirBlackH4 } from '../utils/text';
import InfoOutlined from './SVG/InfoOutlined';
import HelperText from './HelperText';
import { H5 } from '../utils/text';
const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    "& $notchedOutline": {
      borderColor: "#707272",
    },
    "&:hover $notchedOutline": {
      borderColor: "#707272",
    },
    "&$focused $notchedOutline": {
      borderColor: "#707272",
    },
    "&.Mui-error": {
      borderColor: "#AB1212",
      marginLeft: 0
    },
  },
  focused: {},
  notchedOutline: {
    '&.MuiOutlinedInput-root.Mui-error': {
      borderColor: "#AB1212"
    }
  }
}));
const InputText = ({
  value,
  info = "",
  onChange,
  id,
  error,
  placeholder = "",
  type = "text",
  isPlaceHolder = true,
  InputProps = null,
  disabled = false,
  onBlur = false,
  width = null,
  height = 56,
  inputPlaceholder = '',
  paddingBottom,
  errorIcon = true,
  maxLength = null,
  endAdornment,
  colorStyle,
  ...props }) => {
  const style = styles();
  const outlinedInputClasses = useOutlinedInputStyles();
  if (error) {
    props["error"] = true
  }
  const onChangeValue = (e) => {
    let v = e.target.value
    if (props.multiline && props.rows == 1) {
      e.target.value = e.target.value.replace(/\n/g, '')
    }
    if (type == "number" && maxLength) {
      if (v.toString().length > maxLength)
        return
    }
    onChange(e)
  }

  if (type == "number") {
    props = {
      ...props,
      onWheel: (e) => e.target instanceof HTMLElement && e.target.blur()
    }
  }

  return (
    <FormControl data-qaid="inputtext_formcontrol" {...props} variant="outlined" className={style.fullWidth}>
      {isPlaceHolder ?
        <AvenirBlackH4 data-qaid="inputtext_placeholder" label={placeholder} style={{ marginBottom: 2, paddingBottom: paddingBottom }} />
        :
        <></>
      }
      <OutlinedInput
        disabled={disabled}
        data-qaid="inputtext_outlinedinput"
        variant="standard"
        {...props}
        id={id}
        type={type}
        value={value}
        style={{ width: width !== "null" ? width : "100%", height: height !== "null" ? height : 50, ...colorStyle }}
        placeholder={inputPlaceholder}
        onChange={onChangeValue}
        spellCheck={true}
        inputProps={{
          maxLength: maxLength,
          spellCheck: true,
          lang: 'en',

          ...InputProps
        }}
        aria-describedby={`${id}-text`}
        classes={outlinedInputClasses}
        onBlur={onBlur}
        endAdornment={endAdornment}
      />
      {
        error ?
          <FormHelperText data-qaid="inputtext_formhelpertext" id={`${id}-text`} style={{ color: "#AB1212", display: 'flex', marginLeft: 0, marginTop: 8 }}>
            {errorIcon ? <InfoOutlined width={16} height={16} color="#AB1212" style={{ marginRight: 6 }} /> : null}
            < H5 label={error} style={{ color: '#AB1212 ' }} />
          </FormHelperText>
          :
          <>
            {
              info &&
              <HelperText text={info} />
            }
          </>
      }

    </FormControl>

  )
}
export default InputText;
