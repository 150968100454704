import React, { useState, useEffect, useContext, useRef } from 'react';
import SimpleTable from '../../../components/SimpleTable';
import { Grid, Button, Paper, makeStyles } from '@material-ui/core';
import Daterange from '../../../components/DateRange';
import { AvenirBlackH4 } from '../../../utils/text';
import MyContext from '../../../Provider/MyContext';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import RightDrawer from '../../../components/RightDrawer';
import ReportView from './ReportView';
import CloseCircle from '../../../components/SVG/CloseCircle';
import useStyles from '../../../styles/index';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import SmallMailIcon from '../../../components/SVG/SmallMailIcon';
import { downloadEodReport } from '../../../utils/excel';
import { getDateTimeFormat, getDateFormat } from '../../../utils/util';
import {
  fetchListOfUserReports,
  fetchSpecificShiftReport,
  postEmailReport,
} from '../../../redux/actions';
import ReactToPrint from 'react-to-print';
import EmployeeDropDown from '../../../components/EmployeeDropDown';
import SnackBar from '../../../components/SnackBar';
import CircleClose from '../../../components/SVG/CircleClose';
import { addWeeks } from 'date-fns';
import { handleSorting } from '../../../Provider/ReportingServices';
import { downloadEodPdfDocument } from '../../../components/PDFTableGenerator';

const UserReports = () => {
  const toDate = new Date();
  const fromDate = new Date(toDate);
  fromDate.setDate(fromDate.getDate() - 7);
  const style = useStyles();
  const dispatch = useDispatch();
  const printJobRef = useRef();
  const eodShiftReportList = useSelector(
    (state) => state.eodReportsReducer.listEodShiftReports
  );

  const eodSpecificReport = useSelector(
    (state) => state.eodReportsReducer.specificEodShiftReport
  );

  const [table, setTable] = useState([]);
  const Context = useContext(MyContext);
  const [executionId, setExecutionId] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [initialValue, setInitialValue] = useState([
    fromDate,
    toDate,
  ]);
  const [initialRange, setInitialRange] = useState({
    label: Context.langData.last_7days,
    startDate: fromDate,
    endDate: toDate,
  });
  const [date, setDate] = useState({
    from_date: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [snackbar, setSnackbar] = useState(null);

  const [eodColumns, setEodColumns] = useState([
    {
      field: 'executedAt',
      display: Context.langData.date_and_time,
      size: '33%',
    },
    {
      field: 'generatedBy',
      display: Context.langData.generated_by,
      size: '33%',
    },
    {
      field: 'employeeName',
      display: Context.langData.employee_name,
      size: '33%',
    },
  ]);
  const [closeOpen, setCloseOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const onDateChange = (d) => {
    if (
      date['from_date'] !== d.startDate ||
      date['to_date'] !== d.endDate
    ) {
      if (selectedEmployee && selectedEmployee?.length > 0) {
        handleEmployeeFilter(d.startDate, d.endDate);
      } else {
        dispatch({
          type: 'GET_LIST_EOD_SHIFT_REPORTS',
          payload: [],
        });
        dispatch(
          fetchListOfUserReports({
            id: d.startDate,
            id2: d.endDate,
          })
        );
      }
      setDate({
        from_date: d.startDate,
        to_date: d.endDate,
      });
    }
  };

  const onRowClick = (e) => {
    dispatch(fetchSpecificShiftReport(e.executionId));
    setExecutionId(e.executionId);
    setCloseOpen(true);
  };

  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...eodColumns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setEodColumns(columnData);
    setTable(handleSorting(value, table, sortValue));
  };

  const downloadExcel = () => {
    var executed_at = getDateFormat(eodSpecificReport?.executedAt)
    var report_title = eodSpecificReport?.reportName
    var report_subtitle = eodSpecificReport?.employee?.employeeName + "        " + getDateTimeFormat(eodSpecificReport?.executedAt)
    var file_name = "UserReport_" + eodSpecificReport?.employee?.employeeName + "_" + executed_at + ".xlsx"

    downloadEodReport(eodSpecificReport, report_title, report_subtitle, file_name);
  };

  const downloadPDF = () => {
    var executed_at = getDateFormat(eodSpecificReport?.executedAt)
    var report_title = eodSpecificReport?.reportName
    var report_subtitle = eodSpecificReport?.employee?.employeeName + "        " + getDateTimeFormat(eodSpecificReport?.executedAt)
    var file_name = "UserReport_" + eodSpecificReport?.employee?.employeeName + "_" + executed_at + ".pdf"

    downloadEodPdfDocument(
      report_title,
      report_subtitle,
      eodSpecificReport?.ReportData?.data,
      file_name
    );
  };

  useEffect(() => {
    dispatch({
      type: 'GET_LIST_EOD_SHIFT_REPORTS',
      payload: [],
    });
    dispatch(
      fetchListOfUserReports({
        id: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        id2: moment().format('YYYY-MM-DD'),
      })
    );
  }, []);

  const onClear = () => {
    setSelectedEmployee([]);
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate() - 7);
    setDate({
      from_date: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
    });
    setRefresh(true);
    setInitialValue([fromDate, toDate]);
    setInitialRange({
      label: Context.langData.last_7days,
      startDate: fromDate,
      endDate: toDate,
    });
  };

  useEffect(() => {
    if (eodShiftReportList) {
      const sortedList = eodShiftReportList.reverse();
      const formattedEodShiftReportList = sortedList.map((item) => {
        const formattedExecutedAt = getDateTimeFormat(item.executedAt);
        return {
          executionId: item.executionId,
          executedAt: formattedExecutedAt,
          generatedBy: item.executedBy.employeeName,
          employeeName: item.employee.employeeName,
        };
      });
      setTable(formattedEodShiftReportList);
    }
  }, [eodShiftReportList]);

  const handleEmployeeFilter = (
    from_date = date.from_date,
    to_date = date.to_date
  ) => {
    if (selectedEmployee && selectedEmployee.length > 0) {
      const employeeParam =
        selectedEmployee.length > 1
          ? selectedEmployee.join(',')
          : selectedEmployee[0];

      dispatch({
        type: 'GET_LIST_EOD_SHIFT_REPORTS',
        payload: [],
      });
      dispatch(
        fetchListOfUserReports({
          id: from_date,
          id2: to_date,
          id3: employeeParam,
        })
      );
    } else {
      dispatch({
        type: 'GET_LIST_EOD_SHIFT_REPORTS',
        payload: [],
      });
      dispatch(
        fetchListOfUserReports({
          id: from_date,
          id2: to_date,
        })
      );
    }
  };

  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e);
  };

  useEffect(() => {
    handleEmployeeFilter();
  }, [selectedEmployee]);

  const handleEmailReports = () => {
    dispatch(postEmailReport(executionId, handleToast));
  };

  const handleToast = (value) => {
    if (value) {
      setSnackbar({
        message: Context.langData.eod_email_sent_succ,
        severity: 'success',
      });
    } else {
      setSnackbar({
        message: Context.langData.eod_email_failed,
        severity: 'error',
      });
    }
  };

  const onPageChange = (value) => {
    setPageNumber(value);
  };

  return (
    <Grid
      data-qaid="useReportList_dateRange_mainContainer"
      container
      style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Grid data-qaid="useReportList_dateRange_subContainer" container>
        <Grid
          data-qaid="useReportList_dateRange_dateContainer"
          item
          lg={9}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid
              data-qaid="useReportList_dateRange_dateGrid"
              item
              style={{ marginLeft: 10 }}>
              <AvenirBlackH4
                data-qaid="daywiseSales_dateLabel"
                label={Context.langData.from_to}
              />
              <Daterange
                data-qaid="useReportList_dateRange"
                isUTCChange={true}
                onDateChange={onDateChange}
                initialValue={initialValue}
                initialRange={initialRange}
                refreshWithValues={refresh}
              />
            </Grid>
            <Grid
              data-qaid="consumption_vendor_kitchen_grid"
              item
              style={{ marginLeft: 10 }}>
              <EmployeeDropDown
                selectedValue={selectedEmployee}
                handleChange={handleEmployeeChange}
                lg={10}
                width={300}
              />
            </Grid>
          </Grid>

          <Grid
            data-qaid="clearGrid"
            onClick={onClear}
            style={{
              display: 'flex',
              marginRight: 20,
              cursor: 'pointer',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <CircleClose data-qaid="circleCloseIcon"></CircleClose>
            <AvenirBlackH4
              data-qaid="clearLabel"
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        data-qaid="useReportList_dateRange_simpleTableGrid"
        style={{ marginTop: 40 }}>
        <SimpleTable
          data-qaid="useReportList_dateRange_simpleTable"
          columns={eodColumns}
          rows={table}
          isEditArrow={true}
          isOnClick={true}
          sortingOnClick={sortingOnClick}
          onRowClick={onRowClick}
          onPageChange={onPageChange}
        />
      </Grid>
      <RightDrawer
        open={closeOpen}
        onClose={() => {
          setCloseOpen((prev) => !prev);
        }}>
        <Grid display="flex" flexDirection="column">
          <Grid
            data-qaid="close_grid"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}>
            <CloseCircle
              data-qaid="closeicon"
              size={20}
              onClick={() => {
                setCloseOpen(false);
                setExecutionId('');
              }}
              style={{
                marginRight: 40,
                marginTop: 20,
              }}
            />
          </Grid>
          <Paper ref={printJobRef}>
            <ReportView isShiftReport={true} />
          </Paper>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px',
            }}>
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '20px',
              }}>
              <Button
                startIcon={<DownlodeIcon />}
                style={{ marginLeft: 10 }}
                onClick={downloadExcel}>
                <AvenirBlackH4 label="Download Excel" style={{ color: '#004C60' }} />
              </Button>
              <Button
                startIcon={<DownlodeIcon />}
                style={{ marginLeft: 10 }}
                onClick={downloadPDF}>
                <AvenirBlackH4 label="Download PDF" style={{ color: '#004C60' }} />
              </Button>
              <Button
                startIcon={<SmallMailIcon />}
                style={{ marginLeft: 10 }}
                onClick={handleEmailReports}>
                <AvenirBlackH4 label="Email" style={{ color: '#004C60' }} />
              </Button>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '20px',
              }}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    className={style.buttonClass}
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: '#FFB600',
                      height: 40,
                      width: 103,
                      marginLeft: '10px',
                    }}>
                    <AvenirBlackH4
                      data-qaid="print-eod-reports"
                      label={Context.langData.print}
                    />
                  </Button>
                )}
                content={() => printJobRef.current}
              />
            </Grid>
          </Grid>
        </Grid>
      </RightDrawer>
      {snackbar && (
        <SnackBar
          data-qaid="categories_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </Grid>
  );
};

export default UserReports;
