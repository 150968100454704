import * as React from "react";

function PluseIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99992 1.05632C4.13393 1.05632 0.999919 4.19032 0.999919 8.05632C0.999919 11.9223 4.13393 15.0563 7.99992 15.0563C11.8659 15.0563 14.9999 11.9223 14.9999 8.05632C14.9999 4.19032 11.8659 1.05632 7.99992 1.05632ZM0.333252 8.05632C0.333252 3.82213 3.76574 0.389648 7.99992 0.389648C12.2341 0.389648 15.6666 3.82213 15.6666 8.05632C15.6666 12.2905 12.2341 15.723 7.99992 15.723C3.76574 15.723 0.333252 12.2905 0.333252 8.05632ZM7.99992 3.72298C8.18401 3.72298 8.33325 3.87222 8.33325 4.05632V7.72298H11.9999C12.184 7.72298 12.3333 7.87222 12.3333 8.05632C12.3333 8.24041 12.184 8.38965 11.9999 8.38965H8.33325V12.0563C8.33325 12.2404 8.18401 12.3896 7.99992 12.3896C7.81582 12.3896 7.66659 12.2404 7.66659 12.0563V8.38965H3.99992C3.81582 8.38965 3.66659 8.24041 3.66659 8.05632C3.66659 7.87222 3.81582 7.72298 3.99992 7.72298H7.66659V4.05632C7.66659 3.87222 7.81582 3.72298 7.99992 3.72298Z"
        fill="#051D33"
      />
    </svg>

    // <svg
    //   width={16}
    //   height={16}
    //   viewBox="0 0 16 16"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M8.167 16a.5.5 0 01-.5-.5V.5a.5.5 0 111 0v15a.5.5 0 01-.5.5z"
    //     fill="#051D33"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M0 8.167a.5.5 0 01.5-.5h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5z"
    //     fill="#051D33"
    //   />
    // </svg>
  );
}

export default PluseIcon;
