import urls from "../redux/actions/urls";
import axiosInstance from "../redux/actions/axiosInstance";
import { convertSSOModel } from "../models/SsoModelConverter";
import { StorageKey } from "../models/Constant";
import { SaveToSession, ReadFromSession } from "./sessionStorageServices";

const sensitiveQueryParams = ["accessToken"];

export const redirectExternalUrl = (url) => {
  const reportUrl = new URL(url);
  sensitiveQueryParams.forEach((element) => {
    if (reportUrl.searchParams.has(element)) {
      reportUrl.searchParams.delete(element);
    }
  });
  console.log("Redirect to external URL", reportUrl.toString());

  window.location.assign(url);
};

export async function getSsoRedirectUrl(rest_id, app_state = "") {
  return new Promise((resolve, reject) => {
    const funcName = "getSsoRedirectUrl";
    if (!rest_id) return reject(`ERROR in ${funcName}, no rest_id provided.`);
    const url = urls.sso.login;
    const payload = {
      rest_id,
      app_state: app_state,
    };
    axiosInstance
      .post(url, payload)
      .then((res) => {
        const url = convertSSOModel(res.data).url;
        const parsedUrl = new URL(url);
        const state = parsedUrl.searchParams.get("state");
        SaveToSession(StorageKey.SsoState, state);
        SaveToSession(StorageKey.Rest_id, rest_id);
        console.log(`${funcName} result and state`, url, state);
        return resolve(url);
      })
      .catch((err) => {
        const error = `ERROR in ${funcName}, ${err}`;
        console.error(error);
        return reject(err);
      });
  });
}

export async function ssoCallback(payload) {
  return new Promise((resolve, reject) => {
    const funcName = "ssoCallback";
    if (!payload) return reject(`ERROR in ${funcName}, no payload provided.`);
    const url = urls.sso.callback;
    axiosInstance
      .post(url, payload)
      .then((res) => {
        console.log(`${funcName} result and state`, url, res);
        return resolve(res);
      })
      .catch((err) => {
        const error = `ERROR in ${funcName}, ${err}`;
        console.error(error);
        return reject(err);
      });
  });
}

export function ssoReAuth(rest_id) {
  if (ReadFromSession(StorageKey.Is_Sso) === 'true') {
    const path = window.location.pathname
    const state = window.history.state.usr

    getSsoRedirectUrl(rest_id, path).then((result) => {

      // Save state in session storage
      SaveToSession(StorageKey.RouterState, JSON.stringify(state));

      redirectExternalUrl(result);
      return true
    });
  } else {
    // window.location.replace("/login");
    return false
  }
}
