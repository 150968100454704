import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Grid
} from '@material-ui/core';
import {
    fetchAddonItem,
    fetchAddonCategories,
    createAddonCategories,
    editAddonCategories,
    deleteAddonCategories,
} from '../../../redux/actions';
import InputText from '../../../components/InputText';
import MyContext from '../../../Provider/MyContext';
import CirclePluse from '../../../components/SVG/PluseIcon';
import DeleteIcon from '../../../components/SVG/Delete';
import { H3, AvenirBlackH4, H8 } from '../../../utils/text';
import RightDrawer from '../../../components/RightDrawer';
import CircleClose from '../../../components/SVG/CloseCircle';
import SnackBar from '../../../components/SnackBar';
import styles from '../../../styles/index';
import NoData from '../../../components/NoData';
import SimpleTable from '../../../components/SimpleTable';
import _ from 'lodash';
import BackIcon from '../../../components/SVG/Back';
import CirclePluseIcon from '../../../components/SVG/CirclePluse';
import AlertDialog from '../../../components/Dialog';
import { validateAlphaNumeric } from '../../../utils/util';

const AddonsList = () => {
    const style = styles();
    const Context = useContext(MyContext);
    const Categories = useSelector(state => state.menu.addonCategories);
    const [btnDisable, setBtnDisable] = React.useState(true);
    const [openCreateCategories, toggleCreateCategoriesDrawer] = useState(false);
    const [openEditCategories, toggleEditCategoriesDrawer] = useState(false);
    const [row, setRow] = useState({});
    const dispatch = useDispatch();
    const [snackbar, setSnackbar] = useState(null)
    const [pagenumber, setPagenumber] = useState(0);
    const [selectedTabData, setSelectedTabData] = useState([]);
    const [columns, setColumns] = useState([
        { field: 'addon_cat_name', display: Context.langData.modifier_group_name, sort: false, size: '50%' },
        { field: 'modifiers', display: Context.langData.modifier, size: '50%' },

    ]);
    useEffect(() => {
        dispatch(fetchAddonCategories())
    }, [])
    useEffect(() => {
        Categories.map((item) => {
            item["modifiers"] = item.addon_category_items.map(adoonItem => adoonItem.addon_item_name).join(',');
        })
        setSelectedTabData(Categories)
    }, [Categories])
    const btnProps = {};
    if (btnDisable) {
        btnProps["disabled"] = true
    }
    function CommonCallback() {
        toggleCreateCategoriesDrawer(false);
        toggleEditCategoriesDrawer(false);
        dispatch(fetchAddonCategories());
    }
    function CreateCategoriesCallback(isSuuscess) {
        CommonCallback();
        if (isSuuscess) {
            setSnackbar({ message: Context.langData.addon_cat_create_succ, severity: 'success' });
        } else {
            setSnackbar({ message: Context.langData.addon_cat_create_failed, severity: 'error' });
        }
    }
    function editCategoriesCallback(isSuuscess, msg) {
        console.log("editCategoriesCallback", msg)
        CommonCallback();
        if (isSuuscess) {
            setSnackbar({ message: Context.langData.addon_cat_edit_succ, severity: 'success' });
        } else {
            setSnackbar({ message: msg.message, severity: 'error' });
        }
    }
    function deleteCategoriesCallback(isSuuscess, data) {
        console.log("delete.....", data)
        CommonCallback();
        if (isSuuscess) {
            setSnackbar({ message: Context.langData.addon_cat_delete_succ, severity: 'success' });
        } else {
            setSnackbar({ message: data.status, severity: 'error' });
        }
    }

    const onPageChange = (value) => {
        setPagenumber(value)
    }
    const sortingOnClick = (name, desc, index) => {
        var data = [...columns];
        data[index] = { ...data[index], sort: !desc }
        setColumns(data);
        var sortAddons = _.orderBy(selectedTabData, [name], [desc ? 'desc' : 'asc']);
        setSelectedTabData(sortAddons)
    }
    const onRowClick = (rowValue) => {
        console.log("on Click row,rowValue", rowValue)
        setRow(rowValue)
        toggleEditCategoriesDrawer(true);
    }
    return (
        <>

            <Grid data-qaid="categories_main_grid" container justify="flex-start" spacing={3}>
                {
                    selectedTabData.length > 0 ?
                        <>
                            <Grid data-qaid="categories_grid" item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginRight: 20, marginBottom: 10, marginTop: 32, marginLeft: 32 }} >
                                <Grid data-qaid="categories_grid" item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }} >
                                </Grid>
                                <Grid data-qaid="categories_grid" item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row' }} >
                                    <Button className={style.buttonClass} data-qaid="categories_creategrid" style={{ backgroundColor: "#FFB600", color: "white", marginRight: 10 }} variant="contained" id="cancel" onClick={() => toggleCreateCategoriesDrawer(true)}  >
                                        <CirclePluse data-qaid="categories_createpluse" />
                                        <AvenirBlackH4 data-qaid="categories_createlabel" label={Context.langData.create_modifier_group} style={{ paddingLeft: 5 }} />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid data-qaid="categories_body_grid" item xs={12} style={{ marginLeft: 32, marginRight: 32, marginBottom: 30, width: '100%' }}>
                                <SimpleTable data-qaid="categories_simpleTable" columns={columns} rows={selectedTabData} sortingOnClick={sortingOnClick} onPageChange={onPageChange} onRowClick={onRowClick} />
                            </Grid>
                        </>
                        :
                        <NoData page="addon category" callback={() => toggleCreateCategoriesDrawer(true)} />
                }
                {
                    snackbar &&
                    <SnackBar
                        data-qaid="addon_categories_snackbar"
                        open={true}
                        setOpen={() => setSnackbar(null)}
                        severity={snackbar.severity}
                        message={snackbar.message}
                    />
                }
                {
                    openCreateCategories &&
                    <CreateAddonCategory
                        data-qaid="addon_create_categories_snackbar"
                        open={openCreateCategories}
                        onClose={() => toggleCreateCategoriesDrawer(false)}
                        callback={CreateCategoriesCallback}
                    />
                }
                {
                    openEditCategories &&
                    <EditAddonCategory
                        data-qaid="addon_create_categories_snackbar"
                        open={openEditCategories}
                        row={row}
                        onClose={() => toggleEditCategoriesDrawer(false)}
                        callback={editCategoriesCallback}
                        deleteCallback={deleteCategoriesCallback}
                    />
                }
            </Grid >
        </>
    );
};
const CreateAddonCategory = ({ open, callback, onClose }) => {
    const style = styles();
    const [catName, setCatName] = useState("");
    const [addonItems, setAddonItems] = useState([{ "name": '' }]);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const Context = useContext(MyContext);
    const save = () => {
        if (catName.trim()) {
            var items = [];
            if (addonItems.length > 0) {
                addonItems.map((item) => {
                    if (item.name) {
                        items.push(item.name)
                    }
                })
            }
            dispatch(createAddonCategories({
                'addon_cat_name': catName.trim(),
                "addon_items": items
            },
                callback))
        } else {
            setError(Context.langData.no_text_error)
        }

    }
    const setItemName = (val, index) => {
        var array = [...addonItems];
        array[index] = {
            ...array[index],
            "name": val
        }
        setAddonItems(array)
    }
    const increaseItem = () => {
        var array = [...addonItems];
        array.push({ "name": '' })
        setAddonItems(array)
    }
    const setCatname = (val) => {
        setError('');
        setCatName(val)

    }
    return (
        <RightDrawer open={open} padding={1} onClose={onClose} >
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '80%', flexDirection: 'column', overflowX: 'auto' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }} onClick={onClose}>
                            <BackIcon data-qaid="categories_editBack_icon" onClick={() => onClose()} style={{ cursor: 'pointer' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.create_modifier_group} />

                        <Grid style={{ marginLeft: 56, marginRight: 56, width: 500 }}>
                            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="createaddon_valuelabel" label={Context.langData.addon_cat_name} />
                                <InputText
                                    value={catName}
                                    error={error}
                                    onChange={(val) => setCatname(validateAlphaNumeric(val.target.value))}
                                    maxLength={64}
                                    id="cat_name" />
                            </Grid>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', marginTop: 20, borderBottom: '1px solid #EEEEEE' }}>
                                <H3 data-qaid="createaddon_valuelabel" label={Context.langData.add_modifiers} style={{ marginBottom: 10 }} />
                            </Grid>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                {addonItems.length > 0 ?
                                    addonItems.map((item, index) => {
                                        return (
                                            <Grid data-qaid="modifiers_input_field" container key={index} style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
                                                <AvenirBlackH4 data-qaid="createaddon_valuelabel" label={Context.langData.item} />
                                                <InputText
                                                    value={item.name}
                                                    maxLength={64}
                                                    onChange={(val) => setItemName(validateAlphaNumeric(val.target.value), index)}
                                                    id="cat_name" />
                                            </Grid>
                                        )
                                    })
                                    :
                                    <>
                                    </>
                                }
                                <Grid container style={{ display: 'flex', flexDirection: 'row', marginTop: 15, cursor: 'pointer', width: 60 }} onClick={() => { increaseItem() }}>
                                    <CirclePluseIcon data-qaid="createaddon-CirclePluseIcon" color="#007191" style={{ marginRight: 10 }} />
                                    <AvenirBlackH4 data-qaid="createaddon__add" label={Context.langData.add} style={{ color: '#007191' }} />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '20%', flexDirection: 'column', justifyContent: 'flex-end' }}>

                        <Grid data-qaid="createaddon_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                            <Grid data-qaid="createaddon_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="createaddon_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, height: 40, width: 103, border: '2px solid #051D33' }} >
                                    <AvenirBlackH4 data-qaid="createaddon_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="createaddon_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="createaddon_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )

}
const EditAddonCategory = ({ open, callback, row, onClose, deleteCallback }) => {
    const style = styles();
    const [addonItems, setAddonItems] = useState([{ "name": '' }]);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});
    const Context = useContext(MyContext);
    const [showDeleteDialog, toggleDeleteDialog] = useState(false)
    const [errorCheck, setErrorCheck] = useState(false)

    const save = () => {
        var data = { ...response }
        var items = data.addon_items;
        if (addonItems.length > 0) {
            addonItems.map((item) => {
                if (item.name) {
                    items.push({ addon_item_name: item.name })
                }
            })
        }
        data = { ...data, addon_items: items }
        console.log("data===>", data)
        if (errorCheck == false) {
            if (response?.addon_cat_name) {
                dispatch(editAddonCategories(data, callback))
            } else {
                setError(Context.langData.no_text_error)
            }
        } else {
            setErrorCheck(true)
        }
    }
    const setItemName = (val, index) => {
        var array = [...addonItems];
        array[index] = {
            ...array[index],
            "name": val
        }
        setAddonItems(array)
    }
    const increaseItem = () => {
        var array = [...addonItems];
        array.push({ "name": '' })
        setAddonItems(array)
    }
    useEffect(() => {
        dispatch(fetchAddonItem({ 'addon_cat_id': row.addon_cat_id }, getAddonCatResponse));
    }, [])
    const getAddonCatResponse = (data) => {
        setResponse(data)
    }
    const removeItem = (index) => {
        var items = [...response.addon_items]
        items = [...items.slice(0, index), ...items.slice(index + 1)];
        let respnseObj = { ...response, "addon_items": items }
        setResponse(respnseObj)
    }
    const EditItem = (val, index) => {
        var items = [...response.addon_items];
        let item = items[index];
        item["addon_item_name"] = val;
        items = [...items.slice(0, index), item, ...items.slice(index + 1)]
        let respnseObj = { ...response, "addon_items": items }
        setResponse(respnseObj)
        setErrorCheck(val != '' ? false : true)
    }
    const setCatName = (val) => {
        setError();
        var responseData = { ...response, addon_cat_name: val }
        setResponse(responseData)
    }
    const deleteCategories = () => {
        dispatch(deleteAddonCategories([response.addon_cat_id], deleteCallback));
    }
    const handleDeleteClose = (e) => {
        if (e) {
            deleteCategories()
        }
        else {
            toggleDeleteDialog(false)
        }
    }
    return (
        <RightDrawer open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '80%', flexDirection: 'column', overflowX: 'auto' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }} onClick={onClose}>
                            <BackIcon data-qaid="categories_editBack_icon" onClick={() => onClose()} style={{ cursor: 'pointer' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.edit_modifier_group} />
                        <Grid style={{ marginLeft: 56, marginRight: 56, width: 500 }}>
                            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="createaddon_valuelabel" label={Context.langData.addon_cat_name} />
                                <InputText
                                    value={response.addon_cat_name}
                                    error={error}
                                    maxLength={64}
                                    onChange={(val) => setCatName(validateAlphaNumeric(val.target.value))}
                                    id="cat_name" />
                            </Grid>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', marginTop: 20, borderBottom: '1px solid #EEEEEE' }}>
                                <H3 data-qaid="createaddon_valuelabel" label={Context.langData.add_modifiers} style={{ marginBottom: 10 }} />
                            </Grid>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                {addonItems.length > 0 ?
                                    addonItems.map((item, index) => {
                                        return (
                                            <Grid data-qaid="modifiers_input_field" container key={index} style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
                                                <AvenirBlackH4 data-qaid="createaddon_valuelabel" label={Context.langData.item} />
                                                <InputText
                                                    value={item.name}
                                                    maxLength={64}
                                                    onChange={(val) => setItemName(validateAlphaNumeric(val.target.value), index)}
                                                    id="cat_name" />
                                            </Grid>
                                        )
                                    })
                                    :
                                    <>
                                    </>
                                }
                                <Grid container style={{ display: 'flex', flexDirection: 'row', marginTop: 15, cursor: 'pointer', width: 55 }} onClick={() => { increaseItem() }}>
                                    <CirclePluseIcon data-qaid="createaddon-CirclePluseIcon" color="#007191" style={{ marginRight: 10 }} />
                                    <AvenirBlackH4 data-qaid="createaddon__add" label={Context.langData.add} style={{ color: '#007191' }} />
                                </Grid>
                            </Grid>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', marginTop: 20, borderBottom: '1px solid #EEEEEE' }}>
                                <H3 data-qaid="createaddon_valuelabel" label={Context.langData.edit_existing_modifiers} style={{ marginBottom: 10 }} />
                            </Grid>
                            {response?.addon_items?.length > 0 ?
                                response.addon_items.map((item, index2) => {
                                    return (
                                        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid data-qaid="modifiers_input_field_edit" container key={index2} style={{ display: 'flex', flexDirection: 'column', marginTop: 8, width: '80%' }}>
                                                <AvenirBlackH4 data-qaid="createaddon_valuelabel" label={`${Context.langData.item} ${index2 + 1}`} />
                                                <InputText
                                                    value={item.addon_item_name}
                                                    maxLength={64}
                                                    error={item.addon_item_name.length == 0 && "Item is required"}
                                                    onChange={(val) => EditItem(validateAlphaNumeric(val.target.value), index2)}
                                                    id="cat_name" />
                                            </Grid>
                                            <Grid style={{ display: 'flex', width: '20%', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: item.addon_item_name.length == 0 ? '26px' : '0px' }}>
                                                <DeleteIcon onClick={() => { removeItem(index2) }} />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                :
                                <>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '20%', flexDirection: 'column', justifyContent: 'flex-end' }}>

                        <Grid data-qaid="createaddon_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                            <Grid data-qaid="createaddon_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button data-qaid="createaddon_cancel_button" onClick={() => toggleDeleteDialog(true)} variant="text" style={{ marginRight: 16 }} >
                                    <DeleteIcon width={20} height={20} />
                                    <AvenirBlackH4 data-qaid="createaddon_delete_text" label={Context.langData.delete} style={{ marginLeft: 10 }} />
                                </Button>
                                <Button className={style.buttonClass} data-qaid="createaddon_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, height: 40, width: 103, border: '2px solid #051D33' }} >
                                    <AvenirBlackH4 data-qaid="createaddon_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="createaddon_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="createaddon_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    showDeleteDialog &&
                    <AlertDialog
                        showDialog={showDeleteDialog}
                        body={"Are you sure you want to delete this Modifier group?"}
                        heading={"Delete modifier group"}
                        IsError={false}
                        showExtra={false}
                        handleClose={handleDeleteClose} />
                }
            </>
        </RightDrawer>
    )

}

export default AddonsList;
