import * as React from "react"

const Arrow = ({ size = 24, rotation = 0, color = "#004C60", ...props }) => (
  <svg
    style={{
      transform: `rotate(${rotation}deg)`,
    }}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 128 128"
    xmlSpace="preserve"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M76 28a3.988 3.988 0 0 0-2.828 1.172l-32 32a3.997 3.997 0 0 0 0 5.656l32 32c1.563 1.563 4.094 1.563 5.656 0s1.563-4.094 0-5.656L49.656 64l29.172-29.172a3.997 3.997 0 0 0 0-5.656A3.988 3.988 0 0 0 76 28zm52 36c0 35.289-28.711 64-64 64S0 99.289 0 64 28.711 0 64 0s64 28.711 64 64zm-8 0c0-30.879-25.121-56-56-56S8 33.121 8 64s25.121 56 56 56 56-25.121 56-56z"
      data-original="#000000"
      fill={color}
    />
  </svg>
)

export default Arrow
