import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import EditButton from './editButton';
import SocialIcons from './SocialIcons';
import CancelIcon from '../../../components/SVG/Close'
import RightDrawer from '../../../components/RightDrawer'
import InputText from '../../../components/InputText';
import Color from '../../../components/Color'
import EditRestDetails from './EditRestDetails'
import LogoModal from './LogoModal';
import urls from '../../../redux/actions/urls';
import { H4, TBold, AvenirBlackH2 } from '../../../utils/text';

const RestLogo = ({ isPreview = false }) => {
  const [isEditRestName, toggleEditRestName] = useState(false)
  const [showLogoModal, setShowLogoModal] = useState(false)
  const { website, banner } = useSelector(state => state.builder)
  
  return (
    <div style={{
      height: 400,
      width: '100%',
      backgroundColor: '#EEEEEE',
      position: 'relative',
      backgroundImage: "url('"+banner+"')",
      backgroundPosition:'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      <div style={{ position: 'absolute', right: 40, top: 20 }}>
        {
          !isPreview &&
          <EditButton onClick={() => setShowLogoModal(true)} />
        }

      </div>
      <div style={{ position: 'absolute', right: 0, top: 138, zIndex: 0 }}>
        <SocialIcons />
      </div>
      <Grid container style={{ height: '100%', paddingLeft: 10 }} alignItems="center" >
        <Grid item xs={10} md={8} lg={6}>
          <Grid container justify="flex-end" >
            <Grid item xs={12} md={8}
              style={{ backgroundColor: '#fff', padding: 40, borderRadius: 0, position: 'relative' }}
            >
              <div style={{ position: 'absolute', right: 40, top: 5, margin: 10 }}>
                {
                  !isPreview &&
                  <EditButton onClick={() => toggleEditRestName(true)} />
                }

              </div>
              <TBold style={{ fontSize: 32, width: '80%' }} label={website.header} />
              <AvenirBlackH2 label={website.introduction} style={{overflowWrap:'break-word'}}/>
              <div style={{ marginTop: 20 }}>
                <Button variant="contained" style={{ backgroundColor: website.button_color }}>{website.button_text}</Button>
              </div>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      {
        isEditRestName &&
        <RightDrawer open={isEditRestName} onCloseOutside={() => toggleEditRestName(false)}>
          <EditRestDetails
            onClose={() => toggleEditRestName(false)}
            data={website}
          />
        </RightDrawer>
      }
      {
        showLogoModal &&
        <RightDrawer
          open={showLogoModal}
          onCloseOutside={() => setShowLogoModal(false)}>
          <LogoModal
            banner={banner}
            onClose={() => setShowLogoModal(false)}
          />
        </RightDrawer>
      }
    </div>
  )
}


export default RestLogo
