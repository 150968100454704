import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText, makeStyles } from '@material-ui/core';
import styles from '../styles'
import { conformToMask } from 'react-text-mask';
import ExclamationIcon from './SVG/ExclamationIcon';
import { H5, AvenirBlackH7 } from '../utils/text'
import InfoOutlined from "../components/SVG/InfoOutlined"
const regexObj = {
  phone: {
    r: ['1', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    // r: ['1', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    char: "-"
  },
  dob: {
    r: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    char: "-"
  },
  pin: {
    r: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    char: "-"
  },
  postal: {
    r: [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/],
    char: " "
  }
}
function timeMask(value) {

  const chars = value.split('');
  const hours = [
    /[0-2]/,
    chars[0] == '2' ? /[0-3]/ : /[0-9]/
  ];

  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      // borderWidth:0,
      borderColor: "#707272"
    },
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: "none !important"
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: "none !important"
    }
  },
  focused: {},
  notchedOutline: {}
}));
const TextInputMask = ({ value, handleChange, id, error, placeholder = "", autoFocus = false, regex, isFormik = true, onBlur = null,onFocus = null, InputProps, isVendor=false, ...iprops }) => {
  const style = styles();
  const props = {};
  const outlinedInputClasses = useOutlinedInputStyles();

  const [hasError, setError] = useState(false)
  if (hasError) {
    props["error"] = true
  }
  useEffect(() => {
    if (error) {
      setError(true)
    }
  }, [error])

  const onChange = (e) => {
    console.log(regex)
    let val = e.target.value;
    if (regex == 'time') {
      val = convertRegex(val, { r: timeMask(val), char: ':' }, value)
    } else {
      val = convertRegex(val, regexObj[regex], value)
      if (regex == "postal") {
        console.log("postal val", val)
        val = val.toUpperCase();
      }
    }
    if (regex == "phone") {
    if (val.length == 0 || val.length == 14) {
      setError(false);}}
      if (regex == "postal") {
        if (val.length == 0 || val.length == 7) {
          setError(false);
        }}
    handleChange(val)
  }
  const onBlurValue = (val) => {
    if (regex == "postal") {
      if (val.length == 0 || val.length == 7) {
        setError(false);
        onBlur(false)
      }
      else if (val.length !== 7) {
        setError(true);
        onBlur("Invalid Postal Code")
      }else {
        setError(false);
        onBlur()
      }

    }
    if (regex == "pin") {
      if (val.length == '' || val.length == 6) {
        setError(false);
      }
      else if (val.length !== 6) {
        setError(true);
        onBlur("Invalid PIN")
      }

    }
    if (regex == "phone") {
      console.log("blue\r", val.length)
      if (val.length == 0 || val.length == 14) {
        setError(false);
        onBlur(false)
      }
      else if (val.length !== 14) {
        setError(true);
        onBlur("Please enter a valid phone number")
        console.log("Invalid")
      }
    }
  }
  return (
    <FormControl data-qaid="textinputmask_formcontrol" {...props} className={style.fullWidth}>
      <OutlinedInput
        // autoFocus={autoFocus}
        data-qaid="textinputmask_outlinedinput"
        {...iprops}
        id={id}
        value={value}
        mask={regexObj[regex].r}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        // onFocus={(e) => onFocusVal(e)}
        onBlur={e => onBlurValue(e.target.value)}
        aria-describedby={`${id}-text`}
        classes={outlinedInputClasses}
        style={{ background: '#FFF', border: '1px solid green !important', height: 56 }}
        InputProps={InputProps}
      />
      {
        (hasError || error) &&
        <FormHelperText data-qaid="textinputmask_formhelpertext" id={`${id}-text`} style={{ color: "#AB1212", display: 'flex', margin: '3px 0px 0px 0px', paddingLeft: isVendor ? 0 : 10, paddingTop: isVendor ? 5 : 0 }}>
         {(isVendor && error) && <InfoOutlined width={16} height={16} color="#AB1212" style={{ marginRight: 6,}} />} {error ? error : ' '}
        </FormHelperText>
      }
    </FormControl>

  )
}

const convertRegex = (val, reg, pre) => {
  var phoneNumberMask = reg.r;

  if (!val.endsWith(reg.char)) {
    if (pre.length > val.length) {

    }else{
      val = conformToMask(
      val,
      phoneNumberMask,
      { guide: false }
    ).conformedValue
  }}
  else {

    console.log(val)
    val = val.substring(0, val.length - 1);
  }
  console.log(val)
  return val
}


export default TextInputMask;
