import * as React from "react"

const Back = (props) => {
  return (
    <svg
      width={63}
      height={24}
      viewBox="0 0 63 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.336 6.63a.5.5 0 01.034.706l-4.237 4.661H23.75a.5.5 0 010 1H2.128l4.242 4.667a.5.5 0 11-.74.672l-5-5.5a.5.5 0 010-.672l5-5.5a.5.5 0 01.706-.034z"
        fill="#004C60"
      />
      <path
        d="M33.274 16h3.402c1.932 0 3.486-.98 3.486-2.758 0-1.33-.952-2.38-2.142-2.464v-.028c1.008-.336 1.708-1.05 1.708-2.184 0-1.68-1.288-2.478-3.01-2.478h-3.444V16zm1.344-8.736h2.114c1.078 0 1.568.672 1.568 1.456 0 1.078-.826 1.484-1.848 1.484h-1.834v-2.94zm0 4.116h1.988c1.302 0 2.128.588 2.128 1.764 0 1.4-1.33 1.68-2.142 1.68h-1.974V11.38zm7.936-.42c.462-.35.924-.756 1.89-.756 1.078 0 1.582.714 1.582 1.4v.28h-.924c-2.016 0-3.654.588-3.654 2.296 0 1.288 1.064 1.988 2.296 1.988 1.008 0 1.764-.322 2.324-1.176h.028c0 .336.014.672.042 1.008h1.12a10.23 10.23 0 01-.056-1.204v-3.388c0-1.148-.812-2.212-2.772-2.212-.924 0-1.932.322-2.632.98l.756.784zm3.472 1.848v.42c0 .896-.49 1.932-1.89 1.932-.868 0-1.428-.322-1.428-1.078 0-1.064 1.456-1.274 2.646-1.274h.672zm8.702-2.548c-.658-.714-1.582-1.05-2.548-1.05-2.072 0-3.472 1.456-3.472 3.5s1.428 3.458 3.472 3.458c.966 0 1.862-.294 2.52-1.022l-.896-.896c-.42.49-.91.742-1.61.742-1.302 0-2.142-1.008-2.142-2.254s.7-2.352 2.072-2.352c.7 0 1.19.252 1.652.742l.952-.868zM55.784 16h1.26v-3.444L60.124 16h1.792l-3.36-3.612 3.094-3.01H59.9l-2.856 2.87V5.416h-1.26V16z"
        fill="#004C60"
      />
    </svg>
  )
}

const BackIcon = React.memo(Back)
export default BackIcon
