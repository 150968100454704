import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees } from '../redux/actions';
import MultiSelect from './MultiSelect';
import { Grid } from '@material-ui/core';
import { AvenirBlackH4 } from '../utils/text';
import MyContext from '../Provider/MyContext';
import _ from 'lodash';

function EmployeeDropDown({
  selectedValue = [],
  handleChange = [],
  lg = 0,
  width = 200,
}) {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const employeeData = useSelector(
    (state) => state.profileReducers.employeesList.data
  );
  const [modifiedEmployeeData, setModifiedEmployeeData] = useState([]);
  useEffect(() => {
    if (employeeData) {
      const sortedEmployeeList = _.orderBy(
        employeeData,
        ['full_name'],
        ['asc']
      );
      setModifiedEmployeeData(sortedEmployeeList);
    }
  }, [employeeData]);
  useEffect(() => {
    dispatch(fetchEmployees());
  }, []);
  return (
    <Grid item style={{ paddingLeft: 10 }} lg={lg}>
      <AvenirBlackH4
        data-qaid="daywiseSales_employeeFilterLable"
        label={Context.langData.employee}
      />
      <MultiSelect
        list={modifiedEmployeeData}
        value="employee_id"
        name="full_name"
        placeholder={Context.langData.all_employees}
        selectedValue={selectedValue}
        handleChange={(e) => handleChange(e)}
        border={false}
        height={56}
        width={width}
      />
    </Grid>
  );
}

export default EmployeeDropDown;
