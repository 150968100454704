import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Grid
} from '@material-ui/core';
import DataTable from '../../../../components/DataTable';
import styles from '../../../../styles/index';
import {
    fetchDeliveryOptions,
    deleteDeliveryOptions
} from '../../../../redux/actions';
import AlertDialog from '../../../../components/Dialog';
import CirclePluse from '../../../../components/SVG/PluseIcon';
import MyContext from '../../../../Provider/MyContext';
import { AvenirBlackH4 } from '../../../../utils/text';
import DeleteIcon from '../../../../components/SVG/Delete';
import SnackBar from '../../../../components/SnackBar';
import _ from 'lodash';
import NoData from '../../../../components/NoData';
import CreateMore from './CreateMore';
import EditMoreDrawer from './EditMore';
const MoreList = () => {

    const style = styles();
    const Context = useContext(MyContext);
    const [columns, setColumns] = useState([
        { field: 'name', headerName: Context.langData.name, size: '50%', sort: true },
        { field: 'description', headerName: Context.langData.description, size: '50%', sort: false }
    ])
    // const MetaData = useSelector(state => state.settingsReducer.meta);
    const DeliveryOptionsData = useSelector(state => state.settingsReducer.deliveryOptions)
    const [selectedId, setSelectedId] = React.useState([]);
    const [btnDisable, setBtnDisable] = React.useState(true);
    const [openCreate, toggleCreateDrawer] = useState(false);
    const [openEdit, toggleEditDrawer] = useState(false);
    const [row, setRow] = useState({});
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [heading, setHeading] = useState(Context.langData.delete_more);
    const [snackbar, setSnackbar] = useState(null);
    const [Meta, setMeta] = useState([]);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const bodyMessage = selectedId.length > 1 ? 'Are you sure you want to delete ' + selectedId.length + ' receipt labels?' : Context.langData.delete_more_body
    useEffect(() => {
        const filterDeliveryOptionData = _.reverse(DeliveryOptionsData);
        setDeliveryOptions(filterDeliveryOptionData)
    }, [DeliveryOptionsData])
    useEffect(() => {
        dispatch(fetchDeliveryOptions())
    }, [])
    function onCheckedIds(ids) {
        setSelectedId(ids);
        if (ids.length > 0) {
            setBtnDisable(false);
        } else {
            setBtnDisable(true);
        }
    }
    function onClickAction(rowValue) {
        setRow(rowValue)
        toggleEditDrawer(true);
    }
    const btnProps = {};
    if (btnDisable) {
        btnProps["disabled"] = true
    }
    function commanCallback() {
        toggleCreateDrawer(false);
        toggleEditDrawer(false);
        dispatch(fetchDeliveryOptions());
    }
    function createCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.more_create_succ, severity: 'success' });
        } else {
            setSnackbar({ message: Context.langData.more_create_failed, severity: 'error' });
        }
        commanCallback();
    }
    function editCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.more_edit_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.more_edit_failed, severity: 'error' });
        }
        commanCallback();
    }
    function deleteCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.more_delete_succ, severity: 'success' });
        } else {
            setSnackbar({ message: Context.langData.more_delete_failed, severity: 'error' });
        }
        commanCallback();
    }
    const handleClose = (val) => {
        if (val) {
            dispatch(deleteDeliveryOptions(JSON.stringify(selectedId), deleteCallback));
        }
        setShowDialog(false)
    }
    function deletePrinter(value) {
        if (selectedId && selectedId.length > 0) {
            setShowDialog(value)
        } else {

        }
    }
    const onPageinationChange = () => {
        console.log("on page change")
    }
    const sortingOnClick = (value, sortValue, index) => {
        var columnData = [...columns]
        columnData[index] = { ...columnData[index], sort: !sortValue }
        setColumns(columnData)
        var sortComments = _.orderBy(deliveryOptions, [value], [sortValue ? 'desc' : 'asc']);
        setDeliveryOptions(sortComments)
    }
    return (
        <div data-qaid="morelist_div" style={{ marginTop: '2%' }}>
            <>
                {
                    deliveryOptions.length > 0 ?
                        <>

                            <Grid data-qaid="morelist_action_grid" item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginRight: 20, marginBottom: 10 }} >
                                <Button className={style.buttonClass} data-qaid="morelist_deletebutton" style={{ color: "white", marginRight: 10 }} variant="text" id="cancel" onClick={() => deletePrinter(true)} >
                                    <DeleteIcon data-qaid="morelist_delete_icon" width={16} height={16} style={{ marginRight: 5 }} />
                                    <AvenirBlackH4 data-qaid="morelist_delete_label" label={Context.langData.delete} />
                                </Button>
                                <Button className={style.buttonClass} data-qaid="morelist_create_grid" style={{ backgroundColor: "#FFB600", color: "white" }} variant="contained" id="cancel" onClick={() => toggleCreateDrawer(true)}  >
                                    <CirclePluse data-qaid="morelist_createicon" />
                                    <AvenirBlackH4 data-qaid="morelist_create_Label" label={Context.langData.create_deliveryOption} style={{ paddingLeft: 5 }} />
                                </Button>
                            </Grid>
                            <DataTable data-qaid="morelist_datatable" columns={columns} rows={deliveryOptions} refresh={openCreate} onCheckedIds={onCheckedIds} onClickAction={onClickAction} onPageChange={onPageinationChange} sortingOnClick={sortingOnClick} />
                        </>
                        :
                        <NoData page="delivery option" callback={() => toggleCreateDrawer(true)} />
                }
            </>
            {
                openCreate &&
                <CreateMore
                    data-qaid="morelist_createmore"
                    open={openCreate}
                    onClose={() => toggleCreateDrawer(false)}
                    more={Meta}
                    callback={createCallback}
                />
            }
            {
                openEdit &&
                <EditMoreDrawer
                    data-qaid="morelist_editmore"
                    open={openEdit}
                    row={row}
                    more={Meta}
                    onClose={() => toggleEditDrawer(false)}
                    callback={editCallback}
                />
            }
            {
                snackbar &&
                <SnackBar
                    data-qaid="categories_snackbar"
                    open={true}
                    setOpen={() => setSnackbar(null)}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            }
            <AlertDialog
                data-qaid="morelist_alertdialog"
                showDialog={showDialog}
                body={bodyMessage}
                heading={heading}
                IsError={false}
                handleClose={handleClose} />
        </div>
    );
};

export default MoreList;
