import * as React from "react";

const SvgComponent = ({ width = 24, height = 24, color = "#004C60", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_19534_4361)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.002 7.50098C12.4162 7.50098 12.752 7.83676 12.752 8.25098V23.251C12.752 23.6652 12.4162 24.001 12.002 24.001C11.5877 24.001 11.252 23.6652 11.252 23.251V8.25098C11.252 7.83676 11.5877 7.50098 12.002 7.50098Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4716 7.72065C11.7645 7.42775 12.2394 7.42775 12.5323 7.72065L17.0323 12.2206C17.3252 12.5135 17.3252 12.9884 17.0323 13.2813C16.7394 13.5742 16.2645 13.5742 15.9716 13.2813L12.002 9.31164L8.03228 13.2813C7.73939 13.5742 7.26452 13.5742 6.97162 13.2813C6.67873 12.9884 6.67873 12.5135 6.97162 12.2206L11.4716 7.72065Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 1.50098C5.40326 1.50098 4.83097 1.73803 4.40901 2.15999C3.98705 2.58194 3.75 3.15424 3.75 3.75098V16.5C3.75 17.0967 3.98705 17.669 4.40901 18.091C4.83097 18.5129 5.40326 18.75 6 18.75H8.252C8.66621 18.75 9.002 19.0858 9.002 19.5C9.002 19.9142 8.66621 20.25 8.252 20.25H6C5.00544 20.25 4.05161 19.8549 3.34835 19.1516C2.64509 18.4484 2.25 17.4945 2.25 16.5V3.75098C2.25 2.75641 2.64509 1.80259 3.34835 1.09933C4.05161 0.396065 5.00544 0.000976562 6 0.000976562H18C18.9946 0.000976562 19.9484 0.396065 20.6517 1.09933C21.3549 1.80259 21.75 2.75641 21.75 3.75098V16.5C21.75 17.4945 21.3549 18.4484 20.6517 19.1516C19.9484 19.8549 18.9946 20.25 18 20.25H15.75C15.3358 20.25 15 19.9142 15 19.5C15 19.0858 15.3358 18.75 15.75 18.75H18C18.5967 18.75 19.169 18.5129 19.591 18.091C20.0129 17.669 20.25 17.0967 20.25 16.5V3.75098C20.25 3.15424 20.0129 2.58194 19.591 2.15999C19.169 1.73803 18.5967 1.50098 18 1.50098H6Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_19534_4361">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

    // <svg
    //   width={24}
    //   height={24}
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <g
    //     clipPath="url(#prefix__clip0)"
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     fill="#004C60"
    //   >
    //     <path d="M12.002 7.501a.75.75 0 01.75.75v15a.75.75 0 01-1.5 0v-15a.75.75 0 01.75-.75z" />
    //     <path d="M11.472 7.72a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.061l-3.97-3.97-3.97 3.97a.75.75 0 11-1.06-1.06l4.5-4.5z" />
    //     <path d="M6 1.501a2.25 2.25 0 00-2.25 2.25V16.5A2.25 2.25 0 006 18.75h2.252a.75.75 0 010 1.5H6a3.75 3.75 0 01-3.75-3.75V3.751A3.75 3.75 0 016 .001h12a3.75 3.75 0 013.75 3.75V16.5A3.75 3.75 0 0118 20.25h-2.25a.75.75 0 010-1.5H18a2.25 2.25 0 002.25-2.25V3.751A2.25 2.25 0 0018 1.501H6z" />
    //   </g>
    //   <defs>
    //     <clipPath id="prefix__clip0">
    //       <path fill="#fff" d="M0 0h24v24H0z" />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
};

const UploadIcon = React.memo(SvgComponent);
export default UploadIcon;
