/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, useContext } from 'react';
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import { updateKitchenStocks } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import RightDrawer from '../../../components/RightDrawer';
import MyContext from '../../../Provider/MyContext';
import InventoryIcon from '../../../components/SVG/InventoryIcon';
import CircleClose from '../../../components/SVG/CircleClose';
import { AvenirBlackH4, H4, H6, AvenirBlackH3 } from '../../../utils/text';
import SnackBar from '../../../components/SnackBar';
import SingleSelect from '../../../components/SingleSelect';
import SingleDate from '../../../components/SingleDate';
import styles from '../../../styles/index';
import { parseToFloat } from '../../../utils/util';
const useStyles = makeStyles({
  TextInput: {
    fontSize: '14px',
    color: '#051D33',
    fontFamily: 'AvenirLT',
    fontWeight: 400,
  },
});
const UpdateKitchens = ({ open, onClose, callback, kitchen_id }) => {
  const style = styles();
  const classes = useStyles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState(null);
  const kitchenStocks = useSelector((state) => state.inventory.kitchenStocks);
  console.log({ kitchenStocks });
  const groupsData = useSelector((state) => state.inventory.groupsData);
  const [data, setData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [date, setDate] = useState(moment().format('MM/DD/YYYY'));
  const [selectedCatId, setSelctedCatId] = useState(0);
  const [catGroupData, setCatGroupData] = useState([]);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (groupsData) {
      const list = [
        { name: Context.langData.add_all_stock, id: 0 },
        ...groupsData,
      ];
      setCatGroupData(list);
    }
  }, [groupsData]);
  useEffect(() => {
    setDate(getDate());
  }, []);
  useEffect(() => {
    if (kitchenStocks) {
      setAllKitchenStocks();
    }
  }, [kitchenStocks]);

  const getDate = (dateStr = null) => {
    const date = dateStr ? dateStr : new Date();
    const MM = date.getMonth() + 1;
    const da = date.getDate();
    const year = date.getFullYear();
    return `${MM}-${da}-${year}`;
  };
  const setAllKitchenStocks = () => {
    if (kitchenStocks) {
      let kitchenStocksReMappedKeys = kitchenStocks.stocks;
      kitchenStocksReMappedKeys = kitchenStocksReMappedKeys?.map(
        (item, index) => ({
          stock_name: item.name,
          stock_id: item.id,
          consumed: 0,
          disposed: 0,
          units: item.unitTypeCode,
          amount: item.currentBalance,
          left_over: item.currentBalance,
        })
      );
      setData(kitchenStocksReMappedKeys);
      setInitialData(kitchenStocksReMappedKeys);
    }
  };
  const updateQtyValue = (index, key, value) => {
    setIsError(false);
    let v = !value ? 0 : parseFloat(value);
    var dataList = [...data];
    dataList = [
      ...dataList.slice(0, index),
      {
        ...dataList[index],
        [key]: v,
      },
      ...dataList.slice(index + 1),
    ];
    let consumedVal =
      dataList[index].left_over -
      (dataList[index].amount - dataList[index].disposed);
    dataList[index] = { ...dataList[index], consumed: consumedVal.toFixed(2) };
    let id = `${key}_${index}`;
    document.getElementById(id).value = v;
    setData(dataList);
  };
  const selectCatDropDown = (value) => {
    setIsError(false);
    if (parseInt(value) === 0) {
      setAllKitchenStocks();
      setSelctedCatId(value);
    } else {
      let itemArray = [];
      if (groupsData && groupsData.length > 0) {
        var catItem = groupsData.filter((e) => e.id == value);
        if (
          catItem.length > 0 &&
          catItem[0].items &&
          catItem[0].items.length > 0
        ) {
          catItem[0].items.forEach((item) => {
            const StockItem = kitchenStocks.stocks.filter(
              (stocke) => stocke.id == item.id
            );
            if (StockItem.length > 0) {
              const obj = {
                stock_name: StockItem[0].name,
                amount: StockItem[0].currentBalance,
                units: StockItem[0].unitTypeCode,
                stock_id: StockItem[0].id,
                consumed: 0,
                disposed: 0,
                left_over: StockItem[0].currentBalance,
              };
              itemArray.push(obj);
            }
          });
        }
      }
      setData(itemArray);
      setSelctedCatId(value);
    }
  };

  const handleSave = () => {
    let saveData = data.filter((obj1, index) => {
      const obj2 = initialData[index];
      return (
        obj2.stock_id === obj1.stock_id &&
        obj2.units === obj1.units &&
        obj2.stock_name === obj1.stock_name &&
        (obj2.consumed !== obj1.consumed ||
          obj2.disposed !== obj1.disposed ||
          obj2.amount !== obj1.amount ||
          obj2.left_over !== obj1.left_over)
      );
    });
    saveData = saveData?.map((item) => {
      return {
        id: item.stock_id,
        previousQuantity: item.amount,
        newQuantity: item.left_over,
        disposedQuantity: item.disposed,
      };
    });

    if (saveData) {
      dispatch(
        updateKitchenStocks(
          {
            data: saveData,
          },
          kitchen_id,
          callback
        )
      );
    } else {
      setSnackbar({
        message: Context.langData.update_kitchen_error,
        severity: 'error',
      });
    }
  };
  return (
    <RightDrawer open={open} padding={1}>
      <>
        <Grid
          data-qaid="viewIndentId_body_grid"
          style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
          <Grid
            data-qaid="viewIndentId_child1_grid"
            style={{
              display: 'flex',
              height: '85%',
              flexDirection: 'column',
              overflowX: 'auto',
            }}>
            <Grid
              data-qaid="viewIndentId_close_grid"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer',
              }}>
              <CircleClose
                data-qaid="viewIndentId_closeicon"
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid="viewIndentId_settings_grid"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <InventoryIcon
                data-qaid="viewIndentId_setting_icon"
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color="#707272"
              />
              <H4
                data-qaid="viewIndentId_settings"
                label={Context.langData.inventory}
                style={{ color: '#707272' }}
              />
            </Grid>
            <H6
              data-qaid="viewIndentId_title"
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.update_kitchen}
            />
            <AvenirBlackH3
              data-qaid="transaction_title"
              label={Context.langData.transaction_details}
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            />

            <Grid
              style={{
                borderBottom: '1px solid #EEEEEE',
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
              }}></Grid>
            <Grid
              data-qaid="viewIndentId_main_grid"
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Grid
                data-qaid="viewReturnStock_sectionTextLabelView"
                item
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 20,
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Grid
                  style={{
                    display: 'flex',
                    width: '50%',
                    flexDirection: 'column',
                  }}>
                  <AvenirBlackH4
                    data-qaid="viewReturnStock_selectCatDropDownLabel"
                    label={Context.langData.select_category}
                  />
                  <Grid item style={{ marginTop: 5 }}>
                    <SingleSelect
                      minWidth={'100%'}
                      list={catGroupData}
                      valueKey="id"
                      displayKey="name"
                      value={selectedCatId}
                      handleChange={(e) => selectCatDropDown(e.target.value)}
                      border={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                data-qaid="viewIndentId_table_body"
                item
                style={{
                  marginTop: '20px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Grid
                  data-qaid="viewIndentId_subContainer3"
                  item
                  style={{
                    height: '40px',
                    width: '100%',
                    backgroundColor: '#F3FAFD',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Grid
                    data-qaid="viewIndentId_dateGrid"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      width: '20%',
                    }}>
                    <AvenirBlackH4
                      data-qaid="viewIndentId_itemHeading"
                      label={Context.langData.item}
                      style={{ marginLeft: 20 }}
                    />
                  </Grid>
                  <Grid
                    data-qaid="viewIndentId_dateGrid"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      width: '20%',
                    }}>
                    <AvenirBlackH4
                      data-qaid="viewIndentId_initial_balanceHeading"
                      label={Context.langData.initial_balance}
                    />
                  </Grid>
                  <Grid
                    data-qaid="viewIndentId_dateGrid"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      width: '20%',
                    }}>
                    <AvenirBlackH4
                      data-qaid="viewIndentId_current_balanceHeading"
                      label={Context.langData.current_balance}
                    />
                  </Grid>
                  <Grid
                    data-qaid="viewIndentId_dateGrid"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      width: '20%',
                    }}>
                    <AvenirBlackH4
                      data-qaid="viewIndentId_disposedHeading"
                      label={Context.langData.disposed}
                    />
                  </Grid>
                  <Grid
                    data-qaid="viewIndentId_dateGrid"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      width: '20%',
                    }}>
                    <AvenirBlackH4
                      data-qaid="viewIndentId_consumptionHeading"
                      label={Context.langData.adjusted}
                    />
                  </Grid>
                </Grid>
                {data?.length > 0
                  ? data.map((itemObj, index) => {
                      return (
                        <Item
                          key={itemObj.stock_name}
                          itemObj={itemObj}
                          updateQtyValue={updateQtyValue}
                          index={index}
                        />
                      );
                    })
                  : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid="viewIndentId_cancel_main_Grid"
            style={{
              display: 'flex',
              height: '15%',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}>
            <Grid
              data-qaid="viewIndentId_cancelGrid"
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: '#FAFAFA',
                width: '100%',
                paddingTop: '25px',
                alignItems: 'center',
                paddingBottom: '25px',
                border: '1px solid #C3C3C3',
              }}>
              {isError ? (
                <Grid>
                  <H4
                    label={Context.langData.addons_error}
                    style={{ color: '#FF0000' }}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Button
                className={style.buttonClass}
                variant="contained"
                data-qaid="viewIndentId_cancelButton"
                data-qaid-1="taxeslist_creategrid"
                style={{
                  backgroundColor: '#FFF',
                  marginRight: 10,
                  width: 103,
                  height: 40,
                  border: '2px solid #051D33',
                }}
                id="cancel"
                onClick={() => onClose()}>
                <AvenirBlackH4
                  data-qaid="viewIndentId_cancelLabel"
                  data-qaid-1="taxeslist_createlabel"
                  label={Context.langData.cancel}
                  style={{ color: '#004C60' }}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid="viewIndentId_repeatIndentButton"
                size="large"
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: '#FFB600',
                  cursor: 'pointer',
                  marginRight: 56,
                  width: 90,
                  height: 40,
                }}
                onClick={() => {
                  handleSave();
                }}>
                <AvenirBlackH4
                  data-qaid="createGroup_cancelLabel"
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {snackbar && (
          <SnackBar
            data-qaid="shifts_snackbar"
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </>
    </RightDrawer>
  );
};
const Item = ({ updateQtyValue, itemObj, index }) => {
  const classes = useStyles();
  return (
    <Grid
      data-qaid="raiseIndent_stockNameContainer"
      item
      style={{
        marginTop: '10px',
        width: '100%',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#FAFAFA',
      }}>
      <Grid
        data-qaid="viewIndentId_subContainer3"
        item
        style={{
          height: 45,
          width: '100%',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Grid
          data-qaid="viewIndentId_dateGrid"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '20%',
          }}>
          <H4
            data-qaid="viewIndentId_itemHeading"
            label={itemObj.stock_name}
            style={{ marginLeft: 20 }}
          />
        </Grid>
        <Grid
          data-qaid="viewIndentId_dateGrid"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '20%',
          }}>
          <H4
            data-qaid="viewIndentId_initial_balanceHeading"
            label={`${itemObj.amount} ${itemObj.units}`}
          />
        </Grid>
        <Grid
          data-qaid="viewIndentId_dateGrid"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '15%',
            marginRight: '1rem',
          }}>
          <TextField
            data-qaid="custompagination_textinputcomponet"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.TextInput,
              },
            }}
            type="number"
            style={{
              borderRadius: 4,
              border: '1px solid #707272',
              paddingLeft: 5,
            }}
            id={'left_over_' + index}
            value={itemObj.left_over}
            defaultValue={itemObj.left_over}
            onChange={(e) => {
              updateQtyValue(index, 'left_over', e.target.value);
            }}
          />
        </Grid>
        <Grid
          data-qaid="viewIndentId_dateGrid"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '15%',
          }}>
          <TextField
            data-qaid="custompagination_textinputcomponet"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.TextInput,
              },
            }}
            type="number"
            style={{
              border: '1px solid #707272',
              borderRadius: 4,
              paddingLeft: 5,
            }}
            id={'disposed_' + index}
            value={itemObj.disposed}
            onChange={(e) => {
              updateQtyValue(index, 'disposed', e.target.value);
            }}
          />
        </Grid>
        <Grid
          data-qaid="viewIndentId_dateGrid"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '20%',
            flexDirection: 'column',
          }}>
          <H4
            data-qaid="viewIndentId_consumptionHeading"
            label={`${itemObj.consumed > 0 ? '+' : ''}${
              itemObj.consumed || 0
            } ${itemObj.units}`}
            style={{
              marginLeft: 5,
              color:
                itemObj.consumed < 0
                  ? '#FF0000'
                  : itemObj.consumed > 0
                  ? '#008000'
                  : '#051D33',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default UpdateKitchens;
