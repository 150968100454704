import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { AvenirBlackH3, DotText, AvenirBlackH4 } from '../../../../utils/text';
import CustomPagination from '../../../../components/CustomPagination';
import RightArrow from '../../../../components/SVG/RightArrow';
import DownArrow from '../../../../components/SVG/DownArrow';
import MyContext from "../../../../Provider/MyContext";
import ConfirmToDisconnect from './ConfirmToDisconnect'
import { disconnectTerminal } from '../../../../redux/actions';

export default function TerminalDataTable(props) {
  const [terminalData, setTerminalData] = useState(props.rows);
  const [showConfirm, toggleShowConfirm] = useState(false)
  const [disconnectTerminalId, setDisconnectTerminalId] = useState(null)
  const Context = useContext(MyContext);
  const dispatch = useDispatch();

  useEffect(() => {
    let formattedRows = [];

    props.rows.forEach((row) => {
      // Format data for display
      formattedRows.push(formatTerminalRowData(row));
    });

    setTerminalData(formattedRows);
  }, [props.rows]);

  function formatTerminalRowData(row) {
    const formattedRow = {
      device_id: row.device_id,
      status: row.is_active,
      moneris_terminal_id: row.moneris_terminal_id,
      name: row.name,
      paired_device_id: row.paired_device_id,
      paired_device_name:
        row.paired_device_id === null ? 'Not paired' : row.paired_device_name,
      is_new: row.is_new,
    };

    return formattedRow;
  }

  function onClickDisconnect(event, row) {
    toggleShowConfirm(true);
    setDisconnectTerminalId(row.device_id);
    event.stopPropagation();
  }

  const onConfirmDisconnect = (status) => {
    console.log("status: " + status);
    console.log("disconnectTerminalId: " + disconnectTerminalId);
    if (status) {
      // dispatch disconnect api call
      dispatch(disconnectTerminal({ device_id: disconnectTerminalId }, props.disconnectCallback));
      toggleShowConfirm(false);
    } else {
      toggleShowConfirm(false);
      setDisconnectTerminalId(null);
    }
  }

  function onClickView(row) {
    props.onClickView(row);
  }

  function onSortClick(value, sort, index) {
    if (props.sortingOnClick) {
      props.sortingOnClick(value, sort, index);
    }
  }

  return (
    <>
      <ConfirmToDisconnect open={showConfirm} onClose={onConfirmDisconnect} />
      <Grid data-qaid="terminaldatatable_main_grid">
        <Grid style={{ width: '100%' }}>
          <Grid
            data-qaid="terminaldatatable_header_grid"
            style={{
              flexDirextion: 'row',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginTop: 50,
              width: '100%',
              backgroundColor: '#F3FAFD',
              height: 56,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {props.columns.map((column, index3) => (
              <Grid
                data-qaid="terminaldatatable_header_item_grid"
                key={index3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0 10px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  width: '42%',
                  paddingRight: 10,
                }}
              >
                <AvenirBlackH3
                  data-qaid="terminaldatatable_header_label"
                  label={column.headerName}
                />
                <Grid
                  onClick={() => {
                    onSortClick(column.field, column.sort, index3);
                  }}
                >
                  {column.field !== 'status' && <DownArrow />}
                </Grid>
              </Grid>
            ))}
            <Grid
              data-qaid="terminaldatatable_header_item_grid2"
              style={{ width: '16%' }}
            ></Grid>
          </Grid>
        </Grid>
        {terminalData.length > 0
          ? terminalData.map((row, index) => (
            <Grid
              data-qaid="terminaldatatable_row_grid"
              style={{
                flexDirextion: 'row',
                display: 'flex',
                backgroundColor: '#FAFAFA',
                alignItems: 'center',
                marginTop: 10,
                cursor: 'pointer',
                width: '100%',
                height: 56,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={() => {
                onClickView(row);
              }}
            >
              {props.columns.filter((colItem) => colItem.field !== 'status').map((colItem, index3) => (
                <Grid
                  data-qaid="terminaldatatable_roe_item_grid"
                  key={`${index}_${index3}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '42%',
                  }}
                >
                  <DotText
                    data-qaid="terminaldatatable_row_value_label"
                    label={String(row[colItem['field']])}
                    style={
                      colItem.field === 'status' && {
                        color:
                          colItem.field === 'Offline' ? '#1C7D26' : '#051D33',
                      }
                    }
                  />
                  {/* If thte column is terminal ID and it is new, add new badge */}
                  {row.is_new && colItem.field === 'moneris_terminal_id' && (
                    <div
                      style={{
                        backgroundColor: '#F3FAFD',
                        border: '2px solid #92D2E7',
                        borderRadius: 3,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        marginLeft: 10,
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <DotText
                        data-qaid="terminaldatatable_row_value_label2"
                        label="New"
                      />
                    </div>
                  )}
                </Grid>
              ))}

              <Grid
                data-qaid="terminaldatatable_roe_item_grid"
                key={`${index}_status`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '42%',
                }}
              >
                {row['status'] && (
                  <Button
                    data-qaid="disconnect_button"
                    variant="outlined"
                    color="primary"
                    onClick={(e) => { onClickDisconnect(e, row) }}
                  >
                    <DotText
                      data-qaid="terminaldatatable_row_value_label2"
                      label="Disconnect"
                    />
                  </Button>
                )
                }
              </Grid>

              <Grid
                data-qaid="terminaldatatable_right_grid"
                key={`${index}_arrow`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '16%',
                }}
                onClick={() => {
                  onClickView(row);
                }}
              >
                <RightArrow data-qaid="terminaldatatable_rigtarrow"></RightArrow>
              </Grid>
            </Grid>
          ))
          : props.noRowData && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <AvenirBlackH4
                data-qaid="offersList_nodata"
                label={props.noData}
              />{' '}
            </div>
          )}
        <Grid
          data-qaid="terminaldatatable_footer_grid"
          container
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginTop: 25,
            marginBottom: 25,
            width: '100%',
          }}
        >
          <Grid data-qaid="terminaldatatable_custompagination_grid">
            <CustomPagination
              data-qaid="terminaldatatable_custompagination"
              total={1}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
