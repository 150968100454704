// TODO: check the usage of the component and it's respective packages and imports
import React, { useEffect, useState } from 'react';
import { H4, AvenirBlackH4 } from '../../../utils/text'
import { useNavigate, useParams } from 'react-router-dom';
import packageInfo from '../../../../package.json'
import { Grid } from '@material-ui/core';
import CircleClose from '../../../components/SVG/CircleClose'
import Menu from './menu'
import RestInfo from './RestInfo'
import OrderNow from './orderNow'
import RestLogo from './restLogo';
import DeviceEmulator from 'react-device-emulator';
import 'react-device-emulator/lib/styles/style.css';
import { useDispatch } from 'react-redux';
import { getTheme, getWebpages } from '../../../redux/actions'

const Preview = ({ open, onClose }) => {
  let { flag } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  console.log("FLAG", flag)
  const [size, setSizes] = useState([window.innerWidth, window.innerHeight])
  const [mode, setMode] = useState(1)
  const selectStyle = { marginLeft: 20, border: '2px solid white', width: 40, height: 40, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' };
  const nonSelectStyle = { marginLeft: 20, backgroundColor: '#808285', width: 40, height: 40, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }
  useEffect(() => {
    dispatch(getTheme())
    // dispatch(getImage())
    dispatch(getWebpages())
  }, [flag])
  return (


    < Grid
      container
      style={{
        width: '100%',
        height: window.innerHeight,
        padding: 0,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
      }
      }>
      {
        flag != 2 &&

        <Grid item xs={12} style={{ backgroundColor: '#54575A', height: 50 }}>

          <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
            <Grid item xs={12} md={12}>
              <Grid container style={{ display: 'flex', height: 50, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <H4 label='Preview mode' style={{ color: '#FFF' }} />

              </Grid>
            </Grid>
            <Grid item style={{ position: 'absolute', top: 15, right: 10 }} onClick={() => navigate('/home/builder/pages')}>
              <Grid container justify="flex-end" style={{ paddingRight: 20 }} alignItems="center">
                <CircleClose style={{ marginRight: 5 }} color="white" />
                <AvenirBlackH4 label='CLOSE' style={{ color: '#FFF' }} />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      }
      {
        mode == 2 ?

          <DeviceEmulator type="mobile" url={`${packageInfo.homepage}/preview/2`}>

          </DeviceEmulator>
          :
          <Grid container style={{ width: size[0], height: size[1], overflow: 'auto', }}>
            <Grid container style={{ justifyContent: 'center' }}>
              <RestInfo />
            </Grid>
            <Grid container style={{ justifyContent: 'center', backgroundColor: 'white' }}>
              <OrderNow isPreview={true} />
            </Grid>
            <Grid container>
              <RestLogo isPreview={true} />
            </Grid>
            <Grid container>
              <Menu isPreview={true} />
            </Grid>
          </Grid>
      }
    </Grid >


  )
}


export default Preview