import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
    Grid,
    Button,
    makeStyles
} from '@material-ui/core';
import VerticalTabs from '../../../components/verticalTabs';
import { useSelector, useDispatch } from 'react-redux';
import Indents from './indents/indents';
import Issues from './issues/issues';
import StockBalance from './stocks/stockBalance';
import Groups from './groups/groups';
import MyContext from '../../../Provider/MyContext';
import NoPermissionPage from '../../../components/NoPermission';
const useStyles = makeStyles({
    btnApply: {
        backgroundColor: '#f0585f',
        color: 'white',
        marginLeft: '10px'
    },
    btnClear: {
        backgroundColor: '#dcdcdc',
        color: 'black'
    },
})

const InventoryStore = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
    const Context = useContext(MyContext);
    const userPermissions = useSelector(state => state.loginReducer.userPermissions);
    // useEffect (() => {
    //     dispatch(fetchAllRecipeItems());
    //     dispatch(fetchAllRecipeAddons());
    // }, []);

    const Tabs = [
        { name: Context.langData.purchase_order, id: 0 },
        { name: Context.langData.issues, id: 1 },
        { name: Context.langData.stock_balance, id: 2 },
        { name: Context.langData.groups, id: 3 }
    ]

    const callback = (value) => {
        switch (value) {
            case 0:
                return setSelectIndexTabChange(0);
            case 1:
                return setSelectIndexTabChange(1);
            case 2:
                return setSelectIndexTabChange(2);
            case 3:
                return setSelectIndexTabChange(3);
            default:
                break;
        }
    }
    const isStockBalanceHasPermission = () => {
        var obj = userPermissions.filter((e) => e.key == 'inventory_balance');
        if (obj.length > 0) {
            return (
                <StockBalance data-qaid="inventory_stockbalance" />
            )
        } else {
            return (
                <NoPermissionPage data-qaid="inventory_stockbalance_nopermission" />
            )
        }
    }
    return (
        <Grid data-qaid="stocks_verticalTabs" container style={{ backgroundColor: 'white' }}>
            <Grid container style={{ margin: '20px', }}>
                <Grid item xs={12} lg={2} style={{ backgroundColor: '#FAFAFA' }}>
                    {/* <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', height: '50px', paddingTop: '10px', border: '1px solid #f4f4f5', backgroundColor: 'white' }}>{Context.langData.store_functions}</div> */}
                    <VerticalTabs tabData={Tabs} indexCallback={callback} />
                </Grid>
                <Grid data-qaid="viewsendStock_tabChange" item xs={12} lg={10} style={{ paddingLeft: '10px' }}>
                    {
                        selectIndexTabChange == 0 &&
                        <Indents />
                    }
                    {
                        selectIndexTabChange == 1 &&
                        <Issues />
                    }
                    {
                        selectIndexTabChange == 2 &&
                        isStockBalanceHasPermission()
                    }
                    {
                        selectIndexTabChange == 3 &&
                        <Groups />
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InventoryStore;