import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Snackbar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserDetails, saveUserDetails } from '../redux/actions';
import MuiAlert from '@material-ui/lab/Alert';
import { H2, AvenirBlackH4 } from '../utils/text'
import MyContext from '../Provider/MyContext';
import SnackBar from "../components/SnackBar";
export default function MyAccountDialog(props) {
    const dispatch = useDispatch();
    const Context = useContext(MyContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [lastPassword, setLastPassword] = useState('');
    const [snackbar, setSnackbar] = useState(null);
    const handleClose = (value) => {
        if (value) {
            dispatch(saveUserDetails({
                "first_name": firstName,
                "last_name": lastName,
                "old_password": lastPassword,
                "new_password": newPassword
            }, saveCallback))
        } else {
            props.handleClose(false)
        }
    };
    const saveCallback = (value, data) => {
        if (value) {
            setSnackbar({
                message: Context.langData.you_details_message,
                severity: "success",
            });
            setTimeout(() => {
                if (value) {
                    props.handleClose(true);
                }
            }, 1500)
        } else {
            setSnackbar({
                message: data.status,
                severity: "error",
            });
        }
    }
    useEffect(() => {
        if (props.open) {
            dispatch(fetchUserDetails({}, fetchUserCallback))
        }
    }, [props])
    const fetchUserCallback = (response) => {
        if (response) {
            if (response.first_name) {
                setFirstName(response.first_name)
            }
            if (response.last_name) {
                setLastName(response.last_name)
            }
            if (response.new_password) {
                setNewPassword(response.new_password)
            }
            if (response.old_password) {
                setLastPassword(response.old_password)
            }
        }

    }
    return (
        <div data-qaid="myaccount_div">
            <Dialog data-qaid="myaccount_dialog" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle data-qaid="myaccount_dialogtitle" id="form-dialog-title" style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 16, marginTop: 16 }}>
                    <H2 data-qaid="myaccount_title" label={Context.langData.profile} />
                </DialogTitle>
                <DialogContent data-qaid="myaccount_dialogcontent" style={{ marginLeft: 16 }}>
                    <Grid data-qaid="myaccount_body_content" container direction='row' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid data-qaid="myaccount_child_grid_1" container style={{ display: 'flex', flexDirection: 'row', marginRight: 32, marginTop: 32 }}>
                            <Grid data-qaid="myaccount_fname_grid" item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                                <AvenirBlackH4 data-qaid="myaccount_fname_label" label={Context.langData.f_name} />
                                <TextField
                                    data-qaid="myaccount_fname_ip"
                                    autoFocus
                                    variant='outlined'
                                    value={firstName}
                                    id="first_name"
                                    onChange={(e) => { setFirstName(e.target.value) }}
                                />
                            </Grid>
                            <Grid data-qaid="myaccount_lname_grid" item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="myaccount_lname_label" label={Context.langData.l_name} />
                                <TextField
                                    data-qaid="myaccount_lnameip"
                                    variant='outlined'
                                    value={lastName}
                                    id="last_name"
                                    onChange={(e) => { setLastName(e.target.value) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid data-qaid="myaccount_child_grid_2" container style={{ display: 'flex', flexDirection: 'row', marginRight: 32, marginTop: 32 }} >
                            <Grid data-qaid="myaccount_newpassword_grid" item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                                <AvenirBlackH4 data-qaid="myaccount_newpassword_label" label={Context.langData.new_password} />
                                <TextField
                                    data-qaid="myaccount_newpasswordip"
                                    variant='outlined'
                                    defaultvalue=''
                                    id="new_password"
                                    type={'password'}
                                    onChange={(e) => { setNewPassword(e.target.value) }}
                                />
                            </Grid>
                            <Grid data-qaid="myaccount_confirmpassword_grid" item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="myaccount_confirmpassword_label" label={Context.langData.old_password} />
                                <TextField
                                    data-qaid="myaccount_confirmpasswordip"
                                    variant='outlined'
                                    defaultvalue=''
                                    id="last_password"
                                    type={'password'}
                                    onChange={(e) => { setLastPassword(e.target.value) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions data-qaid="myaccount_dialogaction" >
                    <Grid data-qaid="myaccount_actions_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                        <Grid data-qaid="myaccount_actions_child_grid" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                            <Button data-qaid="myaccount_cancel_button" onClick={() => handleClose(false)} variant="contained" style={{ backgroundColor: '#00B6C9', marginRight: 16 }} >
                                <AvenirBlackH4 data-qaid="myaccount_cancel_label" label={Context.langData.cancel} />
                            </Button>
                            <Button
                                data-qaid="myaccount_save_button"
                                variant="contained"
                                style={{ backgroundColor: '#FFB600', marginRight: 32 }}
                                onClick={() => handleClose(true)}>
                                <AvenirBlackH4 data-qaid="myaccount_save_label" label={Context.langData.save} />
                            </Button>
                        </Grid>
                    </Grid>
                    {snackbar &&
                        <SnackBar
                            data-qaid="taxeslist_snackbar"
                            open={true}
                            setOpen={() => setSnackbar(null)}
                            severity={snackbar.severity}
                            message={snackbar.message}
                        />
                    }
                </DialogActions>
            </Dialog>
        </div >
    );
}
function Alert(props) {
    return <MuiAlert data-qaid="myaccount_alert_muialert" elevation={6} variant="filled" {...props} />;
}