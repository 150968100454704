import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Employee from './employees';
import Roles from './roles';
import { Grid } from '@material-ui/core';
import { fetchRoles, fetchEmployees, fetchRolePermissions } from '../../../redux/actions';
import { useLocation } from 'react-router';
import VerticalTabs from '../../../components/verticalTabs';
import NoPermissionPage from '../../../components/NoPermission';
import SnackBar from '../../../components/SnackBar';
import moment from 'moment';

const Employees = ({ tabIndex }) => {
    const { state } = useLocation();
    const userPermissions = useSelector(state => state.loginReducer.userPermissions);
    const selectedRestaurant = useSelector(state => state.loginReducer.selectedRestaurant);
    const Permissions = useSelector((state) => state.profileReducers.permissions);
    const [tab, setTab] = useState(true);
    const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
    const dispatch = useDispatch();
    const limit = useSelector(state => state.profileReducers.limit);
    const roleOffset = useSelector(state => state.profileReducers.roleOffset);
    const [snackbar, setSnackbar] = useState(null);

    const [Tabs, setTabs] = useState([
        { name: 'Employees', id: 0, key: "employees", },
        { name: 'Roles', id: 1, key: "employee_roles", },
    ]);

    useEffect(() => {
        var permissionsIds = selectedRestaurant.permission;
        var rolePermissions = [];
        if (permissionsIds) {
          Object.keys(permissionsIds).forEach(function (key) {
            if (Permissions.permissions.length > 0) {
              var permissionItem = Permissions.permissions.filter((e) => e.permission_id == key);
              if (permissionItem.length > 0) {
                var permissionObj = { ...permissionItem[0], "status": permissionsIds[key] == 1 ? true : false }
                rolePermissions.push(permissionObj)
              }
            }
          });
        }
        if (rolePermissions.length > 0) {
            dispatch({ type: "SET_ROLE_PERMISSIONS", payload: rolePermissions })
          } else {
            console.log("no permissions");
          }   
    }, [])

    useEffect(()=>{
        if(state?.createCustomer && moment().unix() - state.ts < 3){
                if (state?.createCustomer == true) {
                  setSnackbar({ message: state?.message, severity: 'success' });
                }
                else {
                    setSnackbar({ message: 'Employee creation failed', severity: 'error' });
                }            
        }
    },[])
    const isItemHasPermission = useMemo(() => {
        var buttonName = [];
        Tabs.map((childItem) => {
            var obj = userPermissions.filter((e) => e.key == childItem.key);
            if (obj.length > 0) {
                if (obj[0].status) {
                    childObj = { ...childItem, isPermission: true }
                } else {
                    childObj = { ...childItem, isPermission: false }
                }
                buttonName.push(childObj)
            } else {
                var childObj = { ...childItem, isPermission: false }
                buttonName.push(childObj)
            }
        })
        setTabs(buttonName)
        var result = true;
        if (buttonName.length > 0) {
            result = buttonName[selectIndexTabChange].isPermission;
        } else {
            result = false;
        }
        return result;
    }, [userPermissions])

    useEffect(() => {
        dispatch(fetchRolePermissions());
        dispatch(fetchRoles({ limit: limit, offset: roleOffset }, callback));
    }, [])
    const callback = () => {
        // dispatch(fetchEmployees({ limit: limit, offset: roleOffset }));
    }
    useEffect(() => {
        if (tabIndex && (!state || !("emp" in state) || state.emp)) {
            setSelectIndexTabChange(0)
        } else {
            setSelectIndexTabChange(1)
        }
    }, [tabIndex])
    const callbackTabs = (value) => {
        switch (value) {
            case 0:
                return setSelectIndexTabChange(0);
            case 1:
                return setSelectIndexTabChange(1);
            default:
                break;
        }
    }
    const renderSelectedView = () => {
        if (selectIndexTabChange == 0) {
            if (isItemHasPermission) {
                return (
                    <Employee data-qaid="profile_employees"></Employee>
                )
            } else {
                return (
                    <NoPermissionPage data-qaid="profile_customers_nopermissions" />
                )
            }
        } else if (selectIndexTabChange == 1) {
            if (isItemHasPermission) {
                return (
                    <Roles data-qaid="profile_roles" ></Roles>
                )
            } else {
                return (
                    <NoPermissionPage data-qaid="profile_employees_nopermissions" />
                )
            }
        }
    }
    return (

        <Grid container data-qaid="profile_employeeIndex_container" style={{ backgroundColor: 'white' }}>
            <Grid container data-qaid="profile_employeeIndex_subContainer" style={{ margin: '20px', }}>
                <Grid data-qaid="profile_employeeIndex_verticalTabView" item style={{ backgroundColor: '#FAFAFA', width: '12%' }}>
                    <VerticalTabs data-qaid="profile_employeeIndex_verticalTabs" tabData={Tabs} indexCallback={callbackTabs} Tabvalue={selectIndexTabChange} />
                </Grid>
                <Grid data-qaid="profile_employeeIndex_verticalTabs" item style={{ paddingLeft: '10px', width:'88%' }}>
                    {renderSelectedView()}
                </Grid>
                {
                    snackbar &&
                    <SnackBar
                        data-qaid="shifts_snackbar"
                        open={true}
                        setOpen={() => setSnackbar(null)}
                        severity={snackbar.severity}
                        message={snackbar.message}
                    />
                }
            </Grid>
        </Grid>
    )
}

export default Employees