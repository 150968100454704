import {
	Grid,
	createStyles,
	withStyles,
	IconButton,
	Select,
	MenuItem
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { setMonth, getMonth, setYear, getYear } from "date-fns";
import { AvenirBlackH4 } from '../../utils/text'
import moment from 'moment';

const styles = createStyles({
	iconContainer: {
		padding: 5
	},
	icon: {
		padding: 10,
		"&:hover": {
			background: "none"
		}
	},
	underline: {
		borderBottom: "0px solid red !important",
		"&:hover": {
			borderBottom: "0px solid rgba(0,0,0,0)"
		}
	},
	menuPropsStyle: {

    maxHeight: 200
  }
});

const MONTHS = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"June",
	"July",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec"
];

const generateYears = (relativeTo, count) => {
	// const half = Math.floor(count/2 );
	const half = 0;
	const currentYear = parseInt(moment().format("YYYY"))
	return Array(count)
		.fill(0)
		.map((y, i) => currentYear+half-i); // TODO: make part of the state
};

const Header = ({
	date,
	classes,
	setDate,
	nextDisabled,
	prevDisabled,
	onClickNext,
	onClickPrevious,
	onYearChange
}) => {
	const handleMonthChange = (event) => {
		setDate(setMonth(date, parseInt(event.target.value)));
	};

	const handleYearChange = (event) => {
		console.log("YEAR CHANGE",event.target.value)

		console.log(getYear(date),date)
		if(onYearChange){
			onYearChange(event.target.value)
		}else{
			setDate(setYear(date, parseInt(event.target.value)));
		}
	};

	return (
		<Grid container justify="space-between" alignItems="center">
			<Grid item className={classes.iconContainer}>
				<IconButton
					className={classes.icon}
					disabled={prevDisabled}
					onClick={onClickPrevious}>
					<ChevronLeft color={prevDisabled ? "disabled" : "action"} />
				</IconButton>
			</Grid>
			<Grid item>
				<Grid container direction="row" alignItems="center">
					<Grid item>
						{/* <Select
					value={getMonth(date)}
					onChange={handleMonthChange}
					MenuProps={{ disablePortal: true }}>
					{MONTHS.map((month, idx) => (
						<MenuItem key={month} value={idx}>
							{month}
						</MenuItem>
					))}
				</Select> */}
						<AvenirBlackH4 label={MONTHS[getMonth(date)]} />
					</Grid>

					<Grid item style={{ marginTop: 2, marginLeft: 5 }}>
						<Select
							className={classes.underline}
							value={getYear(date)}
							onChange={handleYearChange}
							disableUnderline

							MenuProps={{
								anchorOrigin: {
			            vertical: "bottom",
			            horizontal: "left"
			          },
			          transformOrigin: {
			            vertical: "top",
			            horizontal: "left",

			          },
								getContentAnchorEl: null,
								 disablePortal: true,
								 classes: {
		            paper: classes.menuPropsStyle
		          } }}>
							{generateYears(date, 100).map(year => (
								<MenuItem key={year} value={year}>
									<AvenirBlackH4 label={year} />
								</MenuItem>
							))}
						</Select>

						{/* <Typography>{format(date, "MMMM YYYY")}</Typography> */}
					</Grid>
				</Grid>
			</Grid>
			<Grid item className={classes.iconContainer}>
				<IconButton className={classes.icon} disabled={nextDisabled} onClick={onClickNext}>
					<ChevronRight color={nextDisabled ? "disabled" : "action"} />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(Header);
