import setState from './setState'
const initialState = {

  basicInfo: {},
  accountSettings: {},
  siteSettings: {},
  website: {
    "header": "Your welcome to message goes here",
    "introduction": "Your introduction goes here",
    "button_text": "Button Text",
    "button_color": "#00B6C9"
  },

  info: { contact: [{}], social_media: [{}] },
  theme: { color_pallete: {} },
  siteInfo: {},
  banner: null,
  logo: null,

};

function builder(state = initialState, action) {
  switch (action.type) {


    case 'SET_BASIC_INFO': return { ...state, basicInfo: action.payload }
    case 'SET_SITE_SETTINGS': return { ...state, siteSettings: action.payload }
    case 'SET_WEB_INFO': return { ...state, website: action.payload, banner: action.payload.url, logo: action.payload.logo_url }
    case 'SET_BANNER': return { ...state, banner: action.payload }
    case 'SET_LOGO': return { ...state, logo: action.payload }

    case 'SET_ACCOUNT_SETTINGS': return { ...state, accountSettings: action.payload }
    case "SET_INFO": return { ...state, info: action.payload }
    case "SET_SITE": return { ...state, siteInfo: action.payload, siteSettings: action.payload.pages }
    case 'SET_THEME': return { ...state, theme: action.payload }
    case "CLEAR_BUILDER": return { ...initialState }
    case "CLEAR": return { ...initialState }
    default: return { ...state }
  }
}

export default builder;
