import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
    Grid,
    Button,
    Chip
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
    editGroups
} from '../../../../redux/actions';
import AutoCompliteDropDown from '../../../../components/AutoComplete';
import MyContext from '../../../../Provider/MyContext';
import { AvenirBlackH4, H4, H8, H5 } from '../../../../utils/text';
import InputText from '../../../../components/InputText';
import RightDrawer from '../../../../components/RightDrawer';
import InventoryIcon from '../../../../components/SVG/InventoryIcon';
import CircleClose from '../../../../components/SVG/CircleClose';
import styles from '../../../../styles/index';
import ExclamationIcon from '../../../../components/SVG/ExclamationIcon';
import { validateText } from '../../../../utils/util';
const EditGroup = ({ open, row, onClose }) => {
    const style = styles();
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [itemsList, setItemsList] = useState([]);
    const [itemName, setItemName] = useState('');
    const [groupNameError, setGroupNameError] = useState('');
    const stocksList = useSelector((state) => state.inventory.stocks);
    const Context = useContext(MyContext);
    const [search, setSearch] = useState('');
    const [itemsError, setItemError] = useState('');
    useEffect(() => {
        if (row) {
            setId(row.id)
            setName(row.name);
            if (row.items) {
                var ids = [];
                row.items.map((item) => {
                    ids.push(item.name)
                })
                setItemsList(ids)
            }
        }
    }, []);
    const createGroupCallBAck = (value, data) => {
        if (value) {
            console.log("value", value, "true")
        } else {
            console.log("value", value, "error", data)
        }
        onClose()
    }

    const handleSave = () => {
        var itemIds = [];
        var ErrorFlagCount = 0;
        if (name) {
            setGroupNameError('')
        } else {
            ErrorFlagCount = ErrorFlagCount + 1;
            setGroupNameError(Context.langData.no_text_error)
        }
        if (itemsList.length > 0) {
            itemsList.map((item) => {
                var obj = stocksList.filter((e) => e.name == item);
                if (obj.length > 0) {
                    itemIds.push({ id: obj[0].id})
                }
            })
        } else {
            ErrorFlagCount = ErrorFlagCount + 1;
            setItemError(Context.langData.choose_atleast_one_item)
        }

        if (ErrorFlagCount == 0) {
            dispatch(editGroups(id, { 'id': id, 'name': name, 'items': itemIds }, createGroupCallBAck))
        }
    }
    const handleDelete = (index) => {
        var list = [...itemsList]
        list.splice(index, 1);
        setItemsList(list)
        console.log('delete', index)
    }
    const selectedStock = (value) => {
        setItemError('');
        var itemListArray = [...itemsList];
        var item = itemListArray.filter((e) => e == value)
        if (item.length > 0) {
        } else {
            itemListArray.push(value);
        }
        setItemsList(itemListArray);
        setItemName(value);
        console.log("select value", value, "itemList", itemListArray)

    }
    const onChangeGroupName = (value) => {
        if (value) {
            setGroupNameError('')
        }
        setName(validateText(value))
    }
    const handleSelectStock = (value) => {
        selectedStock(value)
    }
    const unAddedItemsList = useMemo(() => {
        return stocksList.filter(e => itemsList.indexOf(e.name) == -1)
    }, [itemsList, stocksList])
    return (
        <RightDrawer open={open} padding={1}>
            <>
                <Grid data-qaid="createGroup_body_grid" style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid data-qaid="createGroup_child1_grid" style={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
                        <Grid data-qaid="createGroup_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createGroup_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createGroup_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <InventoryIcon data-qaid="createGroup_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createGroup_settings" label={Context.langData.inventory} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createGroup_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.edit_group} />
                        <Grid data-qaid="createGroup_main_grid" direction="column" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56, width: 500, display: 'flex', flexDirection: 'column' }} >

                            <Grid data-qaid="createGroup_groupNameContainer" item style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <AvenirBlackH4 data-qaid="createGroup_groupNameLabel" label={Context.langData.group_name} style={{ marginBottom: 10 }} />
                                <InputText
                                    id="groupName"
                                    error={groupNameError}
                                    value={name}
                                    maxLength={128}
                                    onChange={(e) => { onChangeGroupName(e.target.value) }}
                                />
                            </Grid>
                            <Grid data-qaid="createGroup_stockItemsGrid" item style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <AvenirBlackH4 data-qaid="createGroup_stockItemsLabel" label={Context.langData.stock_items} style={{ marginBottom: 10 }} />
                                <AutoCompliteDropDown
                                    options={unAddedItemsList}
                                    onChange={handleSelectStock}
                                    labelKey={'name'}
                                    key={itemsList}
                                />
                                {itemsError &&
                                    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                        <ExclamationIcon color="#AB1212" style={{ marginRight: 10, marginTop: 8 }} />
                                        <H5 label={itemsError} style={{ color: '#AB1212', marginTop: 8 }} />
                                    </Grid>

                                }
                            </Grid>
                            <Grid data-qaid="createGroup_itemNameGrid" item style={{ marginTop: '20px' }}>
                                {itemsList ?
                                    itemsList.map((itemName, index) => {
                                        return (
                                            <Chip key={itemName} label={itemName} variant="outlined" onDelete={() => handleDelete(index)} style={{ margin: '5px' }} />
                                        )
                                    }) : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid data-qaid="createGroup_cancel_main_Grid" style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="createGroup_cancelGrid" item style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#FAFAFA', width: '100%', paddingTop: '25px', alignItems: 'center', paddingBottom: '25px', border: '1px solid #C3C3C3', paddingRight: 25 }}>
                            <Button className={style.buttonClass} data-qaid="createGroup_cancelButton" style={{ backgroundColor: "#FFF", marginRight: 10, width: 103, height: 40, border: '2px solid #051D33' }} variant="contained" id="cancel" onClick={() => onClose()}  >
                                <AvenirBlackH4 data-qaid="createGroup_cancelLabel" label={Context.langData.cancel} />
                            </Button>
                            <Button className={style.buttonClass} data-qaid="createGroup_saveButton" style={{ backgroundColor: "#FFB600", marginRight: 10, width: 90, height: 40 }} variant="contained" id="cancel" type='submit' onClick={() => handleSave()}>
                                <AvenirBlackH4 data-qaid="createGroup_saveLabel" label={Context.langData.save} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer >
    )
}

export default EditGroup;