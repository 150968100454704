import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
    DialogActions,
    Typography
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useLocation, useNavigate } from 'react-router-dom';
import { H2, H4, AvenirBlackH4 } from '../utils/text';
import MyContext from '../Provider/MyContext';
import WarningIcon from './SVG/WarningIcon';
import ArrorDownIcon from './SVG/ArrowDownIcon';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ showDialog, handleClose, heading, IsError, body, showExtra = true }) {

    const Context = useContext(MyContext);

    return (
        <div data-qaid="dialog_div" >
            <Dialog
                data-qaid="dialog_dialog"
                open={showDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{width: 400, flexDirection:'column', display: 'flex', justifyContent:'space-between', marginTop: 10, marginBottom: 20}}>
                <DialogTitle data-qaid="dialog_dialogtitle" id="alert-dialog-slide-title" >
                    <H2 data-qaid="dialog_heading_label" label={heading} />
                </DialogTitle>
                <DialogContent data-qaid="dialog_dialogcontent" >
                    <DialogContentText data-qaid="dialog_dialogcontenttext" id="alert-dialog-slide-description" style={{flexDirection: 'row', display: 'flex'}}>
                       <div style={{flexDirection: 'row', display: 'flex'}}>
                        {!showExtra &&
                            <div style={{height: 16, width: 16}}>
                                <WarningIcon height={16} width={16}/> 
                            </div>
                        }
                        <div style={{paddingLeft: !showExtra ? 10 : 0, wordBreak: 'break-word'}}>
                        <H4 data-qaid="dialog_body_label" label={body} />
                        {
                            showExtra &&
                            <H4 data-qaid="dialog_body_label" label={Context.langData.this_cannot_be_undone} />
                        }
                        </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                {IsError ?
                    <DialogActions data-qaid="dialog_dialogactions" style={{paddingLeft: '5%', paddingRight:'5%'}}>
                        <Button data-qaid="dialog_ok_button" variant="contained" onClick={() => handleClose(false)} style={{ backgroundColor: '#FFB600', width: '100%', height: 44 }}>
                            <AvenirBlackH4 data-qaid="dialog_ok_label" label={Context.langData.ok} />
                        </Button>
                    </DialogActions> :
                    <DialogActions data-qaid="dialog_dialogeaction_noerror" style={{ paddingLeft: '5%', paddingRight:'5%', justifyContent:'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                        <Button data-qaid="dialog_cancel_button" variant="contained" onClick={() => handleClose(false)} style={{ backgroundColor: '#FFF', width: '48%', height: 44, border: '2px solid #051D33' }}>
                            <AvenirBlackH4 data-qaid="dialog_cancel_label" label={Context.langData.cancel} />
                        </Button>
                        <Button data-qaid="dialog_delete_button" variant="contained" onClick={() => handleClose(true)} style={{ backgroundColor: '#FFB600', width: '48%', height: 44 }}>
                            <AvenirBlackH4 data-qaid="dialog_delete_label" label={Context.langData.confirm} />
                        </Button>
                    </DialogActions>
                }
                </div>
            </Dialog>
        </div>
    );
}
