import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEodSettings,
  fetchEodReports,
  postEodReportSettings,
  editEodSettings,
} from "../../../../redux/actions";
import { FormControlLabel, FormGroup, Box } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import {
  AvenirBlackH2,
  AvenirBlackLightH3,
  AvenirBlackH5,
} from "../../../../utils/text";
import MyContext from "../../../../Provider/MyContext";
import OnOffSwitch from "../../../../components/OnOffSwitch";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import SnackBar from "../../../../components/SnackBar";
import Timer from "../../../websiteBuilder/Timer";
import { handleHoursMinutes as handleTime } from "../../../../utils/util";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";
import ImportantReminder from "../../../../components/ImportantReminder";
import InputText from "../../../../components/InputText";
import moment from "moment-timezone";

function Reports() {
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const eodSettingsObj = useSelector(
    (state) => state.settingsReducer.eodSettings
  );
  const [eodSettings, setEodSettings] = useState({});
  const [eodReports, setEodReports] = useState([]);
  const [eodReportsInitialValues, setEodReportsInitialValues] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const eodReportsObj = useSelector(
    (state) => state.settingsReducer.eodReports
  );

  useEffect(() => {
    if (eodSettingsObj) {
      const updatedValues = {
        ...eodSettingsObj,
        timeZone: getTimezoneWithGMT(eodSettingsObj.timeZone),
      };
      setEodSettings(updatedValues);
    }
  }, [eodSettingsObj]);

  useEffect(() => {
    if (eodReportsObj) {
      setEodReports(eodReportsObj);
      const eodReportsValues = eodReportsObj[0]?.reportSections?.reduce(
        (result, obj) => {
          if (obj.isOptional) {
            result[obj.name] = obj.status === "A" ? true : false;
          }
          return result;
        },
        {}
      );
      setEodReportsInitialValues(eodReportsValues);
    }
  }, [eodReportsObj]);

  useEffect(() => {
    dispatch(fetchEodSettings());
    dispatch(fetchEodReports());
  }, []);

  const handleEodSettings = (params) => {
    const updateData = { ...eodSettings, ...params };
    setEodSettings(updateData);
    dispatch(editEodSettings(updateData, handleToast));
  };

  const handleEodReports = (name) => {
    const updatedData = eodReports.map((itemObj) => ({
      ...itemObj,
      reportSections: itemObj.reportSections.map((section) => {
        if (section.name === name) {
          return {
            ...section,
            status: section.status === "A" ? "C" : "A",
          };
        }
        return section;
      }),
    }));
    setEodReports(updatedData);
    dispatch(postEodReportSettings({ data: updatedData }, handleToast));
  };

  const handleToast = (value) => {
    if (value) {
      setSnackbar({
        message: Context.langData.eod_settings_save_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.eod_settings_save_failed,
        severity: "error",
      });
    }
  };

  const getTimezoneWithGMT = (timezone) => {
    if (timezone) {
      const now = moment().tz(timezone);
      const offset = now.utcOffset();

      const offsetHours = Math.floor(Math.abs(offset) / 60);
      const offsetMinutes = Math.abs(offset) % 60;

      const offsetSign = offset >= 0 ? "+" : "-";
      const formattedOffset = `GMT ${offsetSign}${String(offsetHours).padStart(
        2,
        "0"
      )}:${String(offsetMinutes).padStart(2, "0")}`;
      return `${timezone} (${formattedOffset})`;
    }
  };

  return (
    <Box maxWidth="sm" p={3} textAlign="left">
      <Box marginBottom={3}>
        <AvenirBlackH2 label={Context.langData.service_times} />

        <Box
          display="flex"
          justifyContent="flex-start"
          paddingTop={2}
          alignItems="center"
          width="80%">
          <AvenirBlackLightH3 label={Context.langData.service_times_text} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          paddingBottom={2}
          paddingTop={2}
          alignItems="flex-start">
          <AvenirBlackLightH3
            style={{ marginRight: "8px" }}
            label={Context.langData.Opening_time}
          />
          <Timer
            iconStyle={{ paddingTop: "4px" }}
            disabled={false}
            time={handleTime(eodSettings.openTime)}
            onChange={(e) =>
              handleEodSettings({
                openTime: `${e}:00`,
              })
            }
            width={"13.5rem"}
          />
        </Box>
        <AvenirBlackLightH3
          style={{ marginRight: "8px" }}
          label={Context.langData.timeZone}
        />
        <InputText
          disabled={true}
          value={eodSettings.timeZone || ""}
          id="timeZone"
          name="timeZone"
          variant="outlined"
          // placeholder={Context.langData.timeZone}
          style={{ width: "17rem" }}
        />
        <AvenirBlackH5
          style={{ paddingBottom: 2 }}
          label={Context.langData.timezone_administrator}
        />
      </Box>
      <Box marginBottom={3}>
        <AvenirBlackH2 label={Context.langData.end_of_day_report_automation} />

        <Box
          display="flex"
          justifyContent="flex-start"
          paddingTop={2}
          alignItems="center"
          width="80%">
          <AvenirBlackLightH3
            label={Context.langData.end_of_day_automation_text}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          paddingBottom={2}
          paddingTop={2}
          alignItems="center">
          <AvenirBlackLightH3 label={Context.langData.task_checklist} />
          <OnOffSwitch
            show={eodSettings?.checkEndOfDay ? true : false}
            onChange={(e) =>
              handleEodSettings({
                checkEndOfDay: !eodSettings?.checkEndOfDay,
              })
            }
          />
        </Box>
        <Box
          data-qaid="reportsSettingsList-blurb"
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F3FAFD",
            width: "100%",
            height: "auto",
            borderRadius: 5,
          }}>
          <ImportantReminder
            message={Context.langData.task_validation_reminder}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          paddingBottom={2}
          paddingTop={2}
          alignItems="center">
          <AvenirBlackLightH3 label={Context.langData.auto_resolve} />
          <OnOffSwitch
            show={eodSettings?.autoResolve ? true : false}
            onChange={(e) =>
              handleEodSettings({
                autoResolve: !eodSettings?.autoResolve,
              })
            }
          />
        </Box>
        <Box
          data-qaid="reportsSettingsList-blurb"
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F3FAFD",
            width: "100%",
            height: "auto",
            borderRadius: 5,
          }}>
          <ImportantReminder message={Context.langData.auto_resolve_reminder} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          paddingBottom={2}
          paddingTop={2}
          alignItems="flex-start">
          <AvenirBlackLightH3
            style={{ marginRight: "8px" }}
            label={Context.langData.process_auto_resolve}
          />

          <Timer
            iconStyle={{ paddingTop: "4px" }}
            time={handleTime(eodSettings.endOfDayTime)}
            disabled={false}
            onChange={(e) =>
              handleEodSettings({
                endOfDayTime: `${e}:00`,
              })
            }
            width={"13.5rem"}
          />
        </Box>
      </Box>

      <AvenirBlackH2
        label={Context.langData.end_of_day_report_preferences}
        style={{ paddingBottom: "16px" }}
      />
      <Formik initialValues={eodReportsInitialValues} enableReinitialize={true}>
        {({ values, setFieldValue }) => (
          <Form>
            <section style={{ marginLeft: 10 }}>
              <FormGroup>
                {eodReports[0]?.reportSections?.map((item) => {
                  if (item.isOptional) {
                    return (
                      <FormControlLabel
                        control={
                          <Field
                            type="checkbox"
                            name={item.name}
                            as={CustomCheckbox}
                            onChange={() => {
                              setFieldValue(item.name, !values[item.name]);
                              handleEodReports(item.name);
                            }}
                            style={{ marginLeft: "8px" }}
                          />
                        }
                        label={<AvenirBlackLightH3 label={item.name} />}
                      />
                    );
                  }
                })}
              </FormGroup>
            </section>
          </Form>
        )}
      </Formik>
      {snackbar && (
        <SnackBar
          data-qaid="categories_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </Box>
  );
}

export default Reports;
