import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Grid
} from '@material-ui/core';
import OrderTypesList from './ordertypesList'
import {
    fetchOrderTypes,
    fetchPaymentTypes,
    fetchTaxes,
    fetchOrdertTypeDelivery,
    fetchDietaryAttributes
} from '../../../redux/actions';
import VerticalTabs from '../../../components/verticalTabs';
import MyContext from '../../../Provider/MyContext';
import NoPermissionPage from '../../../components/NoPermission';
import AddonsItems from './addonItems';
import ItemTypes from './itemTypes';
import DietaryAttribute from './dietaryAttributes';

const OrderTypes = () => {
    const dispatch = useDispatch();
    const Context = useContext(MyContext);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTitle, setSelectedTitle] = useState(Context.langData.order_types);
    const userPermissions = useSelector(state => state.loginReducer.userPermissions);
    const Tabs = [
        // {
        //     name: Context.langData.item_types,
        //     id: 0
        // },
        {
            name: Context.langData.order_types,
            id: 0
        },
        {
            name: Context.langData.modifiers,
            id: 1
        },
        {
            name: Context.langData.dietary_attributes,
            id: 2
        }
    ]
    useEffect(() => {
        dispatch(fetchOrderTypes());
        dispatch(fetchPaymentTypes());
        dispatch(fetchTaxes());
        dispatch(fetchOrdertTypeDelivery());
        dispatch(fetchDietaryAttributes());

    }, []);
    function indexCallback(value) {
        const Index = Tabs.findIndex(element => element.id == value);
        setSelectedTitle(Tabs[Index].name);
        setSelectedTab(value)
    }
    const renderView = () => {
        return (
            <>
                {/* {selectedTab == 0 &&
                    <>
                        {
                            isPermisiionCheck("order_types") ?
                                <ItemTypes />
                                :
                                <NoPermissionPage data-qaid="ordertypeList_nopermission">
                                </NoPermissionPage>
                        }
                    </>
                } */}
                {selectedTab == 0 &&
                    <>
                        {
                            isPermisiionCheck("order_types") ?
                                <OrderTypesList data-qaid="ordertypeList_index" >
                                </OrderTypesList >
                                :
                                <NoPermissionPage data-qaid="ordertypeList_nopermission">
                                </NoPermissionPage>
                        }
                    </>
                }
                {selectedTab == 1 &&
                    <>
                        {
                            isPermisiionCheck("menu_addons") ?
                                <AddonsItems data-qaid="AddonsItems_index" >
                                </AddonsItems >
                                :
                                <NoPermissionPage data-qaid="AddonsItems_nopermission">
                                </NoPermissionPage>
                        }
                    </>
                }
                {selectedTab == 2 &&
                    <DietaryAttribute
                        data-qaid="DietaryAttribute_index"
                    >
                    </DietaryAttribute >

                }
            </>
        )
    }
    const isPermisiionCheck = (val) => {
        var isPermission = false;
        var obj = userPermissions.filter((e) => e.key == val);
        if (obj.length > 0) {
            if (obj[0].status) {
                isPermission = true;
            } else {
            }
        } else {
        }

        return isPermission
    }
    return (
        <div data-qaid="ordertypes_index_div" style={{ borderTop: '1px solid #EEEEEE' }}>
            <Grid data-qaid="menu_grid" container direction="row" style={{ margin: 20 }}>
                <Grid data-qaid="menu_tabs" item xs={12} lg={2} style={{ backgroundColor: '#FAFAFA' }}>
                    <VerticalTabs data-qaid="menu_options_vtabs" tabData={Tabs} indexCallback={indexCallback} style={{ marginTop: 24 }}></VerticalTabs>
                </Grid>

                < Grid data-qaid="menu_options_addbutton" item xs={12} lg={10}  >
                    {
                        renderView()
                    }
                </Grid>

            </Grid>
        </div>
    );
};

export default OrderTypes;
