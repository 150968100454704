import moment from 'moment'
import { getWookBook, downloadAs, setHeaderStyle, setNormalRowStyle } from './workbook'
const downloadTransactions = (data) => {
  const workbook = getWookBook()
  const ws = workbook.addWorksheet("Transactions");
  console.log("DATA", data)
  ws.addRow(["Bill no", "Time", "Order type", "Payment", "Amount", "Details"])
  ws.getColumn(1).width = 20;
  ws.getColumn(2).width = 40;
  ws.getColumn(3).width = 40;
  ws.getColumn(4).width = 40;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 40;
  setHeaderStyle(ws, 1)
  data.map((d, i) => {
    ws.addRow([d.bill, d.time, d.ordertype, d.payment, d.final_price, d.split_mode])
    setNormalRowStyle(ws, i + 2)
  })


  downloadAs(workbook, `transactions_${moment().format("yyyyMMDDHHmmss")}.xlsx`)
}

const downloadPurchases = (data) => {
  const workbook = getWookBook()
  const ws = workbook.addWorksheet("Purchases");
  ws.addRow(["Bill no", "Received on", "Vendor", "Amount", "Payment", "Status", "Balance"])
  ws.getColumn(1).width = 20;
  ws.getColumn(2).width = 40;
  ws.getColumn(3).width = 50;
  ws.getColumn(4).width = 20;
  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 20;
  ws.getColumn(6).width = 20;
  setHeaderStyle(ws, 1)
  data.map((d, i) => {
    ws.addRow([d.bill_view, d.date, d.vendor_name, d.total_amount, d.amount_paid, d.status_text, d.balance_amount])
    setNormalRowStyle(ws, i + 2)
  })
  downloadAs(workbook, `purchases_${moment().format("yyyyMMDDHHmmss")}.xlsx`)
}

export {
  downloadTransactions,
  downloadPurchases
}