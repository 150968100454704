import React from 'react';
import { FormHelperText } from '@material-ui/core'
// import ExclamationIcon from './SVG/ExclamationIcon';
import InfoOutlined from './SVG/InfoOutlined'

const HelperText = ({ text, color = "#051D33" }) => {
  return (
    <FormHelperText style={{ color: color, display: 'flex', margin: '8px 0px 0px 0px' }}>
      <InfoOutlined width={16} height={16} color={color} style={{ marginRight: 10 }} />
      {text}
    </FormHelperText>
  )
}

export default HelperText