import instance from './axiosInstance'
import urls from './urls';
const fetchFloorPlans = (params) => {
    return (dispatch) => {
        instance.get(urls.floorPlan.floorPlanUrl).then((response) => {
            console.log(response)
            if (response.status === 200) {
                dispatch({ type: 'GET_FLOOR_PLAN_SUCCESS', payload: response.data });

            }
        });
    };
}

const createFloorPlan = (params, callback) => {
    return (dispatch) => {
        instance.post(urls.floorPlan.floorPlanUrl, params).then((response) => {
            console.log(response)
            if (response.status === 200) {
                callback(true);
                dispatch(fetchFloorPlans())
            }
        }).catch(err => {
            callback(false);
        })
    };
}
const editFloorPlan = (params, callback) => {
    return (dispatch) => {
        instance.put(urls.floorPlan.floorPlanUrl, params).then((response) => {
            console.log(response)
            if (response.status === 200) {
                if (callback) {
                    callback(true, "success")
                }
                dispatch(fetchFloorPlans())
                dispatch({ type: '' })

            }
        }).catch(err => {
            if (callback) {
                callback(false, err)
            }
            console.log(err)
        })
    };
}
const deleteFloorPlan = (params, callback) => {
    return (dispatch) => {
        instance.delete(urls.floorPlan.floorPlanUrl, { data: params }).then((response) => {
            console.log(response)
            if (response.status === 200) {
                dispatch(fetchFloorPlans())
                callback(true)
            }
        }).catch(err => {
            callback(false);
            console.log(err)
        })
    };
}
const getFloorPlanBuilder = (params) => {
    return (dispatch, getState) => {
        instance.get(urls.floorPlan.builder, { params }).then((response) => {
            const { data } = response;
            let currentSection = getState().floorplan.currentSection;
            console.log("datadatadatadatadatadatadatadatadata", data)
            if (data?.sections?.length > 0) {
                let list = data.sections.map((s) => {
                    return { section_id: s.section_id, name: s.name }
                })
                let d = parseFloorBuilder(response.data.sections[0]);
                console.log("dddddddddd value", d, "current section", currentSection)

                if (!currentSection) {
                    console.log("current section")
                    dispatch({ type: "SET_CURRENT_SECTION", payload: data.sections[0] })
                } else {
                    let sec = data.sections.find(e => e.name == currentSection.name)
                    if (sec) {
                        d = parseFloorBuilder(sec);
                        dispatch({ type: "SET_CURRENT_SECTION", payload: sec })
                    } else {
                        dispatch({ type: "SET_CURRENT_SECTION", payload: data.sections[0] })
                    }

                }
                if (d.floor.type)
                    dispatch({ type: "SET_FLOOR", payload: d })
                console.log("secsecsecsecddddddddd", d)
                dispatch({ type: "SECTIONS", payload: data, list: list })
                dispatch({ type: "SET_WORKINGDATA", workingData: d.workingData })
                dispatch({ type: "SET_HISTORY", payload: [d.workingData] })
            } else {
                dispatch({ type: "SET_FLOOR", payload: {} })
                dispatch({ type: "SET_CURRENT_SECTION", payload: null })
                dispatch({ type: "SECTIONS", payload: data, list: [] })
                dispatch({ type: "SET_WORKINGDATA", workingData: [] })
                dispatch({ type: "SET_HISTORY", payload: [] })
            }
        })
    }
}
const copyFloorplan = (params, callback) => {
    return (dispatch) => {
        instance.post(urls.floorPlan.copy, params).then((response) => {
            console.log(response)
            if (response.status === 200) {
                callback(true);
                dispatch({ type: '' })
                dispatch(fetchFloorPlans())
            }
        }).catch(err => {
            callback(false);
            console.log(err)
        })
    };
}
const parseFloorBuilder = (data) => {
    let temp = [];
    data.tables.map(t => {
        temp.push({ ...t.table_config, object_id: t.object_id, table_id: t.table_id, name: t.name, seats: t.seats })
    })
    data.objects.map(t => {
        temp.push({ ...t.object_config, object_id: t.object_id, name: t.name })
    })
    return { workingData: temp, floor: data.section_config }
}
const saveFloorPlanBuilder = (params, callback, flag = true) => {
    return (dispatch) => {
        instance.post(urls.floorPlan.builder, params).then((response) => {
            dispatch({ type: "CURRENTSTEP" })
            if (flag) {
                dispatch(getFloorPlanBuilder({ floorplan_id: params.floorplan_id }))
            } else {
                dispatch({ type: "SET_HAS_CHANGES", payload: false })
            }

            callback(1)
        }).catch(err => {
            callback(0)
        })
    }
}
export {
    fetchFloorPlans,
    createFloorPlan,
    editFloorPlan,
    deleteFloorPlan,
    getFloorPlanBuilder,
    saveFloorPlanBuilder,
    copyFloorplan
}
