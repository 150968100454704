import { List, ListItem, ListItemText } from "@material-ui/core";
import { isSameDay } from "date-fns";

import { H5 } from "../../utils/text";

const isSameRange = (first, second) => {
	const { startDate: fStart, endDate: fEnd } = first;
	const { startDate: sStart, endDate: sEnd } = second;
	if (fStart && sStart && fEnd && sEnd) {
		return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
	}
	return false;
};
const isCustom = (ranges, second) => {
	for (var i = 0; i < ranges.length; i++) {
		const s = isSameRange(ranges[i], second)
		if (s) {
			return false
		}
	}
	return true
}
const DefinedRanges = props => {
	const ranges = [...props.ranges]
	return (
		<List>
			<ListItem button style={{ paddingTop: 2, paddingBottom: 2 }}>
				<ListItemText
					primaryTypographyProps={{
						variant: "body2",
						style: {
							color: "#051D33",
							fontSize: 12,
							fontFamily: 'AvenirLT',
							fontWeight: isCustom(ranges, props.selectedRange)
								? "bold"
								: "normal"
						}
					}}>
					<H5 label={"Custom"} />
				</ListItemText>
			</ListItem>
			{ranges.map((range, idx) => (
				<ListItem button key={idx} onClick={() => props.setRange(range)} style={{ paddingTop: 2, paddingBottom: 2 }}>
					<ListItemText
						primaryTypographyProps={{
							variant: "body2",
							style: {
								color: "#051D33",
								fontSize: 12,
								fontFamily: 'AvenirLT',
								fontWeight: isSameRange(range, props.selectedRange)
									? "bold"
									: "normal"
							}
						}}>
						{range.label}
					</ListItemText>
				</ListItem>
			))}
		</List>
	);
};

export default DefinedRanges;
