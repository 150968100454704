import React, { useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Button,
    Grid
} from '@material-ui/core';
import { H6, AvenirBlackH4 } from '../../utils/text'
import { useState } from 'react';
import MyContext from '../../Provider/MyContext';
import NoPermissionPage from '../../components/NoPermission';
import General from './General';
import Payments from './Payments';
import OrderTypes from '../menu/OrderTypes'
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const Settings = () => {
    const Context = useContext(MyContext);
    const userPermissions = useSelector(state => state.loginReducer.userPermissions);
    const [buttonArray, setButtonArray] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const settingslist = [
        {
            key: "general", title: Context.langData.general, id: 0
        },
        {
            key: "payments", title: Context.langData.payments, id: 1
        },
    ];
    
    const isItemHasPermission = useMemo(() => {
        var buttonName = [];
        var buttonCount = 0;
        var childObj = {}
        settingslist.map((childItem) => {
            if (childItem.id == 0) {
                var menuOptions = ["offers", "shifts", "printers", "kitchen", "reservation_settings", "delivery_options"];
                var isPermission = 0;
                menuOptions.map((menuOptionItem) => {
                    var obj = userPermissions.filter((e) => e.key == menuOptionItem);
                    if (obj.length > 0) {
                        if (obj[0].status) {
                            isPermission = isPermission + 1;
                        } else {
                        }
                    } else {
                    }
                })
                if (isPermission != 0) {
                    buttonCount = buttonCount + 1;
                    childObj = { ...childItem, isPermission: true }
                } else {
                    childObj = { ...childItem, isPermission: false }
                }
                buttonName.push(childObj)
            }
            if (childItem.id == 1) {
                var menuOptions = ["taxes", "order_types", "menu_addons"];
                var isPermission = 0;
                menuOptions.map((menuOptionItem) => {
                    var obj = userPermissions.filter((e) => e.key == menuOptionItem);
                    if (obj.length > 0) {
                        if (obj[0].status) {
                            isPermission = isPermission + 1;
                        } else {
                        }
                    } else {
                    }
                })
                if (isPermission != 0) {
                    buttonCount = buttonCount + 1;
                    childObj = { ...childItem, isPermission: true }
                } else {
                    childObj = { ...childItem, isPermission: false }
                }
                buttonName.push(childObj)
            }
        });
        console.log("buttonCount==>", buttonCount, "buttonNamebuttonName==>", buttonName)
        if (buttonCount == 0) {
            setButtonArray([])
        } else {
            setButtonArray(buttonName)
            var result = true;
            if (buttonName.length > 0) {
                result = buttonName[selectedIndex].isPermission;
            } else {
                result = false;
            }
        return result;
        }
    }, [userPermissions])
    const handleSelectItem = (item) => {
        setSelectedIndex(item)
    }
    const renderSelectedView = () => {
        if (selectedIndex == 0) {
            if (isItemHasPermission) {
                return (
                    <General data-qaid="settings_taxes"></General>
                )
            } else {
                return (
                    <NoPermissionPage data-qaid="settings_taxes_nopermissions" />
                )
            }
        } else if (selectedIndex == 1) {
            if (isItemHasPermission) {
                return (
                    <Payments data-qaid="settings_taxes"></Payments>
                )
            } else {
                return (
                    <NoPermissionPage data-qaid="settings_taxes_nopermissions" />
                )
            }
        } else if (selectedIndex == 2) {
            if (isItemHasPermission) {
                return (
                    <OrderTypes data-qaid="settings_taxes"></OrderTypes>
                )
            } else {
                return (
                    <NoPermissionPage data-qaid="settings_taxes_nopermissions" />
                )
            }
        }
    }
    
    return (
        <Grid data-qaid="settings_index_grid" style={{ backgroundColor: '#FFFFFF', minHeight: '100%', width: '100%', overflow: 'hidden' }}>
            <Grid data-qaid="settings_index_contentgrid" style={{ height: '20%' }}>
                <Grid data-qaid="settings_index_titlegrid" item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: 36 }}>
                    <H6 data-qaid="settings_index_title_label" label={Context.langData.settings} style={{ color: "#051D33", paddingTop: 36, fontWeigth: '800' }}></H6>
                </Grid>
                <Grid data-qaid="settings_index_bodygrid" item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: 36, marginTop: 36, flexDirextion: 'row' }}>
                    {
                        buttonArray.map((item, i) => {
                            return (
                                <Button key={i} data-qaid="settings_button" style={{ backgroundColor: selectedIndex == item.id ? '#051D33' : '#EEEEEE', marginRight: 1, borderRadius: 0, borderTopLeftRadius: i == 0 ? 4 : 0, borderBottomLeftRadius: i == 0 ? 4 : 0, borderTopRightRadius: buttonArray.length - 1 == i ? 4 : 0, borderBottomRightRadius: buttonArray.length - 1 == i ? 4 : 0 }} onClick={() => handleSelectItem(item.id)}>
                                    <AvenirBlackH4 data-qaid="settings_button_label" label={item.title} style={{ color: selectedIndex == item.id ? '#FFFFFF' : '#051D33', paddingLeft: 8, paddingRight: 8, paddingTop: 10, paddingBottom: 10, lineHeight: '24px' }} />
                                </Button>
                            )
                        }
                        )
                    }
                </Grid>
            </Grid>
            <Grid data-qaid="settings_body_view" style={{ height: '80%' }}>
                {renderSelectedView()}
            </Grid>
        </Grid >
    );
};


export default Settings;
