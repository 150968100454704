import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Grid, Button, Checkbox, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchItemsByCatId, deleteItemsByCatId, createOrderTypesPostion, fetchImageUrl, putImageUrl, itemImageUpload, editCategories, deleteCategories, fetchItemTypes, fetchAllCategories, taxProfileList, } from '../../../redux/actions'
import { useSelector, useDispatch } from 'react-redux';
import ItemCard from './ItemCard';
import styles from '../../../styles/index';
import RightDrawer from '../../../components/RightDrawer';
import InputText from '../../../components/InputText';
import AlertDialog from '../../../components/Dialog';
import Postion from '../../../components/Position'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SnackBar from '../../../components/SnackBar'
import MyContext from '../../../Provider/MyContext';
import { AvenirBlackH4, H4, AvenirBlackH7, H8, DotTextMedium } from '../../../utils/text';
import CircleClose from '../../../components/SVG/CloseCircle';
import MenuBookIcon from '../../../components/SVG/Menu';
import PluseIcon from '../../../components/SVG/PluseIcon';
import BackIcon from '../../../components/SVG/Back';
import Delete from '../../../components/SVG/Delete';
import DownVector from '../../../components/SVG/DownVector';
import PizzasModal from './pizzas'
import CreateItem from './create/CreateItem';
import moment from 'moment';
import CombosModal from './combos';
import SingleSelect from '../../../components/SingleSelect';
import { FeatureKeys } from "../../../enums";
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { validateAlphaNumeric } from '../../../utils/util';

const useStyles = makeStyles({
  inputText: {
    fontSize: '14px',
    color: '#051D33',
    fontFamily: 'AvenirLT',
  },

})
const Items = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAlcholol, setAlcohol] = useState(false)
  const [snackbar, setSnackbar] = useState(null);
  const style = styles();
  const [openCreate, toggleCreateDrawer] = useState(false)
  const [openEdit, toggleEditDrawer] = useState(false)
  const [openSetPostion, togglePostionDrawer] = useState(false)
  const [openAddon, toggleAddonDrawer] = useState(false)
  const [itemId, setItemId] = useState('')
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState('');
  const [itemList, setItemList] = useState([]);
  const Context = useContext(MyContext);
  const [isDeleteAllItems, setIsDelteAllItems] = useState(false);
  const [serchText, setSerchText] = useState('');
  const [catName, setCatName] = useState('');
  const [heading, setHeading] = useState(Context.langData.delete_all_items);
  const [bodyMessage, setBodyMessage] = useState(Context.langData.delete_all_items_body);
  const items = useSelector(state => state.menu.category_items)
  const itemTypes = useSelector(state => state.menu.itemTypes)
  const [isOpenComboModal, toggleComboModal] = useState(false);
  const [isOpenPizzaModal, togglePizzaModal] = useState(false)
  const [openCategoryEdit, toggleCategoryEditDrawer] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    const { cat_id } = state;
    const { name } = state;
    setCatName(name)
    dispatch(fetchItemsByCatId({ category_id: cat_id }))
    dispatch(fetchItemTypes())
  }, [state])

  const itemtypeName = [
    { id: 1, value: 'Individual food item' },
    { id: 2, value: 'Alcoholic beverage' },
    { id: 3, value: 'Combos' },
    { id: 4, value: 'Build-your-own item' },
  ]

  //check if build your own item is available for the restaurant which is controlled by feature flag
   const featureFlagClient = useLDClient();
  if (featureFlagClient) {
      const flagValue = featureFlagClient.variation(FeatureKeys.BuildYourOwnItem, true);
      if (flagValue===false) {
        //Remove build your own item from itemtypeName
        const byo = (element) => element.id === 4;
        let index = itemtypeName.findIndex(byo);
        itemtypeName.splice(index, 1);
      } 
  }
  
  const findItem = useMemo(() => {
    let filtered = itemTypes?.filter(e => e.status == 1)
    let filterIds = filtered.map(i => i.item_type_id)
    return itemtypeName.filter(obj => filterIds.includes(obj.id))
  }, [itemTypes])

  const handleChange = (id) => {
    setItemId(null)
    if (id === 1) {

      omMenuItemSelect(false)
    } else if (id === 2) {
      omMenuItemSelect(true)
    } else if (id === 3) {
      toggleComboModal(true)
      handleCloseMenu()
    } else if (id === 4) {
      togglePizzaModal(true)
      handleCloseMenu()
    }

  }
  function deleteCallback() {
    setSnackbar({ message: Context.langData.item_delete_succ, severity: 'success' });
    closingDrawer()
  }
  const createCallback = () => {
    setItemId(null)
    setSnackbar({ message: Context.langData.item_create_succ, severity: 'success' });
    closingDrawer()
  }
  const editCallback = () => {
    setItemId(null)
    setSnackbar({ message: Context.langData.item_edit_succ, severity: 'success' });
    closingDrawer()
  }
  const deleteItemcallback = () => {
    setItemId(null)
    setSnackbar({ message: Context.langData.item_delete_succ, severity: 'success' });
    closingDrawer()
  }
  const copyItemcallback = () => {
    setItemId(null)
    setSnackbar({ message: Context.langData.item_copy_succ, severity: 'success' });
    closingDrawer()
  }
  const closingDrawer = () => {
    setItemId(null)
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    toggleAddonDrawer(false);
    togglePostionDrawer(false)
    const { cat_id } = state;
    dispatch(fetchItemsByCatId({ category_id: cat_id }))
  }
  const addonCallback = () => {
    setSnackbar({ message: Context.langData.item_addon_create_succ, severity: 'success' });
    closingDrawer()
  }
  const postionCallback = () => {
    setSnackbar({ message: Context.langData.item_postion_succ, severity: 'success' });
    closingDrawer()
  }
  const imageUploadCallback = () => {
    setSnackbar({ message: Context.langData.item_image_succ, severity: 'success' });
    closingDrawer()
  }
  const enableAddonDrawer = (itemId) => {
    setItemId(itemId)
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    toggleAddonDrawer(true);
  }
  const enableEditDrawer = (item) => {
    const itemId = item.item_id;
    setItemId(itemId)
    toggleCreateDrawer(false);
    if (item.item_type == "Combo") {
      toggleComboModal(true)
    } else if (item.item_type == "Byo") {
      togglePizzaModal(true)
    } else {
      toggleEditDrawer(true);
    }

    toggleAddonDrawer(false);
  }
  const categoryCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({ message: Context.langData.cat_edit_succ, severity: 'success' });
      toggleCategoryEditDrawer(false);
      dispatch(fetchAllCategories());
    } else {
      setSnackbar({
        message: Context.langData.category_edit_failed,
        severity: "error",
      });
    }
  }

  function deleteAction(id) {
    setId(id)
    setShowDialog(true)
  }
  const handleClose = (val) => {
    if (val) {
      if (isDeleteAllItems) {
        var itemsArray = [];
        if (items && items.length > 0) {
          items.map((itemObj) => {
            itemsArray.push(itemObj.item_id)
          })
        }
        if (itemsArray.length > 0) {
          dispatch(deleteItemsByCatId(JSON.stringify(itemsArray), deleteCallback))
        }
      } else {
        dispatch(deleteItemsByCatId([id], deleteCallback))
      }
    }
    setShowDialog(false)
  }
  const completeLogo = (response, file, itemId) => {
    var obj = {};
    var url = response.upload_url.split("?")[0];
    obj.ContentType = file.type;
    obj.Body = file;
    dispatch(
      putImageUrl(response.upload_url,
        obj,
        () => uploadImageUrl(url, itemId),
        response.storage == "azure" ? true : false
      )
    );
  }
  const uploadImageUrl = (url, itemId) => {
    console.log("upload image item id", itemId)
    dispatch(itemImageUpload({ img_url: url, item_id: itemId }, imageUploadCallback))
  }
  function uploadImageCallBack(file, itemId) {
    var fileName = file.name.split('.');
    var imagename = (fileName[0] ? fileName[0] : "MenuImage") + '_' + moment().unix().toString() + '.png';
    dispatch(fetchImageUrl({
      file_name: imagename,
      content_type: file.type,
      image_loc: "menu"
    }, (response) => completeLogo(response, file, itemId)));
  }
  const deleteAllItems = () => {
    setIsDelteAllItems(true);
    setShowDialog(true);
  }
  const handleBackClick = () => {
    navigate('/home/menu')
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const deleteCallBack = () => {
    setSnackbar({ message: Context.langData.cat_delete_succ, severity: 'success' });
    toggleCategoryEditDrawer(false)
    navigate('/home/menu')
  }
  const omMenuItemSelect = (isAl) => {
    toggleCreateDrawer(true)
    handleCloseMenu()
    setAlcohol(isAl)
  }
  const closeCreateItem = () => {
    togglePizzaModal(false);
    toggleComboModal(false)
    setItemId(null)
    const { cat_id } = state;
    dispatch(fetchItemsByCatId({ category_id: cat_id }))
  }
  return (
    <Grid justify="flex-start" style={{ backgroundColor: "#FFFFFF" }}>
      <Grid style={{ backgroundColor: "#FFFFFF", height: "100%" }}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginLeft: 32,
            marginRight: 32,
            paddingTop: 40,
            overflow: "hidden",
          }}
        >
          {/* <TextField
              style={{ marginRight: 10 }}
              variant='outlined'
              value={serchText}
              placeholder={Context.langData.serch_items}
              onChange={(val) => serchItem(val.target.value)}
              id='serch_id'
            /> */}
          <Grid
            item
            data-qaid="categories_labelView"
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              paddingLeft: 32
              // justifyContent: "flex-start",
            }}
          >
            <BackIcon
              data-qaid="categories_editBack_icon"
              onClick={() => handleBackClick()}
              style={{ cursor: "pointer" }}
            />
            <DotTextMedium
              data-qaid="categories_editBackLabel"
              label={catName}
              style={{ fontWeight: "bold", paddingLeft: 20, fontSize: 24 }}
            />
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              className={style.buttonClass}
              data-qaid="categories_creategrid"
              style={{
                backgroundColor: "#FFFFFF",
                color: "white",
                marginRight: 10,
              }}
              variant="text"
              id="cancel"
              onClick={() => deleteAllItems()}
            >
              <CircleClose
                size={24}
                data-qaid="categories_createpluse"
                style={{ marginRight: 5 }}
              />
              <AvenirBlackH4
                data-qaid="categories_createlabel"
                style={{ color: "#004C60" }}
                label={Context.langData.delete_all_items}
              />
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid="categories_creategrid"
              style={{
                backgroundColor: "#00B6C9",
                color: "white",
                marginRight: 10,
              }}
              variant="contained"
              id="cancel"
              onClick={() => toggleCategoryEditDrawer(true)}
            >
              <AvenirBlackH4
                data-qaid="categories_createlabel"
                label={Context.langData.manage_category}
              />
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid="categories_creategrid"
              style={{
                backgroundColor: "#FFB600",
                color: "white",
                marginRight: 10,
              }}
              variant="contained"
              id="cancel"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <PluseIcon
                data-qaid="categories_createpluse"
                style={{ marginRight: 5 }}
              />
              <AvenirBlackH4
                data-qaid="categories_createlabel"
                label={Context.langData.Create_new}
              />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl) && findItem?.length > 0}
              onClose={handleCloseMenu}
            >
              {findItem.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  onClick={() => handleChange(item.id)}
                >
                  {item.value}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
        <Grid
          data-qaid="categories__border_grid"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            border: "1px solid #EEEEEE",
            marginTop: 34,
            minHeight: ".1%",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          style={{
            marginLeft: 32,
            marginRight: 32,
            marginTop: 56,
            overflowX: "hidden",
            height: window.innerHeight - 180,
          }}
        >
          {serchText ? (
            <Grid container justify="flex-start" spacing={3}>
              {itemList.map((item) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={item.item_id}>
                    <ItemCard
                      item={item}
                      onDelete={deleteAction}
                      enableEditDrawer={enableEditDrawer}
                      enableAddonDrawer={enableAddonDrawer}
                      uploadImageCallBack={uploadImageCallBack}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Grid container justify="flex-start" spacing={3} style={{ paddingBottom: 100 }}>
              {items &&
                items.map((item) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={item.item_id}>
                      <ItemCard
                        item={item}
                        onDelete={deleteAction}
                        enableEditDrawer={enableEditDrawer}
                        enableAddonDrawer={enableAddonDrawer}
                        uploadImageCallBack={uploadImageCallBack}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
      </Grid>
      {isOpenComboModal && (
        <CombosModal
          cat_id={state.cat_id}
          itemId={itemId}
          open={isOpenComboModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => closeCreateItem()}
          deleteItemcallback={deleteItemcallback}
          copyItemcallback={copyItemcallback}
        />
      )}
      {isOpenPizzaModal && (
        <PizzasModal
          cat_id={state.cat_id}
          itemId={itemId}
          open={isOpenPizzaModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => { closeCreateItem(); setItemId(null); }}
          deleteItemcallback={deleteItemcallback}
          copyItemcallback={copyItemcallback}
        />
      )}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {openCreate && (
        <CreateItem
          open={openCreate}
          catName={state.name}
          id={state.cat_id}
          itemsList={items}
          isAlcholol={isAlcholol}
          callback={createCallback}
          onClose={() => { setItemId(null); toggleCreateDrawer(false) }}
          deleteItemcallback={deleteItemcallback}
        />
      )}
      {openEdit && (
        <CreateItem
          open={openEdit}
          catName={state.name}
          id={state.cat_id}
          itemsList={items}
          itemId={itemId}
          callback={editCallback}
          onClose={() => {
            toggleEditDrawer(false);
            setItemId(null);
          }}
          deleteItemcallback={deleteItemcallback}
          copyItemcallback={copyItemcallback}
        />
      )}
      {openAddon && (
        <AddonCreateItem
          open={openAddon}
          catNames={state.name}
          id={itemId}
          itemsList={items}
          callback={addonCallback}
          onClose={() => toggleAddonDrawer(false)}
        />
      )}
      {openSetPostion && (
        <SetPostionItem
          open={openSetPostion}
          itemsList={items}
          callback={postionCallback}
          onClose={() => togglePostionDrawer(false)}
        />
      )}
      {openCategoryEdit && (
        <EditCategory
          open={openCategoryEdit}
          category={state}
          callback={categoryCallback}
          deleteCallBack={deleteCallBack}
          onClose={() => toggleCategoryEditDrawer(false)}
        />
      )}
      <AlertDialog
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </Grid>
  );
}

const EditCategory = ({ open, category, callback, onClose, deleteCallBack }) => {
  const categories = useSelector(state => state.menu.categories);
  const taxProfilesList = useSelector((state) => state.taxConfigurationReducer.taxProfileList);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [selectedTaxProfile, setSelectedTaxProfile] = useState({});
  const [cat_name, setCatName] = useState("");
  const [error, setError] = useState(null);
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const catNames = categories.map(cat => cat.name.toLowerCase());
  const [item, setItem] = useState({});
  const [heading, setHeading] = useState(Context.langData.delete_category);
  const [bodyMessage, setBodyMessage] = useState(Context.langData.delete_category_body);
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    if (category) {
      setCatName(category.name);
      setItem(category)


      setSelectedTaxProfile(category.taxp_id)


    }

  }, [category])
  useEffect(() => {
    dispatch(taxProfileList(restId.rest_id));
  }, []);

  const handleSelectTaxProfile = (value) => {
    console.log("select value", value)
    setSelectedTaxProfile(value);
  }
  const save = () => {
    if (cat_name && cat_name != '') {
      if (catNames.indexOf(cat_name.toLowerCase()) == -1 || cat_name === item.name) {
        dispatch(editCategories({ name: cat_name, cat_id: item.cat_id, kitchen_id: item.kitchen_id, order: item.order, taxp_id: selectedTaxProfile }, callback))
        setError("")
        navigate(null, { replace: true, state: { ...category, name: cat_name, taxp_id: selectedTaxProfile } })
      }
      else {
        setError(cat_name + " already exist");
      }
    } else {
      setError(Context.langData.valid_name_error);
    }
  }

  const onChange = (val) => {
    setCatName(validateAlphaNumeric(val))
    if (catNames.indexOf(val.toLowerCase()) == -1) {
      setError("")
    }
    else {
      setError(validateAlphaNumeric(val) + " already exist");
    }
  }
  const deleteCategory = () => {
    setShowDialog(true)
  }
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteCategories([item.cat_id], deleteCallBack))
    }
    setShowDialog(false)
  }
  return (
    <>
      <RightDrawer data-qaid="editCategory_centermodal" open={open} padding={1}>
        <>
          <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
              <Grid data-qaid="assignkitchen_close_grid" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CircleClose data-qaid="assignkitchen_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
              </Grid>
              <Grid data-qaid="assignkitchen_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                <MenuBookIcon data-qaid="assignkitchen_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                <H4 data-qaid="assignkitchen_settings" label={Context.langData.menu} style={{ color: '#707272' }} />
              </Grid>
              <H8 data-qaid="assignlitchen_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.edit_category} />
              <Grid data-qaid="editCategory_comment_grid" style={{ marginLeft: 56, marginRight: 56, width: 500 }}>
                <Grid data-qaid="editCategory_value_grid" container style={{ display: 'flex', flexDirection: 'column' }}>
                  <AvenirBlackH4 data-qaid="editCategory_valuelabel" label={Context.langData.cat_name} style={{ marginBottom: 10 }} />
                  <InputText
                    value={cat_name}
                    error={error}
                    maxLength={32}
                    onChange={(val) => onChange(val.target.value)}
                    id="cat_name" />
                </Grid>
                <Grid data-qaid="createCategeory_taxProfilelistGrid" style={{ width: "100%", marginTop: 10, }}>
                  <AvenirBlackH4 data-qaid="createCategeory_taxProfileLabel" label={Context.langData.taxProfile_select_profile} style={{ marginTop: 20, paddingBottom: 10 }} />
                  <SingleSelect
                    list={taxProfilesList}
                    value={selectedTaxProfile}
                    valueKey="id"
                    displayKey="name"
                    placeholder="Edit Tax Profile"
                    handleChange={(e) => { handleSelectTaxProfile(e.target.value) }}
                    border={false}
                    height={54}
                    FormWidth={'100%'}
                    borderStyle={'1px solid #707272'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <Grid data-qaid="editCategory_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                <Grid data-qaid="editCategory_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                  <Button className={style.buttonClass} data-qaid="categories_creategrid" style={{ backgroundColor: "#FAFAFA", marginRight: 16 }} variant='text' id="cancel" onClick={() => deleteCategory()}  >
                    <Delete size={24} color={'#004C60'} data-qaid="categories_createpluse" style={{ marginRight: 5 }} />
                    <AvenirBlackH4 data-qaid="categories_createlabel" style={{ color: '#004C60' }} label={Context.langData.delete} />
                  </Button>
                  <Button className={style.buttonClass} data-qaid="editCategory_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, border: '2px solid #051D33', height: 40, width: 103 }} >
                    <AvenirBlackH4 data-qaid="editCategory_cancel_text" label={Context.langData.cancel} />
                  </Button>
                  <Button
                    className={style.buttonClass}
                    data-qaid="editCategory_save_btn"
                    variant="contained"
                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                    onClick={save}>
                    <AvenirBlackH4 data-qaid="createcomment_save_text" label={Context.langData.save} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      </RightDrawer>
      <AlertDialog
        data-qaid="categories_alertDialog"
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose} />
    </>
  )
}

const AddonCreateItem = ({ open, callback, onClose, addonsData, servingsData }) => {
  const style = styles();
  const [expanded, setExpanded] = useState([]);
  const addons = useSelector(state => state.menu.addons);
  const Context = useContext(MyContext);
  const [sendData, setsendData] = useState([]);
  const [value, setValue] = useState(0);
  const [assignResponse, setAssignResponse] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [servings, setServings] = useState([]);
  const [isError, setIsError] = useState(false);

  const save = () => {
    var errorCount = 0;
    if (sendData) {
      var arrayValues = [];
      sendData.map((item) => {
        var addonsArray = [];
        if (item.addons && item.addons.length > 0) {
          item.addons.map((addonItems) => {
            if (addonItems.addon_items.length > 0) {
              var obj = {}
              obj['addon_cat_id'] = addonItems.addon_cat_id;
              obj['addon_cat_name'] = addonItems.addon_cat_name;
              obj['minimum'] = addonItems.minimum;
              obj['maximum'] = addonItems.maximum;
              obj['addon_items'] = addonItems.addon_items;
              addonsArray.push(obj);
              if (addonItems.minError != '' || addonItems.maxError != '') {
                errorCount = errorCount + 1;
                console.log("addonItems,addonItems", addonItems)
              }
              if (addonItems.maximum <= addonItems.addon_items.length) {
              } else {
                errorCount = errorCount + 1;
                console.log("addonItems,addonItems", addonItems)

              }
            }
          })
        }
        if (addonsArray.length > 0) {
          var serivingsobj = {}
          serivingsobj['serving'] = item.serving;
          serivingsobj['serving_id'] = item.serving_id;
          serivingsobj['addons'] = addonsArray;
          arrayValues.push(serivingsobj)
        }
      })
      console.log("errorCounterrorCount", errorCount)
      if (errorCount == 0) {
        callback(arrayValues)
      } else {
        setIsError(true)
      }

    }
  }
  useEffect(() => {
    if (servingsData) {
      var array = [];
      setServings(servingsData);
      servingsData.map(function (item) {
        var obj = {}
        obj['serving'] = item.name;
        obj['serving_id'] = item.serving_id;
        var addonsArray = [];
        addons.map((item) => {
          var obj = {}
          obj['addon_cat_id'] = item.addon_cat_id;
          obj['addon_cat_name'] = item.addon_cat_name;
          obj['minimum'] = 0;
          obj['maximum'] = 0;
          obj['minError'] = '';
          obj['maxError'] = '';
          obj['addon_items'] = [];
          addonsArray.push(obj);
        });
        obj['addons'] = addonsArray;
        array.push(obj)
      })
      if (addonsData.length > 0) {
        addonsData.map((item) => {
          let index = array.findIndex(a => a.serving === item.serving)
          console.log("arrayarrayarrayarray", array, "index", index)
          let selectedArray = array[index]
          let addonsArray = selectedArray.addons;
          if (item.addons.length > 0) {
            item.addons.map((addonItem) => {
              let addonIndex = addonsArray.findIndex(a => a.addon_cat_id == addonItem.addon_cat_id)
              let addon = addonsArray[addonIndex];
              var addonsItemsArray = [];
              if (addonItem.addon_items.length > 0) {
                addonItem.addon_items.map((addonItemId) => {
                  addonsItemsArray.push(addonItemId)
                })
              }
              addonsArray[addonIndex] = { ...addon, minimum: parseInt(addonItem.minimum), maximum: parseInt(addonItem.maximum), addon_items: addonsItemsArray }
              selectedArray["addons"] = addonsArray;
              array[index] = selectedArray;
            })
          }
        })
      }

      setsendData(array)
    }
  }, [servingsData, addonsData])
  const getAddonsList = (id) => {
    var addonsData = 0;
    if (addons) {
      var addonsListObj = addons.filter(e => e.addon_cat_id == id);
      if (addonsListObj.length > 0) {
        addonsData = addonsListObj[0].addons.length;
      }
    }
    return addonsData;
  }
  const setMinValue = (minValue, id) => {
    setIsError(false)
    let newArray = [...sendData];
    let selectedArray = newArray[value]
    let addons = selectedArray.addons;
    let addonIndex = addons.findIndex(a => a.addon_cat_id == id)
    let addon = addons[addonIndex];
    var addonCount = getAddonsList(id);
    if (addon.maximum >= parseInt(minValue) && parseInt(minValue) <= addonCount) {
      addons[addonIndex] = { ...addon, minimum: parseInt(minValue), minError: "", maxError: "" }
    } else {
      addons[addonIndex] = { ...addon, minimum: parseInt(minValue), minError: "inValid", maxError: "", }
    }
    selectedArray["addons"] = addons;
    newArray[value] = selectedArray;
    setsendData(newArray)
  }
  const setMaxValue = (maxValue, id) => {
    setIsError(false)
    let newArray = [...sendData];
    let selectedArray = newArray[value]
    let addons = selectedArray.addons;
    let addonIndex = addons.findIndex(a => a.addon_cat_id == id)
    let addon = addons[addonIndex];
    var addonCount = getAddonsList(id);
    if (addon.minimum <= parseInt(maxValue) && parseInt(maxValue) <= addonCount) {
      addons[addonIndex] = { ...addon, maximum: parseInt(maxValue), maxError: "", minError: "" }
    } else {
      addons[addonIndex] = { ...addon, maximum: parseInt(maxValue), maxError: "inValid", minError: "" }
    }
    selectedArray["addons"] = addons;
    newArray[value] = selectedArray;
    setsendData(newArray)
  }
  const getMinValue = (id) => {
    var minValue = 0;
    if (sendData.length > 0) {
      var addons = sendData[value].addons;
      if (addons.length > 0) {
        let addon = addons.filter(a => a.addon_cat_id == id);
        minValue = addon[0].minimum || 0
      }
    }
    return minValue
  }
  const getMaxValue = (id) => {
    var maxValue = 0;
    if (sendData.length > 0) {
      var addons = sendData[value].addons;
      if (addons.length > 0) {
        let addon = addons.filter(a => a.addon_cat_id == id);
        maxValue = addon[0].maximum || 0
      }
    }
    return maxValue
  }
  const getCheckedValue = (id, addonId) => {
    var isChecked = false;
    if (sendData && sendData[value]) {
      var array = sendData[value].addons.filter((e) => e.addon_cat_id === id);
      if (array.length > 0) {
        if (array[0].addon_items.length > 0) {
          var addonArray = array[0].addon_items.filter((e) => e === addonId);
          if (addonArray.length > 0) {
            isChecked = true;
          }
        } else {
        }
      } else {
      }
    } else {
    }
    return isChecked
  }
  const setCheckValue = (id, addon_item_id) => {
    setIsError(false)
    let newArray = [...sendData];
    let selectedArray = newArray[value]
    let addons = selectedArray.addons;
    let addonIndex = addons.findIndex(a => a.addon_cat_id == id)
    var ArrayValue = []
    let addon = addons[addonIndex];
    if (addon.addon_items.length > 0) {
      ArrayValue = addon.addon_items;
      var index = ArrayValue.indexOf(addon_item_id)
      if (index !== -1) {
        ArrayValue.splice(index, 1);
      } else {
        ArrayValue.push(addon_item_id)
      }
    } else {
      ArrayValue.push(addon_item_id)
    }
    addons[addonIndex] = { ...addon, addon_items: ArrayValue }
    selectedArray["addons"] = addons;
    newArray[value] = selectedArray;
    setsendData(newArray)
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (catName) => {
    var array = [...expanded]
    if (array.length > 0) {
      var isIndex = array.findIndex((e) => e == catName)
      if (isIndex == -1) {
        array.push(catName)
      } else {
        array.splice(isIndex, 1)
      }
    } else {
      array.push(catName)
    }
    console.log("arrayarray", array)
    setExpanded(array)
  }
  const IsExpandTab = (value) => {
    var isExpandReturn = false;
    if (expanded) {
      var isIndex = expanded.findIndex((e) => e == value)
      if (isIndex == -1) {
      } else {
        isExpandReturn = true
      }
    }
    return isExpandReturn;

  }
  const getErrorValue = (catId, isMin) => {
    var errorVal = '';
    if (sendData.length > 0) {
      var addons = sendData[value].addons;
      if (addons.length > 0) {
        let addon = addons.filter(a => a.addon_cat_id == catId);
        if (isMin) {
          errorVal = addon[0].minError;
        } else {
          errorVal = addon[0].maxError;
        }

      }
    }

    return errorVal;
  }
  return (
    < RightDrawer open={open} padding={1} >
      <>
        <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
          <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto' }}>
            <Grid data-qaid="assignkitchen_close_grid" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CircleClose data-qaid="assignkitchen_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
            </Grid>
            <Grid data-qaid="assignkitchen_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
              <BackIcon data-qaid="categories_editBack_icon" onClick={() => onClose()} style={{ cursor: 'pointer' }} />
            </Grid>
            <H8 data-qaid="assignlitchen_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.create_addon_item} />
            <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'auto' }}>
              {/* <Grid style={{ display: 'flex', overflowY: 'auto' }}> */}
              {/* <Paper style={{ marginLeft: 56 }} > */}
              <Tabs
                style={{ marginLeft: 56, borderBottom: '1px solid #EEEEEE' }}
                variant='scrollable'
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
              >
                {servings && servings.length > 0 ?
                  servings.map(function (item, index) {
                    return (
                      <Tab key={index} label={<AvenirBlackH4 label={item.name} />} value={index} >
                      </Tab>
                    )
                  }) : null}
              </Tabs>
              {/* </Paper> */}
              {/* </Grid> */}
              <Grid style={{ display: 'flex', flexDirection: 'column', marginLeft: 56, marginRight: 56, position: 'sticky' }}>
                {addons ?
                  addons.map((item, index3) => (
                    <Accordion key={index3} expanded={IsExpandTab(item.addon_cat_name)} onChange={() => { handleChange(item.addon_cat_name) }} style={{ maxWidth: 500, marginTop: 10, marginBottom: 10 }}>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        expandIcon={<DownVector />}
                        id="panel1d-header"
                        style={{ borderBottom: '1px solid #EEEEEE' }}>
                        <Grid container style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <Grid style={{ display: 'flex', width: '40%', alignItems: 'center' }}>
                            <AvenirBlackH4 label={item.addon_cat_name} />
                          </Grid>
                          <Grid style={{ display: 'flex', width: '10%', flexDirection: 'row', alignItems: 'center' }}>
                            <AvenirBlackH4 label={Context.langData.min} />
                          </Grid>
                          <Grid style={{ display: 'flex', width: '10%', flexDirection: 'row', marginRight: '8%' }}>
                            <InputText
                              value={getMinValue(item.addon_cat_id)}
                              type={"text"}
                              isPlaceHolder={false}
                              placeholder={0}
                              error={getErrorValue(item.addon_cat_id, true)}
                              onChange={(val) => { setMinValue(val.target.value, item.addon_cat_id) }}
                              id={`min${item.addon_cat_id}`}
                              style={{ borderColor: '#ff0000' }} />
                          </Grid>
                          <Grid style={{ display: 'flex', width: '10%', flexDirection: 'row', alignItems: 'center' }}>
                            <AvenirBlackH4 label={Context.langData.max} />
                          </Grid>
                          <Grid style={{ display: 'flex', width: '10%', flexDirection: 'row' }}>
                            <InputText
                              value={getMaxValue(item.addon_cat_id)}
                              onChange={(val) => { setMaxValue(val.target.value, item.addon_cat_id) }}
                              type={"text"}
                              isPlaceHolder={false}
                              placeholder={0}
                              error={getErrorValue(item.addon_cat_id, false)}
                              id={`max${item.addon_cat_id}`}
                            />
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid style={{ width: 700, display: 'flex', flexFlow: 'wrap' }}>
                          {item.addons ?
                            item.addons.map(function (addonItem, index2) {
                              return (
                                <Grid key={index2} style={{ width: '28%', flexDirection: 'row', display: 'flex', alignItems: 'center', marginRight: 20 }} >
                                  <Checkbox
                                    id={addonItem.addon_item_name}
                                    checked={getCheckedValue(item.addon_cat_id, addonItem.addon_item_id)}
                                    onChange={() => setCheckValue(item.addon_cat_id, addonItem.addon_item_id)}
                                    style={{ color: 'black' }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                  <H4 label={addonItem.addon_item_name} style={{ display: 'flex', alignItems: 'center' }} />
                                </Grid>
                              )
                            }) : null
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )) :
                  null}
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Grid data-qaid="assignkitchen_action_grid" container style={{ backgroundColor: '#FAFAFA', border: '1px solid #C3C3C3', marginTop: 20 }}>
              <Grid style={{ display: 'flex', marginLeft: 56 }}>
                {isError ?
                  <H4 label={Context.langData.addons_error} style={{ color: '#FF0000' }} />
                  : null
                }
              </Grid>
              <Grid data-qaid="assignkitchen_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                <Button className={style.buttonClass} onClick={onClose} variant="contained" style={{ backgroundColor: '#00B6C9', marginRight: 16 }} >
                  <AvenirBlackH4 data-qaid="createkitchen_cancel_text" label={Context.langData.cancel} />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid="assignkitchen_save_btn"
                  variant="contained"
                  style={{ backgroundColor: '#FFB600', marginRight: 32 }}
                  onClick={() => save()}
                >
                  <AvenirBlackH4 data-qaid="assignkitchen_save_text" label={Context.langData.save} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer >
  )
}
const SetPostionItem = ({ open, callback, onClose, itemsList }) => {
  const dispatch = useDispatch();
  const CloseCallback = (value, data) => {
    console.log("value", value, "data", data)
    if (value) {
      if (data.length > 0) {
        var array = []
        data.map((item, index) => {
          var obj = []
          obj.push(item.item_id);
          obj.push(index);
          array.push(obj)
        })
        dispatch(createOrderTypesPostion(array, callback))
      }
    } else {
      onClose()
    }
  }
  return (
    < RightDrawer open={open} style={{ width: 500 }}>
      <Postion data={itemsList} callBack={CloseCallback}></Postion>
    </RightDrawer>
  )
}

export default Items;
