import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import SimpleTable from '../../../components/SimpleTable';
import moment from 'moment-timezone';
import Controls from '../Controls';
import _ from 'lodash';
import Hawkeye from './Hawkeye';
import { H2, H3 } from '../../../utils/text';
import { ConvertPrice } from '../../../utils/util';
import { downloadTransactions } from '../../../utils/excel';
import {
  transactionrefund,
  transactionrefundDetails,
} from '../../../redux/actions';
import MyContext from '../../../Provider/MyContext';
import SnackBar from '../../../components/SnackBar';
import YellowCircle from '../../../components/SVG/YellowCircle';
import RedCircle from '../../../components/SVG/RedCircle';
import GreenCircle from '../../../components/SVG/GreenCircle';
import BillDetails from './BillDetails';
import RefundModal from './RefundModal';
import ReasonForRefundModal from './ReasonForRefundModal';
import GiftCardRefundModal from './GiftCardRefundModal';
import GiftCardAddCustomerModal from './GiftCardAddCustomerModal';
import ConfirmRefundModal from './ConfirmRefundModal';
const AllTransactions = () => {
  const Context = useContext(MyContext);
  const [showHawkeye, toggleHawkeye] = useState(false);
  const [columns, setColumns] = useState([
    { field: 'hawkeyeIcon', display: '', size: '3%', removeIcon: true },
    { field: 'bill_no', display: Context.langData.order_number, size: '17%' },
    { field: 'ordertype', display: Context.langData.order_type, size: '20%' },
    {
      field: 'channel_order_no',
      display: Context.langData.channel_order_no,
      size: '20%',
    },
    { field: 'time', display: Context.langData.date, size: '20%' },
    {
      field: 'final_price_tag',
      display: Context.langData.bill_total,
      align: 'right',
      size: '20%',
      isNumber: true,
    },
  ]);
  const { ordertypes } = useSelector((state) => state.metaReducer);
  const transactions = useSelector((state) => state.transactions.transactions);
  const refundObj = useSelector((state) => state.transactions.refundObj);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const dispatch = useDispatch();
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const tz = selectedRestaurant ? selectedRestaurant.tz : '';
  const [colorCodes, setColorCodes] = useState([0, 0, 0]);
  const [trans, setTrans] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filterCode, setFilterCode] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedOrderType, setOrderType] = useState('');
  const [ishawkeye, setIshawkeye] = useState(false);
  const [showBillDetails, setShowBillDetails] = useState(false);
  const [row, setRow] = useState({});
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openReasonRefundModal, setOpenReasonRefundModal] = useState(false);
  const [openGiftCardRefundModal, setOpenGiftCardRefundModal] = useState(false);
  const [opneGiftCardCustomer, setOpenGiftCardCustomer] = useState(false);
  const [openConfirmationrefund, setOpenConfirmationrefund] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [pagenumber, setPagenumber] = useState(0);
  const [isEnableRefundButton, setIsEnableRefundButton] = useState(false);
  const [refundResponse, setRefundResponse] = useState([]);
  const [orderBy, setOrderBy] = useState(true);
  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    if (selectedOrderType == 'All' || selectedOrderType == '')
      if (trans) {
        if (trans.length > 0) {
          if (filterCode != null) {
            const filteredData = trans.filter((t) => t.code == filterCode);
            setFiltered(filteredData);
          } else {
            setFiltered(trans);
          }
        } else {
          setFiltered(trans);
        }
      }
  }, [trans, filterCode]);
  const handleText = (num) => {
    const _data = [...trans];
    if (num != '') {
      let filterText = _data.filter((e) => e.bill_no == num);
      if (filterText.length > 0) {
        setFiltered(filterText);
      } else {
        setFiltered([]);
      }
    } else {
      setFiltered(_data);
    }
    // const searchFilter = transactions?.transactions.filter(e => e.otype_id?.toString().indexOf(num) != -1)
    // if (searchFilter) {
    //   const _data = searchFilter.map((item) => {
    //     console.log(item, 'itemitemitem')
    //     // const order_Type = _.find(ordertypes, (o) => o.otype_id == item.otype_id);
    //     // let otype_name = ""
    //     // if (order_Type) {
    //     //   otype_name = order_Type.otype_name;
    //     // }
    //     item['ordertype'] = item.otype_name;
    //     item['time'] = moment.unix(item.ts).tz(tz).format("D MMM, YYYY hh:mm a");
    //     return item;
    //   })
    //   setFiltered(_data);
    // }
  };
  useEffect(() => {
    var ishawakeyPermission = false;
    if (userPermissions.length > 0) {
      var obj = userPermissions.filter(
        (e) => e.key == 'view_hawkeye' && e.status == true
      );
      if (obj.length > 0) {
        ishawakeyPermission = true;
      } else {
      }
      var obj = userPermissions.filter((e) => e.key == 'website_builder');
      if (obj.length > 0) {
        setIsEnableRefundButton(true);
      } else {
      }
    }
    setIshawkeye(ishawakeyPermission);
  }, [userPermissions]);
  useEffect(() => {
    let amount = 0;
    if (filtered)
      if (filtered.length > 0) {
        filtered?.map((f) => {
          if (f.void == 0) amount += parseFloat(f.final_price);
        });
      }
    amount = Math.round(amount * 100) / 100;
    setTotalAmount(amount);
  }, [filtered]);
  useEffect(() => {
    updateTransactionUi(showHawkeye);
  }, [transactions, showHawkeye]);
  useEffect(() => {
    console.log('selectedOrderType', selectedOrderType, trans);
    if (selectedOrderType == 'All' || selectedOrderType == '') {
      setFiltered(trans);
    } else {
      toggleHawkeye(false);
      setFilterCode(null);
      const data = trans.filter((t) => t.ordertype == selectedOrderType);
      setFiltered(data);
    }
  }, [selectedOrderType]);
  const onRowClick = (values) => {
    setRow(values);
    setShowBillDetails(true);
  };
  const filterByCode = (code) => {
    if (filterCode == code) {
      setFilterCode(null);
    } else {
      setFilterCode(code);
    }
  };
  const toggleSwitch = (val) => {
    if (!val) {
      setFilterCode(null);
    } else {
      setOrderType('');
    }
    updateTransactionUi(val);
    toggleHawkeye(val);
  };
  const updateTransactionUi = (isHawakey) => {
    let codes = [0, 0, 0];
    let color = null;
    if (transactions?.transactions?.length > 0) {
      const rows = transactions?.transactions?.map((t) => {
        // const ordertype = _.find(ordertypes, (o) => o.otype_id == t.otype_id);
        // let otype_name = ""
        // if (ordertype) {
        //   otype_name = ordertype.otype_name;
        // }
        let payment_names = '';
        if (t.payment_mode) {
          payment_names = t.payment_mode.join(', ');
        }
        if (t.edited == 0 && t.void == 0) {
          color = 0;
        } else if (t.edited == 1 && t.void == 0) {
          color = 1;
        } else if (t.void == 1) {
          color = 2;
        }
        const classname = 'hawkeye color_' + color;
        const hawkeye = <span className={classname}></span>;
        const finalPrice = Math.round(t.final_price * 100) / 100;
        const tipAmount = t?.tip_amount != null ? t?.tip_amount : 0;
        const voidType =
          t.void_type == 'void_payment' ? ' (closed)' : ' (void)';
        return {
          empty: <span className="hawkeye"></span>,
          hawkeye: hawkeye,
          code: color,
          bill_no: t.bill_no,
          hawkeyeIcon: (
            <>
              {isHawakey && (
                <span style={{ paddingRight: 20 }}>
                  {(t.edited == 0) & (t.void == 0) ? (
                    <GreenCircle />
                  ) : t.edited == 1 && t.void == 0 ? (
                    <RedCircle />
                  ) : (
                    <YellowCircle />
                  )}
                </span>
              )}
            </>
          ),

          tx_id: t.tx_id,
          time: moment.unix(t.ts).tz(tz).format('hh:mm a MMM D, YYYY'),
          ts: t.ts,
          ordertype: t.otype_name,
          order_channel_name: t.order_channel_name,
          otype_id: t.otype_id,
          //channel_order_no: t.order_channel_name == 'Kiosk' ? t.kiosk : t.order_channel_name == 'Third Party' && t.partner_id == t.integrated_partner_id ? t.external_order_id : t.marketplace_order_number,
          channel_order_no: t.channel_order_number,
          payment: payment_names,
          otype_id: t.otype_id,
          server_name: t.server_name,
          split_mode: t.split_mode || 'NA',
          final_price: ConvertPrice(finalPrice),
          final_price_tag:
            t.void == 1 ? (
              <div>
                <span style={{ textDecoration: 'line-through' }}>
                  ${ConvertPrice(finalPrice)}
                </span>
                <span>{voidType}</span>
              </div>
            ) : (
              ConvertPrice(finalPrice)
            ),
          // priceInt: finalPrice + tipAmount,
          void: t.void,
        };
      });
      setColorCodes(codes);
      setTrans(rows);
    } else {
      setTrans([]);
    }
  };
  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    if (value == 'time') {
      value = 'ts';
    }
    if (value == 'final_price_tag') {
      value = 'final_price';
    }
    var transactionSales =
      value == 'final_price'
        ? _.orderBy(
            filtered,
            [(d) => parseFloat(d[value])],
            [sortValue ? 'asc' : 'desc']
          )
        : _.orderBy(filtered, [value], [sortValue ? 'asc' : 'desc']);
    setFiltered(transactionSales);
  };
  const downloadAllTransactions = () => {
    downloadTransactions(filtered);
  };
  const handlerefundCallback = () => {
    setOpenRefundModal(true);
    setShowBillDetails(false);
  };
  const handleCloseRefundModal = (val) => {
    setOpenRefundModal(false);
    if (val) {
    } else {
      setShowBillDetails(true);
    }
  };
  const handleContinuerefund = (obj, extra) => {
    if (obj) {
      var itemData = [];
      if (obj.items.length > 0) {
        obj.items.map((itemObj) => {
          if (itemObj.amount != 0) {
            var obj = {
              item_id: itemObj.itemId,
              refund_amount: parseFloat(itemObj.amount),
              refund_percentage: itemObj.percentage,
            };
            itemData.push(obj);
          }
        });
      }
      var refund = {
        tx_id: obj.tx_id,
        order_payment_id: obj.order_payment_id,
        refund_type: obj.refund_type,
        total_refund_amount: parseFloat(obj.refund_amount),
        total_refund_percentage: obj.refund_percentage,
        items: itemData,
      };
      dispatch(
        transactionrefundDetails(refund, (status, response) => {
          refundDetailsCallback(status, response, obj, extra);
        })
      );
    }
  };
  const refundDetailsCallback = (status, response, object, extra) => {
    if (status) {
      setOpenRefundModal(false);
      setShowBillDetails(false);
      var isGiftCard = false;
      if (response?.refunds.length > 0) {
        response?.refunds.map((item) => {
          var obj = object?.payments?.find((i) => i.ptype_id == item.ptype_id);
          item['ptype'] = obj?.ptype?.toLowerCase();
          console.log('obj?.ptype?.toLowerCase()', obj?.ptype?.toLowerCase());
          if (obj?.ptype?.toLowerCase() == 'gift') {
            isGiftCard = true;
          }
        });
        var obj = { ...object, refunds_response: response.refunds };
        console.log('refundObj extra', extra);
        if (extra?.name !== 1) {
          obj['items'] = extra?.items || [];
        }
        console.log('refundObj on callback', obj);
        dispatch({ type: 'SET_TRANSACTION_REFUND', payload: obj });
      }
      if (isGiftCard) {
        setOpenGiftCardRefundModal(true);
      } else {
        setOpenReasonRefundModal(true);
      }
    } else {
      setSnackbar({
        message: Context.langData.refund_failed_message,
        severity: 'error',
      });
    }
  };
  const handleBackFromReasonrefund = (value) => {
    setOpenReasonRefundModal(false);
    if (value) {
    } else {
      setOpenRefundModal(true);
    }
  };
  const handleContinueReasonrefund = () => {
    setOpenReasonRefundModal(false);
    handleSaverefund();
  };
  const handleCloseGriftCard = (value) => {
    setOpenGiftCardRefundModal(false);
    if (value) {
    } else {
      setOpenRefundModal(true);
    }
  };
  const handleContinueGiftCardrefund = (value) => {
    setOpenGiftCardRefundModal(false);
    if (value) {
      setOpenReasonRefundModal(true);
    } else {
      setOpenGiftCardCustomer(true);
    }
  };
  const handleContinueGiftCardCustome = () => {
    setOpenGiftCardCustomer(false);
    handleSaverefund();
  };
  const handleCancelGiftCardCustomer = (val) => {
    setOpenGiftCardCustomer(false);
    if (val) {
    } else {
      setOpenGiftCardRefundModal(true);
    }
  };
  const handleSaverefund = () => {
    if (refundObj) {
      var itemData = [];
      if (refundObj.items.length > 0) {
        refundObj.items.map((itemObj) => {
          if (itemObj.amount != 0) {
            var obj = {
              item_id: itemObj.itemId,
              refund_amount: parseFloat(itemObj.amount),
              refund_percentage: itemObj.percentage,
            };
            itemData.push(obj);
          }
        });
      }
      var refundsData = [];
      if (refundObj.refunds_response.length > 0) {
        refundObj.refunds_response.map((itemObj) => {
          var obj = {
            order_payment_detail_id: itemObj.order_payment_detail_id,
            ptype_id: itemObj.ptype_id,
            amount: itemObj.amount,
            payment_trx_number: null,
            masked_number: null,
            activate_gift_card:
              itemObj.ptype == 'gift' && refundObj.is_gift_card ? true : false,
          };
          refundsData.push(obj);
        });
      }
      var refund = {
        tx_id: refundObj.tx_id,
        order_payment_id: refundObj.order_payment_id,
        refund_type: refundObj.refund_type,
        total_refund_amount: parseFloat(refundObj.refund_amount),
        total_refund_percentage: refundObj.refund_percentage,
        refund_comment: refundObj.refund_comment || 'refund_comment',
        is_gift_card: refundObj.is_gift_card ? 1 : 0,
        modify_txn_number: null,
        gift_comment: refundObj.gift_comment,
        customer_id: refundObj.customer_id || null,
        items: itemData,
        is_refunded: 0,
        refunds: refundsData,
      };
      dispatch(transactionrefund(refund, refundSuccessCallback));
    }
  };
  const refundSuccessCallback = (value, response) => {
    if (value) {
      setRefundResponse(response);
      setOpenConfirmationrefund(true);
    } else {
      setSnackbar({
        message: Context.langData.refund_failed_message,
        severity: 'error',
      });
    }
  };
  const handleCancelrefund = (value) => {
    setOpenConfirmationrefund(false);
    if (value) {
    } else {
    }
  };
  const handleConfirmCallback = () => {
    setOpenConfirmationrefund(false);
  };

  const onPageinationChange = (value) => {
    setPagenumber(value);
  };
  let filterCount = 0;
  if (filterCode == 0) {
    filterCount = transactions?.hawkeye?.total_non_edit_count;
  } else if (filterCode == 1) {
    filterCount = transactions?.hawkeye?.total_edit_count;
  } else {
    filterCount = transactions?.hawkeye?.total_void_count;
  }
  const transactionCount =
    filterCode == null ? transactions.total_count : filterCount;
  return (
    <Grid data-qaid="alltrancation_main_grid" container spacing={2}>
      <Grid
        data-qaid="alltrancation_controls_grid"
        item
        xs={12}
        style={{ marginTop: 10 }}>
        <Controls
          data-qaid="alltrancation_controls"
          showTransactions={true}
          showPayments={false}
          showOrderTypes={true}
          showEmployeeFilter={true}
          onDownload={downloadAllTransactions}
          isTransaction={true}
          tz={tz}
          handleText={(e) => handleText(e)}
          limit={25}
          offsetValue={pagenumber}
          total={transactions.total_count}
          filterCode={showHawkeye ? filterCode : null}
          orderBy={orderBy}
          sortBy={sortBy}
        />
      </Grid>
      <Grid
        data-qaid="alltrancation_head_grid"
        container
        style={{ marginTop: 10, marginBottom: 20, marginLeft: 36 }}>
        <Grid
          data-qaid="alltrancation_child_grid"
          container
          justify="flex-start"
          style={{ display: 'flex', marginTop: 20 }}>
          <Grid
            data-qaid="alltrancation_child1_grid"
            item
            xs={12}
            sm={12}
            md={12}
            lg={8}
            style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid
              data-qaid="alltrancation_child_grid2"
              container
              style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid style={{ width: 160 }}>
                {ishawkeye ? (
                  <Hawkeye
                    width={160}
                    data-qaid="alltrancation_hawkeys"
                    codes={transactions.hawkeye}
                    showHawkeye={showHawkeye}
                    onChange={(val) => toggleSwitch(val)}
                    filterByCode={filterByCode}
                    filterCode={filterCode}
                  />
                ) : null}
              </Grid>
              <Grid data-qaid="alltrancation_footer_grid">
                <Grid
                  data-qaid="alltrancation_total_amount"
                  container
                  direction="column"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <H2
                    data-qaid="alltrancation_amount_label"
                    label={'$' + ConvertPrice(transactions.total_amount)}
                  />
                  <H3
                    data-qaid="alltrancation_collection_label"
                    label={Context.langData.total_collected}
                    style={{ color: '#54575A' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        data-qaid="alltrancation_tabel_grid"
        item
        xs={12}
        lg={12}
        md={12}
        style={{ margin: 20 }}>
        <SimpleTable
          data-qaid="alltrancation_simpleTable"
          columns={columns}
          rows={filtered}
          sortingOnClick={sortingOnClick}
          onRowClick={onRowClick}
          onPageChange={onPageinationChange}
          total={transactionCount}
        />
      </Grid>
      {showBillDetails && (
        <BillDetails
          data-qaid="transactions_billdetails_modal"
          open={showBillDetails}
          row={row}
          isEnableRefund={isEnableRefundButton}
          callback={() => handlerefundCallback()}
          onClose={() => setShowBillDetails(false)}
        />
      )}
      {openRefundModal && (
        <RefundModal
          data-qaid="transactions_RefundModal"
          open={openRefundModal}
          callback={handleContinuerefund}
          onClose={(val) => handleCloseRefundModal(val)}
        />
      )}
      {openReasonRefundModal && (
        <ReasonForRefundModal
          data-qaid="transactions_RefundModal"
          open={openReasonRefundModal}
          callback={() => handleContinueReasonrefund()}
          onClose={(val) => handleBackFromReasonrefund(val)}
        />
      )}
      {openGiftCardRefundModal && (
        <GiftCardRefundModal
          data-qaid="transactions_giftcardRefundModal"
          open={openGiftCardRefundModal}
          callback={(val) => handleContinueGiftCardrefund(val)}
          onClose={(val) => handleCloseGriftCard(val)}
        />
      )}
      {opneGiftCardCustomer && (
        <GiftCardAddCustomerModal
          data-qaid="transactions_giftcardRefundModal"
          open={opneGiftCardCustomer}
          callback={() => handleContinueGiftCardCustome()}
          onClose={(val) => handleCancelGiftCardCustomer(val)}
        />
      )}
      {openConfirmationrefund && (
        <ConfirmRefundModal
          data-qaid="transactions_confirmationrefund"
          open={openConfirmationrefund}
          responseData={refundResponse}
          callback={() => handleConfirmCallback()}
          onClose={(val) => handleCancelrefund(val)}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid="transactions_refund_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </Grid>
  );
};

export default AllTransactions;
