import React from 'react';
import Button from '@material-ui/core/Button';

import { H4, T, TBold, AvenirBlackH4 } from '../../../../utils/text'
import CenterModal from '../../../../components/CenterModal';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InfoOutlined from '../../../../components/SVG/InfoOutlined'

const ConfirmToDisconnect = ({ open, onClose }) => {
  return (
    <CenterModal
      open={open}
      borderRadius={5}
      onClose={() => onClose(false)}
      padding={20}
    >

      <Grid container style={{ padding: 32, width: 400, borderRadius: 5 }}>
        <Grid item xs={12}>
          <TBold label="Disconnect terminal" style={{ fontWeight: 500, fontSize: 18 }} />
        </Grid>
        <Grid item xs={14} style={{ marginTop: 20, marginBottom: 40 }}>
          <Grid container direction="row" alignItems='center'>
            {/* <InfoOutlined width={14} height={14} /> */}
            <T label="Payment will no longer be accepted from this terminal. Are you sure you want to disconnect?" style={{ marginLeft: 5, marginTop: 4 }} />
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container justify="center" style={{ padding: '0px 0px', paddingRight: 20 }}>
                <Button
                  onClick={() => onClose(false)}
                  variant="contained" style={{ height: 44, width: '100%', border: '2px solid #808285', backgroundColor: 'white' }}>
                  <AvenirBlackH4 label="Cancel" />
                </Button>
              </Grid>

            </Grid>
            <Grid item xs={6}>
              <Grid container justify="center" style={{ padding: '0px 0px', paddingLeft: 40 }}>
                <Button
                  onClick={() => onClose(true)}
                  variant="contained" style={{ width: '100%', backgroundColor: '#FFB600', height: 44 }}>
                  <AvenirBlackH4 label="Disconnect" />

                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </CenterModal>
  )
}


export default ConfirmToDisconnect