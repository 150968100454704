import React, { useState, useEffect, useContext } from 'react';
import SimpleTable from '../../../components/SimpleTable';
import { Grid, Button, MenuItem, Menu } from '@material-ui/core';
import { fetchEmployeeSalesReport } from '../../../redux/actions';
import _ from 'lodash';
import Daterange from '../../../components/DateRange';
import { AvenirBlackH4, TBold, T } from '../../../utils/text';
import MyContext from '../../../Provider/MyContext';
import CircleClose from '../../../components/SVG/CircleClose';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import TopSearchBar from '../../../components/SearchBar';
import moment from 'moment';
import { downloadEmpSales } from '../../../utils/excel';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPdfDocument } from '../../../components/PDFTableGenerator';

const EmployeeSales = () => {
  const EmployeeSalesData = useSelector(
    (state) => state.salesReportReducer.employeeSales
  );
  const dispatch = useDispatch();
  const [table1, setTable1] = useState([]);
  const Context = useContext(MyContext);
  const [date, setDate] = useState({startDate: moment().startOf('day').format("YYYY-MM-DD"), endDate:moment().endOf('day').format("YYYY-MM-DD")});
  const [ordertype, setOrderType] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [resetDate, toggleResetDate] = useState(false);
  const [addonDetailsColoumns, setAddonDetailsColoumns] = useState([
    { field: 'employee_name', display: Context.langData.emp_name, size: '30%' },
    {
      field: 'employee_sales',
      display: Context.langData.total_sales,
      size: '20%',
      isNumber: true,
    },
    {
      field: 'employee_tips',
      display: Context.langData.tips_collected,
      size: '25%',
      isNumber: true,
    },
    {
      field: 'employee_hours',
      display: Context.langData.hours_worked,
      size: '25%',
    },
  ]);

  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };
  useEffect(() => {
    handleApplyDropdown();
  }, [date, ordertype]);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    if (EmployeeSalesData?.length > 0) {
      let data = EmployeeSalesData.map((item) => {
        item['employee_hours'] = item.employee_hours || 0;
        return item;
      });
      if (searchText) {
        data = data.filter((e) =>
          e.employee_name.toLowerCase().includes(searchText.toLowerCase())
        );
      }
      setTable1(data);
    } else {
      setTable1([]);
    }
  }, [EmployeeSalesData, searchText]);

  const handleApplyDropdown = () => {
    let obj = {
      from_date: date.startDate,
      to_date: date.endDate
    };
    if (ordertype.length > 0) {
      obj = {
        ...obj,
        order_types: ordertype.join(','),
      };
    }
    dispatch(fetchEmployeeSalesReport(obj));
  };

  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...addonDetailsColoumns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setAddonDetailsColoumns(columnData);
    var sortDaywiseSales = _.orderBy(
      table1,
      [value],
      [sortValue ? 'desc' : 'asc']
    );
    setTable1(sortDaywiseSales);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const downloadExcel = () => {
    downloadEmpSales(table1);
    handleCloseMenu();
  };
  const downloadPDF = () => {
    const fileName = 'EmployeeSales';
    downloadPdfDocument(
      addonDetailsColoumns,
      table1,
      Context.langData.employee_sales,
      date,
      fileName
    );
    handleCloseMenu();
  };
  const onClear = () => {
    setSearchText('');
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  return (
    <Grid
      data-qaid="addonDetails_mainContainer"
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid container>
        <Grid
          item
          lg={9}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Grid data-qaid="addonDetails_dateGrid" item lg={4} style={{}}>
            <AvenirBlackH4
              data-qaid="addonDetails_dateLabel"
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid="addonDetails_dateRange"
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
          <Grid
            data-qaid="addonDetails_selectOrderTypeLabelView"
            lg={6}
            item
            style={{ paddingLeft: 20, marginTop: 23 }}>
            <TopSearchBar
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={'Search for an employee'}
              style={{ height: 58 }}
              width={'100%'}
            />
          </Grid>
        </Grid>
        <Grid
          item
          lg={3}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}>
          <Grid
            data-qaid="addonDetails_setOrderTypeGrid"
            onClick={onClear}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 20,
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <CircleClose data-qaid="addonDetails_closeIcon"></CircleClose>
            <AvenirBlackH4
              data-qaid="addonDetails_clearLabel"
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid="addonDetails_downloadButtonView"
            style={{
              backgroundColor: '#FFB600',
              height: 40,
              marginRight: 0,
              width: '149px',
            }}
            variant="contained"
            id="cancel">
            <DownlodeIcon
              data-qaid="addonDetails_downlodeIcon"
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid="addonDetails_downlodeLabel"
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id="simple-menu-download"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid
        data-qaid="addonDetails_simpleTableGrid"
        item
        lg={12}
        style={{ marginTop: 40 }}>
        {table1.length > 0 ? (
          <SimpleTable
            data-qaid="addonDetails_simpleTable"
            columns={addonDetailsColoumns}
            rows={table1}
            isEditArrow={false}
            isOnClick={false}
            sortingOnClick={sortingOnClick}
          />
        ) : (
          <Grid
            item
            lg={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: 20,
            }}>
            <TBold
              label={'No data available yet'}
              style={{ fontSize: 32, color: '#051D33' }}
            />
            <T
              style={{ fontSize: 18, color: '#051D33' }}
              label={
                'When sales are made by employees, their information will appear here.'
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EmployeeSales;
