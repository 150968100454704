import * as React from 'react';

function CircleIcon({ color = '#fff', width = 16, height = 17, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <rect y={0.5} width={16} height={16} rx={8} fill={color} />
    </svg>
  );
}

export default CircleIcon;
