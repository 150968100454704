import * as React from "react"

function CircleClose({ width = 24, height = 25, color = '#004C60', ...props }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22.565c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5zm12-10.5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.2 6.265a.684.684 0 01.967 0L12 11.098l4.833-4.833a.683.683 0 11.967.967l-4.833 4.833 4.833 4.833a.684.684 0 01-.967.967L12 13.032l-4.833 4.833a.683.683 0 11-.967-.967l4.833-4.833L6.2 7.232a.684.684 0 010-.967z"
                fill={color}
            />
        </svg>
    )
}

export default CircleClose
