import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Button, Grid, Card, CardContent, CardActions, IconButton, Typography, InputLabel, Select, MenuItem
} from '@material-ui/core';
import DataTable from '../../../../components/DataTable';
import styles from '../../../../styles/index';
import RightDrawer from '../../../../components/RightDrawer'
import InputText from '../../../../components/InputText';
import {
    EditPrinter,
    createPrinter,
    fetchPrinters,
    deletePrinters,
    fetchAssignKitchenPrinter,
    createAssignKitchen
} from '../../../../redux/actions';
import AlertDialog from '../../../../components/Dialog';
import SingleSelect from '../../../../components/SingleSelect';
import ContextMenu from '../../../../components/ContextMenu';
import MyContext from '../../../../Provider/MyContext';
import { H4, H2, H3, AvenirBlackH4, H8, H5, DotText, DotTextBoldNormal, AvenirBlackH3 } from '../../../../utils/text';
import CenterModal from '../../../../components/CenterModal';
import DeleteIcon from '../../../../components/SVG/Delete';
import CirclePluse from '../../../../components/SVG/PluseIcon';
import _ from 'lodash';
import $ from 'jquery';
import SnackBar from '../../../../components/SnackBar';
import SettingIcon from '../../../../components/SVG/Settings';
import CircleClose from '../../../../components/SVG/CloseCircle';
import UploadIcon from '../../../../components/SVG/UploadIcon';
import NoData from '../../../../components/NoData';
import MaskedInput from 'react-text-mask';
import ExclamationIcon from '../../../../components/SVG/ExclamationIcon';
import InfoOutlinedIcon from '../../../../components/SVG/InfoOutlined';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import { validateAlphaNumeric } from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));
const PrintersList = () => {
    const style = styles();
    const classes = useStyles();
    const Context = useContext(MyContext);
    const [columns, setColumns] = useState(
        [
            { field: 'name', headerName: Context.langData.printer_name, size: '50%', sort: false },
            { field: 'ip_address', headerName: Context.langData.ip_address, size: '50%', sort: false },

        ]
    )
    const ContextMenuData = [
        {
            id: 1,
            icon: UploadIcon,
            name: Context.langData.assign_Printer
        },
        {
            id: 2,
            icon: DeleteIcon,
            name: Context.langData.delete
        }
    ]
    const PrintersData = useSelector(state => state.settingsReducer.printers);
    console.log(PrintersData, 'PrintersDatalskfjd')
    const Kitchens = useSelector(state => state.metaReducer.kitchenName);
    const [selectedId, setSelectedId] = React.useState([]);
    const [btnDisable, setBtnDisable] = React.useState(true);
    const [openCreate, toggleCreateDrawer] = useState(false);
    const [openEdit, toggleEditDrawer] = useState(false);
    const [openAssign, toggleAssignDrawer] = useState(false);
    const [row, setRow] = useState({});
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [heading, setHeading] = useState(Context.langData.delete_printer);
    // const [bodyMessage, setBodyMessage] = useState(Context.langData.printer_body_delete);
    const [snackbar, setSnackbar] = useState(null);
    const [Printers, setPrinters] = useState([])
    const [data, setData] = useState([]);
    const [err, setErr] = useState(false);
    const [msg, setMsg] = useState({});


    const bodyMessage = err ? (selectedId.length > 1 ?
        'Are you sure you want to delete ' + selectedId.length + ' printers?'
        :
        Context.langData.printer_body_delete)
        :
        `Printer ${msg.printer_name} is currently assigned to Kitchen ${msg.kitchen_name}. If you delete this printer, it will be unassigned.`

    useEffect(() => {
        const filterPrinterData = _.reverse(PrintersData)
        filterPrinterData.map((item) => {
            item['is_active'] = item.is_active ? 'Active' : "Inactive"
        })

        setPrinters(filterPrinterData)
    }, [PrintersData])

    useEffect(() => {
        dispatch(fetchAssignKitchenPrinter({}, thiscallback));
    }, [])
    function thiscallback(response) {
        setData(response.data)
    }

    function onCheckedIds(ids) {
        setSelectedId(ids);
        console.log("ids on checked", JSON.stringify(ids));
        if (ids.length > 0) {
            setBtnDisable(false);
        } else {
            setBtnDisable(true);
        }
        const data1 = data.find(e => e.printer_id == ids)
        if (data1 == undefined) {
            setErr(true)
        } else {
            setErr(false)
            setMsg(data1)
        }
    }
    function onClickAction(rowValue) {
        setRow(rowValue)
        toggleEditDrawer(true);
        console.log("Action clicked row56756Î", JSON.stringify(rowValue));
    }
    const btnProps = {};
    if (btnDisable) {
        btnProps["disabled"] = true
    }
    function callback() {
        toggleCreateDrawer(false);
        toggleEditDrawer(false);
        toggleAssignDrawer(false);
        dispatch(fetchPrinters());
    }
    function createCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_create_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_create_failed, severity: 'error' });
        }
        callback();

    } function editCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_edit_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_edit_failed, severity: 'error' });
        }
        callback();
    }
    function deleteCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_delete_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_delete_failed, severity: 'error' });
        }
        callback();
    }
    function assignPrinterCallback(isSuccess) {
        if (isSuccess) {
            setSnackbar({ message: Context.langData.printer_assign_succ, severity: 'success' });
        }
        else {
            setSnackbar({ message: Context.langData.printer_assign_failed, severity: 'error' });
        }
        callback();
    }
    const handleClose = (val) => {
        if (val) {
            console.log("error called", JSON.stringify(selectedId));
            for (const i of selectedId) {
                dispatch(deletePrinters(JSON.stringify(selectedId), deleteCallback));
            }
        }
        setShowDialog(false);
    };

    function deletePrinter(value) {
        setShowDialog(value)
    }
    const onPageinationChange = (value) => {
        console.log("on page change")
    }
    const callBack = (id) => {
        if (id == 1) {
            toggleAssignDrawer(true)
        } else if (id == 2) {
            if (selectedId.length > 0) {
                deletePrinter(true)
            }
        }
    }

    const handleDeletePrinter = () => {
        if (selectedId.length > 0) {
            deletePrinter(true)
        }
    }

    const sortingOnClick = (value, sortValue, index) => {
        var columnData = [...columns]
        columnData[index] = { ...columnData[index], sort: !sortValue }
        setColumns(columnData)
        var sortComments = _.orderBy(Printers, [value], [sortValue ? 'asc' : 'desc']);
        setPrinters(sortComments)
    }

    return (
        <div data-qaid="printerlist_div" style={{ marginTop: '2%' }}>
            <>
                {
                    Printers.length > 0 ?
                        <>

                            <Grid data-qaid="printerlist_grid" item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginRight: 20, marginBottom: 10 }} >
                                {/* <Button className={style.buttonClass} data-qaid="otype_delete_button" style={{ color: "white", marginRight: 10 }} variant="text" id="cancel" onClick={handleDeletePrinter} >
                                    <DeleteIcon data-qaid="otype_deleteicon" width={16} height={16} style={{ marginRight: 5 }} />
                                    <AvenirBlackH4 data-qaid="otype_delete_label" label={Context.langData.delete} />
                                </Button> */}
                                {/* <Button className={style.buttonClass} data-qaid="kitchenList_addbutton" style={{ backgroundColor: "#00B6C9", color: "white", marginRight: 10 }} variant="contained" id="cancel" onClick={() => toggleAssignDrawer(true)}  >
                                    <AvenirBlackH4 data-qaid="kitchenList_createlabel" label={Context.langData.assign_printer} />
                                </Button> */}
                                <Button className={style.buttonClass} data-qaid="printerlist_create+button" style={{ backgroundColor: "#FFB600", color: "white", marginRight: 10 }} variant="contained" id="cancel" onClick={() => toggleCreateDrawer(true)}   >
                                    <CirclePluse data-qaid="printerlist_createicon" />
                                    <AvenirBlackH4 data-qaid="printerlist_createlabel" label={Context.langData.add_printer} style={{ paddingLeft: 5 }} />
                                </Button>
                                <ContextMenu style={{ marginLeft: 10 }} menuItems={ContextMenuData} callBack={callBack} />
                            </Grid>
                            <DataTable data-qaid="printerlist_datatable" columns={columns} rows={Printers} refresh={openCreate || openAssign} onCheckedIds={onCheckedIds} onClickAction={onClickAction} onPageChange={onPageinationChange} sortingOnClick={sortingOnClick} />
                        </>
                        :
                        <NoData page={"printer"} callback={() => toggleCreateDrawer(true)} />
                }
            </>
            {
                openCreate &&
                <CreatePrinter
                    data-qaid="printerlist_createprinter"
                    open={openCreate}
                    onClose={() => toggleCreateDrawer(false)}
                    printers={Printers}
                    callback={createCallback}
                />
            }
            {
                openEdit &&
                <EditPrinterDrawer
                    data-qaid="printerlist_editprinter"
                    open={openEdit}
                    row={row}
                    printers={Printers}
                    onClose={() => toggleEditDrawer(false)}
                    callback={editCallback}
                />
            }
            {
                openAssign &&
                <CreateKitchenPrinterAssign
                    data-qaid="printerlist_assignprinter"
                    open={openAssign}
                    printers={Printers}
                    kitchens={Kitchens}
                    onClose={() => toggleAssignDrawer(false)}
                    callback={assignPrinterCallback}
                />
            }
            {
                snackbar &&
                <SnackBar
                    data-qaid="categories_snackbar"
                    open={true}
                    setOpen={() => setSnackbar(null)}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            }

            <AlertDialog
                data-qaid="printerlist_alertdialogÎ"
                showDialog={showDialog}
                body={bodyMessage}
                heading={heading}
                IsError={false}
                showExtra={err}
                handleClose={handleClose} />
        </div>
    );
};
const CreatePrinter = ({ open, callback, onClose, printers }) => {

    const Context = useContext(MyContext);
    const Permissions = useSelector(state => state.profileReducers.permissions);
    const [name, setName] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const printerModel = [
        { name: "Star TSP100" },
        { name: "Star SP700" },
    ];
    const [pModel, setPmodel] = useState(printerModel[0].name);

    const [error, setError] = useState(null);
    const [ipAddresserror, setIpAddresserror] = useState(null);
    const style = styles();
    const dispatch = useDispatch();
    const [checkError, setCheckError] = useState(false)
    const [checkIt, setCheckIt] = useState([]);
    const checkList = useMemo(() => {
        let list = Permissions?.applications.filter(e => e.application_id != 1)
        return list
    }, [Permissions])
    const handleCheck = (item) => {
        let ch = [...checkIt]
        let index = ch.indexOf(item.application_id)
        if (index !== -1) {
            ch = [...ch.slice(0, index), ...ch.slice(index + 1)]
        } else {
            ch = [...ch, item.application_id]
        }
        setCheckIt(ch)
    }

    const save = () => {
        var errorFlag = 0;
        if (name) {
        } else {
            errorFlag = errorFlag + 1;
            setError(Context.langData.no_printer_error);
        }
        if (ipAddress) {
            const regex = /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/
            const m = regex.test(ipAddress)
            console.log(m);
            if (!m) {
                errorFlag = errorFlag + 1;
                setIpAddresserror(Context.langData.ip_address_error);
            } else {
                setIpAddresserror('');
            }
        } else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(Context.langData.enter_ipaddress_error);
        }
        const elementsIndex = printers.findIndex(indexElement => indexElement.name.toUpperCase() == name.toUpperCase());
        if (elementsIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setError(name + " already exist");
        }

        const elementsipIndex = printers.findIndex(indexElement => indexElement.ip_address == ipAddress);
        console.log("IP ADRESSS", ipAddress, printers)
        if (elementsipIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(ipAddress + " already exist");
        }
        if (errorFlag == 0) {
            dispatch(createPrinter({ ip_address: ipAddress.trim(), name: name.trim(), printer_model: pModel }, callback))
        }
        else {
        }
    }
    const onChange = (val) => {
        setName(validateAlphaNumeric(val))
        if (printers.indexOf(val.toLowerCase()) == -1) {
            setError("")
        }
        else {
            setError(validateAlphaNumeric(val) + " already exist");
        }
    }
    // const onIpChange = (val) => {
    //     setIpAddress(val)
    //     if (printers.indexOf(val.toLowerCase()) == -1) {
    //         setError("")
    //     }
    //     else {
    //         setError(val + " already exist");
    //     }
    // }

    const props = {
        guide: false,
        placeholderChar: '\u2000',
        mask: value => Array(value.length).fill(/[\d.]/),
        pipe: value => {
            if (value === '.' || value.endsWith('..')) return false;

            const parts = value.split('.');
            if (
                parts.length > 4 ||
                parts.some(part => part === '00' || part < 0 || part > 255)
            ) {
                return false;
            }
            return value;
        },
    };

    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto', marginBottom: 20 }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.add_printer} />

                        <Grid container direction="column" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56, width: 500 }} >
                            <Grid data-qaid="createPrinter_name_grid" style={{ display: 'flex', flexDirection: 'column' }} >
                                <AvenirBlackH4 data-qaid="createPrinter_namelabel" label={Context.langData.printer_name} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="createPrinter_nameinput"
                                    value={name}
                                    error={error}
                                    maxLength={64}
                                    onChange={(val) => onChange(val.target.value)}
                                    id="name"
                                    // info="name the printer after the kitchen station in which the printer is situated"
                                    inputProps={{ maxLength: 30 }}
                                />
                                <Grid item style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex', padding: 5, paddingTop: 5, paddingLeft: 0 }}>
                                    <Grid item xs={9} style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', marginTop: 5 }}>
                                        <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                        <H5
                                            label={"Name the printer after the kitchen station in which the printer is situated"}
                                            style={{ paddingLeft: 3 }} />
                                    </Grid>
                                    <Grid item xs={3} style={{ justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 5 }}>
                                        <H5 style={{ textAlign: 'right' }} label={(name?.length == undefined ? '0' : name?.length) + Context.langData._64_characters_count} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '55%' }}>
                                <AvenirBlackH4 data-qaid="" label={Context.langData.printer_model} style={{ marginBottom: 5 }} />
                                <SingleSelect
                                    list={printerModel}
                                    valueKey="name"
                                    displayKey="name"
                                    value={pModel}
                                    handleChange={(e) => setPmodel(e.target.value)}
                                    border={false}
                                    height={54}
                                    FormWidth={'100%'}
                                    borderStyle={'1px solid #707272'}
                                />
                            </Grid>
                            <Grid data-qaid="createPrinter_ipgrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="createPrinter_iplabel" label={Context.langData.ip_address} style={{ marginBottom: 12 }} />
                                {/* <InputText
                                    data-qaid="createPrinter_ipinput"
                                    value={ipAddress}
                                    error={ipAddresserror}
                                    onChange={(val) => onIpChange(val.target.value)}
                                    id="ip_address"
                                    inputPlaceholder="000.000.000.000"
                                /> */}
                                <MaskedInput
                                    {...props}
                                    style={{ height: 56, color: '#051D33', borderRadius: 4, paddingLeft: 10, border: ipAddresserror ? '1px solid #AB1212' : '1px solid #707272', fontSize: 16, fontFamily: 'AvenirLT' }}
                                    value={ipAddress}
                                    onChange={(val) => setIpAddress(val.target.value)}
                                />
                                {ipAddresserror &&
                                    <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginTop: 5 }}>
                                        <ExclamationIcon color="#AB1212" style={{ marginRight: 5 }} />
                                        <H5 label={ipAddresserror} style={{ color: '#AB1212 ' }} />
                                    </Grid>
                                }
                                <Grid style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', paddingTop: 10 }}>
                                    <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                    <H5
                                        label={"Enter the IP address obtained from the receipt that was printed during initial set up of printer"}
                                        style={{ paddingLeft: 5 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="createPrinter_action_grid" container style={{ backgroundColor: '#FAFAFA', border: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="createPrinter_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="createPrinter_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, width: 103, height: 40, border: '2px solid #051D33' }} >
                                    <AvenirBlackH4 data-qaid="createPrinter_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button className={style.buttonClass}
                                    data-qaid="createPrinter_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="createPrinter_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer >
    )
}

const EditPrinterDrawer = ({ open, row, callback, onClose, printers }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const Permissions = useSelector(state => state.profileReducers.permissions);
    const [name, setName] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [pModel, setPmodel] = useState(0);
    const printerModel = [
        { name: "Star TSP100" },
        { name: "Star SP700" },
    ]
    const [printerId, setPrinterId] = useState("");
    const [error, setError] = useState(null);
    const [ipAddresserror, setIpAddresserror] = useState(null);
    const [checkError, setCheckError] = useState(false)
    const [checkIt, setCheckIt] = useState([]);
    const checkList = useMemo(() => {
        let list = Permissions?.applications.filter(e => e.application_id != 1)
        return list
    }, [Permissions])

    const handleCheck = (item) => {
        let ch = [...checkIt]
        let index = ch.indexOf(item.application_id)
        if (index !== -1) {
            ch = [...ch.slice(0, index), ...ch.slice(index + 1)]
        } else {
            ch = [...ch, item.application_id]
        }
        setCheckIt(ch)
    }

    const dispatch = useDispatch();
    useEffect(() => {
        if (row) {
            setPrinterId(row.id);
            setName(row.name);
            setIpAddress(row.ip_address);
            setPmodel(row.printer_model);
            //setCheckIt(row.applications)
        }
    }, [row])
    const save = () => {
        var errorFlag = 0;
        if (name) {
        } else {
            errorFlag = errorFlag + 1;
            setError(Context.langData.no_printer_error);
        }
        if (ipAddress) {
            const regex = /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/
            const m = regex.test(ipAddress)
            console.log(m);
            if (!m) {
                errorFlag = errorFlag + 1;
                setIpAddresserror(Context.langData.ip_address_error);
            } else {
                setIpAddresserror('');
            }
        } else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(Context.langData.enter_ipaddress_error);
        }
        var isArray = printers.filter((e) => e.id != printerId)
        const elementsIndex = isArray.findIndex(indexElement => indexElement.name == name);
        if (elementsIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setError(name + " already exist");
        }
        const elementsipIndex = isArray.findIndex(indexElement => indexElement.ip_address == ipAddress);
        if (elementsipIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(ipAddress + " already exist");
        }
        if (errorFlag == 0) {
            dispatch(EditPrinter({ ip_address: ipAddress.trim(), name: name.trim(), printer_id: printerId, printer_model: pModel }, callback))
        }
        else {
        }
    }

    const onChange = (val) => {
        setName(val)
        if (printers.indexOf(val.toLowerCase()) == -1) {
            setError("")
        }
        else {
            setError(val + " already exist");
        }
    }
    // const onIpChange = (val) => {
    //     setIpAddress(val)
    //     if (printers.indexOf(val.toLowerCase()) == -1) {
    //         setError("")
    //     }
    //     else {
    //         setError(val + " already exist");
    //     }
    // }

    const props = {
        guide: false,
        placeholderChar: '\u2000',
        mask: value => Array(value.length).fill(/[\d.]/),
        pipe: value => {
            if (value === '.' || value.endsWith('..')) return false;

            const parts = value.split('.');
            if (
                parts.length > 4 ||
                parts.some(part => part === '00' || part < 0 || part > 255)
            ) {
                return false;
            }
            return value;
        },
    };

    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto', marginBottom: 20 }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.edit_printer} />
                        <Grid container direction="column" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56, width: 500 }} >
                            <Grid data-qaid="editPrinter_name_grid" style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="editPrinter_namelabel" label={Context.langData.printer_name} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="editPrinter_nameinput"
                                    value={name}
                                    error={error}
                                    maxLength={64}
                                    onChange={(val) => onChange(val.target.value)}
                                    id="name"
                                    inputProps={{ maxLength: 64 }}
                                />
                                <Grid item style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 10, display: 'flex', paddingTop: 5 }}>
                                    <Grid item xs={9} style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex' }}>
                                        <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                        <H5
                                            label={"Name the printer after the kitchen station in which the printer is situated"}
                                            style={{ paddingLeft: 5 }} />
                                    </Grid>
                                    <Grid item xs={3} style={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                        <H5 style={{ textAlign: 'right' }} label={(name?.length == undefined ? '0' : name?.length) + Context.langData._64_characters_count} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '55%' }}>
                                <AvenirBlackH4 data-qaid="" label={Context.langData.printer_model} style={{ marginBottom: 5 }} />
                                <SingleSelect
                                    list={printerModel}
                                    valueKey="name"
                                    displayKey="name"
                                    value={pModel}
                                    handleChange={(e) => setPmodel(e.target.value)}
                                    border={false}
                                    height={54}
                                    FormWidth={'100%'}
                                    borderStyle={'1px solid #707272'}
                                />
                            </Grid>
                            <Grid data-qaid="editPrinter_ipgrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="editPrinter_iplabel" label={Context.langData.ip_address} style={{ marginBottom: 12 }} />
                                {/* <InputText
                                    data-qaid="editPrinter_ipinput"
                                    value={ipAddress}
                                    error={ipAddresserror}
                                    onChange={(val) => onIpChange(val.target.value)}
                                    id="ip_address" /> */}
                                <MaskedInput
                                    {...props}
                                    style={{ height: 56, color: '#051D33', borderRadius: 4, paddingLeft: 10, border: ipAddresserror ? '1px solid #AB1212' : '1px solid #707272', fontSize: 16, fontFamily: 'AvenirLT' }}
                                    value={ipAddress}
                                    onChange={(val) => setIpAddress(val.target.value)}
                                />
                                {ipAddresserror &&
                                    <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginTop: 5 }}>
                                        <ExclamationIcon color="#AB1212" style={{ marginRight: 5 }} />
                                        < H5 label={ipAddresserror} style={{ color: '#AB1212 ' }} />
                                    </Grid>
                                }
                                <Grid style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', paddingTop: 10 }}>
                                    <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                    <H5
                                        label={"Enter the IP address obtained from the receipt that was printed during initial set up of printer"}
                                        style={{ paddingLeft: 5 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Grid data-qaid="editPrinter_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                        <Grid data-qaid="editPrinter_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                            <Button className={style.buttonClass} data-qaid="editPrinter_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, border: '2px solid #051D33', height: 40, width: 103 }} >
                                <AvenirBlackH4 data-qaid="editPrinter_cancel_text" label={Context.langData.cancel} />
                            </Button>
                            <Button
                                className={style.buttonClass}
                                data-qaid="editPrinter_save_btn"
                                variant="contained"
                                style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                onClick={save}>
                                <AvenirBlackH4 data-qaid="editPrinter_save_text" label={Context.langData.save} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        </RightDrawer >
    )
}

const CreateKitchenPrinterAssign = ({ open, callback, onClose, printers, kitchens }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const [name, setName] = useState("");
    console.log("PRINTERS", printers)
    const [ipAddress, setIpAddress] = useState("");
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [sendData, setSendData] = useState([]);
    const dispatch = useDispatch();
    const kitchenPrinters = useMemo(() => {
        let kds = printers.filter(e => {
            if (e.is_active == "Active") {
                return e
            }
        })
        return kds
    }, [printers])
    useEffect(() => {
        dispatch(fetchAssignKitchenPrinter({}, thiscallback));
    }, [])
    function thiscallback(response) {
        console.log("response data", response.data)
        setData(response.data)
    }
    const save = () => {
        if (data.length > 0) {
            var array = [];
            data.map(d => {
                var obj = {}
                obj.kitchen_id = d.kitchen_id;
                obj.printer_id = d.printer_id != 0 ? d.printer_id : null;
                array.push(obj)
            })
            if (array.length > 0) {
                dispatch(createAssignKitchen(array, callback));
            } else {

            }
        } else {
        }
        dispatch(fetchPrinters());
    }
    const handleChangePrinter = (event, name) => {
        const item = event.target.value;
        const elementsIndex = data.findIndex(element => element.kitchen_name == name);
        const printerIndex = printers.findIndex(element => element.printer_id == item);
        let newArray = [...data];
        const kitchenIndex = kitchens.findIndex(element => element.name == name);
        if (kitchenIndex > -1 && printerIndex > -1) {
            newArray[elementsIndex] = { ...newArray[elementsIndex], printer_id: item, printer_name: printers[printerIndex].name, kitchen_id: kitchens[kitchenIndex].kitchen_id }
        } else {
            newArray[elementsIndex] = { ...newArray[elementsIndex], printer_id: 0, printer_name: '', kitchen_id: kitchens[kitchenIndex].kitchen_id }
        }
        setData(newArray);
    };
    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid container style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid item style={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.assign_printer} />
                        <Grid style={{ overflowX: 'auto', display: 'flex', flexDirection: 'column', marginLeft: 56, marginRight: 56, width: 500, marginBottom: 15 }}>
                            {
                                data && data.length > 0 ?
                                    data.map((item, index2) => (
                                        <Grid container style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                                            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <DotTextBoldNormal label={item.kitchen_name} style={{ marginRight: 20, alignItems: 'center', width: '50%', marginBottom: 5 }} />
                                                <SingleSelect
                                                    list={[{ name: "Select printer", printer_id: 0 }].concat(kitchenPrinters)}
                                                    value={item.printer_id}
                                                    handleChange={(val) => handleChangePrinter(val, item.kitchen_name)}
                                                    displayKey="name"
                                                    valueKey="printer_id"
                                                    border={false}
                                                    height={56}
                                                    FormWidth={'100%'}

                                                />
                                            </Grid>
                                        </Grid>
                                    ))
                                    :
                                    <div>
                                        <AvenirBlackH4 label={Context.langData.no_kitchens} />
                                    </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="assignPrinter_action_grid" container style={{ backgroundColor: '#FAFAFA', borderTop: '1px solid #C3C3C3' }}>
                            <Grid data-qaid="assignPrinter_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="assignPrinter_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, height: 40, width: 103, border: '2px solid #051D33' }} >
                                    <AvenirBlackH4 data-qaid="assignPrinter_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="assignPrinter_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="assignPrinter_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )
}

export default PrintersList;
