import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button, Grid
} from '@material-ui/core';
import styles from '../../../../styles/index';
import RightDrawer from '../../../../components/RightDrawer'
import InputText from '../../../../components/InputText';
import {
    createDeliveryOptions
} from '../../../../redux/actions';
import MyContext from '../../../../Provider/MyContext';
import { H4, AvenirBlackH4, H8 } from '../../../../utils/text';
import SettingIcon from '../../../../components/SVG/Settings';
import CircleClose from '../../../../components/SVG/CloseCircle';
import _ from 'lodash';
import { validateText } from '../../../../utils/util';

const CreateMore = ({ open, callback, onClose, more }) => {
    const Context = useContext(MyContext);

    const style = styles();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [id, setId] = useState("");
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const save = () => {
        var errorFlag = 0;
        if (name) {
        } else {
            errorFlag = errorFlag + 1;
            setError(Context.langData.no_text_error);
        }
        const elementsIndex = more.findIndex(indexElement => indexElement.label_name.toUpperCase() == name.toUpperCase());
        if (elementsIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setError(name + " already exist");
        }
        if (errorFlag == 0) {
            dispatch(createDeliveryOptions({ name: name.trim(), description: description }, callback))
        } else {
        }

    }

    const onNameChange = (val) => {
        const upper = validateText(val);
        setName(upper)
        if (more.indexOf(upper.toLowerCase()) === -1) {
            setError("")
        }
        else {
            setError(upper + " already exist");
        }
    }

    const onChangeDescription = (val) => {
        setDescription(val)
    }
    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.create_deliveryOption} />
                        <Grid data-qaid="createmore_main_grid" container direction="column" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56, width: 500 }} >
                            <Grid data-qaid="createmore_name_grid" style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="createmore_namelabel" label={Context.langData.name} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="createmore_nameinput"
                                    value={name}
                                    maxLength={32}
                                    error={error}
                                    onChange={(val) => onNameChange(val.target.value)}
                                    id="name" />
                            </Grid>
                            <Grid data-qaid="createmore_ipgrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="createmore_iplabel" label={Context.langData.description} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="createmore_valueinput"
                                    value={description}
                                    maxLength={300}
                                    onChange={(val) => onChangeDescription(val.target.value)}
                                    id="label_value" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Grid data-qaid="createmore_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                            <Grid data-qaid="createmore_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                                <Button className={style.buttonClass} data-qaid="createmore_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, border: '2px solid #051D33', height: 40, width: 103 }} >
                                    <AvenirBlackH4 data-qaid="createmore_cancel_text" label={Context.langData.cancel} />
                                </Button>
                                <Button
                                    className={style.buttonClass}
                                    data-qaid="createmore_save_btn"
                                    variant="contained"
                                    style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                    onClick={save}>
                                    <AvenirBlackH4 data-qaid="createmore_save_text" label={Context.langData.save} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </RightDrawer>
    )
}

export default CreateMore