import React, { useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { H1, H2, H3, H4, H6, AvenirBlackH4 } from '../../utils/text';
import { useState } from 'react';
import SalesReports from './sales';
import InventoryReports from './inventory';
import MyContext from '../../Provider/MyContext';
import More from './more';
import NoPermissionPage from '../../components/NoPermission';
import EmployeeReports from './employeeReports';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Reports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const [buttonArray, setButtonArray] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const reportsList = [
    { key: 'sale_reports', title: Context.langData.sales_report, id: 0 },
    {
      key: 'inventory_reports',
      title: Context.langData.inventoryreport,
      id: 1,
    },
    {
      key: 'employee_reports',
      title: Context.langData.employee_reports,
      id: 2,
    },
    // hide Visitor log
    // { key: 'more_reports', title: Context.langData.more_reports, id: 3 },
  ];
  const isItemHasPermission = useMemo(() => {
    var buttonName = [];
    var buttonCount = 0;
    reportsList.map((childItem) => {
      var obj = userPermissions.filter((e) => e.key == childItem.key);
      if (obj.length > 0) {
        if (obj[0].status) {
          buttonCount = buttonCount + 1;
          childObj = { ...childItem, isPermission: true };
        } else {
          childObj = { ...childItem, isPermission: false };
        }
        buttonName.push(childObj);
      } else {
        var childObj = { ...childItem, isPermission: false };
        buttonName.push(childObj);
      }
    });
    if (buttonCount == 0) {
      setButtonArray([]);
    } else {
      setButtonArray(buttonName);
      var result = false;
      if (buttonName.length > 0) {
        result = buttonName[selectedIndex].isPermission;
      } else {
        result = false;
      }
      return result;
    }
  }, [userPermissions]);

  const handleSelectItem = (item) => {
    setSelectedIndex(item);
  };
  const renderSelectedView = () => {
    if (selectedIndex == 0) {
      if (isItemHasPermission) {
        return <SalesReports data-qaid="reports_salesreport"></SalesReports>;
      } else {
        return (
          <NoPermissionPage data-qaid="reports_salesreport_nopermissions" />
        );
      }
    } else if (selectedIndex == 1) {
      if (isItemHasPermission) {
        return (
          <InventoryReports data-qaid="reports_inventoryreport"></InventoryReports>
        );
      } else {
        return (
          <NoPermissionPage data-qaid="reports_inventoryreport_nopermissions" />
        );
      }
    } else if (selectedIndex == 2) {
      if (isItemHasPermission) {
        return <EmployeeReports data-qaid="reports_Employee" />;
      } else {
        return <NoPermissionPage data-qaid="reports_more_nopermissions" />;
      }
    } else if (selectedIndex == 3) {
      if (isItemHasPermission) {
        return <More data-qaid="reports_more"></More>;
      } else {
        return <NoPermissionPage data-qaid="reports_more_nopermissions" />;
      }
    }
  };
  return (
    <Grid
      data-qaid="reports_main_grid"
      style={{ backgroundColor: '#FFFFFF', minHeight: '100%', width: '100%' }}>
      <Grid data-qaid="reports_content_grid" style={{ height: '20%' }}>
        <Grid
          data-qaid="reports_title_grid"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36 }}>
          <H6
            data-qaid="reports_title_label"
            label={Context.langData.reports}
            style={{ color: '#051D33', paddingTop: 36 }}></H6>
        </Grid>
        <Grid
          data-qaid="reports_buttons_grid"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ margin: 36, flexDirextion: 'row' }}>
          {buttonArray.map((item, i) => {
            return (
              <Button
                key={i}
                data-qaid="reports_button"
                style={{
                  backgroundColor:
                    selectedIndex == item.id ? '#051D33' : '#EEEEEE',
                  marginRight: 1,
                  borderRadius: 0,
                  borderTopLeftRadius: i == 0 ? 4 : 0,
                  borderBottomLeftRadius: i == 0 ? 4 : 0,
                  borderTopRightRadius: buttonArray.length - 1 == i ? 4 : 0,
                  borderBottomRightRadius: buttonArray.length - 1 == i ? 4 : 0,
                }}
                onClick={() => handleSelectItem(item.id)}>
                <AvenirBlackH4
                  data-qaid="reports_button_label"
                  label={item.title}
                  style={{
                    color: selectedIndex == item.id ? '#FFFFFF' : '#051D33',
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 10,
                    paddingBottom: 10,
                    lineHeight: '24px',
                  }}
                />
              </Button>
            );
          })}
        </Grid>
        <Grid
          data-qaid="reports_border"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}></Grid>
        <Grid
          data-qaid="transactions_border"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ borderTop: '1px solid #EEEEEE' }}></Grid>
      </Grid>
      <Grid
        data-qaid="reports_content_view"
        style={{ height: '80%', marginTop: 20 }}>
        {renderSelectedView()}
      </Grid>
    </Grid>
  );
};

export default Reports;
