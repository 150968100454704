import React, { Component, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Grid, Box, Typography, Button, Paper } from '@material-ui/core'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FloorArea from './FloorArea';
import ItemsList from './ItemsList'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UndoIcon from '../../../components/SVG/Undo';
import RedoIcon from '../../../components/SVG/Redo';
import {
  SvgIcon
} from './Icons'
import {
  ITEMS
} from './items'
import FloorSettings from './FloorSettings'
import ItemSettings from './ItemSettings'
import { connect } from 'react-redux';
import { H5, H4 } from '../../../utils/text';

const getItem = (source, destination, droppableSource, droppableDestination, x, y) => {
  console.log('getItem ==> ', destination, source, droppableSource, droppableDestination, new Date());
  const sourceClone = Array.from(source);

  if (!destination) {
    destination = [];
  }
  const destClone = Array.from(destination);
  let item = sourceClone[droppableSource.index];
  const time = moment().unix().toString();

  let width = 50, height = 50;
  if (item.content === 'table') {
    item = { strokeWidth: 4, seats: 4, stroke: '#fff', color: '#808285', code: time.substring(time.length - 4), content: "circle", category: "Table", name: "Table" }
  }
  if (item.content === 'bar') {
    height = 150;
    item = { ...item, name: "Bar" }
  }
  if (item.content === 'wall') {
    item = { showName: false, content: "rect", category: "Wall", color: '#c6c6c6', stroke: 'transparent', name: "Wall" };
    width = 10;
  }
  if (item.category === 'Desk') {
    item = { ...item, name: "Desk" }
    width = 70;
    console.log(item)
  }
  if (item.category === "Door") {
    item = { ...item, color: 'black' }
  }
  return {
    color: 'transparent',
    name: item.category.toLowerCase(),
    ...item,
    id: time,
    x: x,
    y: y,
    rotate: 0,
    width: width,
    height: height,


  }
}
// const tempItems = []
const Item = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem 1rem;
  margin: 0 0  0.5rem 0;
  align-items: flex-start;
  align-content: flex-start;
 `;
const Item1 = styled.div`
  display: flex;
  user-select: none;

  margin: 0 0  0.5rem 0;
  align-items: center;
  align-content: center;
 `;
const Clone = styled(Item)`
  + div {
    display: none!important;
  }
`;

const WIDTH = 20, HEIGHT = 20;


class DargAndDrop extends Component {
  state = {
    list: [],
    ITEMS: ITEMS,
    expanded: false,
    tempItems: [],
    showElements: true,
    dimensions: { width: 800, height: 400 },
    isDragging: false,
    positions: { x: 50, y: 50 },
    workingData: [],
    innerHeigth: window.innerHeight
  }

  handleIconClick = (item) => {
    if (this.props.currentSection) {
      if (this.props.currentSection !== null) {
        console.log(item, 'itemData')
        const time = moment().unix().toString();
        const x = 400, y = 300;

        let width = 50, height = 50;
        item = { ...item, color: '#808285' }
        if (item.content === 'table') {
          item = { strokeWidth: 4, seats: 4, stroke: '#fff', color: '#808285', code: time.substring(time.length - 4), content: "circle", category: "Table", name: "Table" }
        }
        if (item.content === 'bar') {
          height = 150;
          item = { ...item, name: "Bar", color: '#808285' }
        }
        if (item.content === 'wall') {
          item = { showName: false, content: "rect", category: "Wall", color: '#808285', stroke: 'transparent', name: "Wall" };
          width = 10;
        }
        if (item.category === 'Desk') {
          item = { ...item, name: "Desk", color: '#808285' }
          width = 70;
          console.log(item)
        }

        const returnObj = {
          color: 'transparent',
          name: item.category.toLowerCase(),
          ...item,
          id: time,
          x: x,
          y: y,
          rotate: 0,
          width: width,
          height: height,
        }
        this.props.addItem(returnObj);
      }
    } else {
      console.log("Create seaction first")
      if (this.props.sectionsList.length == 0)
        this.props.onError("Create section", true)
      else
        this.props.onError("Selecte floorplan", false)
    }
  }

  handleChange = (panel) => {
    if (panel == this.state.expanded) {
      this.setState({ expanded: false });
    }
    else
      this.setState({ expanded: panel });
  };
  onDragEnd = (result, provided) => {

    console.log("currentSection", this.props)
    if (this.props.currentSection !== null) {
      var elem = document.getElementById("con");
      // var fv = document.getElementById("floor_overlay");
      // // elem.addEventListener("mousemove", onMousemove, false);
      // const v = elem.getBoundingClientRect()
      // fv.style.display = 'inline-block';
      // fv.style.top = v.top+"px";
      // fv.style.left = v.left+"px"
      // setTimeout(()=>{
      //   fv.style.display = 'none';
      // },1000)
      const { source, destination } = result;
      // console.log('==> result', result, provided,v);
      this.props.dragging(true)
      if (!destination) {
        return;
      }
      if (destination && source) {
        if (source.droppableId == destination.droppableId) {
          return;
        }
      }
      // var x = 50, y = 50;
      const { x, y } = this.state.positions;
      let _this = this;
      console.log("POSITIONS", this.state.positions)
      const newTemp = [...this.state.tempItems]
      switch (source.droppableId) {

        case "ITEMS":
          // newTemp.push(getItem(_this.state[source.droppableId],
          //   _this.state[destination.droppableId],
          //   source,
          //   destination,
          //   x,
          //   y
          // ))

          _this.props.addItem(
            getItem(_this.state[source.droppableId],
              _this.state[destination.droppableId],
              source,
              destination,
              x,
              y
            ))
          _this.setState({ isDragging: true })
        default:
          _this.setState({
            ITEMS: ITEMS,
            // isDragging: false
          });
          break;
      }


    } else {
      console.log("Create seaction first")
      if (this.props.sectionsList.length == 0)
        this.props.onError("Create section", true)
      else
        this.props.onError("Selecte floorplan", false)
    }

  };
  onMousemove = (event) => {
    const x = event.layerX;
    const y = event.layerY;

    this.setState({ positions: { x, y } })
  }
  getSizes = () => {
    const { device, rotate } = this.props.floorDetails
    console.log("DEVICE", device)
    const rotation = rotate % 180 == 0;
    const { sizes } = device;
    if (rotation)
      return {
        width: 800,
        height: (800 / sizes[0]) * sizes[1]
      }
    else {
      return {
        height: 800,
        width: (800 / sizes[0]) * sizes[1]
      }
    }
  }
  componentDidMount() {
    console.log("componentDidMount")
    this.props.selectedIndexDefault()
    this.props.dragging(false)
    var elem = document.getElementById("con");
    elem.addEventListener("mousemove", this.onMousemove, false);
    // window.addEventListener('resize', this.onResizeWindow)
    let h = document.getElementById("floor_container").offsetHeight;
    this.setState({ innerHeigth: h })
    console.log("resize floor_container", h)
  }
  onResizeWindow = (e) => {
    console.log("resize", e.target.innerHeight)
    this.setState({ innerHeigth: e.target.innerHeight })
  }
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.workingData) !== JSON.stringify(state.workingData)) {
      //Change in props
      return {
        // isDragging: false,
        workingData: props.workingData

      };
    }
    return null; // No change to state
  }
  render() {
    const items = ITEMS || [];
    const sizes = this.getSizes();
    console.log("sizes", sizes, this.state.isDragging, this.state.tempItems)
    const dimensions = { width: sizes.width, height: sizes.height }
    const { expanded } = this.state;
    const { workingData, selectedIndex, undo, redo, isFloorSelected } = this.props;
    const height = this.state.innerHeigth - 70;

    return (
      <div id="drgdrpcntx">
        <DragDropContext

          onDragEnd={this.onDragEnd}>
          <Grid container >
            {
              this.state.showElements &&
              <Grid item xs={12} sm={12} md={1} >
                <Box display="display" style={{ height: height, maxWidth: 150, backgroundColor: 'white', padding: 0, paddingTop: 30, marginTop: 2, alignItems: 'center' }}>
                  <H5 label='Click on any element to add it to the canvas' style={{ textAlign: 'left', marginBottom: 10, paddingLeft: 10 }} />
                  <Source items={items} handleIconClick={this.handleIconClick} />
                </Box>
              </Grid>
            }
            <Grid id="floor_container" item xs={12} sm={12} md={this.state.showElements ? 8 : 9} style={{ position: 'relative', }}>
              <Grid container style={{ zIndex: 999, position: 'absolute', top: '50%', left: this.state.showElements ? '-6.3%' : -54, width: 0 }} >
                <Typography
                  onClick={() => this.setState({ showElements: !this.state.showElements })}
                  style={{ display: 'flex', padding: '6px 20px', backgroundColor: 'white', cursor: 'pointer', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}
                  className="rotate270">
                  <H4 label='Elements' style={{ marginLeft: 10, paddingLeft: 6 }} />
                  <>
                    {
                      this.state.showElements ?
                        <ExpandLessIcon style={{ marginLeft: 20 }} />
                        :
                        <ExpandMoreIcon style={{ marginLeft: 20 }} />
                    }
                  </>
                </Typography>

              </Grid>
              <Grid container>
                <Grid container justify="center" >
                  <Paper elevation={0} style={{ width: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'transparent' }}>
                    <div style={{ paddingTop: 5, paddingBottom: 5, display: 'flex', alignItems: 'flex-start', width: dimensions.width }}>
                      <Button onClick={undo} startIcon={<UndoIcon  disabled={this.props.hasChanges} />}  disabled={!this.props.hasChanges} >
                        <H4 label='Undo' style={{color: this.props.hasChanges ? "" : "#C3C3C3"}} />
                      </Button>
                      <Button onClick={redo} startIcon={<RedoIcon disabled={this.props.hasChanges} />} disabled={!this.props.hasChanges}>
                        <H4 label='Redo' style={{color: this.props.hasChanges ? "" : "#C3C3C3"}} />
                      </Button>
                      {
                        this.props.isDragging &&
                        <div><Typography variant="h4">Item is positioning in droped area</Typography></div>
                      }

                    </div>


                    <Droppable droppableId="list">
                      {(provided, snapshot) => {
                        console.log(snapshot)
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              width: dimensions.width,
                            }}
                            id="con"
                            ref={provided.innerRef}
                            // isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}>

                            <h5 style={{ color: '#f1f1f1', width: 0 }}>{this.state.list.length > 0 ? '' : 'Floor Design'}</h5>
                            <FloorArea
                              items={workingData}
                              dimensions={dimensions}
                              tempItems={this.state.tempItems}
                              showElements={this.state.showElements} />
                            {provided.placeholder}


                          </div>
                        )
                      }}
                    </Droppable>
                    <div
                      id="floor_overlay"
                      style={{
                        width: dimensions.width,
                        height: dimensions.height,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        display: 'none',
                        backgroundColor: "#00000099"
                      }}>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Paper elevation={0} style={{ height: height, marginTop: 2, overflow: 'auto' }}>
                {
                  isFloorSelected ?
                    <FloorSettings />
                    :
                    selectedIndex === -1 ?
                      <ItemsList data={workingData} selectedIndex={selectedIndex} saveOnEdit={this.props.saveOnEdit} />
                      :
                      <ItemSettings />
                }
              </Paper>
            </Grid>
          </Grid>

        </DragDropContext>

      </div>
    );
  }
}


const Source = ({ items, handleIconClick }) => {
  return (
    <Droppable droppableId="ITEMS" isDropDisabled={true}>
      {(provided, snapshot) => {
        let index = -1;
        return (
          <div
            ref={provided.innerRef}
          // isDraggingOver={snapshot.isDraggingOver}
          >
            {

              items.map((item, j) => {
                index++;
                const Icon = item.content;
                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    isDragDisabled={true}
                    style={{ cursor: 'pointer' }}
                    index={j}>
                    {(provided1, snapshot1) => {
                      return (
                        <Item1
                          style={{ padding: 0, cursor: 'pointer' }}
                          ref={provided1.innerRef}
                          {...provided1.draggableProps}
                          {...provided1.dragHandleProps}
                          isDragging={snapshot1.isDragging}
                        >
                          {/* <span style={{ width: '25%', padding: 5, cursor: 'pointer' }} onClick={() => handleIconClick(item)}>
                            <span style={{ padding: 5, height: 30, width: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EEEEEE' }}>
                              <SvgIcon icon={item.content} width={item.width} height={item.height} stroke="#000" strokeWidth={2} />
                            </span>

                          </span>
                          <span style={{ width: '70%', marginLeft: '5%', cursor: 'pointer' }} onClick={() => handleIconClick(item)}>
                            <Typography variant="h6">&nbsp;&nbsp;{item.category}</Typography>
                          </span> */}
                          <SourceIcon item={item} handleIconClick={handleIconClick} />

                          {
                            snapshot.isDragging && (
                              <SourceItem item={item} />
                            )
                          }
                        </Item1>
                      )
                    }
                    }
                  </Draggable>

                  // <div style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'flex-start', display: 'flex', cursor: 'pointer' }} onClick={() => handleIconClick(item)}>
                  //   <span style={{ width: '50%', justifyContent:'flex-end', alignItems: 'flex-end', display: 'flex', flexDirection: 'row', padding: 10}}>
                  //     <span style={{ padding: 5, height: 30, width: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EEEEEE' }}>
                  //       <SvgIcon icon={item.content} width={item.width} height={item.height} stroke="#000" strokeWidth={2} />
                  //     </span>
                  //   </span>
                  //   <span style={{ width: '50%', justifyContent:'flex-start'}}>
                  //     <H5 label={item.category} />
                  //   </span>
                  // </div>
                )
              })
            }

          </div>
        )
      }}

    </Droppable >

  )
}
const SourceIcon = ({ item, handleIconClick }) => {
  const [isHover, toggleHover] = useState(false)
  const onMouseLeave = () => {

    toggleHover(false)
  }
  const onHover = () => {

    toggleHover(true)
  }
  return (
    <>
      <span onMouseOver={onHover} onMouseLeave={onMouseLeave} style={{ width: '25%', padding: 5, cursor: 'pointer' }} onClick={() => handleIconClick(item)}>
        <span style={{ padding: 5, height: 30, width: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: isHover ? "#004C60" : '#EEEEEE' }}>
          <SvgIcon icon={item.content} width={item.width} height={item.height} stroke={isHover ? "#FFF" : "#000"} strokeWidth={2} />
        </span>

      </span>
      <span onMouseOver={onHover} onMouseLeave={onMouseLeave} style={{ width: '70%', marginLeft: '5%', cursor: 'pointer' }} onClick={() => handleIconClick(item)}>
        <Typography variant="h6">&nbsp;&nbsp;{item.category}</Typography>
      </span>
    </>
  )
}
const SourceItem = ({ item }) => {
  const onHover = () => {
    console.log("you hovered", item)
  }
  return (
    <Clone>
      <div onMouseEnter={onHover}>
        <SvgIcon
          icon={item.content} width={item.width} height={item.height}

        />
      </div>
    </Clone>
  )
}
const mapStateToProps = (state) => ({
  workingData: state.floorplan.workingData,
  selectedIndex: state.floorplan.selectedIndex,
  isFloorSelected: state.floorplan.isFloorSelected,
  floorDetails: state.floorplan.floorDetails,
  isDragging: state.floorplan.isDragging,
  currentSection: state.floorplan.currentSection,
  sectionsList: state.floorplan.sectionsList
})
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    addItem: (item) => dispatch({ type: 'ADD_ITEM', payload: item }),
    deleteItem: (index) => dispatch({ type: 'DELETE_ITEM', index: index }),
    undo: () => dispatch({ type: 'UNDO' }),
    redo: () => dispatch({ type: 'REDO' }),
    dragging: (flag) => dispatch({ type: 'DRAGGING', payload: flag }),
    setPosition: (item, index) => dispatch({ type: "UPDATE_POSITION", payload: item, index: index }),
    selectedIndexDefault: () => dispatch({type: 'SELECT_ITEM', index: -1})
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DargAndDrop)
