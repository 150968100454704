import * as React from "react"

function SvgComponent({ size = 24, color = "#004C60", ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2 6.2a.684.684 0 01.967 0L12 11.033 16.833 6.2a.683.683 0 11.967.967L12.967 12l4.833 4.833a.684.684 0 01-.967.967L12 12.967 7.167 17.8a.684.684 0 01-.967-.967L11.033 12 6.2 7.167a.684.684 0 010-.967z"
        fill={color}
      />
    </svg>
  )
}

const CloseCircle = React.memo(SvgComponent)
export default CloseCircle
