import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { getThemeProps } from "@material-ui/styles";
import TimeIcon from "../../components/SVG/TimeIcon";
import { Grid, InputAdornment } from "@material-ui/core";
import DownVector from "../../components/SVG/DownVector";

const useStyles = makeStyles((theme) => ({}));

export default function Timer({
  time = "09:00",
  disabled = false,
  onChange,
  width,
}) {
  const classes = useStyles();
  const props = {};
  if (disabled) {
    props["disabled"] = true;
  }
  return (
    <Grid
      style={{
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%",
      }}>
      <TextField
        variant="outlined"
        id="time"
        type="time"
        defaultValue="00:00"
        value={time}
        className={classes.textField}
        title=""
        onChange={(e) => onChange(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <TimeIcon color={disabled ? "#C3C3C3" : "#004C60"} />
            </InputAdornment>
          ),
        }}
        inputProps={{
          step: 300,
          style: {
            paddingLeft: 0,
            color: disabled ? "#C3C3C3" : "#051D33",
            width: width,
          },
        }}
        {...props}
      />
    </Grid>
  );
}
