import React from 'react'

function AmexCardIcon(props) {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M27.668 25.5H15.1725H2.66797V13.0548V0.500654H15.1327H27.668V13V25.5Z" fill="white"/>
        <path d="M26.7239 18.1564H18.5087L17.9871 17.4787V18.1564H16.2067V17.0753C15.9815 17.1595 15.7262 17.2098 15.4409 17.2098H15.1503V18.1564H12.5114L12.1073 17.5923L11.7162 18.1564H7.42353V14.0152H11.7325L12.1327 14.5708L12.5284 14.0152H27.668V0.5H2.66797V10.6536L3.53499 8.6329H5.2612L5.70711 9.67032V8.6329H7.81526L8.09338 9.45422L8.37086 8.6329H17.5333V9.01875C17.8761 8.75434 18.322 8.6329 18.8084 8.6329H21.6537L22.0996 9.67032V8.6329H23.8042L24.3004 9.4033V8.6329H26.1272V12.7748H24.3298L23.863 12.0507V12.7748H21.3129L21.0726 12.1597H20.534L20.2944 12.7748H18.7621C18.3573 12.7748 17.8996 12.6918 17.5333 12.4176V12.7748H14.9447L14.423 12.0971V12.7748H4.91975L4.68014 12.1597H4.14151L3.90126 12.7748H2.66797V25.5H27.668V17.8868C27.4133 18.0546 27.0902 18.1564 26.7239 18.1564Z" fill="#006FCF"/>
        <path d="M10.4457 15.2734V14.6126H8.04706V17.5591H10.4457V16.8977H8.79591V16.397H10.4079V15.7532H8.79591V15.2734H10.4457ZM12.8111 17.5591H13.7493L12.563 16.0731L13.7415 14.6126H12.8274L12.1334 15.5175L11.4388 14.6126H10.5006L11.679 16.0895L10.5045 17.5591H11.4179L12.1125 16.6451L12.8111 17.5591ZM13.8126 14.6126V17.5591H14.5615V16.5994H15.4455C16.0938 16.5994 16.4933 16.1743 16.4933 15.6063C16.4933 15.0168 16.1023 14.6126 15.4709 14.6126H13.8126ZM15.7406 15.6102C15.7406 15.7911 15.6224 15.9386 15.3952 15.9386H14.5615V15.2734H15.3952C15.6224 15.2734 15.7406 15.4248 15.7406 15.6102ZM17.5628 16.5151H17.8526L18.7327 17.5591H19.6539L18.6988 16.4858C19.1832 16.38 19.4731 16.0222 19.4731 15.5639C19.4731 14.9998 19.082 14.6126 18.4507 14.6126H16.8132V17.5591H17.5628V16.5151ZM18.3749 15.2734C18.6021 15.2734 18.7197 15.4124 18.7197 15.5763C18.7197 15.7447 18.6021 15.8753 18.3749 15.8753H17.5628V15.2734H18.3749ZM22.1708 15.2734V14.6126H19.7721V17.5591H22.1708V16.8977H20.5209V16.397H22.1329V15.7532H20.5209V15.2734H22.1708ZM24.0269 16.8977H22.5037V17.5591H24.023C24.6628 17.5591 25.0415 17.1713 25.0415 16.6033C25.0415 16.0692 24.7085 15.7571 24.1235 15.7571H23.4249C23.2696 15.7571 23.1599 15.6605 23.1599 15.513C23.1599 15.3661 23.2696 15.2734 23.4249 15.2734H24.6961L24.9866 14.6126H23.4334C22.7936 14.6126 22.411 15.0037 22.411 15.5384C22.411 16.094 22.7727 16.4139 23.3329 16.4139H24.0269C24.1829 16.4139 24.2965 16.5112 24.2965 16.6581C24.2965 16.8057 24.1829 16.8977 24.0269 16.8977ZM26.7246 16.8977H25.2014V17.5591H26.7207C27.3605 17.5591 27.7392 17.1713 27.7392 16.6033C27.7392 16.0692 27.4062 15.7571 26.8219 15.7571H26.1233C25.9673 15.7571 25.8576 15.6605 25.8576 15.513C25.8576 15.3661 25.9673 15.2734 26.1233 15.2734H27.3938L27.6843 14.6126H26.1311C25.4913 14.6126 25.1087 15.0037 25.1087 15.5384C25.1087 16.094 25.4704 16.4139 26.0306 16.4139H26.7246C26.8806 16.4139 26.9942 16.5112 26.9942 16.6581C26.9942 16.8057 26.8806 16.8977 26.7246 16.8977Z" fill="#006FCF"/>
        <path d="M5.3248 12.1772H6.16636L4.90826 9.23081H3.93613L2.67346 12.1772H3.49805L3.74222 11.5753H5.08062L5.3248 12.1772ZM3.9988 10.9355L4.40751 9.92547L4.82012 10.9355H3.9988ZM7.76134 12.1772H8.41356L9.19245 9.99729V12.1772H9.93346V9.23081H8.75437L8.09822 11.1078L7.43751 9.23081H6.24601V12.1772H6.98703V9.99729L7.76134 12.1772ZM12.829 9.89152V9.23081H10.4297V12.1772H12.829V11.5165H11.1792V11.0158H12.7911V10.3714H11.1792V9.89152H12.829ZM13.9989 11.1333H14.2895L15.1689 12.1772H16.0908L15.1349 11.1039C15.6187 10.9988 15.9093 10.641 15.9093 10.1827C15.9093 9.61862 15.5182 9.23081 14.8868 9.23081H13.2494V12.1772H13.9989V11.1333ZM14.8111 9.89152C15.0383 9.89152 15.1565 10.0306 15.1565 10.1951C15.1565 10.3636 15.0383 10.4941 14.8111 10.4941H13.9989V9.89152H14.8111ZM16.2083 12.1772H16.9578V10.7044V9.23081H16.2083V10.7044V12.1772ZM18.6873 12.1772H18.746L19.0529 11.5165H18.8133C18.3419 11.5165 18.0644 11.2306 18.0644 10.7252V10.6828C18.0644 10.2408 18.3125 9.91307 18.8008 9.91307H19.5921V9.23081H18.7414C17.8157 9.23081 17.3195 9.84582 17.3195 10.6828V10.7252C17.3195 11.6301 17.8411 12.1772 18.6873 12.1772ZM21.7173 12.1772H22.5588L21.3007 9.23081H20.3286L19.0659 12.1772H19.8905L20.1347 11.5753H21.4731L21.7173 12.1772ZM20.3919 10.9355L20.8 9.92547L21.2119 10.9355H20.3919ZM24.5958 12.1772H25.5053V9.23081H24.7603V11.0576L23.5858 9.23081H22.6391V12.1772H23.3841V10.2832L24.5958 12.1772Z" fill="#006FCF"/>
        <path d="M0.891422 24.6473V24.347H1.07031C1.19827 24.347 1.27205 24.3868 1.27205 24.4939C1.27205 24.6075 1.19827 24.6473 1.07227 24.6473H0.891422ZM0.767375 25.12H0.891422V24.7459H1.07619L1.27597 25.12H1.41046L1.20023 24.7316C1.31775 24.7022 1.39544 24.6284 1.39544 24.488C1.39544 24.3242 1.27988 24.236 1.07815 24.236H0.767375V25.12ZM1.06574 25.4034C0.672708 25.4034 0.365856 25.0861 0.365856 24.6917C0.365856 24.2987 0.67075 23.984 1.06378 23.984C1.45486 23.984 1.76106 24.3007 1.76106 24.6957C1.76106 25.0887 1.45681 25.4034 1.06574 25.4034ZM1.06378 25.5C1.511 25.5 1.86617 25.1409 1.86617 24.6917C1.86617 24.2439 1.51361 23.8874 1.06574 23.8874C0.613949 23.8874 0.260742 24.2465 0.260742 24.6957C0.260742 25.1429 0.611991 25.5 1.06378 25.5Z" fill="#006FCF"/>
    </svg>

  )
}

export default AmexCardIcon
