import * as React from "react"

const VisaIcon = ({...props}) => {
    return (
        <svg width="40" height="14" viewBox="0 0 40 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M15.1166 1.06104L9.89114 13.5649H6.46967L3.91913 3.54938C3.7325 2.92729 3.60809 2.67846 3.17263 2.42963C2.36392 1.99417 1.11975 1.62092 0 1.37208L0.0622075 0.998833H5.59876C6.28305 0.998833 6.90513 1.4965 7.09176 2.30521L8.46034 9.52139L11.8196 0.998833H15.1166V1.06104ZM28.5537 9.45918C28.5537 6.16213 24.0124 5.9755 24.0124 4.4825C24.0124 4.04704 24.4479 3.54938 25.381 3.42496C25.8165 3.36275 27.1229 3.30054 28.5537 3.98484L29.1135 1.30988C28.3048 0.998833 27.3095 0.75 26.0653 0.75C22.8927 0.75 20.591 2.42963 20.591 4.91796C20.591 6.72201 22.2084 7.71734 23.4526 8.33942C24.6967 8.96151 25.1322 9.33476 25.1322 9.89463C25.1322 10.7033 24.1369 11.0766 23.2037 11.1388C21.5863 11.1388 20.6532 10.7033 19.9067 10.3301L19.3468 13.0673C20.0933 13.4405 21.5241 13.6893 22.9549 13.7516C26.3142 13.7516 28.5537 12.0719 28.5537 9.45918ZM37.014 13.5649H40L37.3872 1.06104H34.6501C34.028 1.06104 33.5303 1.43429 33.2815 1.99417L28.4292 13.5649H31.8507L32.535 11.6987H36.703L37.014 13.5649ZM33.4059 9.14813L35.0855 4.4203L36.0809 9.14813H33.4059ZM19.7823 1.06104L17.1073 13.5649H13.8725L16.5474 1.06104H19.7823Z" fill="#1434CB"/>
        </svg>

    )
}

export default VisaIcon
