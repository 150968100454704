import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RecipeDataTable from '../../../components/RecipeDataTable';
import RecipeFormula from './recipeFormula';
import MyContext from '../../../Provider/MyContext';
import { H4, H2, H3, AvenirBlackH4 } from '../../../utils/text';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import UploadIcon from '../../../components/SVG/UploadIcon';
import _ from 'lodash';
import {
  fetchAllRecipeItems,
  uploadItemRecipes,
  fetchAllRecipeItemDetails,
} from '../../../redux/actions';
import styles from '../../../styles/index';
import { DownloadItemRecipe } from '../../../utils/excel';
import UploadItemRecipe from '../../../components/UploadModal';
import UploadErrosTAble from '../../../components/UploadErrosTable';
import SnackBar from '../../../components/SnackBar';
import $ from 'jquery';
const Items = () => {
  const style = styles();
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const RecipeItemsData = useSelector((state) => state.inventory.recipeItems);
  const RecipeItemDetails = useSelector(
    (state) => state.inventory.recipeItemDetails
  );
  const stocksData = useSelector((state) => state.inventory.stocks);
  const [row, setRow] = useState({});
  const [viewRecipe, setViewRecipe] = useState(false);
  const [UploadModal, setUploadModal] = useState(false);
  const Context = useContext(MyContext);
  const [isDownload, setIsDownload] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [uploadTitle, setUploadTitle] = useState();
  const [updatedList, setUpdatedList] = useState([]);
  const [errosData, setErrosData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [columns, setColumns] = useState([
    { field: 'name', headerName: Context.langData.item_name, sort: false },
    { field: 'recipeStatus', headerName: Context.langData.recipe, sort: false },
  ]);
  const valueArray = [
    { name: Context.langData.update_items_recipe, value: 1 },
    { name: Context.langData.add_items_recipe, value: 2 },
  ];
  const [recipeItems, setRecipeItems] = useState([]);
  useEffect(() => {
    setRecipeItems(RecipeItemsData);
  }, [RecipeItemsData]);

  useEffect(() => {
    if (isDownload && RecipeItemDetails && RecipeItemDetails.length > 0) {
      DownloadItemRecipe(RecipeItemDetails);
      setIsDownload(false);
    }
  }, [RecipeItemDetails]);
  const onClickView = (rowValue) => {
    setRow(rowValue);
    setViewRecipe(true);
  };
  useEffect(() => {
    dispatch(fetchAllRecipeItems());
  }, []);
  const commanCallback = () => {
    dispatch(fetchAllRecipeItems());
  };
  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    var sortRecipeItems = _.orderBy(
      recipeItems,
      [
        (d) => {
          return typeof d[value] === 'string'
            ? d[value].toLowerCase()
            : d[value];
        },
      ],
      [sortValue ? 'desc' : 'asc']
    );
    setRecipeItems(sortRecipeItems);
  };
  const callback = (isSuccess, data) => {
    setViewRecipe(false);
    if (isSuccess) {
      if (data.status == 'AllSucceeded') {
        setSnackbar({
          message: Context.langData.recipe_edit_succ,
          severity: 'success',
        });
      } else {
        setSnackbar({
          message: Context.langData.recipe_edit_with_error,
          severity: 'warn',
        });
      }
    } else {
      setSnackbar({
        message: Context.langData.recipe_edit_failed,
        severity: 'error',
      });
    }
    setIsDownload(false);
    dispatch(fetchAllRecipeItems());
  };
  const download = () => {
    if (isDownload && RecipeItemDetails && RecipeItemDetails.length > 0) {
      DownloadItemRecipe(RecipeItemDetails);
      setIsDownload(false);
    } else {
      setIsDownload(true);
      var recipeArray = [];
      if (recipeItems && recipeItems.length > 0) {
        recipeItems.map((rItem, index) => {
          if (rItem.serving && rItem.serving.length > 0) {
            rItem.serving.map((item) => {
              if (item.recipe_id > 0) {
                recipeArray.push(item.recipe_id);
              }
            });
          }
        });
      }
      if (recipeArray.length > 0) {
        var ids = recipeArray.join(',');
        var params = { ext_product_id: ids };
        dispatch(fetchAllRecipeItemDetails(params));
      }
    }
  };

  const triggerUpload = () => {
    setUploadModal(true);
  };
  const uploadCallback = (fileName, itemsList, valueKey) => {
    parseItemsRecipeFile(itemsList, valueKey);
    setUploadTitle(Context.langData.missing_recipe_information);
  };

  const parseItemsRecipeFile = (json, valueKey) => {
    var data = [];
    var row = null;
    var items = [];
    json?.map((item, index) => {
      if (item['Serving name'] == '') delete item['Serving name'];
    });
    if (json?.length > 0) {
      json.map((item, index) => {
        var obj = {};
        if (typeof item['Serving name'] != 'undefined') {
          if (row != null) {
            row['recipe'] = items;
            data.push(row);
            items = [];
          }
          row = {
            serving: item['Serving name'],
            item_name: item['Item name'],
            index: index,
          };
          obj = {
            stock_name: item['Stock name'],
            amount: item['Quantity'],
            units: item['Units'],
            index: index,
          };
        } else {
          obj = {
            stock_name: item['Stock name'],
            amount: item['Quantity'],
            units: item['Units'],
            index: index,
          };
        }
        items.push(obj);
      });
      if (row != null) {
        row['recipe'] = items;
        data.push(row);
      }
    }
    parseItemsRecipe(data, valueKey);
  };
  const parseItemsRecipe = (data, uploadStep) => {
    var responce = RecipeItemsData;
    var stocks = stocksData;
    var errors = [];
    var parsedData = [];
    data?.map((item, i) => {
      var obj = {};
      var tempArray = [];
      var tempError = [];
      if (typeof item.item_name == 'undefined' || item.item_name == '')
        tempError.push('Menu item name not entered at row ' + (item.index + 2));
      if (tempError.length == 0) {
        var result = $.grep(responce, function (e) {
          return (
            e.name.toString().toLowerCase() ==
            item.item_name.toString().toLowerCase()
          );
        });
        console.log('resiult data', result);
        if (result.length != 0) {
          if (uploadStep == 1 && result[0].serving[0].recipe_id == 0)
            tempError.push(
              "Recipe doesn't exist to " +
                item.item_name +
                '(' +
                item.serving +
                ')'
            );
          if (uploadStep == 2 && result[0].serving[0].recipe_id > 0)
            tempError.push(
              'Recipe already exist to ' +
                item.item_name +
                '(' +
                item.serving +
                ')'
            );
          obj['item_name'] = item.item_name;
          obj['serving'] = item.serving;
          obj['item_id'] = result[0].item_id;
          var servingArray = result[0].serving;
          var servingObj = servingArray.filter((e) => e.name == item.serving);
          if (servingObj.length != 0) {
            obj['serving_id'] = servingObj[0].serving_id;
          } else {
            tempError.push(
              item.serving + ' serving name is not matched with any item sizes'
            );
          }
          item?.recipe.map((stock, j) => {
            var stocksErr = [];
            if (
              typeof stock.stock_name == 'undefined' ||
              stock.stock_name == ''
            )
              stocksErr.push(
                'Stock name not entered at row ' + (stock.index + 2)
              );
            else if (typeof stock.units == 'undefined' || stock.units == '')
              stocksErr.push(
                'Units not entered to ' +
                  stock.stock_name +
                  ' at row ' +
                  (stock.index + 2)
              );
            else if (typeof stock.amount == 'undefined' || stock.quantity == '')
              stocksErr.push(
                'Quantity not entered to ' +
                  stock.stock_name +
                  ' at row ' +
                  (stock.index + 2)
              );
            else if (isNaN(stock.amount))
              stocksErr.push(
                stock.stock_name +
                  ' quantity is not a number at row ' +
                  (stock.index + 2)
              );

            if (stocksErr.length == 0) {
              var r = $.grep(stocks, function (e) {
                return (
                  e.name.toString().toLowerCase() ==
                  stock.stock_name.toString().toLowerCase()
                );
              });
              if (r.length != 0) {
                if (
                  stock.units.toString().toLowerCase() ==
                  r[0].units.toString().toLowerCase()
                )
                  stock.stock_id = r[0].stock_id;
                else
                  stocksErr.push(
                    stock.stock_name +
                      ' units not matched with stock item units'
                  );
              } else
                stocksErr.push(
                  stock.stock_name + ' stock item not found in store'
                );

              tempArray.push(stock);
            }
            $.merge(tempError, stocksErr);
          });
        } else {
          tempError.push(item.item_name + ' is not found in Menu');
        }
      }
      obj['recipe'] = tempArray;
      if (tempError.length == 0) {
        parsedData.push(obj);
      } else {
        errors.push({ name: item.item_name, errors: tempError.join(',') });
      }
    });
    setUpdatedList(parsedData);
    setErrosData(errors);
    if (errors.length > 0) {
      setUploadModal(false);
      setIsUploadError(true);
    } else {
      if (parsedData.length > 0) {
        setUploadModal(false);
        dispatch(uploadItemRecipes(parsedData, itemRecipesUploadCallback));
      } else {
        setUploadModal(false);
        setSnackbar({
          message: Context.langData.upload_valid_file,
          severity: 'error',
        });
      }
    }
  };
  const handleProceedUpload = () => {
    if (updatedList.length > 0) {
      dispatch(uploadItemRecipes(updatedList, itemRecipesUploadCallback));
    } else {
      setSnackbar({
        message: Context.langData.no_item_recipes_error,
        severity: 'error',
      });
    }
    setIsUploadError(false);
  };
  const handleCancelUpload = () => {
    setIsUploadError(false);
    setUpdatedList([]);
  };
  const itemRecipesUploadCallback = (isSuccess) => {
    commanCallback();
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.item_recipe_upload_succ,
        severity: 'success',
      });
    } else {
      setSnackbar({
        message: Context.langData.item_recipe_upload_failed,
        severity: 'error',
      });
    }
  };
  return (
    <>
      <Grid data-qaid="items_main_grid" container style={{ marginBottom: 10 }}>
        <Grid
          data-qaid="items_button_grid"
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: 20,
            marginLeft: 16,
          }}>
          <Grid
            data-qaid="items_child_grid"
            item
            xs
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 15,
              justifyContent: 'flex-end',
            }}>
            <Button
              onClick={download}
              className={style.buttonClass}
              data-qaid="items_creategrid"
              style={{ marginRight: 24 }}
              variant="text"
              id="cancel">
              <DownlodeIcon
                data-qaid="items_createpluse"
                style={{ marginRight: 5 }}
                color="#004C60"
              />
              <H4
                data-qaid="items_createlabel"
                label={Context.langData.download}
                style={{ color: '#004C60' }}
              />
            </Button>
            {/* <Button onClick={triggerUpload} className={style.buttonClass} data-qaid="items_creategrid" style={{ backgroundColor: "#FFB600", marginRight: 24 }} variant="contained" id="cancel"  >
                            <UploadIcon data-qaid="items_createpluse" style={{ marginRight: 5 }} />
                            <H4 data-qaid="items_createlabel" label={Context.langData.item_upload} />
                        </Button> */}
          </Grid>
        </Grid>
      </Grid>
      {recipeItems.length > 0 && (
        <RecipeDataTable
          data-qaid="items_recipeTable"
          columns={columns}
          rows={recipeItems}
          sortingOnClick={sortingOnClick}
          // onCheckedIds={onCheckedIds}
          onClickView={onClickView}
          noRowData={false}
        />
      )}
      {viewRecipe && (
        <RecipeFormula
          data-qaid="items_recipeformula"
          open={viewRecipe}
          row={row}
          onClose={() => setViewRecipe(false)}
          callback={callback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid="taxeslist_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {UploadModal && (
        <UploadItemRecipe
          data-qaid="items_recipeupload"
          open={UploadModal}
          onClose={() => setUploadModal(false)}
          callback={uploadCallback}
          valueArray={valueArray}
          dropDownName={Context.langData.item_upload}
          dropDownBody={Context.langData.item_upload_body}
        />
      )}
      {isUploadError && (
        <UploadErrosTAble
          data-qaid="items_recipeupload"
          open={isUploadError}
          onClose={() => handleCancelUpload()}
          proceed={() => handleProceedUpload()}
          data={errosData}
          labelName={Context.langData.recipe_name}
          title={uploadTitle}
          error={Context.langData.recipe_error_body}
        />
      )}
    </>
  );
};
export default Items;
