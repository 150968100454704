import { Grid } from '@material-ui/core';
import React from 'react';
import InputText from '../../../../components/InputText';
import Delete from '../../../../components/SVG/Delete';
import { ConvertPrice, validateAlphaNumeric } from '../../../../utils/util';
const AddonItem = ({ item, onChange, onDelete }) => {
  const onNameChange = (value) => {
    onChange({ ...item, addon_item_name: validateAlphaNumeric(value) })
  }
  const onPriceChange = (value) => {
    onChange({ ...item, price: value })
  }
  // const price = item.price ?? "";
  return (
    <Grid container direction="row" style={{ marginTop: 20 }} justify="space-between" alignItems="center">
      <Grid item xs={6}>
        <InputText
          disabled={true}
          maxLength={64}
          value={item.addon_item_name}
          onChange={e => onNameChange(e.target.value)} />
      </Grid>
      <Grid item xs={3}>
        <InputText
          value={`${item.price == undefined ? "" : "$" + item.price}`}
          inputPlaceholder="$0.00"
          maxLength={9} // with $ length
          onChange={e => onPriceChange(e.target.value.replace("$", "").replace(/[^0-9\.?]/g, "") || "")}
          onBlur={() => onPriceChange(ConvertPrice(item.price))}
        />
      </Grid>
      <Grid item xs={1}>
        <Delete onClick={onDelete} />
      </Grid>
    </Grid>
  )
}

export default AddonItem;