import React from 'react';
import { Grid } from '@material-ui/core'
import { H4, AvenirBlackH4, AvenirBlackH3, DotTextMedium } from '../../../../utils/text'
import PencileIcon from '../../../../components/SVG/PencileIcon'
import DeleteIcon from '../../../../components/SVG/Delete'
import { ConvertPrice } from '../../../../utils/util'
const ModifierList = ({ modifiers, onEdit, isMandatory = true, onDeleteMandatoryModifier }) => {

  return (
    <Grid container style={{ padding: '0px 24px' }}>
      {
        modifiers.map((modifier, index) => {
          return (
            <ModifierItem
              key={index}
              isMandatory={isMandatory}
              onEdit={onEdit}
              onDelete={() => onDeleteMandatoryModifier(index)}
              item={modifier} />
          )
        })
      }
    </Grid>
  )
}

const ModifierItem = React.memo(({ item, isMandatory, onEdit, onDelete }) => {

  const str = isMandatory ? "mandatory" : "optional";
  return (
    <Grid container>
      <Grid container justify="space-between" style={{ borderBottom: '1px solid #EEE', paddingBottom: 10, marginTop: 50, width: '100%' }}>
        <Grid style={{width: '80%'}}>
          <DotTextMedium label={`${item.addon_cat_name} (${str})`} style={{ fontSize: 16 }}/>
        </Grid>
        <Grid style={{width: '20%'}}>
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <Grid item xs={12}  >
              <Grid direction="row" justify="flex-end" style={{ textAlign: 'end' }}>
                <DeleteIcon style={{ marginRight: 5 }} onClick={onDelete} />
                <PencileIcon style={{ marginRight: 5 }} onClick={() => onEdit(item)} />
                {/* <AvenirBlackH4 label="Delete" style={{ color: "#004C60" }} onClick={() => onEdit(item)} /> */}
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </Grid>
      <Grid container style={{ marginTop: 15, color: "#707272" }}>
        <Grid item xs={9}>
          <H4 label="Name" style={{ color: "#707272" }} />
        </Grid>
        <Grid item xs={3}>
          <H4 label="Price" style={{ color: "#707272", textAlign: 'right' }} />
        </Grid>

      </Grid>
      <Grid container>
        {
          item.addons.map((addonItem, indx) => {
            return (
              <Grid container key={indx} style={{ marginTop: 5 }}>
                <Grid item xs={9}>
                  <H4 label={addonItem.addon_item_name} style={{ wordBreak: 'break-all' }} />
                </Grid>
                <Grid item xs={3}>
                  <H4 label={`+$${ConvertPrice(addonItem.price)}`} style={{ textAlign: 'right' }} />
                </Grid>

              </Grid>
            )
          })
        }

      </Grid>
    </Grid>
  )
})

export default React.memo(ModifierList)