import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getSsoRedirectUrl,
  redirectExternalUrl,
} from "../../Provider/SsoServices";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/actions";
import { ReadFromSession } from "../../Provider/sessionStorageServices";
import { StorageKey } from "../../models/Constant";

const SsoLogin = () => {
  const [searchParams] = useSearchParams();
  const rest_id = searchParams.get("rest_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.loginReducer.loginDetails);

  useEffect(() => {
    if (rest_id) {
      dispatch({ type: "LOADING" });
      // clear session
      if (loginDetails && loginDetails.oauth_token) {
        dispatch(
          logoutUser({ oauth_token: loginDetails.oauth_token }, undefined)
        );
      }
      const prevAppRoute = ReadFromSession(StorageKey.PreviousAppRouteState) || '';
      getSsoRedirectUrl(rest_id, prevAppRoute)
        .then((result) => {
          redirectExternalUrl(result);
        })
        .catch(() => {
          navigate("/500", { state: { message: "SSO error happened" } });
        })
        .finally(() => {
          dispatch({ type: "LOADED" });
        });
    } else {
      navigate("/500", { state: { message: "Restaurant id is not provided" } });
    }
  }, [rest_id, loginDetails, dispatch, navigate]);

  return (
    <></>
    // <Dialog
    //   open={alertData.open}
    //   onClose={handleClose}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    // >
    //   <DialogTitle id="alert-dialog-title">SSO</DialogTitle>
    //   <DialogContent>
    //     <DialogContentText
    //       id="alert-dialog-description"
    //       style={{ color: "#AB1212" }}
    //     >
    //       {alertData.msg}
    //     </DialogContentText>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button onClick={handleClose}>Go back to login</Button>
    //   </DialogActions>
    // </Dialog>
  );
};

export default SsoLogin;
