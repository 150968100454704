import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import MyContext from '../Provider/MyContext';
import { H4, H2, H3, AvenirBlackH4 } from '../utils/text';
import _ from 'lodash';
import styles from '../styles/index';
import CenterModal from './CenterModal';
import SingleSelect from './SingleSelect';
import DeleteIcon from './SVG/Delete';
import InputText from './InputText';
import { uploadStocks } from '../utils/excel';
import UploadIcon from '../components/SVG/UploadIcon';
import * as XLSX from 'xlsx';
const UploadModal = ({ open, onClose, callback, valueArray, dropDownName, dropDownBody }) => {
    const Context = useContext(MyContext);
    const dispatch = useDispatch();
    const style = styles();
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [itemsList, setItemsList] = useState([]);
    const triggerUpload = () => {
        document.getElementById("upload").click();
    }
    const upload = (event) => {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            var arr = String.fromCharCode.apply(null, new Uint8Array(data));
            var wb = XLSX.read(btoa(arr), { type: 'base64' });
            var resultsData = to_json(wb);
            setItemsList(resultsData);
        };
        setFileName(file?.name);
        reader.readAsArrayBuffer(file);
    }
    const to_json = (workbook) => {
        var result = [];
        var sheetName = workbook.SheetNames[0];
        var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        if (roa.length > 0) {
            result = roa;
        }
        return result;
    }
    const DeleteUploadFile = () => {
        setItemsList([]);
        setFileName('');
    }
    const isDisable = (name && fileName) ? false : true;
    return (
        <CenterModal open={open} onClose={onClose}  >
            <Grid style={{ width: 495, height: fileName ? 458 : 320 }}>
                <Grid style={{ margin: 32 }}>
                    <Grid style={{ height: fileName ? 323 : 200 }}>
                        <H2 label={dropDownName} style={{ marginBottom: 32 }} />
                        <Grid container style={{ display: 'flex', flexDirection: 'column', marginBottom: 32 }}>
                            <AvenirBlackH4 data-qaid="createcomment_valuelabel" label={dropDownBody} style={{ marginBottom: 8 }} />
                            <SingleSelect
                                id="innerView"
                                data-qaid="alltrancation_ordertype_singleselect"
                                list={valueArray}
                                value={name}
                                valueKey="value"
                                displayKey="name"
                                placeholder="Select option"
                                handleChange={(e) => { setName(e.target.value) }}
                                border={false}
                                height={56}
                            />
                        </Grid>
                        {
                            fileName ?
                                <Grid container style={{ display: 'flex', flexDirection: 'column', marginBottom: 32 }}>
                                    <AvenirBlackH4 data-qaid="createcomment_valuelabel" label={Context.langData.file} style={{ marginBottom: 8 }} />
                                    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Grid style={{ display: 'flex', width: '75%' }}>
                                            <InputText
                                                value={fileName}
                                                disabled={true}
                                                id="fileName" />
                                        </Grid>
                                        <Grid style={{ display: 'flex', width: '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer' }} onClick={() => DeleteUploadFile()}>
                                            <DeleteIcon />
                                            <AvenirBlackH4 data-qaid="items_createlabel" label={Context.langData.delete} style={{ color: '#004C60', marginLeft: 8 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                    <Button onClick={triggerUpload} className={style.buttonClass} data-qaid="items_creategrid" style={{ backgroundColor: "#FFFFFF" }}   >
                        <UploadIcon data-qaid="items_createpluse" color='#004C60' width={20} height={20} style={{ marginRight: 8 }} />
                        <AvenirBlackH4 data-qaid="items_createlabel" label={Context.langData.upload_a_file} style={{ color: '#004C60' }} />
                        <input onChange={upload} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" style={{ display: 'none' }} id="upload" />
                    </Button>
                </Grid>
                <Grid style={{ height: 106, backgroundColor: '#FAFAFA', borderTop: '1px solid  #C3C3C3', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} className={style.buttonClass} data-qaid="items_creategrid" style={{ backgroundColor: "#FFFFFF", marginRight: 24, border: '2px solid #051D33', width: 103, height: 40 }} variant="outlined"   >
                        <H4 data-qaid="items_createlabel" label={Context.langData.cancel}  />
                    </Button>
                    <Button onClick={() => { callback(fileName, itemsList, name) }} disabled={isDisable} className={style.buttonClass} data-qaid="items_creategrid" style={{ backgroundColor: !isDisable ? "#FFB600" : '#c1c1c1', width: 103, height: 40, marginRight: 32 }} variant="contained"  >
                        <H4 data-qaid="items_createlabel" label={Context.langData.save} />
                    </Button>
                </Grid>
            </Grid>
        </CenterModal >
    )

}

export default UploadModal;