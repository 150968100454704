import { ColorPicker } from 'material-ui-color';
import React from 'react';
import { H4 } from '../utils/text';

const paletteObj = {
  red: '#ff0000',
  gray: '#808285'
}
const Color = ({ color = '#808285', setColor, width = 158 }) => {
  console.log(color)
  return (
    < div
      data-qaid="color_main_div" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', top: 0 }}>
      <div style={{ height: 56, width: width, backgroundColor: '#fff', border: `1px solid #707272`, borderRadius: 4, alignItems: 'center', display: 'flex', paddingLeft: 20 }}>
        <H4 label={color} />
      </div>
      <ColorPicker
        data-qaid="color_colorpicker"
        hideTextfield
        deferred
        value={color}
        style={{ borderColor: color }}
        onChange={e => { setColor(e.css.backgroundColor) }} />
      {/* <span data-qaid="color_span" >{color}</span> */}
    </div >
  );
}
export default Color;