import * as React from "react"

const DownVector = ({color="#004C60", ...props}) => {
    return (
        <svg
            width={13}
            height={8}
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.295 1.705l-1.41-1.41-4.59 4.58-4.59-4.58-1.41 1.41 6 6 6-6z"
                fill={color}
            />
        </svg>
    )
}

export default DownVector
