import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import FloorPlanLayout from './layouts/FloorPlanLayout';
import DashboardView from './views/dashboard/DashboardView';
import LoginView from './views/auth/LoginView';
import ErrorView from './views/errors/ErrorView';
import RegisterView from './views/auth/RegisterView';
import Branches from './views/auth/branches';
import Customers from './views/profile/customers/customers';
import NewCustomers from './views/profile/customers/newCustomers';
import Employees from './views/profile/employees';
import AllTransactions from './views/transactions/AllTransactions';
import CreateRoleFirstStep from './views/profile/employees/createRoleFirstStep';
import CreateEmployee from './views/profile/employees/createEmployee';
import EditEmployee from './views/profile/employees/editEmployee';
import EditRole from './views/profile/employees/editRole';
import Transactions from './views/transactions';
import Menu from './views/menu';
import ItemsList from './views/menu/items/itemsList';
import Categories from './views/menu/items/index';
import Dialog from './components/Dialog';
import FloorPlan from './views/floorplan';
import CreateFloorPlan from './views/floorplan/CreateFloorPlan';
import Printers from './views/settings/General/Printers';
import Offers from './views/settings/General/Offers';
import More from './views/settings/General/More';
import Shifts from './views/settings/General/Shifts';
import PaymentMethods from './views/settings/PaymentMethods';
import TaxProfiles from './views/settings/Payments/TaxProfiles';
import Taxes from './views/settings/Payments/Taxes';
import Reports from './views/reports';
import SalesReport from './views/reports/sales';
import InventoryReport from './views/reports/inventory';
import Positions from './views/menu/items/Positions';
import EditCustomer from './views/profile/customers/editCustomer';
import Inventory from './views/inventory';
import InventoryVendors from './views/inventory/vendors';
import InventoryStore from './views/inventory/store';
import InventoryKitchen from './views/inventory/kitchen';
import InventoryRecipe from './views/inventory/recipe';
import InventoryConsumptions from './views/inventory/consumption';
import InventoryCostings from './views/inventory/costings';
import OrderTypes from './views/menu/OrderTypes';
import Kitchens from './views/settings/General/KitchenCreation';
import Settings from './views/settings';
import Profile from './views/profile';
import BasicInfo from './views/websiteBuilder/createNew/basicInfo';
import AccountSettings from './views/websiteBuilder/createNew/AccountSettings';
import SiteSettings from './views/websiteBuilder/createNew/siteSettings';
import PettyCash from './views/pettyCash';
import ActivationFirstStep from './views/activation/index';
import ActivationSecondStep from './views/activation/activateSecondStep';
import TermsAndUsers from './views/activation/userTerms';
import ActivationVerified from './views/activation/accountActivation';
import packageInfo from '../package.json';
import PasswordReset from './views/auth/PasswordReset';
import CreatePassword from './views/activation/CreatePassword';
import NoAccessPage from './components/NoAccesPage';
import Preview from './views/websiteBuilder/pages/preview';
import MFA from './views/activation/mfa';
import LockoutPage from './views/auth/LockOutPage';
import Terms from './components/Terms';
import SsoLogin from './views/sso/SsoLogin';
import SsoLogout from './views/sso/SsoLogout';
import SsoCallback from './views/sso/SsoCallback';
import AuthRoute from './components/AuthRoute';

const appRoute = packageInfo.routepath;
const routes = [
  {
    path: `${appRoute}/home`,
    element: <DashboardLayout />,
    children: [
      {
        path: 'dashboard',
        element: (
          <AuthRoute element={DashboardView} redirectTo={`/${appRoute}`} />
        ),
      },
      {
        path: 'transactions',
        element: (
          <AuthRoute element={Transactions} redirectTo={`/${appRoute}`} />
        ),
        children: [{ path: 'all', element: <AllTransactions /> }],
      },
      {
        path: 'settings',
        element: <AuthRoute element={Settings} redirectTo={`/${appRoute}`} />,
        children: [
          { path: 'printers', element: <Printers /> },
          { path: 'more', element: <More /> },
          { path: 'shifts', element: <Shifts /> },
          { path: 'paymentMethods', element: <PaymentMethods /> },
          { path: 'offers', element: <Offers /> },
          { path: 'taxes', element: <Taxes /> },
          { path: 'taxProfiles', element: <TaxProfiles /> },
          { path: 'ordertypes', element: <OrderTypes /> },
          { path: 'kitchens', element: <Kitchens /> },
        ],
      },
      {
        path: 'profile',
        element: <AuthRoute element={Profile} redirectTo={`/${appRoute}`} />,
      },
      { path: 'profile/employees', element: <Employees /> },
      { path: 'dialog', element: <Dialog /> },
      { path: 'terms_of_use', element: <Terms /> },
      {
        path: 'profile/employees/createRoleFirstStep',
        element: <CreateRoleFirstStep />,
      },
      {
        path: 'profile/employees/createEmployee',
        element: <CreateEmployee />,
      },
      { path: 'profile/employees/editEmployee', element: <EditEmployee /> },
      { path: 'profile/customers', element: <Customers /> },
      { path: 'profile/customers/newCustomers', element: <NewCustomers /> },
      { path: 'profile/customers/editCustomer', element: <EditCustomer /> },
      { path: 'profile/employees/editRole', element: <EditRole /> },
      {
        path: 'floorplan',
        element: <AuthRoute element={FloorPlan} redirectTo={`/${appRoute}`} />,
      },
      {
        path: 'settings',
        element: <AuthRoute element={Settings} redirectTo={`/${appRoute}`} />,
      },
      {
        path: 'reports',
        element: <AuthRoute element={Reports} redirectTo={`/${appRoute}`} />,
        children: [
          { path: 'sales', element: <SalesReport /> },
          { path: 'inventory', element: <InventoryReport /> },
        ],
      },
      {
        path: 'menu',
        element: <AuthRoute element={Menu} redirectTo={`/${appRoute}`} />,
      },
      { path: 'menu/categories', element: <Categories /> },
      {
        path: 'menu/itemsList',
        element: <ItemsList />,
      },
      { path: 'menu/positions', element: <Positions /> },
      { path: '404', element: <ErrorView /> },
      { path: '', exact: true, element: <Navigate to='/home/dashboard' /> },
      {
        path: 'pettyCash',
        element: <AuthRoute element={PettyCash} redirectTo={`/${appRoute}`} />,
      },
      {
        path: 'inventory',
        element: <AuthRoute element={Inventory} redirectTo={`/${appRoute}`} />,
        children: [
          { path: 'vendors', element: <InventoryVendors /> },
          { path: 'store', element: <InventoryStore /> },
          { path: 'kitchens', element: <InventoryKitchen /> },
          { path: 'recipe', element: <InventoryRecipe /> },
          { path: 'consumption', element: <InventoryConsumptions /> },
          { path: 'costings', element: <InventoryCostings /> },
        ],
      },
      { path: '*', element: <Navigate to='/home/404' /> },
    ],
  },
  {
    path: `${appRoute}/`,
    element: <MainLayout />,
    children: [
      { path: '', element: <LoginView /> },
      { path: 'auth/login', element: <SsoLogin /> },
      {
        path: 'auth/logout',
        element: (
          <AuthRoute
            element={SsoLogout}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      { path: 'auth/callback', element: <SsoCallback /> },
      { path: 'register', element: <RegisterView /> },
      {
        path: '404',
        element: (
          <ErrorView
            status={'404'}
            message={
              'We can no longer find the page you are looking for. It may not exist, or it was moved.'
            }
          />
        ),
      },
      {
        path: '500',
        element: <ErrorView status={'500'} message={'Internal server error'} />,
      },
      { path: 'branches', element: <Branches /> },
      {
        path: 'lockout',
        element: (
          <AuthRoute
            element={LockoutPage}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      {
        path: '/activate',
        element: (
          <AuthRoute
            element={ActivationFirstStep}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      { path: 'mfa', element: <MFA /> },
      { path: 'activateStep2', element:(
        <AuthRoute
          element={ActivationSecondStep}
          redirectTo={`/home/dashboard`}
          unProtected={true}
        />
      ) },
      {
        path: 'termsandUser',
        element: (
          <AuthRoute
            element={TermsAndUsers}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      {
        path: 'accountVerified',
        element: (
          <AuthRoute
            element={ActivationVerified}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      {
        path: 'passwordReset',
        element: (
          <AuthRoute
            element={PasswordReset}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      {
        path: 'createPassword/:token',
        element: (
          <AuthRoute
            element={CreatePassword}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      { path: 'noAccess', element: <NoAccessPage /> },
      {
        path: 'terms_of_use',
        element: (
          <AuthRoute
            element={Terms}
            redirectTo={`/home/dashboard`}
            unProtected={true}
          />
        ),
      },
      {
        path: 'preview/:flag',
        element: <Preview />,
      },
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
  {
    path: `${appRoute}/floorplan`,
    element: (
      <AuthRoute element={FloorPlanLayout} redirectTo={`/${appRoute}`} />
    ),
    children: [
      {
        path: 'create',
        element: <CreateFloorPlan />,
      },
    ],
  },
  {
    path: `${appRoute}/builder`,
    element: <FloorPlanLayout />,
    children: [
      {
        path: '1',
        element: <BasicInfo />,
      },
      {
        path: '2',
        element: <AccountSettings />,
      },
      {
        path: '3',
        element: <SiteSettings />,
      },
    ],
  },
];
export default routes;
