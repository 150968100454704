import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Paper
} from '@material-ui/core';
import Logo from '../../components/SVG/Logo';
import GoIcon from '../../components/SVG/Go'
const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: 64,
    backgroundColor: theme.palette.background.topbar,
    zIndex:9999
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Paper>
        {/* <Toolbar className={classes.toolbar}>
          <Logo width={100} height={30} />
          <GoIcon width={98} height={30} />
        </Toolbar> */}
      </Paper>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
