// import * as React from "react"
// /* SVGR has dropped some elements not supported by react-native-svg: title */

// function ArrowDownIcon(props) {
//   return (
//     <svg
//       width={48}
//       height={1}
//       viewBox="0 0 48 1"
//       xmlns="http://www.w3.org/2000/svg"
//       {...props}
//     >
//     <path 
//       d="M12.2949 1.70492L10.8849 0.294922L6.29492 4.87492L1.70492 0.294922L0.294922 1.70492L6.29492 7.70492L12.2949 1.70492Z"
//       fill="#063855" fillRule="evenodd" 
//     />
//       {/* <path d="M12.2949 1.70492L10.8849 0.294922L6.29492 4.87492L1.70492 0.294922L0.294922 1.70492L6.29492 7.70492L12.2949 1.70492Z" fill="#004C60" /> */}

//     </svg>
//   )
// }

// export default ArrowDownIcon


import React from 'react';

function ArrowDownIcon(props) {
    return (
        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2949 1.70492L10.8849 0.294922L6.29492 4.87492L1.70492 0.294922L0.294922 1.70492L6.29492 7.70492L12.2949 1.70492Z" fill="#004C60" />
        </svg>
    )
}

export default ArrowDownIcon;