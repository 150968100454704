import React, { useEffect, useState, useContext } from "react";
import RightDrawer from "../../../../components/RightDrawer";
import { Grid, InputAdornment, Button } from "@material-ui/core";
import CircleClose from "../../../../components/SVG/CircleClose";
import CirclePlus from "../../../../components/SVG/CirclePluse";
import BackIcon from "../../../../components/SVG/Back";
import InputText from "../../../../components/InputText";
import { AvenirBlackH3, AvenirBlackH4, T, H5 } from "../../../../utils/text";
import { useSelector } from "react-redux";
import DeleteIcon from "../../../../components/SVG/Delete";
import AutoComplete from "../../../../components/AutoComplete";
import { ConvertPrice } from "../../../../utils/util";
import Title from "./Title";
import MyContext from '../../../../Provider/MyContext';
import InfoOutlinedIcon from '../../../../components/SVG/InfoOutlined';
import { validateAlphaNumeric } from "../../../../utils/util";

const EditComboSelection = ({ open, onClose, combo, onSave }) => {
  const Context = useContext(MyContext);
  const [comboItem, setCombo] = useState({
    combo_selection_name: "",
    combo_selection_items: [],
  });
  const { items } = useSelector((state) => state.menu);
  const [allItems, setAllItems] = useState([]);
  const [autocompleteVal, setAutoCompleteVal] = useState("");
  useEffect(() => {
    if (combo) setCombo(combo);
  }, [combo]);
  useEffect(() => {
    let filteredItems = [];
    items.map((item) => {
      let combos = { ...comboItem };
      // if (item.serving)
      // console.log(">>>>>>>>>", item)
      let comboItems = [...combos.combo_selection_items];
      let index = comboItems.findIndex((e) => e.item_id == item.item_id);
      console.log("IYEMMMMMMM", item)
      if (index == -1 && item.item_type !== "Combo" && item.item_type !== "Byo") {
        if (item.serving) {
          const s = item.serving.split(";");
          const sids = item.serving_ids.split(";");
          if (sids.length > 1) {
            s.map((ss, i) => {
              filteredItems.push({
                combo_item_name: item.name + " - " + ss,
                name: item.name + " - " + ss,
                item_id: item.item_id,
                baseprice: item.price,
                serving_id: sids[i],
                price: "",
              });
            });
          } else {
            filteredItems.push({
              combo_item_name: item.name,
              name: item.name,
              item_id: item.item_id,
              baseprice: item.price,
              serving_id: sids[0],
              price: "",
            });
          }
        } else {
          filteredItems.push({
            combo_item_name: item.name,
            name: item.name,
            item_id: item.item_id,
            baseprice: item.price,
            serving_id: "",
            price: "",
          });
        }
      }
    });
    console.log("items count", filteredItems.length);
    setAllItems(filteredItems);
  }, [items, comboItem]);
  const onSaveCombo = () => {
    if (comboItem.combo_selection_items.length > 0 && comboItem.combo_selection_name !== "")
      onSave(comboItem);
  };
  const onChangeFields = (field, value) => {
    let _data = value;
    if (field == 'combo_selection_name') {
      _data = validateAlphaNumeric(value)
    }
    setCombo({ ...comboItem, [field]: _data });
  };
  const onItemSelect = (item) => {
    console.log("IIIIIIIIIIII", item);
    setAutoCompleteVal(item);
  };
  const onAddItem = () => {
    let selitem = allItems.find((e) => e.name == autocompleteVal);
    if (selitem) {
      let combos = { ...comboItem };
      let comboItems = [...combos.combo_selection_items];
      let itm = comboItems.findIndex((e) => e.item_id == selitem.item_id);
      console.log("selected item", selitem, itm);
      comboItems.push({ ...selitem, item_name: autocompleteVal, price: "" });

      setCombo({ ...combos, combo_selection_items: comboItems });
      setAutoCompleteVal("");
    }
  };
  const onChangeComboFields = (field, value, index) => {
    let _data = value;
    if (field == 'item_name') {
      _data = validateAlphaNumeric(value);
    }
    setCombo({
      ...comboItem,
      combo_selection_items: [
        ...comboItem.combo_selection_items.slice(0, index),
        { ...comboItem.combo_selection_items[index], [field]: _data },
        ...comboItem.combo_selection_items.slice(index + 1),
      ],
    });
  };
  const deleteComboItem = (index) => {
    setCombo({
      ...comboItem,
      combo_selection_items: [
        ...comboItem.combo_selection_items.slice(0, index),
        ...comboItem.combo_selection_items.slice(index + 1),
      ],
    });
  };
  return (
    <RightDrawer open={open} onClose={onClose}>
      <Grid
        container
        style={{
          minWidth: 500,
          position: "relative",
          maxWidth: 500,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            height: window.innerHeight - 50,
            overflow: "auto",
            paddingBottom: 80,
          }}
        >
          <Grid
            container
            data-qaid="create_item_container"
            style={{ height: 60, padding: "0px 24px" }}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Grid container justify="flex-end" alignItems="center">
                <CircleClose
                  data-qaid="assignkitchen_closeicon"
                  size={20}
                  onClick={onClose}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ paddingLeft: 20 }}>
            <BackIcon onClick={onClose} />
          </Grid>
          <Grid
            container
            direction="row"
            style={{ marginTop: 30, padding: "0px 24px" }}
          >
            <Grid item xs={12}>
              <InputText
                onChange={(e) =>
                  onChangeFields("combo_selection_name", e.target.value)
                }
                maxLength={40}
                value={comboItem.combo_selection_name}
                placeholder="Combo selection name"
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Title title={"Add Item"} />
          </Grid>
          <Grid item style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', display: 'flex', padding: 5, paddingTop: 5, paddingLeft: 20 }}>
            <Grid item xs={22} style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', marginTop: 5 }}>
              <InfoOutlinedIcon style={{ height: 22, width: 22 }} />
              <H5
                label={"Customizing 'Build Your Own' items within combo meals is not supported. To add a pizza to a combo, create it as a regular menu item."}
                style={{ paddingLeft: 10 }} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ padding: 20, paddingBottom: 0 }}>
              <AutoComplete
                key="id"
                options={allItems}
                onChange={onItemSelect}
                labelKey="combo_item_name"
                placeholder="Item"
                inputValue={autocompleteVal}
                onInputChange={(e) => setAutoCompleteVal(e)}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              style={{ paddingLeft: 20, width: 'auto', cursor: 'pointer' }}
              onClick={onAddItem}
            >
              <CirclePlus color={"#004C60"} />
              <AvenirBlackH3
                label="Add"
                style={{ marginLeft: 5, color: "#004C60" }}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: 20 }}>
            <Title title={"Edit existing items"} />
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ padding: 20 }}>
              {comboItem.combo_selection_items.map((citem, index) => {
                return (
                  <Grid
                    container
                    direction="row"
                    key={index}
                    justify="space-between"
                    style={{ marginTop: 15 }}
                  >
                    <Grid item xs={6}>
                      <InputText
                        onChange={(e) =>
                          onChangeComboFields(
                            "item_name",
                            e.target.value,
                            index
                          )
                        }
                        disabled={true}
                        value={citem.item_name}
                        info={`Regular price $${ConvertPrice(citem.baseprice)}`}
                        placeholder=""
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputText
                        name="price"
                        id="price"
                        value={`${citem.price == "" ? "" : "$" + citem.price}`}
                        maxLength={9}//length with $
                        onChange={(e) =>
                          onChangeComboFields(
                            "price",
                            e.target.value.replace("$", "").replace(/[^0-9\.?]/g, "") || "",
                            index
                          )
                        }
                        inputPlaceholder="$0.00"
                        onBlur={() =>
                          onChangeComboFields(
                            "price",
                            ConvertPrice(citem.price),
                            index
                          )
                        }
                      // inputProps={{ step: 0.01, min: 0.00 }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        style={{ height: "100%" }}
                      >
                        <DeleteIcon onClick={() => deleteComboItem(index)} />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            position: "absolute",
            zIndex: 999,
            backgroundColor: "white",
            height: 60,
            backgroundColor: "white",
            borderTop: "1px solid #EEE",
            justifyContent: "flex-end",
            bottom: 0,
            left: 0,
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <Grid item xs={12} md={6}>
            <Grid container justify="flex-end">
              <Button style={{ border: "2px solid #707272" }} onClick={onClose}>
                <AvenirBlackH4 label="Cancel" />
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#FFB600", marginLeft: 20, marginRight: 20 }}
                onClick={onSaveCombo}
              >
                <AvenirBlackH4 label="Save" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

export default EditComboSelection;
