import React, { useState, useCallback, useEffect, useContext } from 'react';
import SimpleTable from '../../../components/SimpleTable';
import { Grid, Button, MenuItem, Menu } from '@material-ui/core';
import { fetchdaywiseSales } from '../../../redux/actions';
import _ from 'lodash';
import Daterange from '../../../components/DateRange';
import { AvenirBlackH4 } from '../../../utils/text';
import MultiSelect from '../../../components/MultiSelect';
import MyContext from '../../../Provider/MyContext';
import CircleClose from '../../../components/SVG/CircleClose';
import DownlodeIcon from '../../../components/SVG/DownlodeIcon';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ConvertPrice } from '../../../utils/util';
import { downloadDailySales } from '../../../utils/excel';
import EmployeeDropDown from '../../../components/EmployeeDropDown';
import { downloadPdfDocument } from '../../../components/PDFTableGenerator';

const DaywiseSales = () => {
  const dispatch = useDispatch();
  const [table1, setTable1] = useState([]);
  const daywiseSales = useSelector(
    (state) => state.salesReportReducer.daywiseSales
  );
  const Context = useContext(MyContext);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const employeeData = useSelector(
    (state) => state.profileReducers.employeesList.data
  );
  const [date, setDate] = useState({ startDate: moment().startOf('day').format("YYYY-MM-DD"), endDate: moment().endOf('day').format("YYYY-MM-DD") });
  const [ordertype, setOrderType] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [resetDate, toggleResetDate] = useState(false);
  const [salesColumns, setSalesColumns] = useState([
    { field: 'date', display: Context.langData.date, size: '12%' },
    {
      field: 'total_price',
      display: Context.langData.subtotal,
      size: '12.5%',
      isNumber: true,
    },
    {
      field: 'total_discount',
      display: Context.langData.discount_promotions,
      size: '23.5%',
      isNumber: true,
    },
    {
      field: 'delivery_charges',
      display: Context.langData.delivery,
      size: '13%',
      isNumber: true,
      tooltip: Context.langData.delivery,
    },
    {
      field: 'total_tax',
      display: Context.langData.taxes,
      size: '12%',
      isNumber: true,
    },
    {
      field: 'final_price',
      display: Context.langData.total,
      size: '10%',
      isNumber: true,
    },
    {
      field: 'seats',
      display: Context.langData.num,
      size: '13%',
      tooltip: Context.langData.num_of_cus,
    },
    {
      field: 'avg_bill',
      display: Context.langData.avg_bill,
      size: '13%',
      isNumber: true,
    },
  ]);
  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    if (daywiseSales?.length > 0) {
      daywiseSales.map((item) => {
        item['avg'] = parseFloat(item.avg_bill);
        item['avg_bill'] = ConvertPrice(item.avg_bill);
      });
      setTable1(daywiseSales);
    } else {
      setTable1([]);
    }
  }, [daywiseSales]);
  useEffect(() => {
    handleApplyDropdown();
  }, [date, ordertype, empData]);
  const handleApplyDropdown = () => {
    let obj = {
      ...date,
      from_date: date.startDate,
      to_date: date.endDate
    };
    delete obj.startDate;
    delete obj.endDate;
    delete obj.start_date;
    delete obj.end_date;
    // delete obj.start;
    // delete obj.end;
    if (ordertype || empData.length > 0) {
      obj = {
        ...obj,
        order_types: ordertype.join(','),
        employee_id: empData.join(','),
      };
    }
    dispatch(fetchdaywiseSales(obj));
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...salesColumns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setSalesColumns(columnData);
    if (value == 'avg_bill') value = 'avg';
    var sortDaywiseSales = _.orderBy(
      table1,
      [value],
      [sortValue ? 'desc' : 'asc']
    );
    setTable1(sortDaywiseSales);
  };
  const downloadExcel = () => {
    downloadDailySales(table1);
    handleCloseMenu();
  };

  const downloadPDF = () => {
    const fileName = 'DailySales';
    downloadPdfDocument(
      salesColumns,
      table1,
      Context.langData.day_wise_sales,
      date,
      fileName
    );
    handleCloseMenu();
  };

  const onClear = () => {
    setOrderType([]);
    setEmpData([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  return (
    <Grid
      data-qaid="daywiseSales_mainContainer"
      container
      style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Grid data-qaid="daywiseSales_subContainer" container>
        <Grid
          data-qaid="daywiseSales_dateContainer"
          item
          lg={9}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Grid container>
            <Grid
              data-qaid="daywiseSales_dateGrid"
              item
              style={{ marginLeft: 10 }}>
              <AvenirBlackH4
                data-qaid="daywiseSales_dateLabel"
                label={Context.langData.from_to}
              />
              <Daterange
                data-qaid="daywiseSales_dateRange"
                onDateChange={onDateChange}
                refresh={resetDate}
              />
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <AvenirBlackH4
                data-qaid="daywiseSales_orderTypeLabel"
                label={Context.langData.order_types}
              />
              <MultiSelect
                list={ordertypes}
                value="otype_id"
                name="otype_name"
                placeholder="All order types"
                selectedValue={ordertype}
                handleChange={(e) => setOrderType(e)}
                border={false}
                height={56}
                width={160}
              />
            </Grid>

            <EmployeeDropDown
              selectedValue={empData}
              handleChange={setEmpData}
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid="daywiseSales_clearContainer"
          item
          lg={3}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}>
          <Grid
            data-qaid="daywiseSales_clearGrid"
            onClick={onClear}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 20,
              alignItems: 'center',
              cursor: 'pointer',
              paddingLeft: 10,
            }}>
            <CircleClose data-qaid="daywiseSales_closeIcon"></CircleClose>
            <AvenirBlackH4
              data-qaid="daywiseSales_clearLabel"
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid="daywiseSales_downloadButtonView"
            style={{
              backgroundColor: '#FFB600',
              height: 40,
              marginRight: 0,
              width: '149px',
            }}
            variant="contained"
            id="cancel">
            <DownlodeIcon
              data-qaid="daywiseSales_downlodeIcon"
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid="daywiseSales_downloadLabel"
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id="simple-menu-download"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        data-qaid="daywiseSales_simpleTableGrid"
        style={{ marginTop: 40 }}>
        <SimpleTable
          data-qaid="daywiseSales_simpleTable"
          columns={salesColumns}
          rows={table1}
          isEditArrow={false}
          isOnClick={false}
          sortingOnClick={sortingOnClick}
          displayFontSize="14px"
        />
      </Grid>
    </Grid>
  );
};

export default DaywiseSales;
