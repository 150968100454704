import React, { useCallback, useEffect, useState, useContext } from "react";
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  Typography,
} from "@material-ui/core";
import TopBar from "../../layouts/DashboardLayout/TopBar";
import Page from "../../components/Page";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { fetchBranchSales, refresh } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import Currency from "../../components/Currency";
import clsx from "clsx";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import MyContext from "../../Provider/MyContext";
import moment from "moment-timezone";
import Message from "../../components/Message";
import { DotTextBoldNormal } from "../../utils/text";
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    minWidth: "25%",
    width: "25%",
    cursor: "pointer",
  },
  listspan: {
    paddingBottom: 5,
  },
  sales: {
    color: "#f2957a",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Branches = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [restSales, setRestSales] = useState([]);
  const restaurants = useSelector(
    (state) => state.loginReducer.loginDetails.restaurants
  );
  const refresh_token = useSelector(
    (state) => state.loginReducer.loginDetails.refresh_token
  );
  const branchSales = useSelector((state) => state.loginReducer.branchSales);
  const navigate = useNavigate();
  const Context = useContext(MyContext);
  const ldClient = useLDClient();
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const onDateChange = useCallback((dates) => {
    dispatch(fetchBranchSales(dates));
  }, []);
  useEffect(() => {
    dispatch({ type: "CLEAR_PROFILE_DATA" });
    dispatch(
      fetchBranchSales({
        start_date: moment().startOf("day").unix(),
        end_date: moment().endOf("day").unix(),
      })
    );
  }, []);
  useEffect(() => {
    console.log(branchSales, "branchSalesbranchSales");
    const salesData = restaurants.map((rest) => {
      const data = _.find(branchSales, function (o) {
        return o.rest_id == rest.rest_id;
      });
      const sales = typeof data !== "undefined" ? data.total_sales : 0;
      console.log(
        "sales data===>sales",
        sales,
        "datadatadatadata====>data",
        data
      );
      return {
        rest_id: rest.rest_id,
        name: rest.name,
        landmark: rest.landmark,
        status: rest.status,
        sales,
      };
    });
    setRestSales(salesData);
  }, [restaurants, branchSales]);
  const setBranch = (restObj) => {
    console.log("restObjrestObjrestObj", restObj);
    if (restObj.status == 1) {
      const branch = _.find(
        restaurants,
        (rest) => rest.rest_id === restObj.rest_id
      );
      console.log(
        "branch name",
        branch,
        "branch.tzbranch.tzbranch.tzbranch.tzbranch.tz",
        branch.tz
      );
      if (branch.tz) {
        moment.tz.setDefault(branch.tz);
      }
      dispatch({ type: "SET_BRANCH", payload: branch });
      // TODO: revert back to the dispatch
      dispatch(refresh({ refresh_token, rest_id: restObj.rest_id }, callback(restObj.rest_id )))
    } else {
      setTimeout(() => {
        let d = {
          open: true,
          severity: "error",
          msg: Context.langData.access_denied_error,
        };
        setAlertData(d);
        setTimeout(() => {
          setAlertData({ open: false, severity: "success", msg: "" });
        }, 3000);
      }, 2000);
    }
  };
  const callback = (rest_id) => {

    var context = {
      "kind": "restaurant",
      "key": "restaurant",
      "restId": rest_id
    }
    ldClient.identify(context, null, () => {
      console.log("New context's flags available");
    });
    navigate("/home/dashboard");
  };

  const vertical = "top";
  const horizontal = "center";
  return (
    <Page
      data-qaid="brancehs_page"
      className={classes.root}
      title={Context.langData.branches}
    >
      <Container data-qaid="brancehs_container" maxWidth={false}>
        <TopBar
          data-qaid="brancehs_topbar"
          onMobileNavOpen={() => setMobileNavOpen(true)}
        />
        <div data-qaid="brancehs_div" className={classes.root}>
          <div data-qaid="brancehs_paper" style={{ marginTop: 65 }}>
            {/* <Grid data-qaid="brancehs_date_grid" container spacing={3} >
              <Grid item lg={6} sm={6} xl={6} xs={12} justify="flex-start" style={{ textAlign: 'initial' }}>
                <Daterange data-qaid="brancehs_datrange" onDateChange={onDateChange} />
              </Grid>
            </Grid> */}
          </div>
          <Grid data-qaid="brancehs_body_grid" container spacing={3}>
            {restSales.map((rest) => (
              <Grid
                data-qaid="brancehs_resr_grid"
                item
                key={rest.rest_id}
                className={classes.gridItem}
                onClick={() => setBranch(rest)}
              >
                <Paper
                  data-qaid="brancehs_resr_paper"
                  className={classes.paper}
                >
                  <Grid
                    data-qaid="brancehs_resr_name"
                    container
                    direction="row"
                    justify="center"
                    style={{ width: "90%" }}
                    className={classes.listspan}
                  >
                    <DotTextBoldNormal
                      label={rest.name}
                      style={{ fontSize: 24 }}
                    />
                  </Grid>

                  <Grid
                    data-qaid="brancehs_resr_landmark"
                    container
                    direction="row"
                    justify="center"
                    className={classes.listspan}
                  >
                    <LocationOnIcon data-qaid="brancehs_resr_location_icon" />
                    <Typography data-qaid="brancehs_resr_lmlabel" variant="h6">
                      {rest.landmark}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    data-qaid="brancehs_resr_sales"
                    className={clsx(classes.listspan, classes.sales)}
                  >
                    <Currency
                      data-qaid="brancehs_resr_currency"
                      variant="h4"
                      type="dollor"
                    />
                    <Typography
                      data-qaid="brancehs_resr_sales_label"
                      variant="h4"
                    >
                      {rest.sales}
                    </Typography>
                  </Grid>
                  <Grid
                    data-qaid="brancehs_resr_total_sales"
                    container
                    direction="row"
                    justify="center"
                    className={classes.listspan}
                  >
                    <Typography
                      data-qaid="brancehs_resr_totalsales_label"
                      variant="h5"
                    >
                      {Context.langData.total_sale}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
      <Message
        data-qaid="loginView_formik_message"
        open={alertData.open}
        severity={alertData.severity}
        msg={alertData.msg}
      />
    </Page>
  );
};

export default Branches;
